var data = [
    {"id":"ACC 1100","title":"Survey of Accounting","description":"Survey of financial accounting for non-accounting majors. Accounting concepts, financial statements, internal control, cash, and payroll."},
    {"id":"ACC 1210","title":"Introduction to Financial Accounting","description":"An introduction to preparation and use of accounting reports for business entities; focus on uses of accounting for external reporting, emphasizing accounting as a provider of financial information. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"ACC 1220","title":"Introduction to Managerial Accounting","description":"An introduction to the use of accounting information by managers. Topics include the use of accounting information for planning and control, performance evaluation, decision-making and the statement of cash flows, along with financial statement analysis. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"ACC 1510","title":"Computerized Accounting Systems","description":"This course uses general ledger software and spreadsheets to record, report and analyze accounting information."},
    {"id":"ACC 2101","title":"Intermediate Accounting I","description":"Development of accounting standards, conceptual framework of financial accounting. Review of the accounting cycle, preparation of income statement, statement of owner&apos;s equity, balance sheet, statement of cash flows and additional reporting issues. Time value of money, current asset and current liability accounting and reporting. It is strongly recommended that you complete ACC 1510 prior to registering for ACC 2101. However ACC 1510 may be taken concurrently."},
    {"id":"ACC 2102","title":"Intermediate Accounting II","description":"Transaction analysis and financial reporting for long-term assets, long-term liabilities and stockholder's equity."},
    {"id":"ACC 2211","title":"Cost Accounting","description":"Application of cost accounting concepts and techniques to complex problems in manufacturing accounting and service firms.  It is strongly recommended that you complete ACC 1510 prior to registering for ACC 2211. However ACC 1510 may be taken concurrently."},
    {"id":"ACC 2212","title":"Managerial Accounting & Finance","description":"This course uses accounting and finance concepts to effectively analyze and manage business finances."},
    {"id":"ACC 2270","title":"Accounting Internship","description":"Students will secure a work site in the field of accounting and prepare and complete appropriate accounting learning outcomes for the work site. The accounting learning outcomes must be approved by the work site supervisor and the chairperson of the Accounting Instruction Department or full-time accounting faculty/work site coordinator."},
    {"id":"ACC 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format."},
    {"id":"ACC 2321","title":"Federal Taxation","description":"Introductory course including the basic tax model, personal gross income, personal deductions and credits, property transactions, special tax computations and introduction to corporate taxation."},
    {"id":"ACC 2322","title":"Advanced Taxation","description":"Course covers tax research, federal, state and local business income tax returns and preparation of information returns, tax filings for trusts, estates and nonprofit organizations, and the reporting of advanced tax compliance issues for individual and business tax returns."},
    {"id":"ACC 2435","title":"Auditing","description":"Review of accounting information systems and an overview of auditing. Covers internal controls and system documentation, transaction processing and databases, professional standard and ethics. Review of legal liability, audit evidence, risk evaluation and audit planning, audit procedures and audit reports."},
    {"id":"ACC 2510","title":"Advanced Accounting","description":"Review of different accounting areas, including investment accounting, consolidation accounting, governmental accounting and partnership accounting. Review of International Accounting Financial Standards."},
    {"id":"AFR 1100","title":"African-American Studies","description":"Social science introduction to the origins, relevance and scope of African American Studies. Topics include African American history, religion, sociology, politics, economics and psychology within a multicultural context."},
    {"id":"AFR 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"AGR 1160","title":"Introduction to Agriculture Science","description":"This class is an overview of Agriculture Industry.  Guest lecture presentations, field trips, career research, and industry personnel interviews provide students with real-world examples of the skills and abilities necessary to compete in the world of Agriculture Science."},
    {"id":"AGR 1200","title":"Agricultural Economics","description":"Introductory course on the basic principles of agricultural economics. Principles of supply and demand, resource economics, production optimization, price elasticity, market price determination, competitive versus noncompetitive market models, and agricultural public policy. These principles are applied to agriculture and the role of agriculture in the United States and world economies."},
    {"id":"AGR 1300","title":"Agronomy","description":"Crop growth and development, pesticide safety and application, properties of the soil, and conservation practices of Ohio’s row crops are all covered in detail in this engaging curriculum dealing with the form and function of the crops that shape agriculture in Ohio, and the practices that we as agriculturalists take daily to keep them healthy and pertinent in society."},
    {"id":"AGR 1400","title":"Agriculture Internship","description":"Agricultural Internship provides an opportunity for students to apply concepts learned in the Agriculture A.A.S. pathway to learning in a professional setting.  Twenty-one practicum hours per week."},
    {"id":"ALH 1001","title":"Introduction to Simulation in Healthcare","description":"This course introduces students to the history of simulation, concepts in healthcare as applied to simulation interprofessional education, and trends in simulation.  Students will be introduced to simulation equipment, healthcare equipment, supplies and environments."},
    {"id":"ALH 1101","title":"Introduction to Healthcare Delivery","description":"Orientation to the health care delivery system, including history, economics, medical/legal issues, professionalism, ethics, sociological aspects and wellness concepts. Orientation to the use of technology in the health care system will also be provided, including user interfaces, telecommunications and networks. The development of health care team skills, including critical thinking and problem-solving strategies and multicultural health care perspectives, will be presented. One classroom, three lab hours per week."},
    {"id":"ALH 1102","title":"Basic Healthcare Practices & Medical Scribe","description":"Orientation to safe and effective basic health care practice including medical scribe, community health worker, patient assessment and documentation, infection control, body mechanics, and environmental safety considerations. Two classroom, two lab hours per week."},
    {"id":"ALH 1103","title":"Test Taking Strategies","description":"This course provides strategies and techniques to maximize individual test performance through prioritized learning and focused study time for the Health Sciences programs. Test-taking strategies and techniques are presented using simulated testing situations. Techniques learned will help improve thinking and discrimination skills to enhance test performance."},
    {"id":"ALH 1105","title":"Overview of Holistic Health","description":"Holistic models of health and wellness; value of integrating holistic models in traditional healthcare settings; overview of specific holistic health models such as aromatherapy, reflexology, guided imagery, supplements, and neuro linguistic programming; efficacy data; indications and contraindications."},
    {"id":"ALH 1107","title":"Core Concepts of Public Health","description":"The course will introduce core concepts of public health, including analytical methods, biomedical basis of public health, social and behavioral factors in health, environmental issues, medical care and public health and the future of public health."},
    {"id":"ALH 1110","title":"Principles of Electrocardiography","description":"Principles of electrocardiography, including equipment operation, recording and troubleshooting, as well as fundamental principles of the cardiovascular physiology and basic ECG interpretation. Students will also gain knowledge and skills to provide CPR for victims of all ages and will practice CPR in a team setting.  Students will receive an American Heart Association Basic Life Support for Healthcare Provider card upon successful completion of skills. One classroom, three lab hours per week. A simulated clinical rotation will be completed during the course."},
    {"id":"ALH 1113","title":"Clinical Phlebotomy","description":"Introduction to the fundamental and clinical methods and practices of phlebotomy, including basic hematology, venipuncture and microcollection techniques, along with routine processing and special testing procedures. One classroom, three lab hours per week."},
    {"id":"ALH 1114","title":"Clinical Phlebotomy Practice","description":"Introduction to the phlebotomy clinical setting involving structured observation and participation in the blood collection aspects of patient care under the supervision of a phlebotomist; performing venipunctures and microcollection techniques on adult and pediatric patients. One hour seminar on main campus and 105 hours un-paid practicum per term."},
    {"id":"ALH 1120","title":"Nurse Aide Training","description":"Provide education to individuals in the basic skills necessary to provide personal care services and activities, under the delegation and supervision of a registered or licensed practical nurse, to residents in a long-term care facility. Three classroom, three clinical lab hours per week."},
    {"id":"ALH 1121","title":"Acute Care Nurse Aide","description":"Acute Care Nurse Aide will provide a supplement to nurse aide training and provide the student with the knowledge and skills required for care of the patient within an acute care facility as a Patient Care Technician. One classroom, two lab, three clinical hours per week."},
    {"id":"ALH 1122","title":"Pharmacy Technician I","description":"This course is the first of two core courses in the Pharmacy Technician Program and will  help prepare students for the Pharmacy Technician Certification Board Exam. Course content includes the duties and responsibilities of the pharmacy technician, as well as the mathematical components and the pharmacology foundation necessary to become a successful pharmacy technician. Scope of pharmacy practice including handling of infectious and hazardous waste, interpersonal skills and beginning pharmacology and dose calculations."},
    {"id":"ALH 1123","title":"Pharmacy Technician II","description":"This course is the second of two core courses in the Pharmacy Technician Program and will continue to help prepare students for the Pharmacy Technician Certification Board Exam. Course content will continue to include the duties and responsibilities of the pharmacy technician, as well as the mathematical components and the pharmacology foundations necessary to become a successful pharmacy technician. Additional skills learned will include sterile compounding, non-sterile compounding and inventory control. Course contains a simulated directed practice."},
    {"id":"ALH 1124","title":"Pharmacy Technician Directed Practice","description":"This course will provide the students with real world experience in a pharmacy (i.e. hospital and retail pharmacy).  Students will be provided with a valuable learning experience and potential contacts and/or references for employment.  This course will also provide the student with additional review for the Pharmacy Technician Certification Board Exam.  Students will complete 210 hours of non-paid, supervised directed practice in a hospital and retail pharmacy. Background checks will be required prior to attending the directed practice."},
    {"id":"ALH 1130","title":"Basic Life Support Training for Healthcare Provider","description":"The American Heart Association (AHA) Basic Life Support for Healthcare Providers (BLS-HCP) is designed to train participants to save lives of victims in cardiac arrest through high-quality cardiopulmonary resuscitation (CPR). This course prepares healthcare professionals (those currently working in a healthcare setting or potential for working in a healthcare setting) to know how to perform CPR in both in- and out-of-hospital settings. The course includes adult, child, and infant rescue techniques, administration of the AED, and  first aid. Those who successfully complete the course will receive an AHA Heartsaver First Aid Provider Card and AHA BLS for Healthcare Provider card, valid for two years."},
    {"id":"ALH 1132","title":"Heartsaver First Aid, CPR & AED","description":"The American Heart Association (AHA) Heartsaver First Aid with Cardiopulmonary Resuscitation (CPR) and Automated or Automatic External Defibrillator (AED) course is designed for the individuals who are not working or planning to work within a healthcare environment. The course provides students with training in basic first aid procedures, including the first aid skills recommended by OSHA, CPR and AED. Students who complete the course qualify for the AHA Heartsaver First Aid with CPR and AED course completion card."},
    {"id":"ALH 1140","title":"Fundamentals of Disease Processes","description":"Pathological changes associated with the most commonly occurring diseases of each body system. Correlates changes with patient's response, diagnostic studies, and treatment modalities."},
    {"id":"ALH 1155","title":"MEPAP I - Activity Assistant","description":"This course is a Sinclair provided Activity Assistant Certificate based on NCCAP’s MEPAP I program core competencies for activity practice settings. The course includes colloquy, agency regulation and support, advocacy, resident assessment, care planning, and therapeutic programming. After successful completion of the course the student will receive a certificate of completion for NCCAP’s MEPAP I program by a preapproved instructor of NCCAP. Three classroom, three clinical hours per week."},
    {"id":"ALH 1183","title":"Pharmacy Technician Lab","description":"This is a laboratory course designed to provide pharmacy technician students with simulations to practice a variety of pharmacy technician&apos;s activities and responsibilities, such as product preparation, sterile compounding, non-sterile compounding, inputting prescriptions, calculations, pharmacy tools and equipment. Six lab hours per week."},
    {"id":"ALH 1250","title":"Health Science Practicum","description":"Enhances practicum skills in health concepts and resources related to the role and responsibility of Healthcare professional. Emphasis on working in community-based settings, publicly and privately funded health and social services. 210 unpaid practicum hours are required. background check, health certificate, immunizations, and student health insurance will be required to complete the practicum portion of this course."},
    {"id":"ALH 1252","title":"Medical Scribe Practice","description":"This course provides the students an opportunity to gain practical experience in a real medical setting.  The course is designed to facilitate the development of the skill set used in becoming a medical scribe in today&apos;s electronic medical world.  Additionally, students will gain valuable insight into today&apos;s medical world, along with a new understanding of modern healthcare that is directly applicable to a future career as a healthcare provider. One classroom, two lab hours per week."},
    {"id":"ALH 1256","title":"MEPAP II- Activity Director","description":"This course is a Sinclair provided Activity Programming Certificate based on NCCAP's MEPAP II program core competencies for activity practice settings. The course includes the practices and principles of managing and leading an activity department and the collaboration needed for interaction with other departments and agencies. After successful completion of the course the student will also receive a certificate of completion for NCCAP's MEPAP II course by a preapproved instructor of NCCAP. Three classroom, three clinical hours per week."},
    {"id":"ALH 2101","title":"Simulation Technology Basic Repair","description":"Introduction to basic repairs in a healthcare simulation environment.  Students learn the principles and guidelines for troubleshooting issues and maintenance of the equipment used in healthcare simulation."},
    {"id":"ALH 2131","title":"Lab for Simulation Technology Basic Repair","description":"Introduction to basic repairs in a healthcare simulation environment. Students learn to use tools to apply the principles and guidelines for troubleshooting issues and maintenance of the equipment used in healthcare simulation. Two lab hours per week."},
    {"id":"ALH 2201","title":"Survey of Drug Therapy","description":"Overview of the conventional drug classes presenting only the more commonly prescribed preparations primarily emphasizing common effects and indications for use."},
    {"id":"ALH 2202","title":"General Pharmacology","description":"General principles of drug absorption, distribution, metabolism, actions and effects presented according to conventional drug classification with emphasis on the prototype of each class; for registered nursing and medical assistant technology students but may be of interest to other allied health students or general studies students majoring in biological sciences. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"ALH 2220","title":"Pathophysiology","description":"Study of human disease using a system approach emphasizing abnormal physiological processes that result in the signs and symptoms of each disorder. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"ALH 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"ALH 2901","title":"Simulation Technology Seminar","description":"The Simulation Technology Seminar will provide an assessment of the student’s knowledge, experience and skills as Simulation Technologist. Preparation and presentation of qualifications through written resume and portfolio. Guidelines and preparation for employment interview. Investigation into simulation issues."},
    {"id":"ALH 2996","title":"Simulation Technology Practice","description":"The Simulation Technology Practice course will provided practical training within the healthcare simulation environment under the direction of an approved simulation supervisor. Fourteen practicum hours per week."},
    {"id":"ART 1101","title":"2-D Foundations","description":"The study of composition/visual elements with color theory applied to utilizing design principles (using line, shape, texture, value and color) and color psychology emphasizing the Josef Albers color theories in a studio setting with emphasis on hands-on learning.  Six studio hours per week."},
    {"id":"ART 1102","title":"3-D Foundations","description":"Basic foundation studio course dealing with methods, materials, principles of organization and elements of design applied to the third dimension. Six studio hours per week."},
    {"id":"ART 1107","title":"Beginning Photoshop","description":"An introductory course in the Photoshop imaging program. Basic introduction to scanning, capturing and altering images for the art major or non-art major."},
    {"id":"ART 1110","title":"Art Appreciation - Introduction to Art & Art Media","description":"Emphasis on the language of art, exposure to many different art forms, formulative ideas about what is viewed and exploration of specific media."},
    {"id":"ART 1111","title":"Drawing I","description":"Studio drawing develops visual skills relative to the drawing process, with emphasis on traditional as well as contemporary problems on representation and composition. Six studio hours per week.  It is strongly recommended that you complete ART 1101 prior to registering for ART 1111.  However ART 1101 may be taken concurrently."},
    {"id":"ART 1112","title":"Drawing II","description":"Personal expression developed through a variety of two-dimensional media, cubistic techniques, gestural and figure studies. Six studio hours per week."},
    {"id":"ART 1121","title":"Beginning Painting I","description":"Studio painting with an emphasis on color, form and space in compositional design. Introduction to personal expression and modern applications. Six studio hours per week."},
    {"id":"ART 1122","title":"Beginning Painting II","description":"Personal expression with instruction in 20th-century techniques and concepts. Complex problems in color and composition. Six studio hours per week."},
    {"id":"ART 1131","title":"Introduction to Sculpture","description":"Introduces basic principles of sculpture and expands personal definitions and interpretations of contemporary three-dimensional art. Introduces methods of sculpture with clay, wood, plaster and other materials for constructing three-dimensional art work.  Six studio hours per week."},
    {"id":"ART 1132","title":"Intermediate Sculpture","description":"Develop complex visual principles of sculpture and develop a personal expression of individual style in interpretations of contemporary three-dimensional art. Six studio hours per week."},
    {"id":"ART 1133","title":"Figurative Sculpture","description":"Study of the human anatomy of the head and figure as applied to three-dimensional form. Six studio hours per week."},
    {"id":"ART 1141","title":"Introduction to Ceramics","description":"Materials and processes of ceramic art for the beginning student; handbuilding and wheel-throwing and glazing demonstrated through a variety of functional and sculptural projects. Six studio hours per week."},
    {"id":"ART 1142","title":"Intermediate Ceramics","description":"Materials and processes of ceramic art for the intermediate student; intermediate skills of wheel throwing and glazing are demonstrated through a variety of functional and sculptural projects. Six studio hours per week."},
    {"id":"ART 1161","title":"Black & White Darkroom Photography I","description":"An introduction to the art and technique of black and white photography. Photographic shooting, processing and printing are stressed. Students to supply their own adjustable camera, (35mm or 120) film and print paper. Six studio hours per week."},
    {"id":"ART 1162","title":"Black & White Darkroom Photography II","description":"Intermediate course in black and white photography. Further introduction and application of the tools/techniques of photographic art with emphasis on artistic portfolio development. Students shoot a minimum of 15 rolls of film to satisfy the portfolio project. Film and correct contact sheets must be included in the working portfolio. Six studio hours per week."},
    {"id":"ART 1170","title":"Non-Silver Photography","description":"Principles and theories of nonsilver chemical processes used for print production including gum, cyanotype, Van Dyke Brown, palladium, salt printing and wet-plate collodion process. Six studio hours per week."},
    {"id":"ART 1171","title":"Studio Photography","description":"Mechanics and aesthetics of photography in a studio environment covering a range of subjects and emphasizing lighting techniques and equipment as well as use of all camera formats and digital media. Six studio hours per week."},
    {"id":"ART 1175","title":"Computer Photography","description":"Techniques for transforming photographic images through use of computers and digital cameras. Use of a computer to create fine art digital images. Advanced PhotoShop techniques including layers, color correction, masking and special effects. Students will be challenged to address learning outcomes in their work to demonstrate creative process and critical thinking."},
    {"id":"ART 2111","title":"Intermediate Drawing I","description":"Definition of a personal expression through the drawing process; traditional and modern approaches to drawing the figure, still life, and other contemporary subjects. Six studio hours per week."},
    {"id":"ART 2112","title":"Intermediate Drawing II","description":"Emphasis on the technical process and the language of drawing; a variety of media and techniques focusing on personal expression. Six studio hours per week."},
    {"id":"ART 2141","title":"Advanced Ceramics","description":"Materials and processes of ceramic art for the advanced student; advanced skills of wheel throwing and glazing are demonstrated through a variety of functional and sculptural projects. Six studio hours per week."},
    {"id":"ART 2216","title":"Life Drawing & Anatomy I","description":"Figure drawing with a foundation in anatomical study.  Emphasis on proportion as well as design with an application towards mood and expression. Six studio hours per week."},
    {"id":"ART 2217","title":"Life Drawing & Anatomy II","description":"Advanced figure drawing with a foundation in anatomical study.  Development of mood and content through form and topic. Six studio hours per week."},
    {"id":"ART 2221","title":"Intermediate Painting-Observation & Concept","description":"Art as a means of communication, through content and expression. Incorporation of contemporary concepts and styles of art. Six studio hours per week."},
    {"id":"ART 2222","title":"Intermediate Painting - The Figure","description":"The figure as the subject of study and how it has been used academically and expressively. Six studio hours per week."},
    {"id":"ART 2230","title":"Art History: Ancient through Medieval Periods","description":"Art history from early cave paintings through the Medieval period of Western civilization."},
    {"id":"ART 2231","title":"Art History: Renaissance through Contemporary Periods","description":"Art history from the early Italian Renaissance through the contemporary period."},
    {"id":"ART 2235","title":"History of Photography","description":"Historical survey of photography as an art form from its beginnings in the 1830's until the present day; developments in photographic processes, artistic trends, and study of major photographic artists."},
    {"id":"ART 2236","title":"History of Women Artists","description":"A history of women artists from the Middle Ages to the present day, with emphasis on the history of style, and on women's historical roles."},
    {"id":"ART 2237","title":"History of American Art","description":"Art history of the United States from the pre-colonial to contemporary periods."},
    {"id":"ART 2238","title":"History of African Art","description":"History of African art from ancient to contemporary periods."},
    {"id":"ART 2265","title":"Digital Color Photography I","description":"An introduction to the technique of digital color photography, printing techniques, the presentation of digital prints and various camera and computer techniques will be employed to enhance the print. Student will supply own digital Single-lens Reflex (SLR) camera and pigment-based photographic printer. Six studio hours per week."},
    {"id":"ART 2266","title":"Digital Color Photography II","description":"An advanced digital color photographic course focusing on creative computer and camera techniques. Student to supply own Single-lens Reflex (SLR) digital camera and pigment-based printer. Six studio hours per week."},
    {"id":"ART 2269","title":"Printmaking I","description":"Examines the philosophy, history and techniques of multiple image preparation including woodcut and intaglio processes. Six studio hours per week."},
    {"id":"ART 2270","title":"Fine Art Internship","description":"Practicum providing student with experience in organizing and hanging art exhibits, assisting in studios or working in arts administration. Seven practicum hours per week."},
    {"id":"ART 2279","title":"Printmaking II","description":"Exploration of color printing in relief, intaglio, lithographic, and monotype processes. Six studio hours per week."},
    {"id":"ART 2280","title":"Intermediate Printmaking I","description":"Examines an advanced use of combined processes, mixed-media, and interdisciplinary approaches to image-making in printmaking. Six studio hours per week."},
    {"id":"ART 2281","title":"Intermediate Printmaking II","description":"Focused development of individualized concepts and independent expression in printmaking with intensive concentration on selected processes. Six studio hours per week."},
    {"id":"ART 2285","title":"Printmaking - Monotype","description":"Variety of image-making techniques to explore monotype printing methods in black-and-white and color. Six studio hours per week."},
    {"id":"ART 2294","title":"Photography Portfolio Development","description":"One-on-one instruction regarding the student's final graduating photography portfolio. Instructor will meet with the student during final term of study to help the student compose his or her final graduating portfolio. Student may repeat course up to three times to achieve a passing grade. Failure to satisfactorily complete this course will make the student ineligible for the Photographic Technology Short-Term Certificate."},
    {"id":"ART 2295","title":"Graduation Portfolio Development & Exhibition","description":"The student will write an artist statement and an art-related resume, attend lectures and demonstrations on professional presentation of artwork, take quality promotional images of his/her artwork and select a portfolio of his/her original artwork for graduation exhibition."},
    {"id":"ART 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"ART 6121","title":"Non-Credit Painting","description":"Non-credit studio painting with an emphasis on color, form and space in compositional design. Introduction to personal expression and modern applications."},
    {"id":"ART 6141","title":"Non-Credit Ceramics","description":"Non-credit materials and processes of ceramic art for the beginning student; handbuilding and wheel-throwing and glazing demonstrated through a variety of functional and sculptural projects."},
    {"id":"ASL 1101","title":"Orientation to Deafness","description":"Study the culture of the American Deaf community. Issues raised include the relationship between language and culture, the history of deaf education, the Deaf President Now revolution and the collective goals and values of the Deaf community. Gain an understanding of the effects of hearing loss by way of basic audiology, speech pathology and the anatomy of the ear. We will also address access to services for the Deaf, hard-of-hearing, and Deaf-Blind consumers."},
    {"id":"ASL 1102","title":"Interpreting Theory & Best Practices","description":"An in-depth examination of the multiple facets that constitute effective interpreting. These include communication theory, cognitive processing skills, cultural adjustments, contextual and situational factors, expansion techniques, controlling legislation and ethics and best practices. Various interpreting settings are examined, with special emphasis on educational interpreting, interpreter licensure and the national interpreting evaluation process."},
    {"id":"ASL 1111","title":"Beginning American Sign Language I","description":"This course provides a foundation for non-signers to study American Sign Language (ASL) and learn about deaf culture. It includes principles, methods and techniques for communicating with deaf individuals who sign. Focusing on development of receptive and expressive sign skills, manual alphabet, numbers, sign vocabulary, syntax, grammar and culture."},
    {"id":"ASL 1112","title":"Beginning American Sign Language II","description":"Continue to study American Sign Language (ASL) grammatical structure, vocabulary, fingerspelling, use of signing space, conversational regulators and introductory aspects of deaf culture."},
    {"id":"ASL 1228","title":"Intermediate American Sign Language I","description":"Express abstract concepts in ASL using appropriate grammatical structure, signing space, vocabulary, fingerspelling and nonmanual markers. The course also features continued development of conversational regulators and aspects of deaf culture."},
    {"id":"ASL 1229","title":"Intermediate American Sign Language II","description":"Expands ability to express abstract concepts in American Sign Language (ASL), further develops vocabulary and receptive and expressive fluency. Read and discuss topics related to deaf culture. Deaf community interaction required."},
    {"id":"ASL 2201","title":"Interpreting I","description":"An introduction to the theories and models of interpreting, cognitive process techniques, interpreting logistics and strategies, as well as the code of professional conduct and all aspects of confidentiality."},
    {"id":"ASL 2202","title":"Interpreting II","description":"Students further develop and demonstrate mastery of advanced interpreting principles and techniques. Classroom activities include platform interpreting, team interpreting and application of the Registry of Interpreters for the Deaf (RID) Code of Professional Responsibility to a variety of interpreting situations. Two classroom, two lab hours per week."},
    {"id":"ASL 2203","title":"Interpreting III","description":"Students will demonstrate proficiency in both simultaneous and consecutive interpreting while executing the necessary techniques, principles, and models to effectively interpret between source and target languages. Two classroom, two lab hours per week."},
    {"id":"ASL 2207","title":"Role of the Interpreter","description":"This course will address how setting, register and preferred language mode of clients impacts the role of the interpreter. During weekly in-class role plays, students will employ interpreting techniques learned in other advanced interpreting courses as well as elements of the Demand-Control Schema."},
    {"id":"ASL 2212","title":"Specialized Interpreting I","description":"A study of interpreting in settings with specialized vocabulary such as medical, technical, and employment. Practice and performance of the vocabulary used in these settings is designed to increase student&apos;s comfort and skills for interpreting in these specialized settings.  One classroom, two lab hours per week."},
    {"id":"ASL 2213","title":"Specialized Interpreting II","description":"A study of interpreting in legal, mental health, sexuality, and substance abuse settings. The course will focus on the comprehension and sign production of the unique and specialized vocabulary used in these settings. Two classroom, two lab hours per week."},
    {"id":"ASL 2231","title":"Advanced American Sign Language I","description":"The first advanced course in the study of American Sign Language (ASL) is an intensive study of the linguistic structure of English and American Sign Language (ASL). Students explore the syntactic similarities and differences between the two languages and learn how to find functional equivalence between the two languages."},
    {"id":"ASL 2236","title":"Transliterating & Signing Modalities","description":"Course includes practice in Signing Exact English II (SEE II), transliterating and various signing modalities used in special settings or by various special populations such as deaf-blind or individual with other disabilities. Students will focus on transliterating signed/spoken English in educational and technical situations and develop specialized vocabulary in areas typically utilizing transliterators."},
    {"id":"ASL 2261","title":"Practicum I","description":"Students are required to complete 150 hours of socialization in the deaf community, interpreting and observation at an off-campus setting under the supervision of a mentor. Weekly seminar provides opportunities to synthesize on-site experiences with instructor and peers. Two classroom, seven practicum hours per week."},
    {"id":"ASL 2262","title":"Practicum  II","description":"Students are required to attend weekly seminar class and complete 150 hours of interpreting/observation and socialization at off-campus settings under the supervision of a mentor(s). Two classroom, seven practicum hours per week."},
    {"id":"ASL 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format."},
    {"id":"ASL 2300","title":"Educational Interpreting","description":"This course presents an overview of educational interpreting with a focus on the K-12 setting. Topics include: the role of the educational interpreter, deafness and other disabilities, the Individualized Educational Plan (IEP) process and the Ohio Department of Education’s (ODE) Educational Interpreter Guidelines and licensure process."},
    {"id":"AST 1111","title":"The Solar System","description":"Patterns and movements of celestial objects; history of astronomy; gravity, light, and matter; various types of telescopes; origins of the solar system; properties of planets and their moons; asteroids, comets, meteoroids and space exploration. Students must sign up for concurrent lab sections."},
    {"id":"AST 1112","title":"Stars, Galaxies & the Universe","description":"Properties and evolution of stars including the Sun; black holes and other stellar remnants; Milky Way and other galaxies; origin and fate of the universe. Students must sign up for concurrent lab sections."},
    {"id":"AST 1117","title":"Lab for the Solar System","description":"Laboratory and field activities to supplement The Solar System. Three lab hours per week."},
    {"id":"AST 1118","title":"Lab for Stars, Galaxies & the Universe","description":"Lab and field activities to supplement Stars, Galaxies & the Universe.  Three lab hours per week."},
    {"id":"AST 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format."},
    {"id":"AUT 1100","title":"Consumer Automotive","description":"Language of automotive systems and functions. Students will perform a series of activities related to vehicle maintenance such as oil change, chassis lubrication, safety inspections, ignition tune-up, cooling system testing, brake inspections and evaluation of a used vehicle. Basic hands tools are required. One classroom, three lab hours per week."},
    {"id":"AUT 1102","title":"Introduction to Automotive Service","description":"Work assignment practices that are necessary for beginning automotive service technicians. Hand tool usage, correcting wind noise and water leaks, oil changes, tire rotations and balancing, new vehicle prep, used car inspection. Eye protection and hand tools are required."},
    {"id":"AUT 1108","title":"Automotive Engine Systems","description":"Engine operation, nomenclature, measurements and tolerances, including service and overhaul procedures.   Cooling, lubrication and valve train systems are discussed.  Basic engine machining practices are covered.   Basic hand tools are required for the course."},
    {"id":"AUT 1111","title":"Automotive Service Consulting & Advising","description":"Introduction to service department as it pertains to a service consultant or service advisor at automotive repair facility. Skill development for intake of vehicle, preparing repair order, communicating with customer and selling of labor and parts."},
    {"id":"AUT 1114","title":"Automotive Electrical/Electronic Systems I","description":"Comprehension of Ohm’s law, basic electrical circuits, digital meter usage, batteries, starting and charging system operation. Diagnosis of wire harness repair procedures and service. One classroom, six lab hours per week."},
    {"id":"AUT 1115","title":"Automotive Engine Performance I","description":"Operation and service of fuel injection systems.  Testing and evaluation of emission controls, on-board diagnostic systems and engine condition.  Basic hand tools required.  Two classroom, six lab hours per week."},
    {"id":"AUT 1116","title":"Automotive Steering & Suspension Systems","description":"Steering system diagnosis and service including front and rear suspension components, wheel and tire and front and rear wheel alignment. Basic hand tools are required. One classroom, six lab hours per week."},
    {"id":"AUT 1142","title":"Automotive Manual Transmission & Driveline","description":"Theory and operation of clutch, manual transmission and transaxle, rear axle, limited slip differential, drive shaft, universal joint, four- wheel drive/all-wheel drive, diagnosis and repair. Basic hand tools required. One classroom, six lab hours per week."},
    {"id":"AUT 1146","title":"Automotive Heating Ventilation & Air Conditioning Systems","description":"Theory and operation of automotive heating and air-conditioning systems. Includes lab activity in diagnosis, service and repair procedures. Basic hand tools required. One classroom, six lab hours per week."},
    {"id":"AUT 1165","title":"Automotive Brake Systems","description":"Theory and operation of hydraulic braking systems, drum brake, disc brake and power assist diagnosis and service. One classroom, six lab hours per week."},
    {"id":"AUT 1170","title":"Automotive Internship I","description":"Students work in the field at an approved automotive service business. The credit that is earned for the internship is applied toward degree requirements. Students prepare and submit reports online and are evaluated by the course instructor as well as their on-site supervisor."},
    {"id":"AUT 1171","title":"Automotive Internship II","description":"Students work in the field at an approved automotive service business. The credit that is earned for the internship is applied toward degree requirements. Students prepare and submit reports online and are evaluated by the course instructor as well as their on-site supervisor. Students will practice service procedures on steering and suspension, electrical systems and engines following their second semester. Twenty co-op hours per week."},
    {"id":"AUT 1172","title":"Automotive Internship III","description":"Students work in the field at an approved automotive service business. The credit that is earned for the internship is applied toward degree requirements. Students prepare and submit reports online and are evaluated by the course instructor as well as their on-site supervisor. Students will practice service procedures on engine performance, manual transmissions and axles following their third semester. Twenty co-op hours per week."},
    {"id":"AUT 1173","title":"Automotive Internship IV","description":"Students work in the field at an approved automotive service business. The credit that is earned for the internship is applied toward degree requirements. Students prepare and submit reports online and are evaluated by the course instructor as well as their on-site supervisor. Students will practice service procedures on automatic transmissions and air-conditioning systems following their fourth semester. Twenty co-op hours per week."},
    {"id":"AUT 2214","title":"Automotive Electrical/Electronic Systems II","description":"Advanced electrical/electronic system diagnosis and troubleshooting of starting and charging systems, lighting systems, dashboard instrumentation, body control and accessory circuits. SRS system operation, testing and diagnosis. AC and DC motor theory, operation and diagnosis. Schematic utilization. Diagnose serial data bus communication and module systems.  Two classroom, six lab hours per week."},
    {"id":"AUT 2215","title":"Automotive Engine Performance II","description":"Advanced diagnosis and repair of computer controlled fuel delivery, fuel injection, ignition, emission systems and proper use of advanced engine performance diagnostic equipment. Basic handtools required."},
    {"id":"AUT 2221","title":"High Performance Engine Blocks & Heads","description":"High-performance engine building plan development. Disassembly, cleaning and inspection of components. Reconditioning/modification of components. Preparation of components prior to final/trial assembly. Three classroom, nine lab hours per week."},
    {"id":"AUT 2222","title":"High Performance Engine Assembly & Dyno Testing","description":"High-performance engine block and cylinder head final assembly. Finished engine assembly is dynamometer tested for performance output. Three classroom, nine lab hours per week."},
    {"id":"AUT 2224","title":"High Performance Fuel Induction Systems","description":"Performance rebuilding and tuning of Holley carburetors. Introduction to the operation and performance application of electronic fuel injection. Introduction to superchargers, turbochargers and nitrous oxide. Engine performance evaluation and tuning utilizing engine and chassis dynamometers. Basic hand tools required. One and one-half classroom, four and one-half lab hours per week."},
    {"id":"AUT 2226","title":"High Performance Fabrication","description":"Basic chassis design and construction for high-performance racing applications. Suspension design, types and fabrication. Interior and exterior sheet-metal design and fabrication. Two classroom, six lab hours per week."},
    {"id":"AUT 2230","title":"Hybrid Electric Vehicle Systems","description":"Hybrid vehicle safety, theory and operation of automotive hybrid high voltage systems, batteries, charging systems, drivetrain components and emission systems.  One classroom, three lab hours per week."},
    {"id":"AUT 2240","title":"Automotive Diesel Systems","description":"This course provides content on light duty diesel applications in automobiles.  Students will receive instruction on theory and operation, maintenance, service, repair, and diagnostic skills of diesel engines, fuel systems, exhaust systems, induction, and emission systems.  One classroom, three lab hours per week."},
    {"id":"AUT 2241","title":"Automatic Transmission Systems","description":"Theory and operation of automotive transmissions and transaxle systems. Lab experience in the overhaul and service of automatic transmissions and transaxles including mechanical, hydraulic and electronic systems diagnostics and testing."},
    {"id":"AUT 2250","title":"Automotive Service Operations","description":"Actual experience in the laboratory with diagnosis, repair, use of manuals, customer relations, safety, communications, supervision and delegation of work.  Automotive service facility and operation consideration.  Basic hand tools required.  Four classroom, twelve lab hours per week."},
    {"id":"AUT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format."},
    {"id":"AUT 2304","title":"GM Noise Vibration & Harshness","description":"This course covers the theory of vibration, including basic to advanced vibration diagnosis. Specific topics include usage of the Electronic Vibration Analyzer (EVA), tools used to measure runout and driveline working angles and other current tools."},
    {"id":"AUT 2306","title":"GM Rear Axle & Propeller Shaft Service","description":"This class focuses on diagnostic and repair skills required to service GM rear axle (including Getrag) and propeller shaft components."},
    {"id":"AUT 2309","title":"GM Braking Systems","description":"This course provides system operation and diagnostic information on various base and antilock brake systems and their related components. Topics also include master cylinder operation, quick take-up valve operation, disc/drum operation and hydraulic system fundamentals."},
    {"id":"AUT 2325","title":"GM Automatic Transmission Transaxle Theory, Operation & Diagnostics","description":"This course will develop the knowledge and skills needed to properly diagnose transmission faults related to hydraulic, mechanical and electrical systems that affect transmission operation. Specifics covered in this course include transmission operation, diagnostic procedures and service procedures."},
    {"id":"AUT 2333","title":"GM Electrical Electronics Terminals & Connectors","description":"This course covers connector system identification, terminal, connector and wire harness repair, service information navigation and circuit diagnostics."},
    {"id":"AUT 2336","title":"GM Entertainment Systems","description":"This course covers methods of operation and procedures for diagnosis of both GM audio and video entertainment systems. Systems covered include AM/FM/XM antennas, lead-in cables, integral receivers, remote components including receivers, control heads, tape players, CD, DVD, and VHS video players, and Steering Wheel Controls (SWC). Diagnosis and correction of audio systems, noise conditions and video system malfunction are also covered."},
    {"id":"AUT 2337","title":"GM Supplemental Restraint Systems","description":"This course focuses on design, operations, servicing and handling procedures of air bag systems. Technicians will gain skill and knowledge concerning air bag components. Diagnostic tests are introduced utilizing the Tech 2 scan tool, SIR Driver/Passenger Load Tool and Digital MultiMeter."},
    {"id":"AUT 2338","title":"GM Waterleak & Windnoise Management","description":"This course consists of proven diagnostic procedures, test equipment and methods for waterleak and windnoise diagnosis and the tools for adjustment and sealing operations."},
    {"id":"AUT 2345","title":"GM HVAC Systems & Operation","description":"This course focuses on HVAC system diagnostics, with additional emphasis placed on electrical and control systems. Specific topics include manual and automatic A/C, dual zone A/C, and rear A/C systems, system control circuits. Additional topics include the engine cooling system and the air distribution through the passenger compartment."},
    {"id":"AUT 2348","title":"GM Powertrain Performance","description":"This course focuses on engine control subsystems and proper diagnosis of performance-related conditions. Specific topics include driveability, diagnosis, fuel-injection systems, ignition systems, emission controls, PCM functions and Tech 2 scan tool usage."},
    {"id":"AUT 2359","title":"GM Chassis Control Systems","description":"This course will cover suspension component identification, operation and diagnosis of various chassis systems such as independent and non-independent, tire pressure monitoring systems, level control systems, active suspension systems and serial data communications."},
    {"id":"AUT 2360","title":"GM Engine Mechanical Diagnosis & Measurement","description":"This course covers the proper techniques and fundamental knowledge necessary to correctly isolate and diagnose abnormal engine mechanical conditions. Topics include recommended diagnostic, measurement and overhaul/repair procedures for GM engines."},
    {"id":"AUT 2371","title":"GM Manual Gearbox Service","description":"This course provides an in-depth unit repair of front and rear wheel drive manual gearboxes. The internal components will be described and also identified for the technician. There will also be an introduction to special tools and usage to perform repairs efficiently."},
    {"id":"AUT 2372","title":"GM Moveable Roof Systems","description":"This hands-on component begins with pre-planned workstations related to the location, diagnosis and repair of GM moveable roof systems that are performed by students working in groups."},
    {"id":"AUT 2373","title":"GM Global Diagnostic System/Multiple Diagnostic Interface","description":"This course is designed to provide the technician with the skills necessary to properly diagnose current and future vehicle platforms, using GDS (Global Diagnostic System) and the MDI (Multiple Diagnostic Interface) vehicle data acquisition systems."},
    {"id":"AUT 2374","title":"GM Six-Speed Automatic Transmission/Transaxle Mechanical Service","description":"This course provides an introduction to the 6T70/75 six-speed front- wheel drive Hydramatic transaxle and the 6L80/90 six-speed rear- wheel drive Hydramatic transmission. One full day will be spent on each unit performing a complete teardown and reassembly. All special tools will be introduced and available for use. Bearing preload procedures and clutch pack travel measurements will be performed by participants."},
    {"id":"AUT 2375","title":"GM Global Electrical Systems","description":"This hands-on component is designed to enhance the service technician's abilities and understanding on the new global format of service diagnostic procedures, including the common circuit types and functions used in GM electrical architecture. This component also covers diagnostics of serial data communication systems. Upon completion of this component technicians will be able to diagnose and/or test the types of voltage and ground circuits, as well as the types of signal circuit inputs and control circuit outputs."},
    {"id":"AUT 2376","title":"Strategies for Efficient Diagnosis","description":"This course presents General Motors diagnostic strategy for proper, efficient diagnosis of vehicle concerns including an in-depth review with the instructor of Strategy Based Diagnosis and Diagnostic System Checks. Special attention will be focused on known vehicle concerns that are resulting in high warranty costs and high \"No Trouble Found\" results for returned parts, by introducing new tools, discussing GM warranty policies and reviewing additional training opportunities."},
    {"id":"AUT 2500","title":"Tesla Student Automotive Technician START","description":"This course is intended to educate service technicians on the technology used in Tesla motors.  Topics will include: safety when working in or around high voltage, maintenance, regenerative braking, inverter power transfer, battery technologies, battery management systems, high voltage bus and charging, pack connector and penthouse controls, and autonomous technology. Electric vehicle applications and their integrated systems from Tesla motors will be used to discuss the physics of battery storage, material composition, and high-voltage generation systems. The Tesla START program provides students with the skills necessary for a successful career with Tesla."},
    {"id":"AVT 1101","title":"Introduction to Unmanned Aerial Systems","description":"Foundations of unmanned aerial systems (UAS), including history, elemental systems including payloads, data links, ground support equipment, classes of UAS, categories, applications, mission planning and control, and launch/recovery systems."},
    {"id":"AVT 1102","title":"Orientation to Inflight Services","description":"Provides students with the knowledge of the duties and responsibilities of flight attendants, inflight service procedures, safety briefing announcements, customer service skills, airline terminology, airline schedules, airport identifiers and airline flight attendant interview techniques."},
    {"id":"AVT 1103","title":"Remote Pilot Ground School","description":"The application of unmanned aerial systems (UAS) for commercial and civil purposes requires an understanding of contemporary technical, legal and regulatory issues. This course provides the foundational knowledge to take the FAA-mandated Part 107 Remote Pilot exam to operate as a commercial UAS pilot."},
    {"id":"AVT 1104","title":"UAS Standards, Regulations & Law","description":"This course reviews the current legal considerations of unmanned aerial system (UAS) operations, provides an outlook on future considerations, and informs students on existing and trending UAS related standards and regulations."},
    {"id":"AVT 1105","title":"Orientation to Aviation","description":"Overview of aviation career specialties required for successful entry into aviation industry-related fields. Evaluation of career interests relative to the market for aviation opportunities. Guest lecturers and site visits will be used to illustrate the broad spectrum of aviation occupations available."},
    {"id":"AVT 1106","title":"Airframe Safety Systems","description":"Aircraft safety systems will cover the following: chemical and electric/pneumatic ice and rain protection systems, fire warning and extinguishing systems, landing gear and throttle safety warning systems, aural warning systems and troubleshooting and repair of wiring for these systems. One classroom, two lab hours per week."},
    {"id":"AVT 1107","title":"Fuel Systems","description":"Inspection, operational checkout and repair of fuel systems and components to include tanks, transfer pumps, indicating systems and fuel heating; leak detection, identification and repair; proper servicing and regulatory compliance. Two classroom, three lab hours per week."},
    {"id":"AVT 1108","title":"UAS First Responder Applications","description":"Students will learn learn the fundamental principles of Unmanned Aerial Systems (UAS) technologies, capabilities, regulations, legal responsibilities, cost and benefit consideration for potential use in law enforcement, fire, rescue, emergency medical and disaster response applications."},
    {"id":"AVT 1110","title":"Private Pilot Ground School","description":"Prepares students with the knowledge necessary to successfully complete the Federal Aviation Administration (FAA) Private Pilot knowledge exam. Topics include pilot training, aircraft systems, aerodynamic principles, safety of flight, air traffic control procedures, weather theory, weather hazards and conditions, federal aviation regulations, aircraft performance, weight and balance principles and navigation procedures."},
    {"id":"AVT 1111","title":"Helicopter Private Pilot Ground","description":"Prepares students with the knowledge necessary to successfully complete the Federal Aviation Administration (FAA) Private Pilot knowledge exam. Topics include pilot training, aircraft systems, aerodynamic principles, safety of flight, air traffic control procedures, weather theory, weather hazards and conditions, federal aviation regulations, aircraft performance, weight and balance principles and navigation procedures."},
    {"id":"AVT 1112","title":"UAS Precision Agriculture","description":"This course describes the current state of Unmanned Aerial Systems and related technologies as they may be applied to precision agriculture. Students will determine the commercial viability of Unmanned Aerial System applications in precision agriculture and will review current regulatory and operational considerations for their use. One classroom, two lab hours per week."},
    {"id":"AVT 1113","title":"Drawings for Aviation","description":"Knowledge and skill development in using and making aircraft drawings, graphs and wiring diagrams; drawing symbols, electrical schematics, drawing repairs and alterations to industry and Federal Aviation Administration (FAA) standards; aviation performance charts and graphs, aviation gas laws, force and motion, work and power, energy and weight, mass and matter that affect aircraft performance. Two classroom, three lab hours per week."},
    {"id":"AVT 1114","title":"Geospatial Information for UAS","description":"This course defines common terms used in the Geographic Information System (GIS) community, explains common geospatial applications, compares and contrasts the difference between data and information, and presents how to evaluate data from multiple sources in terms of usefulness, accuracy and potential uses. In addition to examining sources of data, the course also discusses production methods and analysis procedures for geospatial data as they relate to unmanned aerial system (UAS) operations. A fundamental grounding is also provided in the technical aspects to underpin geospatial data and key geospatial technologies that support Unmanned System operations. One classroom, two lab hours per week."},
    {"id":"AVT 1115","title":"Introduction to UAS Entrepreneurship","description":"Provides students with an introduction to the principles of entrepreneurship and how they can leverage UAS technologies and applications to create or expand a successful business. Lectures, case studies, and experiential learning are used to develop critical thinking and interpersonal skills."},
    {"id":"AVT 1116","title":"Regulations for Maintenance","description":"This course provides the aviation mechanic with critical knowledge in the following areas: FAA regulations, airworthiness directives, mechanics' privileges, legal aircraft record entries, maintenance publications, repair manuals, wiring diagrams, structural repair manuals, Air Transport Association (ATA) numbering system and human factors. Two classroom, two lab hours per week."},
    {"id":"AVT 1118","title":"Weight & Balance","description":"This course covers aviation maintenance performance calculations to include theory of aircraft weight and balance encompassing documentation, weighing the aircraft, locating the center of gravity, adverse center of gravity checks, large aircraft weight and balance computations and determination of ballast requirements. Two classroom, three lab hours per week."},
    {"id":"AVT 1119","title":"Aviation Meteorology","description":"Prepares students with the knowledge necessary to comprehend the fundamentals of meteorology, analyze weather factors, hazards and in-flight weather conditions and  weather conditions as they relate to aircraft and flight performance using aviation meteorology charts and internet weather resources."},
    {"id":"AVT 1120","title":"Electro-Optical & Infrared Data Analysis","description":"Introduces the foundations of electro-optical and infrared data analysis. Students will acquire knowledge of the characteristics of electro-optical and infrared sensors, data, and remote sensing applications applicable to civil unmanned aerial system operations. Emphasis is placed on data processing. One classroom, two lab hours per week."},
    {"id":"AVT 1121","title":"Multispectral & Hyperspectral Data Analysis","description":"Introduces the foundations of multispectral and hyperspectral data analysis. Students will acquire knowledge of the characteristics of multispectral  and hyperspectral sensors, data, and remote sensing applications applicable to civil unmanned aerial system operations. Emphasis is placed on data processing. One classroom, two lab hours per week."},
    {"id":"AVT 1122","title":"Synthetic Aperture Radar & Light Detection & Ranging Data Analysis","description":"Introduces the foundations of synthetic aperture radar and light detection and ranging data analysis. Students will acquire knowledge of the characteristics of synthetic aperture radar and light detection and ranging sensors, data, and remote sensing applications applicable to civil unmanned aerial system operations. Emphasis is placed on data processing. One classroom, two lab hours per week."},
    {"id":"AVT 1123","title":"Acoustic & CBRNE Data Analysis","description":"Introduces the foundations of acoustic and chemical, biological, radiological, nuclear, and explosives data analysis. Students will acquire knowledge of the characteristics of acoustic and chemical, biological, radiological, nuclear, and explosives sensors, data, and remote sensing applications applicable to civil unmanned aerial system operations. Emphasis is placed on data processing."},
    {"id":"AVT 1124","title":"Private Pilot Flight Lab - Airplane Single Engine","description":"Prepares students with the knowledge necessary to successfully obtain the aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Private Pilot Certificate with an Airplane Category and Single Engine Land Class Rating. The course is conducted under 14 CFR Part 61. Topics include familiarization with the training aircraft, flight maneuvers, maximum performance takeoff and landing procedures, attitude control by instrument reference, solo flight, night flying, cross country operations and navigation procedures. Course Performance Standards require 40 hours of flight time and 100% completion of the course syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Three lab hours per week."},
    {"id":"AVT 1126","title":"Private Pilot Flight Lab - Rotorcraft Helicopter","description":"Prepares students to successfully obtain the aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Private Pilot Certification with a rotorcraft helicopter class rating. The course is conducted under 14 CFR Part 61. Topics include familiarization with the training aircraft, flight maneuvers, takeoff and landing procedures, solo flight, night flight, cross country operations and navigation procedures. Course Performance Standards require 40 hours of flight time and 100% completion of the syllabus requirements.  Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Three lab hours per week."},
    {"id":"AVT 1128","title":"Powerplant Safety Systems","description":"Troubleshooting of electrical wiring and connections on instruments, legal repairs allowed on instruments by Airframe and Powerplant (A&P) mechanics, different types of fire protection systems, different extinguishing agents used, Auxiliary Power Unit (APU) use, inspection, operation, removal, and replacement of APUs requiring servicing and troubleshooting and unducted fan engines. Two classroom, two lab hours per week."},
    {"id":"AVT 1131","title":"Basic Aviation Electricity","description":"Basic electrical principles to include the following: alternating and direct current (A/C and D/C) circuits, production of electricity, batteries, Ohm's law, capacitance, load analysis, electrical load circuits, integrated circuits, parallel, series, and compound circuits, and A/C and D/C motors. Two classroom, three lab hours per week."},
    {"id":"AVT 1133","title":"Instruments/Communications","description":"Inspection, removal and installation of flight instruments and controls to include gyroscopic and magnetic instruments, pitot-static lines, wiring and legal repairs by mechanics. Communications equipment operation, inspection, removal and installation of radios, antennas, coax cables, wave guides and next generation of combination instrument/communication equipment to include GPS/satellite communication. Two classroom, three lab hours per week."},
    {"id":"AVT 1135","title":"Materials & Processes","description":"Selection and proper use of nondestructive inspection techniques and equipment; basic heat treatments, identification and selection of correct aircraft hardware. Inspection of welds and precision measurements. Tube bending, cutting and flaring, high-pressure (MS) flareless fittings, repair of rigid lines, identification of fluid lines, fabrication of high and low pressure hose lines, bulkhead fittings. Two classroom, six lab hours per week."},
    {"id":"AVT 1136","title":"Sheet Metal","description":"Identification and selection of sheet metal types; inspection, cleaning, preparation, forming, layout, bending, cutting, dimpling, countersinking, drilling, installing special fasteners and rivets in sheet metal. Fabrication of sheet metal projects is required. Two classroom, six lab hours per week."},
    {"id":"AVT 1140","title":"Introduction to Business Aviation","description":"Overview of International Civil Aviation Organization (ICAO) definitions of aviation activities; definition of business and private aviation, reasons for using business aviation, the actual costs of use versus airlines and other modes of transportation, differences from job opportunities in other areas of aviation, opportunities for specific kinds of jobs from architect to aero engineering,  discussions with professionals from the field."},
    {"id":"AVT 1141","title":"Principles of Aviation Leadership","description":"Strategic planning in business aviation operations, relationship among management, flight crews, corporate business aviation flight department employees and those external to the flight department, including fixed based operators (FBOs); team building, decision making, communication with the corporate business aviation flight department."},
    {"id":"AVT 1148","title":"Aircrew Emergency Management","description":"Provide students with the knowledge of the duties and responsibilities of airline crew during emergency operations which will include smoke/fire, first aid, evacuations/ditchings, decompressions, security and hazardous materials."},
    {"id":"AVT 1151","title":"Crew Survival & Rescue Techniques","description":"Overview of the psychology of survival, post-crash survival techniques, prioritization and necessities, survival physiology in the emergency environment, clothing protection and improvised shelter, signaling, air and ground search and rescue, survival kits and emergency equipment and survival skills. Includes winter, desert, jungle, water, hostile territories and extended in-plane hostage survival."},
    {"id":"AVT 1170","title":"Instrument Pilot Ground School","description":"Basic nonvisual cockpit instrument reference education, including principles of basic attitude instrument flight and limitations of flight instruments, instrument flight procedures for departure, en-route and arrival operations, federal aviation regulations, weather factors and emergency situations."},
    {"id":"AVT 1171","title":"Helicopter Instrument Pilot Ground","description":"Basic nonvisual cockpit instrument reference education, including principles of basic attitude instrument flight and limitations of flight instruments, instrument flight procedures for departure, en-route and arrival operations, federal aviation regulations, weather factors and emergency situations."},
    {"id":"AVT 1213","title":"Corrosion","description":"Causes of corrosion, the chemical process, types of corrosion, locations susceptible to corrosion, detecting corrosion, removing and treating corrosion, cleaning of the interior and exterior of the airplane, polishing windshields and windows and paint removal and protection of bare surfaces. Two classroom, two lab hours per week."},
    {"id":"AVT 1214","title":"Cabin Atmospheric Control","description":"Inspection, operation, troubleshooting, repair and service of the following items: heating, cooling, air conditioning, pressurization, air cycle machines and gaseous oxygen systems. One classroom, two lab hours per week."},
    {"id":"AVT 1218","title":"Utility Systems","description":"Hydraulic and pneumatic aircraft systems, introduction to landing gear systems,  development of repair and inspection skills, critical thinking and development of analysis used in troubleshooting and repair of hydraulic and pneumatic systems and landing gear. Three classroom, nine lab hours per week."},
    {"id":"AVT 1224","title":"Instrument Pilot Flight Lab - Airplane Single Engine","description":"Prepares students with the skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Instrument Pilot Certificate. The course is conducted under 14 CFR Part 141.Topics include attitude instrument flying, instrument navigation, holding patterns, instrument approaches and instrument cross-country flight including instrument flight rules en-route procedures. Course Performance Standards require 35 hours of flight time and 100% completion of the course syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Three lab hours per week."},
    {"id":"AVT 1226","title":"Instrument Pilot Flight Lab - Rotorcraft Helicopter","description":"Prepares students with the skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Instrument Pilot certificate with a rotorcraft helicopter Class Rating. The course is conducted under 14 CFR Part 141. Topics include attitude instrument flying, instrument navigation, holding patterns, instrument approaches and instrument cross country flight including instrument flight rules enroute procedures. Course Performance Standards require 40.5 hours of flight time 100% completion of the syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Three lab hours per week."},
    {"id":"AVT 1241","title":"Blind Flying Hazards","description":"Provides pilots with an understanding of spatial disorientation, its causes and prevention and techniques for safe recovery. Two lab hours per week."},
    {"id":"AVT 1245","title":"Aviation Law","description":"Prepares students with knowledge of the origins of aviation law, legal terminology and a general understanding of aviation industry laws, legislation and court decisions affecting the aviation community."},
    {"id":"AVT 1246","title":"Air Traffic Control Communications","description":"Overview of the history of air traffic control, air traffic control tower procedures, radar systems, radar separation, radio communications and techniques, flight plan clearances, traffic management and emergency procedures and priority handling."},
    {"id":"AVT 1254","title":"Flight Simulator Instruction","description":"Prepares students with the knowledge and practice necessary to successfully control an aircraft solely by reference to flight instruments. Topics include full and partial panel reference, accuracy and proficiency in flying holding patterns and instrument approach procedures and recovery from unusual attitudes and spatial disorientation scenarios.Contact the Department for the current course fee."},
    {"id":"AVT 2115","title":"UAS Entrepreneurship Capstone","description":"Provides students with a real-world design challenge during which they will develop their own UAS related business concepts or explore a provided scenario. Tasks include market analysis, business case and plan development, creation of a business proposal, and delivery of a presentation as is common for those pursuing venture capital or other investment opportunities. One classroom, two lab hours per week."},
    {"id":"AVT 2121","title":"Assembly & Rigging","description":"This course covers proper adjustment of cables and torque tubes, proper alignment of primary and secondary control surfaces, proper inspection and alignment of landing gear components and associated controls, correct alignment of all structures in both fixed wing and rotary wing aircraft. Two classroom, three lab hours per week."},
    {"id":"AVT 2122","title":"Ignition & Starting","description":"This course covers magneto removal, inspection, repair and installation; internal and external magneto timing; inspection, repair and installation of powerplant wiring and ignition harnesses. One classroom, six lab hours per week."},
    {"id":"AVT 2125","title":"Developments in Aviation I","description":"Provides pilots and other aviation professionals with an in-depth understanding of how aviation technology has evolved. This course begins with the earliest balloon flights, the invention of the airplane and covers all of the subsequent technology developments through the end of WWII."},
    {"id":"AVT 2126","title":"Reciprocating Engines","description":"This course covers reciprocating engine removal, engine configurations, firing order, inspections, critical parts measurement, use of overhaul manual, powerplant troubleshooting, installation and repair. One classroom, eighteen lab hours per week."},
    {"id":"AVT 2129","title":"Propellers","description":"Removal, inspection, repair, dressing and installation of propellers. Propeller pitch, angle of attack and forces. Metal, wood and composite propellers. Variable pitch propellers including constant speed, reversing, feathering and ground adjustable propellers. Propeller systems including governors, ice control and auxiliary systems. Propeller storage and return to service. Propeller certificate data. Two classroom, six lab hours per week."},
    {"id":"AVT 2132","title":"Airframe Electrical Systems","description":"Electrical distribution, controls, switches, transformers and solid-state devices. Use of electrical measuring devices in troubleshooting and testing circuits. Repair of wiring and terminal ends. Use of electrical schematics and wiring diagrams to troubleshoot systems and trace electrical signals. Two classroom, six lab hours per week."},
    {"id":"AVT 2138","title":"Engine Fuel & Fuel Metering","description":"Fuel system components for turbine and reciprocating engines, carburetor adjustment and overhaul, installation and removal of carburetors, repair of fuel metering components, repair and installation of fuel system components, inspection, adjustment and servicing of engine fuel metering system components. Two classroom, three lab hours per week."},
    {"id":"AVT 2139","title":"Induction/Exhaust/Cooling","description":"Powerplant ice protection, reciprocating engine induction system, superchargers, turbochargers, heat exchangers, turbine engine inlet designs, exhaust system inspection, repair, removal, and installation, and thrust reversers. One classroom, three lab hours per week."},
    {"id":"AVT 2143","title":"Review & Recommendation","description":"This course provides the aviation mechanic with critical review for the required Federal Aviation Administration (FAA) knowledge exams in the following areas under Part 147 Appendixes B, C and D: General, Airframe, and Powerplant aviation maintenance subjects with hands-on review in preparation for oral and practical exams."},
    {"id":"AVT 2146","title":"Introduction to Airline Operations","description":"An introduction to the structure of an airline, including the functions of the operational control center, airline marketing, maintenance control, fleet planning and scheduling, dispatch release, airline operating certificates and specifications, Part 121 Federal Aviation Regulations and an understanding of the principles and concepts of crew and dispatcher resource management (CRM) through interactive discussion and scenario analysis as it relates to aircraft dispatchers and airline flight crews."},
    {"id":"AVT 2150","title":"Crew Resource Management for UAS","description":"Provides students with an introduction to the principles and concepts of crew resource management (CRM) through interactive discussion and scenario based analysis as it relates to UAS operations and the challenge of optimizing the human/machine interface and accompanying flight operations. Discussion and scenario based activities include CRM markers, principles and concepts of CRM, team building, information transfer, problem solving, risk management and decision making, communications process, conflict resolution and maintaining situational awareness when dealing with UAS automated systems."},
    {"id":"AVT 2151","title":"UAS Operations I","description":"This course consists of lecture, simulator instruction and flight operation demonstration specific to an unmanned aerial system (UAS). Lecture will cover topics in UAS: aerodynamic theory, operations theories and techniques, platform categories, sensors and payloads, technical documents and processes of automation. Students will observe and participate in flight operations on various UAS platforms. Two classroom, two lab hours per week."},
    {"id":"AVT 2157","title":"Aircraft Performance I","description":"Principles of advanced aerodynamics, high-speed flight, takeoff, enroute and landing jet aircraft performance. Operational factors affecting aircraft performance in aircraft dispatch."},
    {"id":"AVT 2158","title":"Aircraft Performance II","description":"Prepares students with the aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Aircraft Dispatcher Certificate. Topics include DC-9, B-727, B-737 and BE-1900 weight and balance and advanced transport category aircraft performance calculations."},
    {"id":"AVT 2159","title":"Canadair Regional Jet (CRJ) Aircraft Systems","description":"Prepares students with the knowledge necessary to successfully complete the Federal Aviation Administration (FAA) Aircraft Dispatcher Practical exam. Topics include theory of Canadair Regional Jet (CRJ) aircraft systems, including minimum equipment and configuration deviation list items and their application to aircraft dispatch applications."},
    {"id":"AVT 2166","title":"Practical  Dispatch Applications","description":"In-depth coverage of joint Aircraft Dispatcher/Pilot responsibilities and dispatch functions including communications, operational control, fuel planning, flight planning, aircraft weight and balance, abnormal and emergency situations, weather, NOTAMs (Notices to Airmen) and airport facilities as they relate to flight planning."},
    {"id":"AVT 2167","title":"Instrument Flight Rules (IFR) Navigation & Planning","description":"Principles of aeronautical charts, national airspace system, aircraft navigation instruments, navigational systems and global differences in navigational operations.  Air traffic control procedures and pilot responsibilities as they relate to enroute operations, terminal area and radar operations; including instrument departure and approach procedures."},
    {"id":"AVT 2168","title":"Dispatcher Oral Preparation","description":"Preparation for the Federal Aviation Administration (FAA) Aircraft Dispatcher Certificate through an in-depth understanding of regulations, meteorology, navigation, aircraft systems, communications, air traffic control, emergency and abnormal procedures and practical dispatch applications. At the completion of the course, students will be prepared for the Federal Aviation Administration Aircraft Dispatcher oral examination."},
    {"id":"AVT 2211","title":"Advanced Navigation Science","description":"Study of advanced navigational systems used in commercial and corporate flight operations. Global Positioning Systems (GPS), Flight Management Systems (FMS) and automated flight planning programs will be demonstrated and practiced. Study of long range navigational procedures."},
    {"id":"AVT 2219","title":"Turbine Engines","description":"Physics of gas turbine engines, air and non-air breathing engine types, production of thrust, engine sections, types of accessories, engine operations, maintenance requirements, inspections, repair of electrical connections, troubleshooting electrical and pneumatic systems and testing/trimming of engines. Two classroom, six lab hours per week."},
    {"id":"AVT 2236","title":"Non-Metallic Structures","description":"This course covers composites, different types of composite glass, wood structures, types of wood used in aircraft, defects in wood, proper repair of fabric and wood structures, types of fabrics used to cover aircraft structures, sewing, lacing and finishing, and the required inspection of fabrics on aircraft. Two classroom, six lab hours per week."},
    {"id":"AVT 2237","title":"Aircraft Inspections","description":"Conduct an annual/100-hour inspection for a complete aircraft including the airframe, engine and related components. Perform necessary servicing related to an annual inspection and check for airworthiness directives, service bulletins and compliance with manufacturer's directives. Operation, servicing, hand communications, aircraft movement and airport operations safety. Two classroom, three lab hours per week."},
    {"id":"AVT 2240","title":"Human Factors in Aviation","description":"Provides pilots and other aviation professionals with an in-depth knowledge of human performance capabilities and limitations and their relationship with aircraft systems operation. Automation and human errors, fatigue, diet, motivation and learning, training principles, human sensory capabilities and limitations, supervisory control and Crew Resource Management (CRM) are among the topics this course will address."},
    {"id":"AVT 2242","title":"Aircraft Accident Investigation I","description":"Provides pilots and other aviation professionals with an understanding of techniques used by investigators to identify causes of accidents and how to make recommendations to reduce the likelihood of recurrence and reduce the consequences."},
    {"id":"AVT 2247","title":"Aerodynamics & Flight Mechanics I","description":"Properties of the Standard Atmosphere. Applies basic physics conservation concepts to incompressible, low-speed aerodynamics to develop analytical equations for lift and drag. Develops methods for basic aircraft performance analysis to include maximum angle and rate of climb, cruise and gliding flight. Discusses basic static and dynamic stability requirements."},
    {"id":"AVT 2250","title":"Commercial Pilot Ground","description":"Prepares  fixed-wing students with the aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Commercial Pilot Certificate with an Airplane Category and Single Engine Land Class Rating. Topics include federal aviation regulations applicable to commercial pilot operations, airspace, flight information, meteorology, aeronautical decision making, Visual Flight Rules (VFR) cross-country flight planning and navigation."},
    {"id":"AVT 2251","title":"Helicopter Commercial Pilot Ground","description":"Prepares students with the aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Commercial Pilot Certificate for Helicopter pilots. Topics include federal aviation regulations applicable to commercial pilot operations, airspace, flight information, meteorology, aeronautical decision making, Visual Flight Rules (VFR) cross-country flight planning and navigation."},
    {"id":"AVT 2258","title":"Flight Instructor Ground","description":"Prepares students with the knowledge necessary to complete the Federal Aviation Administration (FAA) Fundamentals of Instruction and Certified Flight Instructor knowledge exams. Topics include foundations of learning, communication process, the fundamentals of instruction, flight instructor responsibilities and professionalism, development of lesson plans and evaluations, logbook endorsements and review of private and commercial pilot aeronautical knowledge areas as it pertains to the FAA Certified Flight Instructor."},
    {"id":"AVT 2263","title":"Commercial Pilot Flight Lab - Airplane Single Engine","description":"Prepares students with the aeronautical knowledge, skills and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Commercial Pilot Certificate with an Airplane Category and Single Engine Land Class Rating. The course is conducted under 14 CFR Part 141. Topics include familiarization with the complex training aircraft, commercial flight maneuvers, simulated emergency procedures, maximum performance takeoff and landing procedures and extended cross-country flight operations. Course Performance Standards require 120 hours of flight time and 100% completion of the course syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Nine lab hours per week."},
    {"id":"AVT 2264","title":"Commercial Pilot Single Engine Additional Rating","description":"Prepares students with the aeronautical knowledge, skills and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Commercial Pilot Certificate with an Airplane Category and Single Engine Land Class Rating. The course is conducted under 14 CFR Part 141. Topics include familiarization with the complex training aircraft, commercial flight maneuvers, simulated emergency procedures, maximum performance takeoff and landing procedures and extended cross-country flight operations. Course Performance Standards require 120 hours of flight time and 100% completion of the course syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Nine lab hours per week."},
    {"id":"AVT 2265","title":"Commercial Pilot Flight Lab - Rotorcraft Helicopter","description":"Prepares students with the aeronautical knowledge, skills and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Commercial Pilot Certificate with a rotorcraft helicopter Class Rating. The course is conducted under 14 CFR Part 141. Topics include familiarization with Commercial flight maneuvers, simulated emergency procedures, maximum performance takeoff and landing procedures and extended cross-country flight operations. Course Performance Standards require 115 hours of flight time and 100% completion of the course syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Nine lab hours per week."},
    {"id":"AVT 2266","title":"Multi Engine Flight Lab","description":"Prepares students with the aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Commercial Pilot Certificate with an Airplane Category and Multi Engine Land Class Rating. The course is conducted under 14 CFR Part 141. Topics include multi-engine aircraft systems, aerodynamics, flight maneuvers, single-engine operations, maximum performance takeoff and landing procedures, attitude control by instrument reference during single engine operations and single engine emergency procedures. Course Performance Standards require 15 hours of flight time and 100% completion of the course syllabus requirements.  Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Two lab hours per week."},
    {"id":"AVT 2269","title":"Flight Instructor Flight Lab - Airplane Single Engine","description":"Prepares students with the aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Certified Flight Instructor Pilot Certificate with an Airplane Category and Single Engine Land Class Rating. The course is conducted under 14 CFR Part 141. Topics include the fundamentals of instruction as it pertains to the training aircraft, flight maneuvers, maximum performance takeoff and landing procedures, attitude control by instrument reference, solo flight, night flying, cross-country operations and navigation procedures. Course Performance Standards require 30 hours of flight time and 100% completion of the course syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Three lab hours per week."},
    {"id":"AVT 2271","title":"Flight Instructor Flight Lab - Rotorcraft Helicopter","description":"Prepares students with the aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Certified Flight Instructor Pilot Certificate with rotorcraft helicopter class rating. The course is conducted under 14 CFR Part 141. Topics include the fundamentals of instruction as it pertains to the training helicopter, flight maneuvers, maximum performance takeoff and landing procedures, attitude control by instrument reference, night flying, cross-country operations and navigation procedures. Course Performance Standards require 25 hours of flight time and 100% completion of the course syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Three lab hours per week."},
    {"id":"AVT 2275","title":"Instrument Flight Instructor Ground","description":"Prepares students with the knowledge necessary to complete the Federal Aviation Administration (FAA) Certified Flight Instructor Instrument knowledge exam. Topics include flight instructor responsibilities and professionalism, development of instrument flight lesson plans and evaluations, instrument pilot logbook endorsements and review of instrument pilot aeronautical knowledge areas as it pertains to the FAA Certified Flight Instructor Instrument certificate. Both fixed -wing and helicopter sections are offered."},
    {"id":"AVT 2277","title":"Instrument Flight Instructor Flight Lab - Airplane Single Engine","description":"Prepares students with the aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Certified Flight Instructor Pilot Certificate with rotorcraft helicopter class rating. The course is conducted under 14 CFR Part 141. Topics include the fundamentals of instruction as it pertains to the training helicopter, flight maneuvers, maximum performance takeoff and landing procedures, attitude control by instrument reference, night flying, cross-country operations and navigation procedures. Course Performance Standards require 25 hours of flight time and 100% completion of the course syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Three lab hours per week."},
    {"id":"AVT 2278","title":"Instrument Flight Instructor Flight Lab - Rotorcraft Helicopter","description":"Prepares students with aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Certified Flight Instructor Instrument Pilot certificate with rotorcraft - helicopter class rating. The course is conducted under 14 CFR Part 141. Topics include the fundamentals of instruction as it pertains to the instrument pilot certificate, instrument flight maneuvers, instrument scanning techniques, instrument fundamentals, attitude control by instrument reference, cross country instrument flight, and instrument approach procedures. Course Performance Standards require 15 hours of flight time and 100% completion of the course syllabus requirements.  Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Two lab hours per week."},
    {"id":"AVT 2279","title":"Unmanned Aerial Systems Project","description":"Demonstration of command and control simulations of unmanned aerial system (UAS) operations, including mission planning, decision making, data management, avionics, sensors, communications, and situational awareness during UAS operations. One classroom, four lab hours per week."},
    {"id":"AVT 2280","title":"Introduction to UAS Maintenance","description":"This course will concentrate on the repair of the components of unmanned aerial systems (UAS). Students will develop knowledge in operator maintenance, composites, fuel systems, communication and instrumentation systems, rigging and assembly, and trouble shooting of UAS and their respective components. This course prepares students with the knowledge to determine and identify the technical problems associated with UAS. One classroom, two lab hours per week."},
    {"id":"AVT 2286","title":"Multi Engine Flight Instructor Flight Lab","description":"Prepares students with aeronautical knowledge, skill and experience necessary to meet the requirements for a Federal Aviation Administration (FAA) Certified Flight Instructor Instrument Pilot certificate with rotorcraft - helicopter class rating. The course is conducted under 14 CFR Part 141. Topics include the fundamentals of instruction as it pertains to the instrument pilot certificate, instrument flight maneuvers, instrument scanning techniques, instrument fundamentals, attitude control by instrument reference, cross country instrument flight, and instrument approach procedures. Course Performance Standards require 15 hours of flight time and 100% completion of the course syllabus requirements.  Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. Two lab hours per week."},
    {"id":"AVT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"AVT 2298","title":"UAS Agriculture Transfer","description":"Content includes Agricultural Economics and Crop Science which are of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format. Contact Department for details."},
    {"id":"AVT 2321","title":"Airline Transport Pilot","description":"Students completing the Airline Transport Pilot course will gain knowledge in the areas of federal aviation regulations, Airline Transport Pilot privileges, advanced aviation weather and sources of weather information, instrument approach charts and enroute charts, aeronautical decision making, effective briefings, automation management, advanced aircraft performance, advanced aircraft systems, and advanced weight and balance scenarios. Contact the Department for the current lab fee."},
    {"id":"AVT 2325","title":"Airline Transport Pilot Flight Lab","description":"Students completing the Airline Transport Pilot flight lab course will obtain the flying skills and aeronautical experience necessary to meet the requirements for an Airline Transport Pilot certification with an airplane category, and multi-engine, land class rating.  Contact the Department for the current lab fee. Two lab hours per week."},
    {"id":"AVT 2700","title":"Aviation Internship","description":"Supervised work experience related to the students' major or career program to develop new skills and professional work experience which will enhance marketability and networking.  Note: UAS students should have their FAA Part 107 certificate prior to enrolling in this course."},
    {"id":"AVT 3100","title":"Contemporary Technical, Legal & Regulatory Issues in UAS","description":"The application of unmanned aerial system (UAS) for commercial and civil purpose requires an understanding of contemporary technical, legal and regulatory issues. This course builds on a foundational knowledge obtained in prerequisite courses by developing an expanded view of application specific guidance augmented by case studies and scenarios."},
    {"id":"AVT 3125","title":"Developments in Aviation II","description":"Provides pilots and other aviation professionals with an in-depth understanding of how aviation technology has evolved.  Covers the time period beginning with the development of jet aircraft and their sophisticated flight systems to the development of space flight and modern unmanned systems."},
    {"id":"AVT 3150","title":"Crew Resource Management","description":"Understanding of cognitive and interpersonal skills and mental processes used by the flight crew.  Topics include situational awareness, planning, decision-making, workload management, adaptability, teamwork, communications, and advanced aircraft automation integration, constituting the traditional crew resource management markers."},
    {"id":"AVT 3151","title":"UAS Operations II","description":"This course builds on the knowledge and skills developed in prerequisite unmanned aerial system (UAS) courses to develop an understanding of advanced UAS operations. The course includes knowledge relating to proper implementation of safety risk mitigation, crew resource management, procedures, mission planning, and data management to enable successful execution of fixed-wing and vertical-takeoff-and-landing operations."},
    {"id":"AVT 3152","title":"UAS Operations II Lab","description":"This is a companion course to AVT 3151 in which students will gain advanced hands-on training with a variety of UAS vehicles via a lab format. This course builds on the knowledge and skills developed in prerequisite unmanned aerial system (UAS) courses to develop an understanding of advanced UAS operations. The course includes proper implementation of safety risk mitigation, crew resource management, procedures, mission planning, and data management to enable successful execution of fixed-wing and vertical-takeoff-and-landing operations. Four lab hours per week."},
    {"id":"AVT 3200","title":"UAS Logistics","description":"The capability of unmanned aerial systems (UAS) to support airborne cargo delivery and inventory control and continues to be advanced. This course reviews current and future applications, technologies, regulations, processes, and other considerations related to UAS logistical support with focuses on aerial cargo delivery, inventory, distribution system support."},
    {"id":"AVT 3241","title":"Aircraft Systems","description":"A study of basic systems common to transport category aircraft. Topics include aircraft structures, emergency equipment, electrical, hydraulics, pneumatics, fuel, flight controls, landing gear, powerplant, pressurization and air conditioning and how they are related to the Federal Aviation Regulations."},
    {"id":"AVT 3242","title":"Aircraft Accident Investigation II","description":"Building upon skills and knowledge learned in Aircraft Accident Investigation I, the student will expand into areas of accident site field investigation and demonstrate the ability to study what they observe and make conclusions based upon the evidence seen."},
    {"id":"AVT 3247","title":"Aerodynamics & Flight Mechanics II","description":"Advanced Aerodynamics is studied in this course, students will examine current flight applications and problems. Specifically, this includes transonic, supersonic, and hypersonic aerodynamics, principles of aircraft stability and control, and operational strength considerations. Emphasis is placed on the applications of the rapidly changing technological innovations in aerodynamics and the solutions to the problems created by these advances. Use the wind tunnel and set up and perform experiments with various airfoils. Two classroom, two lab hours per week."},
    {"id":"AVT 3300","title":"Artificial Intelligence (AI) in Aviation","description":"Introduction to the main foundational concepts and techniques used in Artificial Intelligence (AI); including decision making, planning, machine learning, and perception. Includes a range of real-world applications in which AI is currently used in aeronautical and aerospace systems."},
    {"id":"AVT 3400","title":"Human Sensation & Perception in Aviation","description":"Examines how the human senses transform stimulus patterns of physical energy into the neural codes that become our perceptions of the world. Vision, audition, smell, taste, touch, balance, and phenomena common to all sensory modalities, such as feature enhancement, inhibition, adaptation, and stages of neural coding will be studied.  This course will provide a basis for the understanding of these perceptual capabilities as components in Artificial Intelligence in aviation/aerospace systems."},
    {"id":"AVT 3500","title":"UAS Design Concepts","description":"Unmanned Aerial Systems (UAS) applications have resulted in an array of designs. This course reviews considerations that influence UAS design and includes an analysis of applications and requirements to inform the development of a proposed preliminary UAS design proposal. Includes aerodynamic, structural and sensor design considerations. Two classroom, four lab hours per week."},
    {"id":"AVT 4146","title":"Advanced Airline Operations & Training","description":"Advanced study of airline operations and training procedures with emphasis on training targeted at what a new-hire pilot would experience during Part 121 initial aircraft training, to include cockpit flows, checklist usage, and flight management systems indoctrination."},
    {"id":"AVT 4151","title":"Unmanned Systems Mission Planning","description":"This course provides an in-depth exploration of UAS mission planning for a variety of commercial and civil applications.  Includes a review of considerations, resources, and tools that will be used to create UAS flight plans that meet mission requirements while maintaining safety, following regulations, and considering commercial viability. One classroom, four lab hours per week."},
    {"id":"AVT 4154","title":"Advanced Flight Simulator Instruction","description":"Practical application of cross-country flight operations for the advanced pilot including air traffic control communications, flight planning, advanced instrument flight procedures and normal and abnormal flight procedures."},
    {"id":"AVT 4160","title":"System Safety in Aviation","description":"Advanced system safety management and the structured processes for recognizing the role of the flight crew and flight operations management in accident prevention, safety promotion, institution of a just safety culture, and the methods of assessing risk before a system fails."},
    {"id":"AVT 4170","title":"Airport Operations","description":"A study of airport management and government regulatory requirements under Part 139 including but not limited to, construction and maintenance of runways, taxiways, and ramps, noise abatement procedures, security, Notice to Airmen generation, and environmental impact studies."},
    {"id":"AVT 4171","title":"Advanced Flight Operations","description":"Practical application of complex, high performance aircraft in and out of controlled airspace and tower controlled airfields, advanced instrument procedures and simulated airline operations. One classroom, three lab hours per week."},
    {"id":"AVT 4210","title":"Advanced UAS Maintenance","description":"This course builds on the knowledge and skills developed in previous UAS maintenance courses. Topics include safety procedures, operator and depot level maintenance procedures, composite repair, fuel systems, data link and instrumentation systems, rigging and assembly, reliability testing and trouble shooting of UAS and their respective components. One classroom, four lab hours per week."},
    {"id":"AVT 4215","title":"Autonomous Systems in Aviation","description":"Introduction to Autonomous Systems. Surveys the fundamentals of autonomous aircraft system operations, from sensors, controls, and automation to safety procedures, human factors and Human Autonomy Teaming (HAT).  Presents the latest major commercial uses of UAS, and manned aircraft that will be going from 2-pilot operations to 1-pilot operations to unmanned operations.  Research and design an Autonomous System, build it, and test it."},
    {"id":"AVT 4220","title":"Human Autonomy Teaming in Aviation","description":"The field of human-autonomy teaming (HAT) is fast becoming a significant area of research, especially in aviation. The basic objective is to create natural and effective interactions between people and machines. HAT is highly interdisciplinary, bringing together methodologies and techniques from robotics, artificial intelligence, human-computer interaction, cognitive psychology, neuroscience, neuroergonomics, education, and other fields. The topics covered will include technologies that enable human-machine interactions, the psychology of interaction between people and machines, how to design and conduct HAT studies, and real-world applications such as assistive machines."},
    {"id":"AVT 4263","title":"Commercial Pilot Fixed Wing Add-on for Rotorcraft Pilots","description":"Provides students with the necessary skill, knowledge and aeronautical experience necessary to meet the requirements for a commercial pilot airplane certificate with single-engine, land additional class rating. Course Performance Standards require 55 hours of flight time and 100% completion of the course syllabus requirements. Additional fees including a course fee and a flight lab fee as well as other out-of-pocket expenses apply. Contact the Department for the current listing of these fees and expenses. One classroom, four lab hours per week."},
    {"id":"AVT 4270","title":"UAS Internship II","description":"This course provides a senior supervised work experience related to aviation and unmanned aerial systems (UAS) major and career focuses. Students will apply knowledge and skills while gaining professional work experience, which will enhance marketability and networking.  Note: Students should have their FAA Part 107 certificate prior to enrolling in this course."},
    {"id":"AVT 4279","title":"Unmanned Aerial Systems Senior Project","description":"This course provides a senior capstone project experience. Areas of focus that may be incorporated into the student designed project include but are not limited to entrepreneurial business plan development, application focused UAS flight operations, data analytics, and system and component design, prototyping, and testing. Two classroom, four lab hours per week."},
    {"id":"AVT 4290","title":"Aviation Senior Capstone Project","description":"Concludes the Bachelor of Applied Science degree where the student develops an aeronautical project that draws on all phases of aviation studies in the degree program. Working with their instructor and industry partners, students will develop a subject of research, evaluate data, and complete a project in a specific area of aviation study. Areas of focus that may be incorporated into the student designed project includes but are not limited to flight operations, aeronautical system and component design, prototyping, testing, artificial intelligence and autonomous systems. Two classroom, two lab hours per week."},
    {"id":"BIO 1101","title":"Body Structure & Function","description":"Basic anatomy and physiology background for medical personnel emphasizing basic principles of body structure and function."},
    {"id":"BIO 1104","title":"HIV/AIDS","description":"Basic understanding and function of the human immune system and the effects of viruses (HIV/AIDS) on the human immune system."},
    {"id":"BIO 1107","title":"Human Biology","description":"The survey course studying the structure and function of the human body. Topics include introductory terminology, cytology, the integumentary system, the skeletal system, the muscular system, the nervous system, the endocrine system, the cardiovascular system, (blood, heart and blood vessels), the lymphatic system, the respiratory system, the digestive system, the urinary system and the reproductive system. Two classroom, two lab hours per week."},
    {"id":"BIO 1108","title":"Lab for Human Biology","description":"The lab component of a survey course that studies the structure and function of the human body. Lab work topics include histology, cytology and the anatomy of the skeleton, muscles, nervous system structures, blood components, the heart, blood vessels and structures within the respiratory, digestive, urinary and male and female reproductive systems. Summarization is achieved through the dissection of a preserved fetal pig."},
    {"id":"BIO 1111","title":"General Biology I","description":"This course is designed as the first in a series of two general education science courses.  Covers basic chemistry and biochemistry; cellular and molecular biology. Three classroom, two lab hours per week."},
    {"id":"BIO 1117","title":"Lab for General Biology I","description":"The lab for this course is the first in a series of two general education science courses.  Covers laboratory exercises relevant to basic chemistry and biochemistry; cellular and molecular biology."},
    {"id":"BIO 1121","title":"Human Anatomy & Physiology I","description":"The first course in a two-semester sequence studying the structure and function of the human body. Topics include introductory terminology, biochemistry, cytology, the integumentary system, the skeletal system, the muscular system, the nervous system and the endocrine system. Two classroom, two lab hours per week."},
    {"id":"BIO 1141","title":"Principles of Anatomy & Physiology I","description":"The first course in a two-semester sequence studying the structure and function of the human body. Topics include introductory terminology, biochemistry, cells, the integumentary system, the skeletal system, the muscular system, the nervous system and the endocrine system. Three classroom, two lab hours per week."},
    {"id":"BIO 1147","title":"Lab for Principles of Anatomy & Physiology I","description":"Lab for the first course in a two semester sequence studying the structure and function of the human body."},
    {"id":"BIO 1171","title":"Principles of Biology I","description":"The first course of a two-semester university-parallel sequence for biology and science majors. Topics include scientific method; chemical and biochemical foundations; cell structure, function and reproduction; cellular respiration, photosynthesis, Mendelian genetics, chromosomal genetics, molecular genetics, protein synthesis, gene regulation, genomes, viruses and biotechnology. Three classroom, six lab hours per week."},
    {"id":"BIO 1211","title":"General Biology II","description":"This course is designed as the second in a series of two general education science courses.  Covers evolution, biodiversity and ecology. Three classroom, two lab hours per week."},
    {"id":"BIO 1217","title":"Lab for General Biology II","description":"This second lab is in a series of two general education science courses.  Covers laboratory exercises relevant to evolution, biodiversity and ecology."},
    {"id":"BIO 1222","title":"Human Anatomy & Physiology II","description":"The second course in a two-semester sequence studying the structure and function of the human body. Topics include the cardiovascular system, the lymphoid system, immunity, the digestive system, the urinary system and the reproductive system. Two classroom, two lab hours per week."},
    {"id":"BIO 1242","title":"Principles of Anatomy & Physiology II","description":"The second course in a two-semester sequence studying the structure and function of the human body. Topics include the cardiovascular system, the respiratory system, the digestive system, metabolism, the urinary system, fluid and electrolyte balance, acid-base balance and the reproductive system. Three classroom, two lab hours per week."},
    {"id":"BIO 1248","title":"Lab for Principles of Anatomy & Physiology II","description":"Lab for the second course in a two-semester sequence studying the structure and function of the human body."},
    {"id":"BIO 1272","title":"Principles of Biology II","description":"The second course of a two-semester university-parallel sequence for biology and science majors. Topics include Darwinian evolution, evolution of populations, origin of species, history of life on Earth, phylogeny and systematics, prokaryotes, protists, plants, fungi, animals and ecology. Three classroom, six lab hours per week."},
    {"id":"BIO 2205","title":"Microbiology","description":"Morphology and physiology of microorganisms and selected human parasites, mechanisms of disease production, host responses, spread of infectious diseases.  Three classroom, three lab hours per week."},
    {"id":"BIO 2206","title":"Lab for Microbiology","description":"Students carry out aseptic techniques; simple and special staining procedures; methods utilized for culturing, isolation and identification of bacteria (known and unknown); molecular genetic and immunological methods dealing with microbes. Also, exercises involving eukaryotic microbes (fungi, protozoa and helminths) are conducted."},
    {"id":"BIO 2211","title":"Human Physiology","description":"Essentials of human physiology for students who have previous course work in human anatomy and physiology.  Topics include biochemistry, cell physiology and physiology of the major organ systems. Three classroom, three lab hours per week."},
    {"id":"BIO 2212","title":"Lab for Human Physiology"},
    {"id":"BIO 2222","title":"Evolution","description":"Emphasis on Charles Darwin, speciation, fossils, radiometric dating, natural selection, mutations, macroevolution, mass extinctions, coevolution, sexual reproduction, human evolution and religious issues."},
    {"id":"BIO 2225","title":"Ecology","description":"General concepts in ecology and application to current environmental issues. Focus on evolutionary ecology, populations, communities, ecosystems and global ecology. Field experiences and lab techniques emphasizing data collection, analysis and interpretation. Three classroom, three lab hours per week."},
    {"id":"BIO 2235","title":"Genetics","description":"Fundamental principles, concepts and techniques of genetics. Lab work includes basic methods of genetic research and analysis. Three classroom, two lab hours per week."},
    {"id":"BIO 2236","title":"Lab for Genetics"},
    {"id":"BIO 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format."},
    {"id":"BIS 1105","title":"IT Fundamentals","description":"This course is designed for students who are interested in Information Technology but have limited technical knowledge of computing systems at the fundamental level. Students will explore foundational concepts related to computer hardware and software, networking, databases, programming, Information Systems, and data security."},
    {"id":"BIS 1120","title":"Introduction to Software Applications","description":"Use word processing, spreadsheet, database and presentation software applications to create reports, spreadsheets, databases and presentations for business and other applications."},
    {"id":"BIS 1201","title":"Keyboarding & Document Formatting","description":"Students will learn and develop \"touch\" keyboarding skills, including the ten-key numeric keypad.  By the end of the course, students must perform keyboarding speed and accuracy on three-minute timed writings at a minimum of 30 wpm with three or fewer errors.  Students will learn to use word processing software to format and produce reports, letters, memos, and other business documents.  Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"BIS 1220","title":"Word Processing Software","description":"Beginning to advanced word processing software concepts including editing, formatting, desktop publishing design and editing techniques, document control and automation."},
    {"id":"BIS 1221","title":"Specialized Computer Applications for Health Information Management","description":"Introduces students to personal computer concepts including hardware, system software, application software, and the Internet.  Learn the components of computer systems and develop a broad understanding of computer hardware and emerging technologies.   Students will be introduced to Office application software (word processing, spreadsheets, presentation software, and databases,) and specific features of those applications for medical reports, narrating presentations, Autofilters, form creation and software integration will be applied."},
    {"id":"BIS 1230","title":"Spreadsheet Software","description":"Students will learn techniques to properly manage large and multi-sheet spreadsheets, use spreadsheets to arrange and manage data, develop advanced spreadsheet formulas and functions, perform &quot;What-If&quot; analysis using spreadsheet tools and design and create end-user spreadsheet applications."},
    {"id":"BIS 1240","title":"Presentation Software","description":"Beginning to advanced presentation software techniques including editing and formatting presentations, fundamentals of effective presentations, transitions, animations, multimedia content, advanced navigation tools and master and template modification."},
    {"id":"BIS 1250","title":"Specialized Business Software Application","description":"Introduction to a specialized business software application such as MS SharePoint, MS Outlook, etc., that will provide an overview of the application's features and common uses."},
    {"id":"BIS 1260","title":"Database Software","description":"Students will learn to design and manage databases using the relational model, use database objects to manage data including data integrity, data analysis and reporting, learn to derive useful information from raw data using functions and querying techniques, and create end-user database applications."},
    {"id":"BIS 1400","title":"Customer Service","description":"Introduction to concepts of customer service. Topics to include: face-to-face and phone-based communication with customers, professionalism and workplace behavior, decision making, problem solving, conflict resolution and negotiation skills, use of emerging technologies, role-play scenarios, case studies and preparation for career advancement."},
    {"id":"BIS 1500","title":"Software Testing Fundamentals","description":"In this course, students will be introduced to the field of software testing at the fundamental level. Software testing theory and terminology for this course are primarily based on the International Software Testing Qualifications Board (ISTQB) foundation level certification requirements. Students will learn about the practical application of software testing throughout the software development life cycle.  Hands-on exercises will provide students with practical skills in executing test cases, documenting results and participating in peer reviews. Successful completion of this course will entitle students to sit for the ISTQB Foundation Level certification exam."},
    {"id":"BIS 1600","title":"Data Management & Visualization","description":"In today’s business environment, the need to work effectively and efficiently with data is critical. This course primarily focuses on using spreadsheet software to acquire data from various sources, transform that data into useful information and create analytical tools in the form of powerful visualizations that will enhance business intelligence."},
    {"id":"BIS 2170","title":"BIS Capstone","description":"Analyze business problems and apply critical-thinking skills and software knowledge and communication skills learned in previous classes to solve problems and perform work-related tasks."},
    {"id":"BIS 2180","title":"Medical Office Simulation","description":"Basic principles of office support, bookkeeping, record-keeping and reporting responsibilities pertinent to the medical office and health care agencies."},
    {"id":"BIS 2270","title":"Business Information Systems Internship","description":"Students will work in a technical or software support capacity for a cooperating organization for 210 hours during a semester.  Responsibilities are established by the worksite supervisor, and students will use the listed responsibilities to develop learning outcomes and identify action steps that are approved by the worksite supervisor and internship faculty member.  Students will be evaluated by their worksite supervisor at the middle and end of the semester.  Students will post weekly journal entries online regarding their experience, and complete projects specific to their degree concentration.  Thirty co-op hours per week.  Recommended prerequisites: BIS 1220, BIS 1230, BIS 1250 and BIS 1260."},
    {"id":"BIS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"BIT 0010","title":"Computer Fundamentals","description":"This hands-on class focuses on the components of a personal computer, an introduction to the Windows environment, and use of the mouse and understanding icons, buttons, and menus.  Also includes file management including creating directories, copying and moving files, and changing and enhancing desktop features.  The Internet and Sinclair&apos;s Learning Management System will be explored.  Students with little or no current computer skills will find this fundamental course helpful.   Note:  As with all courses that begin with a zero, this course is developmental in nature. Credits earned in developmental courses will not apply to the overall program hours."},
    {"id":"BTN 1110","title":"Biotechnology & Bioethics","description":"Introduction to the major fields in biotechnology and the basic science involved in understanding those fields."},
    {"id":"BTN 1120","title":"Laboratory Safety & Regulatory Compliance","description":"Introduction to lab safety culture, precautionary labels, Material Safety Data Sheets, using personal protective equipment, handling lab equipment safely, handling , storing and disposing of chemicals safely, using emergency equipment as well as safety planning."},
    {"id":"BTN 1130","title":"Biological Reagents Preparation","description":"Basic understanding and overview of chemical grades of reagents used in biological research with an emphasis on chemical formulas and preparation of biological media and reagents.  Two classroom, three lab hours per week."},
    {"id":"BTN 1131","title":"Lab for Biological Reagents Preparation"},
    {"id":"BTN 1140","title":"Cell Culture","description":"Introduction to cell culturing techniques. One classroom, two lab hours per week."},
    {"id":"BTN 1141","title":"Lab for Cell Culture"},
    {"id":"BTN 1201","title":"Biotechnology Careers","description":"Discover career opportunities, develop a resume/cover letter and increase interviewing skills for the biotechnology industry."},
    {"id":"BTN 2210","title":"Protein Purification & Analysis","description":"Introduction to protein purification, isolation quantification and analysis. Two classroom, four lab hours per week."},
    {"id":"BTN 2211","title":"Lab for Protein Purification & Analysis","description":"Fundamental principles, concepts, and techniques of protein purification techniques.  Lab work includes basic methods of protein research and analysis."},
    {"id":"BTN 2220","title":"Microbiology & Fermentation Methods","description":"Introduction to fermentation and microbial metabolism. Two classroom, three lab hours per week."},
    {"id":"BTN 2221","title":"Lab for Microbiology & Fermentation Methods","description":"Introduction into fermentation and microbial metabolism within the laboratory setting."},
    {"id":"BTN 2230","title":"Molecular Biology Techniques","description":"Structure and function of macromolecules and their interactions in DNA replication, DNA cloning and genetic engineering techniques analysis, introduction to public domain DNA and protein sequence databases, use of software and internet resources for database searching. Two classroom, four lab hours per week."},
    {"id":"BTN 2231","title":"Lab for Molecular Biology Techniques","description":"Structure and function of protein and nucleic acids, DNA replication mechanisms, DNA cloning, genetic engineering techniques, use of plasmids, nucleic acid analysis by electrophoresis, Southern hybridization, DNA amplification and sequencing, introduction to public domain DNA and protein sequence databases, use of software and internet resources for database searching."},
    {"id":"BTN 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"BTN 2700","title":"Biotechnology Internship","description":"Students work at an approved biotechnology industry site and will earn credits toward degree requirements for their work experiences. Students already working may apply to use that experience to meet internship requirements. Students prepare and submit reports and/or projects describing their industry experience and are evaluated by the course instructor as well as their on-site supervisor. Ten work hours per week per credit hour."},
    {"id":"CAM 1107","title":"Introduction to Mechanical Drafting with AutoCAD","description":"The course focuses on the study and interpretation of the graphic language used in manufacturing and engineering. This will include principles of: shape description, axonometric projection, specifications, symbology and spatial relationships.The student will apply problem solving and critical thinking skills using both standard and automated communication methods. Two classroom, two lab hours per week."},
    {"id":"CAM 1109","title":"Fundamentals of Tooling & Machining","description":"An introduction to the manufacturing processes used in the tooling and machining industry. Safety, mechanical hardware, hand tools, metrology, drill press, vertical mill, lathe, and the surface grinder will be the major focus of this course. Two classroom, two lab hours per week."},
    {"id":"CAM 1110","title":"Advanced Machine Operations","description":"This course will increase student’s proficiency in the use of manually operated machine shop equipment with an emphasis on high tolerance parts where precision machining is necessary for project completion. Two classroom, two lab hours per week."},
    {"id":"CAM 1111","title":"Lab for Advanced Machine Operations","description":"An advanced lab in the manufacturing processes used in the tooling and machining industry. Safety, handtools, metrology, engine lathe, milling, sawing, and precision grinding, working within close tolerances along with assembly and fit will be the major focus of this course.  Two classroom, two lab hours per week."},
    {"id":"CAM 1116","title":"Fundamentals of Computer Numerical Control Operations","description":"This course will cover machine safety, setup and operation of computer numerical control (CNC) milling machines and lathes. Adjusting tool and work offsets to hold part tolerances on both types of equipment. Calculation of spindle speeds and feed rates. Introduction to basic programming codes and development of CNC programs for three axis mills and two axis lathes.  Two classroom, two lab hours per week."},
    {"id":"CAM 1142","title":"Advanced Shop Floor Math","description":"This course applies the principles of geometry and trigonometry and the computing of angles using law of sines and law of cosines dealing with situations encountered in the machining industry. It also gives a brief introduction to the calculations required in computer numerical control programming. Two classroom, two lab hours per week."},
    {"id":"CAM 1161","title":"Machine Operations Laboratory I","description":"An introduction to the manufacturing processes used in the tooling and machining industry.  Safety, handtools, metrology, engine lathe, milling, sawing and grinding will be the major focus of this course.  Two classroom, eighteen lab hours per week."},
    {"id":"CAM 1162","title":"Machine Operations Laboratory II","description":"Students will advance their proficiency in the use of manually operated machine shop equipment. Emphasis will be on precision grinding, fitting parts for assembly, increasing independence developing process plans and setting up machinery. Two classroom, eighteen lab hours per week."},
    {"id":"CAM 1180","title":"Welding & Metal Joining I","description":"This course is a broad introduction to welding with in-depth hands-on labs to include SMAW, GMAW, OAW, GTAW, and Resistance, commonly known as MIG, TIG, Ox-acetylene, and Stick (ARC) welding along with other types of metal joining options and instruction to include sheet metal layout, shearing, bending, riveting, brazing and spot welding.  Welding safety, personal protection equipment, special welding tools will be taught in-depth and used by the students within the lab setting.  The student will apply critical thinking and problem-solving skills to achieve the end results required using blue prints, verbal instructions or a combination of both.  Two classroom, two lab hours per week."},
    {"id":"CAM 1181","title":"Welding & Metal Joining II","description":"This course, the second course in this series, on welding and metal joining will dive further into the this career field providing further in-depth hands-on labs which will include MIG, TIG, Stick (ARC), and Ox-acetylene use along with other types of metal joining options and instruction to include sheet metal layout, shearing, bending, riveting, brazing and spot welding. This course will include a deeper understanding of design and blueprints along with manual machining integration and new fabrication techniques like waterjet and laser technology. The student will apply critical thinking and problem solving skills to achieve the end results required using blue prints, verbal instructions and a combination of both. Two classroom, two lab hours per week."},
    {"id":"CAM 1184","title":"Weldability of Ferrous & Nonferrous Metals","description":"This course will provide the non- metallurgist with basic knowledge of various metals and their weldability, including metal properties, selecting filler metals, heat input, preheating, and post-heating. The course will consist of and be designed to provide training on metal properties, basic metallurgy, classification of ferrous metals, heat treatments, hard surfacing, and metals identification; along with weldability of stainless steels, tool steels, cast iron,  aluminum alloys, magnesium alloys, nickel alloys, and titanium alloys. Two classroom, two lab hours per week."},
    {"id":"CAM 1186","title":"Welding for Tool Room, Fixture & Repair","description":"Welding is more than just joining metals and hosts a wide variety of uses within the manufacturing industry which provides careers in tool room welding, fixture welding and repair, and repair welding in general from small home repairs to larger industrial repairs. This course provides in-depth coverage of topics to include:  safety, print reading for tooling, jig and fixture repair, stamping die repair, forgings and casting repair,  mild steel repair, soldering and brazing repair, strength of materials, welding repair for enclosed tanks and vessels, welding repair for exotic metal repair, welding repair for the household, and lab problems in weld repair. This course provides hands-on welding projects preparing students for exciting careers involving welding in the manufacturing world.  Two classroom, two lab hours per week."},
    {"id":"CAM 1214","title":"Computer Numerical Control Mill Programming","description":"This is an intermediate course covering the development of Computer Numerical Control (CNC) programs for three axis milling machines including spindle controls, tool changes, linear and circular interpolation, drilling and tapping, subroutines, and G&amp;M codes. Setup and operation of milling machines. Adjusting tool and work offsets to hold part tolerance. Two classroom, two lab hours per week."},
    {"id":"CAM 2114","title":"Jig & Fixture Design","description":"Theory, principles and drawing techniques for the design of jigs and fixtures.  Two classroom, two lab hours per week."},
    {"id":"CAM 2145","title":"Shop Floor Programming","description":"Operation and programming of conversational controlled lathe and milling machines. Includes programming and manufacturing a variety of machined parts utilizing ProtoTRAK two-axis and three-axis conversational CNC controls. Two classroom, two lab hours per week."},
    {"id":"CAM 2204","title":"Computer Numerical Control Lathe Programming","description":"Development of Computer Numerical Control (CNC) programs for two axis CNC lathes including linear and circular interpolation, turning,grooving and threading cycles; drilling and tapping; G &amp; M codes. Review setup and operation of CNC lathe; adjusting tool offsets to hold part tolerance.  Two classroom, two lab hours per week."},
    {"id":"CAM 2212","title":"Computer Assisted Programming","description":"An introductory course in the use of Computer Aided Design (CAD)/Computer Aided Manufacturing (CAM) software (MasterCAM) as applied to computer numerical control vertical machining centers.  Two classroom, two lab hours per week."},
    {"id":"CAM 2225","title":"Tool Design","description":"Design theory, principles and drawing techniques for the tool design industry.  Two classroom, two lab hours per week."},
    {"id":"CAM 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"CAM 2700","title":"Computer Aided Manufacturing Internship","description":"Students earn credits toward degree requirements for work learning experience, especially related to a co-op experience. Ten co-op hours per credit hour per week."},
    {"id":"CAM 2780","title":"Computer Aided Manufacturing Capstone","description":"Assessment of achievement by Computer Aided Manufacturing students in attaining program-related outcomes by completing a project demonstrating principles and practices of the major.  Two classroom, two lab hours per week."},
    {"id":"CAM 2781","title":"Precision Machining Capstone","description":"Assessment of achievement by Precision Machining students in attaining program related outcomes with the completion of a comprehensive project. They will demonstrate the principles and practices of the Precision Machining major.  Two classroom, two lab hours per week."},
    {"id":"CAT 1101","title":"Architectural Graphics I","description":"Introduction to various graphic media tools and techniques, including sketching, manual drafting, and computer assisted drafting as appropriate to the built environment.  Includes developing 3D visualization and analysis skills.  Two classroom, two lab hours per week."},
    {"id":"CAT 1111","title":"Mechanical Systems Blueprint Reading","description":"Reading blueprints of commercial buildings, emphasizing plumbing, electrical, HVAC and fire protection systems.  One half  classroom, one and one half lab hours per week."},
    {"id":"CAT 1121","title":"Architectural Graphics II","description":"Learn Building Information Modeling (BIM) techniques and methodology.  Develop proficiency with Revit Architecture modeling software including: user interface, modeling techniques, proper modeling workflow and document generation.  Learn rendering and animation communication techniques.  Two classroom, two lab hours per week."},
    {"id":"CAT 1131","title":"Introduction to Revit MEP","description":"Study and application of advanced drawing using AutoDesk Revit. Major emphasis on building information modeling (BIM) theory along with construction of Mechanical, Electrical and Plumbing (MEP) systems.  Two classroom, two lab hours per week."},
    {"id":"CAT 1141","title":"Architectural Blueprint Reading","description":"Basic techniques for reading and interpreting construction plans and specifications, both residential and commercial. Includes all major building uses and types of construction as defined by the building code.  One classroom, two lab hours per week."},
    {"id":"CAT 1161","title":"Introduction to Civil & Architectural Technology","description":"An introduction to career fields of Architecture and Civil Engineering Technology.  Equivalent to Project Lead the Way CEA.  One classroom, two lab hours per week."},
    {"id":"CAT 1201","title":"Construction Methods & Materials","description":"Construction methods of materials for both residential and commercial structures. Emphasis on processes and techniques. Understanding of blueprint reading of architectural and civil drawings. Hands-on exercises of residential and commercial applications. Two classroom, three lab hours per week."},
    {"id":"CAT 1211","title":"Construction Materials Testing","description":"This course presents some of the basics of testing materials (concrete, steel, wood, etc.) used in the construction industry. Emphasis on how properties of materials affect their use in the construction process. Utilizes American Society for Testing and Materials (ASTM) Standards. One classroom, three lab hours per week."},
    {"id":"CAT 1241","title":"Building Systems","description":"Basic mechanical and electrical system design principles for residential and commercial structures.  Structural engineering principles for designing residential and commercial structures using wood, steel and concrete.  Research appropriate building codes and apply knowledge to solve engineering challenges.  Two classroom, two lab hours per week."},
    {"id":"CAT 1301","title":"Introduction to Civil Construction CAD","description":"Develop proficiency in CAD software to develop civil-construction working drawings. Proper generation of plans to connect the earth&apos;s topography and land records will be emphasized.  Two classroom, two lab hours per week."},
    {"id":"CAT 1341","title":"Architectural Design I","description":"Architecture is the manifestation of history, the values of a society, and our collective human sensibility.  Introductory survey of world architectural history and theory.  Develop understanding of architectural vocabulary, form and expression.  Examine worlds major monuments and buildings. Two classroom, two lab hours per week."},
    {"id":"CAT 1401","title":"Construction Estimating","description":"Construction estimating, beginning with an understanding of the costs of labor equipment and materials, as well as profit and overhead.  Quantity measurements of basic construction materials will be used to develop bidding packages.  Two classroom, two lab hours per week. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"CAT 1431","title":"OSHA Construction Standards 10 Hour","description":"Introduction to rules, interpretations, record keeping and standards required by OSHA (29CFR Part 1926) for the construction industry to ensure employees a safe, healthful work place. The course complies with the guidelines and requirements for the OSHA 10 hour outreach training completion card."},
    {"id":"CAT 1501","title":"Fundamentals of Surveying & Mapping","description":"This course covers the fundamental principles of distance, elevation and angular measurements used in the practice of engineering surveys.  It also includes basic error theory in field observations and mathematical calculations, level circuit and traverse field techniques and basic principles of digital map making.  Two classroom, three lab hours per week."},
    {"id":"CAT 1601","title":"Building Electric & Controls","description":"An introduction to building electrical and control systems for HVAC technicians.  Includes AC/DC circuits, single phase and three phase motors and motor control, HVAC equipment control, wiring techniques, control components including sequencers, and an introduction to building pneumatic and DDC control. Two classroom, four lab hours per week."},
    {"id":"CAT 1701","title":"Construction Craft Skills/Concrete","description":"An orientation to construction trades and working with concrete with strong emphasis on hands-on learning exercises. Includes Occupational Safety &amp; Health Administration (OSHA) 10-hour construction safety. Two classroom, four lab hours per week."},
    {"id":"CAT 1721","title":"Structural Framing Systems","description":"Advanced technical training in wood and light-gauge steel framing systems, including exterior wall finishing and roof construction. Two classroom, four lab hours per week."},
    {"id":"CAT 1741","title":"Residential Electrical Systems","description":"Basic safety procedures, use of power and hand tools, electrical circuit theory and basics of residential wiring observing the National Electric Code (NEC).  One classroom, four lab hours per week."},
    {"id":"CAT 1761","title":"Interior & Exterior Finishes","description":"An orientation to interior and exterior finishes on frame construction. One classroom, four lab hours per week."},
    {"id":"CAT 1781","title":"Construction Project","description":"Students will work on a significant construction project such as a home for Habitat for Humanity. This course gives students hands-on experience in all phases of a construction project. Most class sessions will be at a construction site in the Greater Dayton Metropolitan area. One classroom, six lab hours per week."},
    {"id":"CAT 1810","title":"Construction Techniques I (NCCER Core)","description":"Basic safety, hand and power tools, wood building materials and fasteners and framing systems. May be taken for two semesters. One classroom, four lab hours per week."},
    {"id":"CAT 1820","title":"Construction Techniques II (NCCER Level 1)","description":"Construction of concrete structures including forming, placing and finishing.  May be taken for two semesters.  One  classroom, four lab hours per week."},
    {"id":"CAT 1830","title":"Construction Techniques III (NCCER Level 2)","description":"Exterior and interior finishing of frame structures including roofing materials, siding, drywall, stairs, doors and trim. May be taken for two semesters. One classroom, four lab hours per week."},
    {"id":"CAT 1840","title":"Construction Techniques IV (NCCER Level 3)","description":"Development of advanced skills for construction technicians including site layout, floor and roof systems and metal buildings. An introduction to welding, light equipment operation and project management. May be taken for two semesters.  One classroom, four lab hours per week."},
    {"id":"CAT 2101","title":"Architectural Design II","description":"Develop proficiency with Revit design techniques, developing architectural models and coordinating a set of construction documents. Includes architectural and Mechanical, Electrical and Plumbing (MEP) problem-solving skills. Two classroom, six lab hours per week."},
    {"id":"CAT 2201","title":"Architectural Visualization","description":"Learn the philosophy of building information modeling and how Revit and other computer-based modeling software can assist in the design, analysis and documentation of buildings.  Two classroom, two lab hours per week."},
    {"id":"CAT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"CAT 2301","title":"Land Development Design in Civil 3D","description":"Application of civil engineering technology principles to land development. Design elements include boundary and topographic surveying, remote sensing, roadway, hydrology and quality estimates within Computer Aided Design software. Emphasis is placed upon federal, state and local regulations related to the development of real property. Two classroom, two lab hours per week."},
    {"id":"CAT 2401","title":"Engineering Technology Project Management","description":"Practical planning and control of construction and engineering-based projects. Interrelationships and operations of project management and skills required for success in the current engineering environment. Theory, nomenclature and practical application of engineering management using computer software. Two classroom, two lab hours per week.  Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"CAT 2411","title":"Architectural Practice, Codes & Laws","description":"Building code history, development, application and review processes. Analysis of existing building to determine code status, form recommendations and make presentation.  Examine Ohio law impacting various codes, safety, contracting and personnel issues with regard to built environment professions. Develop sample contract specifications. Two classroom, two lab hours per week."},
    {"id":"CAT 2421","title":"Soil Mechanics","description":"Theories of soil mechanics including soil classifications, sampling and testing methods, stress distribution, shearing resistance and strength of soils. Two classroom, two lab hours per week."},
    {"id":"CAT 2431","title":"OSHA Construction Standards","description":"Rules, interpretations, record keeping and standards required by Occupational Safety & Health Administration (OSHA) (29CFR Part 1926) for the construction industry to ensure employees a safe, healthful workplace. Successful completion of the course provides the 30 hour OSHA Construction Safety Card."},
    {"id":"CAT 2501","title":"GPS & GIS for Engineering Technology Professionals","description":"This course covers collection, adjustment, analysis and management of geospatial data used in land development.  Integration of Global Positioning Systems field collected data with Geographic Information Systems to maintain public works, cadastrial and utility record keeping systems.  One classroom, three lab hours per week."},
    {"id":"CAT 2531","title":"Advanced Surveying & Mapping","description":"Utilization of surveying equipment and Computer Aided Drafting (CAD) software to perform field data collection and produce civil engineering drawings. Two classroom, three lab hours per week."},
    {"id":"CAT 2561","title":"Route Surveying with Construction Applications","description":"Solving complex surveying problems for construction layout of buildings, sites and roads using appropriate mathematical calculations and surveying equipment. One classroom, two lab hours per week."},
    {"id":"CAT 2571","title":"NSPS Certified Survey Technician Preparation","description":"This course is an in-depth review of the eleven areas of concentration in preparation of the Level I and/or Level II Survey Technician Certification used by the National Society of Professional Surveyors (NSPS). One classroom, two lab hours per week."},
    {"id":"CAT 2581","title":"Legal Principles for Surveyors","description":"Legal principles of surveying.  Field investigation and case studies are used to understand the elements that govern establishment of real property boundaries."},
    {"id":"CAT 2610","title":"Stakeholders & Participants for Design & Construction Projects","description":"Understanding the role and responsibilities of those individuals and organizations actively involved in the project delivery process. Development of interrelationships, communication skills and procedures required for the successful administration of the construction process from start-up to close out. Traditional testing (proctored or in Testing Center) is used in all online sections. Two classroom, three lab hours per week."},
    {"id":"CAT 2620","title":"Construction Documents, Legal Requirements, & Project Delivery","description":"Understanding the content of contract and construction documents with respect to the responsibilities and legal obligations for the stakeholders and participants of a construction project. Development of knowledge base for different types of project delivery systems. Develop an understanding of project control methods and procedures that comply with industry standards and practice. Traditional testing (proctored or in Testing Center) is used in all online sections. Two classroom, three lab hours per week."},
    {"id":"CAT 2630","title":"Architectural Practice Project Deliverables & Contractual Obligations","description":"Develop a working knowledge of the architectural program development process for the design of a construction project. Understanding of the design process with owner review, comment, and questions. Working knowledge of the contractual obligations and project deliverables for the architect and their consultants for the construction project. Practical planning and control of the drawings, revisions, requests for interpretations, submittals, shop drawings and close out documents. Traditional testing (proctored or in Testing Center) is used in all online sections. Two classroom, three lab hours per week."},
    {"id":"CAT 2640","title":"Construction Project Change Management","description":"Practical initiation and control of changes to design documents for construction projects. Implementation and documentation of procedures to approve and distribute the changes deemed necessary to accommodate unforeseen conditions, omissions and errors in the design and construction of the project. Development of effective meeting management and communication skills."},
    {"id":"CAT 2700","title":"Architectural Technology Internship","description":"Students earn credits toward degree requirements for work-based learning experience. Students already working may apply to use that experience to meet internship requirements. In collaboration with faculty and employers, students establish learning outcomes and prepare related reports and/or projects each term. Ten work hours per week per credit hour."},
    {"id":"CAT 2701","title":"Civil Engineering Technology Internship","description":"Students earn credits toward degree requirements for work-based learning experience. Students already working may apply to use that experience to meet internship requirements. In collaboration with faculty and employers, students establish learning outcomes and prepare related reports and/or projects each term. Ten work hours per week per credit hour."},
    {"id":"CAT 2702","title":"Construction Management Technology Internship","description":"Students earn credits toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each term. Ten work hours per week per credit hour."},
    {"id":"CAT 2741","title":"Current Topics in Architecture","description":"Explore recent developments in the architectural profession, especially as related to the architectural technology curriculum. Topics to include environment, green building, energy conservation, building technology, etc.  One classroom, two lab hours per week."},
    {"id":"CAT 2780","title":"Architectural Technology Capstone","description":"Assessment of achievement by Architectural Technology students in attaining program outcomes by completing a project demonstrating principles and practice of the major. Teamwork on projects will be emphasized. Only offered spring semester. Should be taken last spring term of program. Two classroom, six lab hours per week."},
    {"id":"CAT 2781","title":"Civil Engineering Technology Capstone","description":"Assessment of achievement by Civil Engineering Technology students in attaining program outcomes by completing a project demonstrating principles and practices of the major. Teamwork on projects will be emphasized. Only offered spring semester.  Should be taken last spring term of program. Two classroom, six lab hours per week."},
    {"id":"CAT 2782","title":"Construction Management Technology Capstone","description":"Assessment of achievement by Construction Management Technology students in attaining program outcomes by completing a project demonstrating principles and practice of the major. Teamwork on projects will be emphasized.  Only offered spring semester. Should be taken last spring term of program. Two classroom, six lab hours per week."},
    {"id":"CHE 1111","title":"Introduction to Chemistry I","description":"An introductory survey course for students pursuing health science degrees or who have not previously taken high school chemistry. Topics include matter and measurement, atoms and molecules, chemical reactions, energy changes, atomic structure and bonding, acid/base chemistry, chemical kinetics, and organic chemistry. Three classroom hours, two lab hours per week. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"CHE 1121","title":"Introduction to Chemistry II","description":"The second half of an introductory survey course for students pursuing health science degrees or biotechnology. Topics include organic functional groups, biomolecules, enzymes, body fluids and the metabolism of carbohydrates, proteins and lipids. Three classroom, three lab hours per week."},
    {"id":"CHE 1151","title":"Lab for Introduction to Chemistry I"},
    {"id":"CHE 1161","title":"Lab for Introduction to Chemistry II"},
    {"id":"CHE 1211","title":"General Chemistry I","description":"A university-parallel course in chemistry for the science major. The first half of a comprehensive first-year survey of chemistry. Topics include the basics of matter, atoms and molecules, chemical reactions, bonding, molecular geometry and gases. Students registering for this course should have previously taken high school chemistry or equivalent. Four classroom hours, three lab hours per week."},
    {"id":"CHE 1221","title":"General Chemistry II","description":"The second half of a university-parallel course in chemistry for the science or engineering major. Topics include liquids and solids, solutions, chemical reaction kinetics, chemical equilibrium, acid/base chemistry, electrochemistry, representative metals, metalloids and non-metals and organic chemistry. Four classroom hours, three lab hours per week."},
    {"id":"CHE 1251","title":"Lab for General Chemistry I"},
    {"id":"CHE 1261","title":"Lab for General Chemistry II"},
    {"id":"CHE 1311","title":"College Chemistry I","description":"A university-parallel course in chemistry for the nonscience major. Atomic theory, periodic law, chemical bonds, chemical reactions, states of matter, solutions, acids and bases and the impact of chemistry upon the world and the environment. Three classroom, two lab hours per week. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"CHE 1321","title":"College Chemistry II","description":"A university-parallel course in chemistry for the non-science majors. A continuation of College Chemistry I. Oxidation/reduction reactions, nuclear reactions, organic chemistry, polymers, energy, structure of biomolecules and biochemistry, nutrition, medicines, chemistry of useful materials. Three classroom, two lab hours per week."},
    {"id":"CHE 1351","title":"Lab for College Chemistry I","description":"Lab for CHE 1311."},
    {"id":"CHE 1361","title":"Lab for College Chemistry II","description":"Lab for CHE 1321."},
    {"id":"CHE 2111","title":"Organic Chemistry I","description":"The study of alkanes, stereochemistry, alkyl halides, organometallic compounds, alkenes, alkynes, aromatic hydrocarbons and spectroscopic methods of organic analysis. Four classroom, three lab hours per week."},
    {"id":"CHE 2121","title":"Organic Chemistry II","description":"The study of alcohols, ethers, epoxides, aldehydes, ketones, carboxylic acids, derivatives of carboxylic acids, enolates, carbanions, amines, polycyclic and heterocyclic aromatic compounds, pericyclic reactions, and polymers. Four classroom, three lab hours per week."},
    {"id":"CHE 2151","title":"Lab for Organic Chemistry I","description":"Lab for CHE 2111."},
    {"id":"CHE 2161","title":"Lab for Organic Chemistry II","description":"Lab for CHE 2121."},
    {"id":"CHE 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"CHE 2781","title":"Methods & Practice of Teaching Science in Secondary Schools","description":"This course provides the framework for the development of methods and pedagogy for the effective teaching of science content in secondary schools. Designed to immerse the future teacher in the areas of problem solving, science in everyday life, scientific methods, process-oriented learning, scientific inquiry and integrating technology. The development of pedagogical content knowledge as it pertains to the teaching and learning of science is emphasized. Various assessment strategies are introduced including using data to improve student achievement."},
    {"id":"CHN 1100","title":"Conversational Chinese I","description":"A foundation for gaining knowledge about Chinese culture and basic phrases related to simple spoken Chinese, including travel situations."},
    {"id":"CHN 1101","title":"Elementary Chinese I","description":"Foundation for understanding, speaking, reading and writing Chinese. Work outside of class and/or in the language laboratory is required."},
    {"id":"CHN 1102","title":"Elementary Chinese II","description":"This Elementary Chinese II class intends to help students reach a basic level of Chinese proficiency in listening, speaking, reading, and writing in the cultural context.  This course will focus on essential speaking and writing skills while helping students develop contextual readings and listening competencies."},
    {"id":"CHN 1105","title":"Conversational Chinese II","description":"Develops the conversational skills to a greater degree of complexity and covering more situations.  Promotes free expression in Chinese within more specific and complex cultural contents."},
    {"id":"CHN 2201","title":"Intermediate Chinese I","description":"This intermediate Chinese class intends to help students reach a higher level of Chinese proficiency in speaking, listening, reading, and writing in the cultural context.  While continuing to enhance speaking and listening skills, competency in contextual readings and character writing will be emphasized."},
    {"id":"CHN 2202","title":"Intermediate Chinese II","description":"This course is to help students reach an intermediate level of Chinese proficiency in speaking, listening, reading, and writing in the cultural context.  It takes an integrated and balanced approach to enable students to apply learned Chinese knowledge to effective oral and written expressions and presentations."},
    {"id":"CHN 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"CHN 2301","title":"Business Chinese I","description":"This course is designed to help students develop Chinese language competency in the business world.  With immersion learning and integrated approach, students will learn oral and written business communications with real-world sceneries in cultural and global contexts."},
    {"id":"CHN 2302","title":"Business Chinese II","description":"This course is designed to help students build upon their Chinese language skills to gain proficiency in Business communications in formal and professional settings.  With integrated learning, students will be able to command immersion business dialogues and formal corporate apparatus like letters, reports, charts, and presentations in structured business environments.  While focusing on the Chinese language that is used in the business world, this course will also highlight business related issues like economic climate, cultural differences that can hinder or strengthen business relations."},
    {"id":"CIS 1107","title":"Introduction to Operating Systems","description":"In this class, you will learn the foundation of introduction to operating systems, virtualization and basic networking concepts.  You will learn how to manage and administer current desktop operating systems (Windows, Linux, and Mac OS X). In addition, you will explore operating systems for mobile devices (Apple IOS, Google Android) and will be introduced to widespread applications for IoT and Cloud concepts. This class will also cover personal and enterprise cybersecurity principles to help protect yourself from common cyber-attacks."},
    {"id":"CIS 1111","title":"Introduction to Problem Solving & Computer Programming","description":"Introduction to problem solving techniques used in programming. Students learn to use tools such as flowcharts and pseudocode to plan solutions. Using C++ programming languages, students will design, code and test programs using the basic structures of sequence, selection, iteration, functions and arrays."},
    {"id":"CIS 1130","title":"Network Fundamentals","description":"Introduction to computer networking. Topics include network standards and the Open Source Interconnection (OSI) model, topologies and Ethernet standards, network hardware, remote connectivity, wireless networking, in-depth TCP/IP, network security, network troubleshooting and network management."},
    {"id":"CIS 1140","title":"Information Systems Analysis & Design","description":"Introduction to the systems development life cycle and the four-phase model (planning, analysis, design and implementation). Emphasis on requirements gathering, methodology, modeling and skills related to specifications, design and documentation. Discussion of business processes, law, legal issues and ethics for IT professionals."},
    {"id":"CIS 1160","title":"Introduction to Data Literacy","description":"In this course, students will learn how to identify data sources and evaluate whether data is credible and relevant.   The course will introduce techniques to cleanse, analyze, and manage data. Visualization tools are covered in the course to assist in identifying and communicating data patterns and trends.   Presentation of data findings and communicating meaning through storytelling is an important element of this course.  In addition, students will gain an understanding on the impact of data in our society."},
    {"id":"CIS 1202","title":"C++ Software Development","description":"A continuation of C++ software development building on prior software development studies. Topics include arrays, searching and sorting, pointers, characters and strings, structures, file operations, C++ classes, inheritance, polymorphism, virtual functions, exceptions, templates, the Standard Template Library (STL), problem analysis and C++ software solution design, coding and testing."},
    {"id":"CIS 1350","title":"Web Site Development with HTML & CSS","description":"HyperText Markup Language (HTML) and Cascading Style Sheets (CSS) are widely used technologies to create and display content on the web.  HTML is the primary language used for creating web pages including basic text formatting, linking between pages and adding images and other media.  CSS is a styling language that enables the separation of content from style and provides precision control over the display including layout, colors and fonts.  Students will learn to apply best practices for web design and create sites that enhance the usability and interactivity of the pages."},
    {"id":"CIS 1375","title":"JavaScript","description":"This class introduces the JavaScript language, with a focus on the language features and client-side programming. Topics covered include basic syntax, object-oriented programming, functions, the DOM. The class will also introduce recent libraries, including jQuery. The class will include in-class coding exercises and assignments consisting of implementation of web applications."},
    {"id":"CIS 1411","title":"Introduction to Networks","description":"This course covers networking architecture, structure, and functions. The course introduces the principles and structure of IP addressing and the fundamentals of Ethernet concepts, media, and operations to provide a foundation for the Network Engineering curriculum. Students will understand the functions and services associated with the two major models (OSI and TCP/IP) used to plan and implement networks. Students will be able to design an IP addressing scheme to provide network connectivity for a small to medium sized network. Use Command Line Interface (CLI) to configure initial settings on routers and switches to implement basic network connectivity between devices."},
    {"id":"CIS 1510","title":"Windows Client Operating System","description":"Installing and administering systems that incorporate the current Microsoft desktop operating system. Administering shared resources including files, folders and printers; installing, managing and troubleshooting hardware devices; monitoring and optimizing system performance and reliability; implementing network protocols and configuring security elements."},
    {"id":"CIS 2165","title":"Database Management","description":"Introduction to database management systems. Discussion of database environments, design, planning, implementation and administration in a relational model environment. Students will design and develop a simple database and implement a portion of this application including forms, queries and reports. Emphasis on database design techniques, normalization and the SQL database language."},
    {"id":"CIS 2170","title":"Computer Information Systems Internship","description":"Students earn academic credit toward their graduation requirement by working in a professional information technology environment. Students must work at 5 - 10 hours per week per credit hour for one semester and be supervised/mentored by a professional in the field. The BPS internship coordinator can help guide students in their search, but students are ultimately responsible for obtaining a qualifying position. Students already working in the field may apply to use that experience to meet the internship requirement if they can prove that new learning will take place"},
    {"id":"CIS 2178","title":"Computer Information Systems Capstone","description":"Assessment of skills and competencies of Computer Information Systems students through project-based activities. Demonstration of achievement of degree option outcomes via oral and written presentations and creation of a professional growth plan. Course should be taken in the last term prior to graduation."},
    {"id":"CIS 2207","title":"Data Structures & Algorithms","description":"This course covers data structures using the C++ Programming Language. Topics include data abstraction, encapsulation, information hiding, the use of recursion, searching and sorting algorithms, and the creation and manipulation of various data structures: lists, queues, tables, trees, heaps, and graphs."},
    {"id":"CIS 2212","title":"Java Software Development I","description":"Introduction to Java software development. Topics include object orientation, Java syntax, data types, logic structures of sequence, selection and iteration, processing calculations, files, methods, classes and objects, graphical user interface (GUI) applications, arrays and the ArrayList class, problem analysis and Java software solution design, coding and testing."},
    {"id":"CIS 2217","title":"Java Software Development II","description":"This course builds on prior Java studies and includes abstract classes and interfaces, binary input/output, recursion, generics, use of collection framework lists, stacks, queues, and priority queues, use of sets and maps, developing efficient algorithms and computational complexity, sorting algorithms, implementing lists, stacks, queues and priority queues, software development ethics, binary search trees, problem analysis and Java software solution design, coding and testing."},
    {"id":"CIS 2222","title":"ASP.NET with C#","description":"This course introduces server side web programming to develop web applications based on ASP.NET.  Students will learn how to develop ASP.NET applications employing web forms and data controls. Microsoft SQL Server will be used for database manipulations."},
    {"id":"CIS 2240","title":"Introduction to Mobile Applications","description":"Students will plan, develop, and code mobile applications, learn the syntax of the programming language, store data in mobile devices and the internet and prepare an application for upload to an application stores across multiple platforms."},
    {"id":"CIS 2250","title":"Web Site Development with php","description":"PHP is a server-side scripting language and is used to create web sites. This course provides the knowledge necessary to design and develop dynamic, database-driven web pages. Emphasis is placed on programming techniques to design, code, test, debug and create a dynamic web site using PHP. Students will  be  introduced to MySQL, which is a popular relational database management system."},
    {"id":"CIS 2265","title":"Data Visualization","description":"This course will introduce students to the field of data preparation and visualization including design and hands-on experience. Students will learn how to collect, transform, curate, and analyze datasets. The course will introduce students to design and build principles for telling stories for effective communications to facilitate data-driven decision-making, provide insights, and help speed up organizations that are data rich and information poor."},
    {"id":"CIS 2266","title":"Python for Data Analytics","description":"This course introduces students to analyzing data using Python. The basics of Python will be taught.  Students will learn how to obtain, cleanse and prepare data for analysis. Data analytic and statistical tools will be used to visualize data, predict outcomes and categorize data."},
    {"id":"CIS 2268","title":"Structured Query Language (SQL) Programming","description":"Introduction to  database management system in a client/server environment. The course covers Structured Query Language (SQL) and  development and administrative tools. Students are taught to create and maintain database objects and to store, retrieve and manipulate data, and create blocks of application code that can be shared by multiple forms, reports, and data management applications."},
    {"id":"CIS 2269","title":"Data Analytics Theory & Solutions","description":"An introduction to business intelligence, data analysis, data warehousing, data mining theory and tools, and how to structure the data and prepare reports in a way that is meaningful to business users. Emphasis is placed upon understanding business intelligence techniques to construct and use business intelligence solutions for decision support."},
    {"id":"CIS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"CIS 2416","title":"Routing & Switching Essentials","description":"This course focuses on learning the architecture, components and operations of routers and switches in a small network. Students will learn how to configure a router and a switch for basic functionality. Commonly used network services and protocols will be studied."},
    {"id":"CIS 2418","title":"Basic Firewall Security","description":"This course offers an understanding of security principles and tools available to achieve an appropriate level of network security. Hardware devices (routers and switches from multiple vendors), protocols and switching technologies will be considered including Virtual Local Area Networks (VLANs), VLAN Trunking Protocol (VTP), Rapid Spanning Tree Protocol (RSTP) and others."},
    {"id":"CIS 2421","title":"Scaling Networks","description":"The focus of this course is on the architecture, components and operations of routers and switches in a larger and more complex network. Students will learn how to configure routers and switches for advanced functionality. Students will be able to configure and troubleshoot routing protocols and wireless networks using IPv4 and IPv6 on equipment from various vendors."},
    {"id":"CIS 2426","title":"Connecting Networks","description":"This course focuses on Wide Area Network (WAN) technologies and services required by converged applications in a complex network.  Students will learn the selection criteria for devices and technologies to meet WAN requirements.  Configuring devices and resolving issues with data link protocols will be emphasized."},
    {"id":"CIS 2510","title":"Microsoft Windows Server Operating System","description":"Introductory and Intermediate aspects of Windows Server administration. Outcomes include installation of the current Windows Server operating system, setup of roles and features, virtualization, client server networking, and knowledge of Active Directory and Group Policy."},
    {"id":"CIS 2515","title":"Windows Network Infrastructure","description":"Intermediate administration and support functions of the current Windows Server operating system. Focus is on more detailed functions of common roles and features such as core networking, security, and Windows Updating. Also more advanced use of Active Directory and  Group Policy."},
    {"id":"CIS 2520","title":"Windows Server Advanced Services","description":"Advanced windows services such as advanced network services, security, backups, and IP Address Management (IPAM). High availability through Network Load Balancing, Failover Clustering, Site failover. Also features such as Federation Services, Certificate Services and Rights Management Services and other advanced topics included."},
    {"id":"CIS 2550","title":"Linux Operating System","description":"Linux operating system installation, management, administration, troubleshooting techniques, writing and debugging shell procedures, pipes and interprocess communications, command lists and network configuration for beginning and intermediate students. This course prepares students for the CompTIA Linux + exam."},
    {"id":"CIS 2630","title":"Securing a Windows Network Environment","description":"Successfully plan, build, and secure systems for a Microsoft Windows Server environment. The primary purpose of this course is to provide experience using actual enterprise state-of-the-art class servers hardware and software. Our classroom is equipped with many new enterprise servers for a hands-on experience. It also includes sections on introductory forensics and securing servers with penetration testing."},
    {"id":"CIS 2640","title":"Network Security","description":"Intermediate computing and network security fundamentals. Topics include network vulnerabilities and attacks, network defenses, wireless network security, access control, network assessment and auditing, cryptography and organizational security. Preparation will also be given for the ComptTIA Security + exam."},
    {"id":"CIS 2642","title":"PenTest","description":"Intermediate computer and Penetration Testing fundamentals. Topics include: Penetration testing, and vulnerability assessment and attacks, social engineering, network and application exploitation and best practices to communicate recommended strategies to improve the overall state of IT security. Preparation will also be given for the ComptTIA PenTest+ exam."},
    {"id":"CIS 2650","title":"Ethical Hacker","description":"Intermediate level system and network security related topics including legal ramifications, assessment, social engineering, vulnerability testing, system hacking, network scanning and analysis, mobile device security, Cloud and IoT, cryptography, and remediation. This course includes the TestOut Ethical Hacker certification exam and will help students in their preparation for the ECCouncil Certified Ethical Hacker CEH exam."},
    {"id":"CIS 2670","title":"Fundamentals of Information Systems Security","description":"Intermediate security related topics including security and risk management, security engineering, communication and network security, identity management, security assessment and operations, and software development security.  This course will help students in their preparation for the Certified Information Systems Security Professional (CISSP) exam."},
    {"id":"CIS 2711","title":"Enterprise Desktop Support Technician","description":"Intermediate and advanced problem solving techniques for Windows desktop operating systems. Includes network and cloud applications and remote access administration. Configure and problem solve operating system functions in real world hands on labs."},
    {"id":"CIS 2731","title":"A+ Hardware & Software","description":"This class is for intermediate to advanced students seeking both the theoretical and practical aspects of building a PC.  The class covers CPU, storage devices, printers, and networking devices.  This class also includes functions and installation of operating systems as well as troubleshooting steps and common tools.  Will help students in preparation for the CompTIA A+ Certification."},
    {"id":"CIS 2808","title":"Introduction to Computer Forensics","description":"Computer forensics is the study of obtaining and analyzing evidence/information for use as evidence in civil, criminal or administrative cases."},
    {"id":"CJS 1101","title":"Introduction to Criminal Justice Science","description":"Overview of the criminal justice system and an analysis of the interdependence of its components, including legislative, law enforcement, prosecution, court and correctional systems. Examination of responsibilities of professionals in each of these systems, including ethical and legal responsibilities."},
    {"id":"CJS 1103","title":"Constitutional Law & Evidentiary Procedures","description":"Survey and interpretation of the Federal Constitution, and an overview of state and federal law and court systems.  Emphasis on the Bill of Rights with particular attention to the Fourth, Fifth, Sixth, Eighth, and Fourteen amendments.  Study, analysis, and application of the Rules of Evidence and Rules of Criminal Procedure from investigation to arrest, trial, and the appellate process.  Additionally, ethical guidelines for Criminal Justice professionals in the detection, apprehension, and prosecution of the accused and constitutional restrictions on government actions."},
    {"id":"CJS 1105","title":"Criminal Law","description":"Basic concepts of Criminal Law and analysis of state and federal criminal statutes. Elements of crimes, criminal liability, jurisdiction over criminal offenses and criminal defenses and criminal responsibility will be examined. Additionally, crimes against property, crimes against persons and alcohol and drug crimes will be covered."},
    {"id":"CJS 1106","title":"Transition Skills","description":"Engage students in the process of building a personal portfolio that includes career and financial goals, a professional resume, job search process, a personal budget, a savings and investment plan and access to community resource information. This course will address the process of community re-entry from a personal, social and occupational perspective."},
    {"id":"CJS 1110","title":"Interrogation, Documentation & Testimony","description":"Development of communication skills applicable to criminal justice professionals. Emphasis on interviewing, interrogation, documentation of evidence in various documents, forms, reports and oral testimony."},
    {"id":"CJS 1125","title":"Policing","description":"Management and leadership of law enforcement agencies, including investigations, patrol, internal affairs, traffic enforcement and an overview of community based and problem-oriented policing theory and practice. Emphasis on crime analysis and prevention, community partnerships to reduce crime and community education. Principles of organization, staffing, budgeting, controlling, training and planning."},
    {"id":"CJS 1155","title":"Homeland Security Issues & Administration","description":"Overview of homeland security threats, statutes, resources and the role of law enforcement as first responders with the emphasis on inter-agency cooperation. Examination of contemporary security issues in public and private spaces including risk analysis, critical incident management, inter-agency collaboration, specialized security fields, intelligence gathering and litigation. Exploration of the career opportunities in homeland security."},
    {"id":"CJS 1165","title":"Corrections","description":"Analysis of operations of correctional facilities from historical, functional and management perspectives. Attention to administrative and management issues in different types of facilities, with different populations and in community-based programs. Examination of best practices in the field of corrections, including state and federal programs for institutional and community settings."},
    {"id":"CJS 1197","title":"Corrections Full Service Jails/Basic Correction Officer Academy","description":"Mandated Ohio Attorney General/Ohio Peace Officer Training Academy training for individuals to attain certification for performing corrections officer functions in full-service jail facilities.  Not open to the general student population.  Consists of minimum 158 mandated classroom hours of both academic/physical training."},
    {"id":"CJS 2111","title":"Ethics & Professionalism in Criminal Justice","description":"Examination and analysis of legal and ethical obligations of criminal justice professionals in law enforcement, corrections and the courts. Study and assessment of policy and actions of individuals and organizations within the criminal justice system regarding conformity to accepted ethical and legal standards."},
    {"id":"CJS 2145","title":"Correctional Case Management","description":"Survey of case management theories and approaches for criminal offenders. Understanding of intervention strategies for different types of offenders in institutional and community-based correctional programs."},
    {"id":"CJS 2200","title":"Human Relations, Mediation & Conflict Resolution","description":"Examination of cultural differences and the handling of special needs population by the criminal justice system, including current trends in meeting community needs. Emphasis on development of the knowledge and skill sets required of the criminal justice professional to address the needs and issues of diverse clientele throughout the criminal justice process.  Strategies for mediation, conflict resolution and critical incident management for law enforcement and corrections personnel, including hostage negotiation."},
    {"id":"CJS 2205","title":"Introduction to Criminal Investigation & Forensic Science","description":"Survey of legal, technical and ethical aspects of criminal investigation. Common principles and techniques of criminal investigation, including crime scene procedures, collection and preservation of evidence, development of leads and criminalistics (current terminology for forensics). Skills necessary to investigate crimes and obtain legally admissible evidence. Basic science of physical, chemical and biological evidence."},
    {"id":"CJS 2209","title":"Computer Crime","description":"Overview of criminal investigation of crimes committed in conjunction with computer technology. Types of crimes, prosecution and prevention strategies."},
    {"id":"CJS 2270","title":"Criminal Justice Science Internship","description":"Observation and participation in a criminal justice agency appropriate to the student&apos;s professional goals.  Opportunity for integration and application of learning in a professional setting. One classroom, fourteen hours field experience per week."},
    {"id":"CJS 2280","title":"Basic Peace Officer Training I","description":"First half of the mandated Ohio Attorney General/Ohio Peace Officer Training Academy training for individuals to attain certification as peace officers in Ohio law enforcement agencies.  Consists of mandated cognitive and psychomotor skills training for entry level Ohio law enforcement officers."},
    {"id":"CJS 2281","title":"Basic Peace Officer Training II","description":"Second half of the mandated Ohio Attorney General/Ohio Peace Officer Training Academy training for individuals to attain certification as peace officers in Ohio law enforcement agencies.  Consists of mandated cognitive and psychomotor skills training for entry level Ohio law enforcement officers."},
    {"id":"CJS 2295","title":"Criminal Justice Science Seminar","description":"Capstone experience for Criminal Justice Science students that focuses on the integration of learning throughout the program through case study analysis, research and service learning. Additionally, attention will be given to the preparation for employment in the field of criminal justice."},
    {"id":"CJS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"CLT 1200","title":"Introduction to Clinical Laboratory","description":"The course will introduce students to the terms, concepts, procedures, and equipment used in a professional medical laboratory.  One classroom, three lab hours per week."},
    {"id":"CLT 1203","title":"Lab for Introduction to Clinical Laboratory","description":"Lab portion of CLT 1200 - Introduction to Clinical Laboratory."},
    {"id":"CLT 2110","title":"Urine & Body Fluid Analysis/Immunology/Serology","description":"The course will provide instruction on the structure and function of the kidney, renal pathology and the principles, sources of error and interpretation of test results in urinalysis. Principles of CSF and serous fluid analysis are covered. This course is also an introduction to the principles of immunology, covering the broad areas of the body&apos;s defense mechanisms, the nature of the mammalian immune system and the immune response, and discusses immunological disease states of auto-immunity, tumor immunology, transplant immunology, immunodeficiency, and the theory behind immunoassays used in the laboratory environment. One classroom, two lab hours per week."},
    {"id":"CLT 2113","title":"Lab for Urine & Body Fluid Analysis/Immunology/Serology","description":"Lab portion of CLT 2110 - Urine &amp; Body Fluid Analysis/Immunology/Serology"},
    {"id":"CLT 2210","title":"Hematology","description":"The course will introduce the students to the theory and practical application of routine and special hematology procedures, both manual and automated; red blood cells and white blood cells maturation sequences, and normal and abnormal morphology and associated diseases.  Three classroom, three lab hours per week."},
    {"id":"CLT 2213","title":"Lab for Hematology","description":"Lab portion of CLT 2210 - Hematology"},
    {"id":"CLT 2310","title":"Clinical Chemistry","description":"The course will introduce the students to the theory and application of human biochemistry and principles of chemistry techniques used in the analysis of blood and other body fluids.  Two classroom, four lab hours per week."},
    {"id":"CLT 2313","title":"Lab for Clinical Chemistry","description":"Lab portion of CLT 2310 - Clinical Chemistry."},
    {"id":"CLT 2410","title":"Clinical Microbiology/Parasitology","description":"Basic concepts of microbiology with emphasis on microbial pathogenesis and immunity. Medically important microorganisms including bacteria, fungi, viruses, rickettsia, protozoa, and the diseases which they produce. This course will also introduce students to the basic knowledge of the physical and chemical properties of clinically significant micro-organisms, the emphasis will be on describing phenotypic characteristics of clinically relevant organisms and the principles of antimicrobial action. Three classroom, three lab hours per week."},
    {"id":"CLT 2413","title":"Lab for Clinical Microbiology/Parasitology","description":"Lab portion of CLT 2413 - Clinical Microbiology/Parasitology"},
    {"id":"CLT 2510","title":"Immunohematology","description":"An introduction to theory and practice of blood banking and transfusion medicine.  Various Blood typing and Antibody identification tests will be performed in the Clinical blood banking simulated lab. This course presents the physiological basis for the test, the principle and procedure for the test, and the clinical significance of the test results, including quality control. One classroom, three lab hours per week."},
    {"id":"CLT 2513","title":"Lab for Immunohematology","description":"Lab portion of CLT 2510 - Immunohematology"},
    {"id":"CLT 2810","title":"CLT Practicum","description":"Practical training in clinical chemistry, microbiology, hematology and serology under the direction of a National Accrediting Agency for Clinical Laboratory Sciences (NAACLS) approved/accredited hospital educational program personnel."},
    {"id":"COM 2201","title":"Introduction to Mass Communication","description":"An extensive examination of media theory and social effects.  Topics covered include history, practices and functions of the press, television, radio, film, advertising, digital media and public relations.  Course investigates mass media's influence on modern society."},
    {"id":"COM 2206","title":"Interpersonal Communication","description":"Exploration of the development, maintenance and termination of interpersonal relationships. The focus is on effective verbal and nonverbal interactions between two people, highlighting methods of initiating and maintaining effective communication with, and understanding of, others through learning and applying interpersonal communication theory."},
    {"id":"COM 2211","title":"Effective Public Speaking","description":"Designed to improve speaking and listening skills through the study and application of public speaking structure, content and style.  This course requires 5 speeches in front of a live audience. The online course sections require the recordings to be created by the student with at least 8 adults present for each speech.  Any questions, please contact the Communication Department at com.dept@sinclair.edu."},
    {"id":"COM 2220","title":"Introduction to Communication Theory","description":"Examination of major foundational theories that inform the field of communication. Special emphasis on communication theories that examine the self and the message, relationship development, groups and organizations, the public and the media, as well as culture and diversity."},
    {"id":"COM 2225","title":"Small Group Communication","description":"Focusing on development of effective small group decision-making and leadership skills, stressing better methods of expressing oneself and understanding others through learning group communication, theory and participating in small group decision-making experiences."},
    {"id":"COM 2230","title":"Nonverbal Communication","description":"Development of effective nonverbal skills for the successful communicator, stressing better methods of expressing oneself and understanding others through the learning of nonverbal theory and Impression Management."},
    {"id":"COM 2235","title":"Principles of Interviewing","description":"Development of theoretical understanding and effective skills in the interviewing process, as both interviewer and interviewee. Practical experience in key types of interviews including informational, employment, appraisal and survey interviews."},
    {"id":"COM 2245","title":"Intercultural Communication","description":"Analysis of issues associated with communicating across cultures, including the study of communication norms, communication characteristics of major contemporary cultures and effective cross-cultural communication in interpersonal and organizational contexts."},
    {"id":"COM 2270","title":"Communication Internship","description":"Students earn credit toward degree requirements for work learning experience related to the discipline of communication. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes related to communication and prepare reports and/or projects each term, detailing how the experience allowed for the application of communication theory and/or skills. Seven work hours per credit hour each week."},
    {"id":"COM 2278","title":"Communication Capstone","description":"Demonstration of communication skills and competencies through the development of a communication portfolio; independent study under the direction of a Communication faculty member. Five directed practice hours per week."},
    {"id":"COM 2285","title":"Organizational Communication","description":"Study of the theories of communication in organizations. Analysis of the initiation, diffusion, and reception of messages in organizational environments. Exploration of effective communication strategies for work relationships, management practices, and organizational culture."},
    {"id":"COM 2286","title":"Public Relations Principles","description":"Theories, principles and skills of public relations in organizations and in society, integrating organizational communication and management practices."},
    {"id":"COM 2287","title":"Effective Listening","description":"Development of effective listening skills. Practical experience in comprehensive, empathic, critical and appreciative listening. Solid foundation in relevant listening theory."},
    {"id":"COM 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"DAN 1155","title":"Dance History","description":"Historical development of dance from the earliest beginnings to the Renaissance birth of ballet to the twentieth-century emergence of modern dance."},
    {"id":"DAN 1157","title":"Dance Appreciation","description":"Introduction to dance from a nonperforming perspective focusing on its many artistic, theatrical and social forms relating to culture and other arts and humanities."},
    {"id":"DAN 1162","title":"Beginning Middle Eastern Dance","description":"Basic fundamentals and theory of Middle Eastern dance for beginning students.  Class work consists of hip work, isolations, rhythm, history and cultural comparisons."},
    {"id":"DAN 1172","title":"Ballet","description":"Basic fundamentals and theory of classical ballet for beginning  students.  Class work consists of barre work, center combinations and steps. Two classroom, two lab hours per week."},
    {"id":"DAN 1173","title":"Modern Dance","description":"Basic fundamentals and theory of Modern Dance for beginning students.  Two classroom, two lab hours per week."},
    {"id":"DAN 1174","title":"Jazz Dance I","description":"Introduction of the fundamentals of Jazz dance technique for the beginning student. Two classroom, two lab hours per week."},
    {"id":"DAN 1175","title":"Tap Dance","description":"Basic fundamentals of Tap dance technique for the beginning student.  Two classroom, two lab hours per week."},
    {"id":"DAN 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"DAS 1102","title":"Introduction to Dental Assisting Terminology","description":"Orientation to terms related specifically to the science of dentistry. Includes dental terminology application along with definitions and relationships of words to other similar dental terms, the use of root words, prefixes and suffixes related to dentistry. Introduction to the profession of dental assisting and the ethics, laws and rules."},
    {"id":"DAS 1104","title":"Dental Assisting Techniques & Materials I","description":"Principles of dental assisting skills with emphasis on exposure control, dental instrument differentiation, patient anatomy, collecting data and basic dental laboratory procedures. Two classroom, six lab hours per week."},
    {"id":"DAS 1105","title":"Lab Dental Assisting Techniques & Materials I","description":"Laboratory experiences for DAS 1104."},
    {"id":"DAS 1108","title":"Dental Assisting Office Management","description":"This course will introduce the learner to business operating systems in a dental office.  Includes procedure manuals, HIPPA, record keeping, accounts receivable, inventory and scheduling."},
    {"id":"DAS 1204","title":"Dental Assisting Techniques & Materials II","description":"Principles of dental assisting skills with emphasis on dental procedure instrument differentiation. Basic dental laboratory materials and equipment utilization.  Two classroom, six lab hours per week."},
    {"id":"DAS 1205","title":"Lab Dental Assisting Techniques & Materials II","description":"Laboratory experiences for DAS 1204."},
    {"id":"DAS 1206","title":"Dental Assisting Radiography","description":"The learner will be introduced to Dental Radiography for the Dental Assistant. The course will prepare the learner for the state license examination for exposing radiographs in a dental office setting. Includes properties of x-rays, the dental x-ray machine, radiation effects, radiation safety, digital imaging, dental films, and the processing of radiographs.  One classroom, two lab hours per week."},
    {"id":"DAS 1207","title":"Lab Dental Assisting Radiography","description":"Scientific principles of radiation, radiographic production and patient management in dental practice."},
    {"id":"DEH 1102","title":"Introduction to Dental Hygiene","description":"Orientation to terms related specifically to the science of dentistry to prepare students for the dental hygiene program. Includes dental terminology application along with definitions and relationships of words to other similar dental terms, the use of root words, prefixes and suffixes related to dentistry. Introduction to the profession of dental hygiene and the ethics, laws and rules. Students are strongly encouraged to take SCC 1101 prior to this course."},
    {"id":"DEH 1202","title":"Head, Neck & Dental Anatomy","description":"Gross anatomy of the head and neck region including the oral cavity. Morphology and function of permanent and primary dentition. Two classroom, two lab hours per week."},
    {"id":"DEH 1203","title":"Lab for Head, Neck & Dental Anatomy"},
    {"id":"DEH 1204","title":"Preclinical Dental Hygiene I","description":"Scientific principles of dental hygiene practice with emphasis on preventive dental health concepts, promotion of dental health, exposure control, data collection, patient assessment, oral health education and basic dental hygiene instrumentation. Two classroom, six lab hours per week."},
    {"id":"DEH 1205","title":"Lab for Preclinical Dental Hygiene I"},
    {"id":"DEH 1206","title":"Nutrition & Oral Health","description":"An introduction to biochemistry and basic fundamentals of the science of nutrition, the role of nutrition in oral health and disease, nutrition standards and guidelines, nutrition and oral structures, nutrition through the life cycle, dietary analysis and nutritional counseling."},
    {"id":"DEH 1302","title":"Preclinical Dental Hygiene II","description":"Scientific principles of dental hygiene practice with emphasis on preventive dental health concepts, pedodontic care, promotion of dental health, care planning, patient referral and dental specialties,  periodontal instrumentation, care of the removable prosthesis and oral health education.  Two classroom, six lab hours per week."},
    {"id":"DEH 1303","title":"Lab for Preclinical Dental Hygiene II"},
    {"id":"DEH 1304","title":"Oral Histology & Embryology","description":"Microscopic anatomy of the human cell and tissues.  Embryologic development of the head and neck. Histology of tooth development."},
    {"id":"DEH 1305","title":"Medical Emergencies in Dental Practice","description":"Principles of general first aid and managing medical emergencies in dental practice. Two lab hours per week."},
    {"id":"DEH 1306","title":"General & Oral Pathology","description":"Study of human disease processes and their physiological manifestations with emphasis on the etiology, signs and symptoms of pathological conditions within the oral cavity and associated structures."},
    {"id":"DEH 1308","title":"Dental Radiology","description":"Scientific principles of radiation, radiographic production and patient management in dental practice. Two classroom, two lab hours per week."},
    {"id":"DEH 1309","title":"Lab for Dental Radiology"},
    {"id":"DEH 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"DEH 2402","title":"Clinical Dental Hygiene I","description":"Foundations of periodontics with emphasis on periodontal disease progression and classification, etiology of periodontal diseases including gingival disease and periodontitis, assessment for clinical decision making, nonsurgical periodontal therapy."},
    {"id":"DEH 2403","title":"Dental Hygiene Clinic I","description":"Clinical dental hygiene practice with emphasis on the process of dental hygiene care, exposure control, dental imaging and diagnostic assessment procedures, care planning and education, prophylaxis and preventive procedures, pedodontic patient care and management, utilization of practice management technology.  Twenty-one clock hours each week will be spent seeing patients over an 8-week term."},
    {"id":"DEH 2502","title":"Pharmacology in the Dental Practice","description":"Overview of the conventional drug classes with emphasis on the actions, effects and indications for those used in the dental practice."},
    {"id":"DEH 2503","title":"Pain Control in the Dental Practice","description":"Laboratory and clinical training in the administration of local anesthesia and nitrous oxide sedation."},
    {"id":"DEH 2504","title":"Dental Hygiene Research","description":"Overview of statistical terminology needed to evaluate research literature and prepare and present dental hygiene research papers."},
    {"id":"DEH 2506","title":"Dental Materials","description":"General knowledge and use of various dental materials commonly used in the dental practice setting. Introduces the physical and chemical properties, structures, uses and manipulation of materials. One classroom, three lab hours per week."},
    {"id":"DEH 2507","title":"Lab for Dental Materials","description":"Laboratory and clinical training in Dental Materials. Laboratory sessions give the student skill development for correctly using dental materials with an emphasis on safety and infection control."},
    {"id":"DEH 2508","title":"Clinical Dental Hygiene II","description":"A continuation of the dental hygiene process of care, powered instrumentation, child abuse and neglect, risk assessment, medically compromised and special needs patient care, periodontal maintenance, adjunctive therapies, periodontal surgical concepts, dental implant maintenance and periodontal emergencies."},
    {"id":"DEH 2509","title":"Dental Hygiene Clinic II","description":"Clinical dental hygiene practice with emphasis on diagnostic procedures and assessment, care planning and education, preventive and therapeutic procedures, nonsurgical periodontal therapy, periodontal maintenance, medically compromised and special needs patient care, professional communication and case presentation.  Twenty-one practicum hours per week."},
    {"id":"DEH 2601","title":"Community Dental Health","description":"Introduction to community and public health concepts and community health education. Two lab hours per week."},
    {"id":"DEH 2602","title":"Clinical Dental Hygiene III","description":"A continuation of the dental hygiene process of care, advanced instrumentation and procedures, dietary assessment and counseling, tobacco education and cessation, emerging trends and special topics, professional philosophy and life-long learning."},
    {"id":"DEH 2603","title":"Dental Hygiene Clinic III","description":"Clinical dental hygiene practice with emphasis on advanced instrumentation and procedures, adjunctive therapies, dietary assessment and nutritional counseling, tobacco education and cessation strategies, risk assessment, coding strategies, pain control, evaluation of dental hygiene care and prognosis."},
    {"id":"DEH 2604","title":"Dental Hygiene Practice","description":"Examines current trends in dental hygiene including resume and interviewing strategies, practice setting selection, legal and ethical issues, business of dental hygiene, professional development and organized dental hygiene."},
    {"id":"DEV 0015","title":"Integrated Developmental Reading & Writing I","description":"Overview of basic elements of modern English usage. Includes utilization of correct grammar, college-level paragraph writing, context, structure analysis, vocabulary, and text marking reading skills."},
    {"id":"DEV 0035","title":"Integrated Developmental Reading & Writing II","description":"Integrated reading/writing course focusing on essay writing and critical reading/thinking skills.  Includes stated and implied main ideas, college-level vocabulary development, and the stages of the essay writing process."},
    {"id":"DEV 0054","title":"Accelerated English","description":"This is an accelerated developmental writing course, paired with English Composition I, emphasizing the writing process, including invention, drafting, revision, and editing. Students collaborate to write more effective essays, showing evidence of analysis, critical thinking, and cohesion of thought. Students also work closely with online resources, as they work towards fluency in style and mechanics."},
    {"id":"DEV 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"DIT 1105","title":"Introduction to Dietetics","description":"Exploration of the dietetics profession. Introduces the  professional organization and structure. Covers credentialing and the Academy of Nutrition and Dietetics Code of Ethics. Clarifies the roles and requirements of  different nutrition professionals. Investigates areas of employment."},
    {"id":"DIT 1108","title":"Nutrition for the Culinary Professional","description":"Introduction to general nutrition principles emphasizing foundations of healthy cooking.  Explores how to gauge customers&apos; needs/wants while developing and implementing healthy menu options.  Includes National Restaurant Association Education Foundation ManageFirst Nutrition Exam.  Successful completion of exam fulfills requirements toward American Culinary Federation (ACF) certification."},
    {"id":"DIT 1111","title":"Nutrition for Health & Fitness","description":"Overview of general nutrition principles focusing on healthy food choices,  disease prevention and sports nutrition.  Explores fad diets, herb/supplements and use of ergogenic aids.   Incorporates effective use of nutrition information from reliable sources as well as personal responsibility in a professional setting."},
    {"id":"DIT 1210","title":"Medical Terminology for Dietetics","description":"The use of root words, combining forms, prefixes and suffixes related to pathology, diagnosis and treatment of body systems pertaining to the practice of dietetics. Exposure to research journal articles and medical terminology application."},
    {"id":"DIT 1525","title":"Human Nutrition","description":"This is an in-depth study of the principles of nutrition with emphasis on the functions of the nutrients, their digestion, absorption, metabolism, inter-relationships and nutrition requirements. Incorporates assessment of nutritional health risks, health promotion and disease prevention theories. Explores the influence of socioeconomic, cultural, psychological and environmental factors on food and nutritional behavior."},
    {"id":"DIT 1630","title":"Nutrition in the Lifecycle","description":"Nutritional needs of individuals from conception to maturity, including physiological, psychological, environmental and sociological factors affecting nutrition. The Nutrition Care Process is introduced.  Incorporates weekly sessions on weight management  strategies with a client. Examines nutrition concerns for special health conditions. Two classroom, two clinical hours per week."},
    {"id":"DIT 1635","title":"Community Nutrition","description":"Addresses community food/nutrition issues and federal/nongovernmental programs designed to meet needs of at-risk populations. Focuses on tools, strategies and resources to evaluate effectiveness of community programs. Students participate with community agencies providing nutrition programs and education. Two classroom, three clinical lab hours per week."},
    {"id":"DIT 2101","title":"Eating Matters for Dining Assistants","description":"Practical skill development in feeding techniques for the elderly.  Ensures understanding of nutritional needs of residents, communication and interactions between residents/staff,  behavior challenges and safety procedures.  Students receive a Dining Assistant Certificate from Ohio Department of Health upon completion."},
    {"id":"DIT 2180","title":"Medical Nutrition Therapy for Dietary Managers","description":"Introductory course for nutrition care personnel in health care institutions.  Overview of nutrition principles, medical nutrition therapy and menu planning. Exploration of diseases/health conditions that require nutrition intervention.  Addresses multidisciplinary team approach to resident care.  Nutrition Care Process introduced with basic nutrition-related calculations."},
    {"id":"DIT 2190","title":"Dietary Managers Nutrition Clinical","description":"Hands-on experiences in health care institutions. Incorporates the Nutrition Care Process with emphasis on screening/documentation of client information. Utilizes basic nutrition principles for menu planning, medical nutrition therapy while providing quality care. Requires Registered, Licensed Dietitian preceptor for a portion of the four clinical lab hours per week."},
    {"id":"DIT 2240","title":"Education Methods & Materials","description":"Explore teaching methods/materials to maximize educator effectiveness  while accommodating different  learning styles and diverse audiences.  Evaluation of learning is included.  Use of media/education resources and equipment materials  addressed.  Research design methods are introduced and analyzed.  Service Learning projects are incorporated into course activities."},
    {"id":"DIT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"DIT 2305","title":"Food, Culture & Cuisine","description":"Explore cuisines of Asia, Middle East, Africa, Europe, Mediterranean and the Americas. Identify the demographics and research/evaluate the differences and similarities among the various cultures of the world. Demonstrate the relationship between traditional foods, cultural and current food practices.  One classroom, two clinical hours per week."},
    {"id":"DIT 2310","title":"Lab for Food, Culture & Cuisine","description":"This laboratory component of DIT 2305 explores cuisines of Asia, Middle East, Africa, Europe, Mediterranean and the Americas.   Indigenous ingredients and flavor profiles of international cuisines are addressed. Advanced preparation methods and cooking techniques will be utilized and demonstrated.  Two lab hours per week."},
    {"id":"DIT 2510","title":"Institutional Foodservice Systems","description":"This course incorporates food delivery and production systems, facility and materials management, menu planning, food and non-food procurement, cost and quality control methods. Food safety and sanitation principles will be applied."},
    {"id":"DIT 2515","title":"Foodservice Practicum I","description":"Hands-on experience completed in an institutional foodservice kitchen. Covers menu development including modified diets, recipe scaling, forecasting and food production, equipment care and use, kitchen layout and design, safety and sanitation.  Five hours per week at assigned practicum site."},
    {"id":"DIT 2520","title":"Laboratory for Foodservice Systems","description":"This laboratory component of DIT 2510 course addresses food science principles for the functions of ingredients in modified textured and therapeutic recipe preparation. This is a continuation of quantity cooking principles, sensory evaluation of food, recipe standardization, kitchen equipment and food safety and sanitation. Hazard analysis critical control point standards are reinforced. Two lab hours per week."},
    {"id":"DIT 2625","title":"Medical Nutrition Therapy I","description":"Medical nutrition therapy for physiologic stress, diabetes mellitus, cardiovascular disease and disorders of the upper gastrointestinal tract. Content includes modified texture/therapeutic feeding strategies, dietary interventions for swallowing difficulties and enteral/parenteral/IV feeding routes. Incorporates the nutrition care process with emphasis on nutritional assessments, minimum data sets, care assessment triggers and care plans."},
    {"id":"DIT 2630","title":"Medical Nutrition Therapy Clinical I","description":"Clinical component of medical nutrition therapy I series:  for physiologic stress, diabetes mellitus, cardiovascular disease and disorders of the upper  gastrointestinal tract.  Menu writing for therapeutic interventions; feeding routes for enteral, parenteral and IV therapy; patient interviews, nutrition screening and nutrition care process. Ohio Department of Health Dining Assistant certification is embedded in this course. Nine clinical lab hours per week."},
    {"id":"DIT 2735","title":"Foodservice Organization & Management","description":"Describe functions of management and identify a variety of tools used to assist with organizational performance.  Application of marketing concepts, financial reports/budget, quality improvement and current trends/regulations."},
    {"id":"DIT 2740","title":"Foodservice Practicum II","description":"Hands-on experience completed in an institutional foodservice setting. Covers five major aspects of human resource management: planning, organizing, directing, controlling and evaluating and the tools used to assist with organizational performance. Includes marketing of services, budget concerns and cost control measures while maintaining quality service within regulatory guidelines. Five hours per week at assigned practicum site."},
    {"id":"DIT 2845","title":"Medical Nutrition Therapy II","description":"Capstone course for the medical nutrition therapy series. Course content includes medical nutrition therapy for cancer, AIDS, disorders of the lower gastrointestinal tract, gallbladder, liver and renal disease.   Incorporates review modules, case studies, critical thinking exercises and the nutrition care process addressing feeding routes and diseases."},
    {"id":"DIT 2850","title":"Medical Nutrition Therapy Clinical II","description":"Clinical component  covering  topics in Medical Nutrition Therapy II:  cancer, childhood obesity, disorders of the lower gastrointestinal tract, gallbladder, liver, renal diseases.  Practicum includes: menu writing for modified texture/ therapeutic interventions; feeding routes; patient interviews, nutrition screening/education and the nutrition care process from admission to discharge. Six clinical lab hours per week."},
    {"id":"DIT 2855","title":"Dietetics Seminar","description":"Capstone course prepares students for national credentialing dietetic technician examination/employment. Reviews Food and Nutrition, Food Service Systems and Sanitation, Education Methods and Management domains. Includes job interviewing skills and resume writing. Reviews professional ethics, including life-long learning and the Academy of Nutrition and Dietetics Professional Portfolio."},
    {"id":"DIT 6143","title":"Non-Credit Healthy Cooking","description":"Discover how to use nourishing ingredients and choose preparation methods that lead to healthy cooking habits.  Explore flavor combinations and valuable tips for stocking a healthy kitchen.   Expand your cooking skills to create recipes that are wholesome, nutritious and delicious."},
    {"id":"ECE 1100","title":"Introduction to Early Childhood Education","description":"Professional issues in the field of Early Childhood Education. Review of related historical and current trends. Types of programs of early education and care. Center observation required."},
    {"id":"ECE 1101","title":"Introductory Child Development","description":"Theories and principles of child development. Promoting positive growth from prenatal through age eight. Impact of the environment including the family, educational experiences, peer relationships, community and culture on each child’s development."},
    {"id":"ECE 1200","title":"Observation & Assessment","description":"Observing, documenting and assessing young children in programs of early education and care. Center observations required."},
    {"id":"ECE 1201","title":"Curriculum & Planning","description":"Current curriculum standards recognized in the field of Early Childhood Education. Planning high-quality developmentally appropriate learning experiences and environments for young children. Integrating social studies into the early childhood curriculum."},
    {"id":"ECE 1202","title":"Healthy & Safe Environments","description":"Students will examine and discuss content and issues related to the health, safety, and nutrition of young children birth through age 5. Students will explore information that relates to positive interactions that support optimal growth and development of young children.  Students will identify ways to engage the family in supporting these practices as well as licensing rules and state standards."},
    {"id":"ECE 2100","title":"Language, Literacy & Interaction with Young Children","description":"Language and literacy development in children birth through age five. Supporting family literacy, assessing language and literacy development, professional resources and planning curriculum to facilitate development of language and literacy in each child. Interaction techniques to support physical, social, emotional, aesthetic, language and cognitive development. Center observation required."},
    {"id":"ECE 2103","title":"Literacy, Art & Music","description":"Classroom experience with an emphasis on planning developmentally appropriate curriculum for preschool children following guidelines of professional standards.  Content areas include literacy, creative art, and music."},
    {"id":"ECE 2104","title":"Math, Science & Social Studies","description":"Classroom experience with an emphasis on planning developmentally appropriate curriculum for preschool children following guidelines of professional standards.  Content areas include math, science, and social studies."},
    {"id":"ECE 2200","title":"Families, Communities & Schools","description":"Family and community characteristics, supporting and empowering families and communities through respectful, reciprocal relationships and involving families and communities in the development and learning of children."},
    {"id":"ECE 2201","title":"Guidance of Young Children","description":"Guidance and behavioral intervention strategies used by early childhood professionals to help develop positive social and emotional skills in children birth through age 5. Practical application of guidance, problem-solving techniques and collaboration with families. Center observation required."},
    {"id":"ECE 2202","title":"Teaching Techniques","description":"Directed practice experience in the Sinclair Community College Early Childhood Education Centers. One classroom, ten directed practice hours per week."},
    {"id":"ECE 2203","title":"Intentional Practices in the Classroom","description":"Laboratory experience with an emphasis on identification of developmentally appropriate interactions and experiences that support intentional teaching.  Once a week seminar required. Additionally, students will spend 1.5 hours per week in the Sinclair Community College daycare center."},
    {"id":"ECE 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format."},
    {"id":"ECE 2300","title":"Inclusion","description":"Philosophical, historical and legal foundations. Inclusion of learners with special needs. Learning characteristics, instructional and behavioral strategies. Team members, collaboration with family and professionals."},
    {"id":"ECE 2301","title":"Early Childhood Education Practicum","description":"Coordinated practicum experience in an assigned program of early education and care.  Weekly seminar.  Written application required one semester in advance.  Two classroom, twenty-one practicum hours per week."},
    {"id":"ECE 2302","title":"Infant & Toddler Curriculum","description":"This course engages participants in exploration and discussion about high-quality care giving and developmentally appropriate practices when engaging with infants and toddlers and their families. The importance of quality environments that support development, language and literacy, family engagement, advocacy, positive guidance, and professionalism are discussed as they relate to required standards and the care of infants and toddlers."},
    {"id":"ECE 2303","title":"Early Childhood Education Practicum Part 1","description":"Coordinated practicum experience in an assigned program of early education and care. Weekly seminar. Twelve and one half hours of classroom time per week. Must also complete ECE 2304 for total required amount of hours."},
    {"id":"ECE 2304","title":"Early Childhood Education Practicum Part 2","description":"Coordinated practicum experience in an assigned program of early education and care. Weekly seminar. Twelve and one half hours of classroom time per week. Must also complete ECE 2303 for total required amount of hours."},
    {"id":"ECO 1100","title":"Introduction to Economics","description":"Survey of basic microeconomic and macroeconomic issues and concepts. Coverage includes:  Supply, Demand, Equilibrium, the U.S. Economy, the Global Economy and Trade, Competition and Monopoly, Gross Domestic Product, Business Cycles, Unemployment, Inflation, Aggregate Demand and Aggregate Supply, Economic Growth, Fiscal and Monetary Policies."},
    {"id":"ECO 2160","title":"Principles of Macroeconomics","description":"Basic economic principles with macro sequence. Interrelationship of households, business and government with an examination of Keynesian theory, fiscal policy and monetary policy. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"ECO 2180","title":"Principles of Microeconomics","description":"Microeconomic theory including price theory, the theory of the firm, resource demand and wage determination. Also includes public policy toward business, economic inequality, labor, trade, balance of payments and the economics of third-world nations. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"ECO 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"EDU 1100","title":"Introduction to Education","description":"Introduction to the teaching profession. A variety of experiences to facilitate exploration of the role of school and its relationship to society. The knowledge, skills, dispositions and performances necessary for an individual to become an effective teacher."},
    {"id":"EDU 1103","title":"Educational Technology","description":"This is a required course for all preservice teachers. It encompasses effective identification, location, evaluation, design, preparation and efficient usage of technology as an instructional resource in the classroom. Candidates will develop increased classroom communication abilities through lectures, discussions, modeling, laboratory experiences and completion of a comprehensive project."},
    {"id":"EDU 1105","title":"Individuals with Exceptionalities","description":"Introduction to the identification, developmental characteristics, foundations, theory, legal issues and intervention strategies for exceptional children and youth across educational and community settings."},
    {"id":"EDU 1107","title":"Art & Music for Elementary Education","description":"This course includes art and music education for elementary education teachers.  Including philosophy, skills, teaching techniques, concepts, and materials.  Focus on using art and music as teaching tools with other subjects to enhance student learning."},
    {"id":"EDU 2101","title":"Introduction to Early Childhood Trauma","description":"This course is an introductory course that looks at the effects of trauma on the brain, learning, and social and emotional intelligence."},
    {"id":"EDU 2102","title":"Understanding the Brain & Trauma","description":"This course explore brain development and the impact of trauma on individual development. Students will learn about social emotional development and apply trauma sensitive approaches that align with brain development."},
    {"id":"EDU 2103","title":"Trauma-Informed Classrooms","description":"Trauma-Informed Classrooms will explore and create a foundational understanding of the characteristics, effects, teaching strategies, and teacher expectations and responsibilities to assist in working with children in grades K-5  who experienced trauma ."},
    {"id":"EDU 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"EET 1116","title":"Electronics Schematics & Fabrication","description":"Draw circuits using Multisim. Compose directories using Windows commands. Identify schematic symbols and components. Produce a technical document with text, graphs and schematics. Assembly of circuits. Three classroom, three lab hours per week."},
    {"id":"EET 1120","title":"Introduction to DC & AC Circuits","description":"Introduction to direct and alternating current (DC/AC) circuits, power, three phase and test equipment. One classroom, two lab hours per week."},
    {"id":"EET 1121","title":"UAS Remote Sensing & Analysis","description":"This course introduces the foundations of remote sensing and data analysis. Students will acquire knowledge of the characteristics of various sensors and remote sensing applications applicable to civil unmanned aerial system (UAS) operations. Emphasis is placed on data acquisition and processing."},
    {"id":"EET 1131","title":"Digital Electronics","description":"Number systems, operations and codes, logic gates, Boolean algebra, DeMorgan's theorem and logic simplification, combination logic circuits, encoders/decoders, multiplexers/demultiplexers, adders, subtractors and ALUs, flip-flops and related devices, counters, shift registers, memory and storage, integrated circuit technologies. Four classroom, three lab hours per week."},
    {"id":"EET 1139","title":"Electrical Machinery","description":"Basic principle, theory, operation and characteristics of common DC and AC machinery. Two classroom, two lab hours per week."},
    {"id":"EET 1150","title":"DC Circuits","description":"Electrical components and quantities, voltage , current and resistance, Ohm’s law; analysis of series, parallel and series-parallel circuits, circuit theorems, capacitors and inductors, transient response of capacitive and inductive circuits. Three classroom, three lab hours per week."},
    {"id":"EET 1155","title":"AC Circuits","description":"Sinusoidal wave properties, complex numbers and phasors, behavior of transformers, steady-state behavior of RC circuits under AC conditions, steady-state behavior of RL circuits under AC conditions, steady-state behavior of RLC circuits under AC conditions, analysis of basic filter circuits, AC network theorems such as superposition, Thevenin's and Norton's theorems, three phase and polyphase power and power factor analysis. Two classroom, two lab hours per week."},
    {"id":"EET 1158","title":"Aerospace Spatial Visualization","description":"This course provides a basic overview of remote sensing, highlights the need for space astronomy, describes the composition of the space environment, principles of black/white and color photography, highlights the importance and different aspects of aerial photography and videography, aerial ground control and land mapping, visual image interpretation, thermal radiation principles associated with thermal sensing, remote sensing history from space as well as the U.S. Landsat program operations and contribution, digital image processing and classification, and microwave sensing principles and applications. One classroom, two lab hours per week."},
    {"id":"EET 1166","title":"Industrial Machine Wiring","description":"Elementary industrial machine wiring principles; schematics, panel layouts, assembly, wiring techniques and equipment used in automated industry; standards for safe operation of equipment and protection of personnel with emphasis given to hands-on work and actual wiring of panels. Two classroom, two lab hours per week.  Note:  EGR 1106 may be taken concurrently with department approval."},
    {"id":"EET 1181","title":"Electrical Construction I","description":"Basic safety procedures, use of hand and power tools; electrical circuit theory; use of test equipment; basics of residential, commercial and industrial wiring observing National Electric Code (NEC).  One classroom, six lab hours per week"},
    {"id":"EET 1182","title":"Electrical Construction II","description":"Alternating current theory, motors, grounding, conduit bending, conductor installation, National Electric Code (NEC) for cables, terminations and splices, electrical single and three phase installation, circuit breakers and fuses, contactors and relays.  One classroom, six lab hours per week."},
    {"id":"EET 1183","title":"Electrical Construction III","description":"Load calculations for branch circuits, overcurrent protection, wiring devices, distribution equipment, transformers, calculations for motor circuits: motor maintenance and controls and basics of HVAC systems. One classroom, six lab hours per week."},
    {"id":"EET 1184","title":"Electrical Construction IV","description":"Calculation procedures for residential, commercial and farming applications, various wiring systems, stand-by and emergency systems, basic electronics, fire alarms, special transformers, solid-state controls, welding techniques, heat and freeze protection and high-voltage termination. One classroom, six lab hours per week."},
    {"id":"EET 1198","title":"Digital Technology","description":"Electrical fundamentals, introduction to basics of digital logic and circuits, digital systems and basic digital circuit design. One classroom, two lab hours per week."},
    {"id":"EET 2157","title":"Radio Frequency Identification (RFID) Technology","description":"Review of basic radio frequency identification (RFID) terminology, emerging electronic product code (EPCglobal) standards, tag design and applications; interrogators configuration, installation and maintenance of common peripherals, various hardware and software components of a complete system, hands-on lab experience and basic RFID applications.  Two classroom and two lab hours per week."},
    {"id":"EET 2201","title":"Electronic Devices & Circuits","description":"Semiconductor properties, diode applications, special-purpose diodes, bipolar junction transistors (BJTs), BJT biasing circuits and stability, BJT amplifier circuits, multistage amplifier design, power amplifiers, field effect transistors (FETs), JFET and MOSFET biasing circuits, FET amplifier circuits, frequency analysis, thyristors and applications, negative and positive feedback concepts, oscillators, Op-Amp circuits and applications, and electronically regulated power supplies.  Four classroom, three lab hours per week."},
    {"id":"EET 2221","title":"UAS Sensors & Systems","description":"Course will provide students a foundational understanding encompassing all elements of an unmanned aerial system (UAS). Students will be provided the knowledge and necessary skill set to support UAS application. Three classroom, three lab hours per week."},
    {"id":"EET 2257","title":"Radio Frequency Identification (RFID) Capstone","description":"Initiating best analysis, design and implementation of a Radio Frequency Identification (RFID) solution.  Configuration and troubleshooting exercises designed to illustrate the power of today's RFID readers and their interaction with input/output, practical, in-depth instruction and hands-on guidance for leveraging RFID in the real world. Two classroom, two lab hours per week."},
    {"id":"EET 2259","title":"Programming for Electronics Technology","description":"Computer solutions of engineering technology problems using LabVIEW. Covers the LabVIEW programming environment and virtual instruments, data types, debugging, sub-virtual instruments, programming structures, arrays, graphical presentation and analysis, file input/output, instrument control, data acquisition, and applications to electronic circuits. Three classroom, two lab hours per week."},
    {"id":"EET 2261","title":"Microprocessors","description":"Microprocessor architecture, assembly language programming, bus structures and timing diagrams, memory technologies and interfacing, input/output interface and systems, interrupt-processed input/output, direct memory access (DMA), microcontroller applications and microprocessor-based communications.  Three classroom, three lab hours per week."},
    {"id":"EET 2270","title":"Electronics Engineering Technology Internship","description":"Students earn credits toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each semester. Ten co-op hours per week per credit hour."},
    {"id":"EET 2278","title":"Electronics Project Capstone","description":"Review of electronic circuits, analog and digital electronics, microcontrollers, design and layout of printed circuit board, fabricate-assemble-test-troubleshoot working prototype, write report.  Two classroom, four lab hours per week."},
    {"id":"EET 2281","title":"Programmable Logic Controllers","description":"Provides history of control systems and PLCs, use of number systems, ladder logic programming devices, Control I/O modules, relays, contacts, coils, and timers, counters and sequencers, fundamental PLC programming, and data transfer.  Two classroom, two lab hours per week."},
    {"id":"EET 2282","title":"Advanced Programmable Logic Controllers","description":"Demonstrate the use of control and set analog I/O, bit and project based programming, control servos with analog & High Speed Counter (HSC) cards, the use of ethernet network for programmable logic controllers (PLCs), the interaction between PLCs and sensors, installation and repair. Two classroom, two lab hours per week."},
    {"id":"EET 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"EFD 1102","title":"Dental Anatomy for Dental Auxiliaries","description":"A study of form and function of the human dentition. This course is designed to provide an overview of the terminology and characteristics of all teeth in the adult and primary dentition. Includes identification of all anatomical tooth structures, eruption schedule and occlusion."},
    {"id":"EFD 1202","title":"Expanded Functions for Dental Auxiliaries I","description":"Lecture and clinical course designed to teach more extensively the concepts of dental materials and their use in restorative techniques. The principles of the manipulation and placement of dental materials used in delegated intra-oral functions for the expanded function dental auxiliary in Ohio are taught. Three classroom, four lab hours per week plus ten hours of co-op experience per week in the dental office."},
    {"id":"EFD 1203","title":"Lab for Expanded Functions for Dental Auxiliaries I","description":"Laboratory experiences in advanced remediable intra-oral dental tasks and/or procedures involved in the art and placement of restorative materials including amalgam and nonmetallic restorative materials including resin restorations."},
    {"id":"EFD 1302","title":"Expanded Functions for Dental Auxiliaries II","description":"This course is the third in a three-part series for the Expanded Functions Dental Auxiliary Program. Greater emphasis on topics covered in EFD 1202 is examined. Detailed concepts with regards to amalgam, esthetic and preventive resins are discussed. Concepts involving Class IV resin restorations and dental sealants are introduced. Mock boards are included. Three classroom, four lab hours per week, plus ten hours of co-op experience per week in a dental office."},
    {"id":"EFD 1303","title":"Lab for Expanded Functions for Dental Auxiliaries II","description":"Laboratory experiences in advanced remediable intra-oral dental tasks and/or procedures involved in the art and placement of preventive or restorative materials including amalgam, dental sealants and nonmetallic restorative materials including resin restorations."},
    {"id":"EFD 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"EGR 1101","title":"Introductory Mathematics for Engineering Applications","description":"An overview of math topics used in engineering courses: algebra, trigonometry, vectors, complex numbers, sinusoids, systems of equations, matrices, differentiation, integration, differential equations. All math topics are presented within the context of engineering applications, reinforced through examples from engineering courses. Also introduces the engineering analysis software MATLAB. Three classroom, three lab hours per week."},
    {"id":"EGR 1106","title":"Basic Mechanical & Electrical Skills","description":"Utilization of general/specialized hand/power tools that are typically used in the electromechanical industry; use of various dimension measurement devices; simple machine repair procedures from belt replacement to complete subsystem repair; drilling, reaming and tapping holes for various mechanical fasteners. Elementary industrial machine wiring principles; schematics, panel layouts, assembly and wiring techniques. One classroom, two lab hours per week."},
    {"id":"EGR 1111","title":"Introduction to Nanotechnology","description":"Introduction to nanotechnology and its application to engineering systems, emphasizing basic principles, materials, measurement tools, fabrication techniques, and applications.  Two classroom, two lab hours per week."},
    {"id":"EGR 1121","title":"Introduction to the Intelligence Community","description":"Presents an overview of the Intelligence Community (IC), the origin and purpose of the IC, its current structure and the diverse roles and missions of its members. Students will study the intelligence cycle, the heart of the IC, by examining the entire process used for creating intelligence: identifying requirements; tasking appropriate agencies and systems to collect data; the processing, exploiting and analyzing of the data and the production and delivery of timely, accurate and relevant intelligence products. This course will also introduce students to operations and communications security, counterintelligence and covert action, homeland security, intelligence oversight and ethics. Two classroom, two lab hours per week."},
    {"id":"EGR 1122","title":"Fundamentals of Remote Sensing in Intelligence","description":"This course emphasizes the science, technology and applications of remote sensing, bringing together related information in materials science, physics, optics, electronics, computer processing and other disciplines. Students completing this course will be equipped to approach problems ranging from environmental to social to industrial data gathering and interpretation. Two classroom, two lab hours per week."},
    {"id":"EGR 1128","title":"Robotics in Computer Integrated Manufacturing (CIM) Systems","description":"This course serves as an introduction to automated systems. The basics of sensors, logic control systems, motion control systems, robotics and flexible manufacturing systems will be covered. The course will be taught using demonstration and discussion combined with individual and team centered project-based learning. One classroom, four lab hours per week."},
    {"id":"EGR 1144","title":"Sensors & Vision Systems","description":"Introduction to basic sensors used in Computer Integrated Manufacturing (CIM) systems. Theory of operation, wiring, installation, testing and troubleshooting sensors and circuits. The analysis of various methods of utilizing vision systems in industrial applications using camera, lighting and software. Three classroom, three lab hours per week."},
    {"id":"EGR 1201","title":"Introduction to Spectral Sensing with Applications in Intelligence","description":"Concepts of spectral remote sensing as they are applied to military / intelligence applications with special emphasis on commercial sensors and solutions. Advantages and disadvantages of special remote sensors.  Content will cover available unclassified spectral instruments (both hyper-spectral and multi-spectral sensors), their characteristics and how to best employ them.  Topics include Basic Spectral Phenomenology, the Spectral Signature, Sensor Analysis, Data Products and Data Fusion.  Two classroom, two lab hours per week."},
    {"id":"EGR 1202","title":"Introduction to Radar","description":"Capabilities and limitations of radar, the performance and implementation of its critical sub-systems and the requirements particular radars must meet in order to perform common Measurement and Signature Intelligence (MASINT) and Advanced Geospatial Intelligence (AGI) missions (e.g. Synthetic Aperture Radar (SAR), Line of Sight and Over the Horizon). Students will become conversant in Radar and able to exploit its use in a variety of potential intelligence tasks with a basic knowledge enabling them to predict the expected performance of a radar system. Two classroom, two lab hours per week."},
    {"id":"EGR 1211","title":"Introduction to Large Area Surveillance","description":"This course is designed to familiarize the student with the concepts of electro-optical remote sensing of important objects that can appear anywhere in the world without warning for a limited period of time. Some of these objects can also be rapidly moving. Such objects include missiles and aircraft in powered flight, nuclear and conventional explosions, fires and other military activity. Discussion includes the unique object signature and sensor characteristics that make detection of these objects possible while continuously monitoring large areas. Two classroom, two lab hours per week."},
    {"id":"EGR 1212","title":"Measurement & Signal Intelligence","description":"Overview of Measurement and Signature Intelligence (MASINT) and Advanced Geospatial Intelligence (AGI) disciplines including the science behind geophysical signatures such as Chemical, Biological, Radiological and Nuclear Weapons. MASINT as it relates to Seismic and Acoustic phenomena, Geophysical Materials and Radio Frequency Spectrum. Different technologies used in lethal and nonlethal Directed Energy Weapons identifying strengths and vulnerabilities of electromagnetic and chemically powered artillery. Students will apply MASINT/AGI collection and processing techniques and capabilities to develop a collection and analysis plan targeting one of today's challenging intelligence problems. Two classroom, two lab hours per week."},
    {"id":"EGR 1217","title":"Fluid Power & Control","description":"Fundamentals and basic applications of fluid power components, systems, controls and accessories. The design parameters and the terminology required to specify and plan fluid power systems. Basic electrical and Programmable Logic Control (PLC) control of fluid power components.  One classroom, three lab hours per week."},
    {"id":"EGR 2131","title":"Engineering Digital Design","description":"Binary systems, Boolean algebra, combinational and sequential circuits, digital design using computer-aided design (CAD) tools with hardware description language. Laboratory exercises include simulation using CAD tools and implementation of designs on breadboards and on field-programmable-gate-array boards. This course is designed for Engineering University Transfer students. Three classroom, three lab hours per week."},
    {"id":"EGR 2201","title":"Circuit Analysis","description":"Principles of linear circuit analysis, covering circuits containing passive and active components.   Analysis of direct-current (DC) and alternating-current (AC) circuits, including transient behavior and sinusoidal steady-state behavior.  This calculus-based course is designed for Engineering University Transfer students. Three classroom, three lab hours per week."},
    {"id":"EGR 2211","title":"Nanotechnology Applications & Fabrications Techniques","description":"Discussions and examples of applications of nanotechnology in Biology, Physics, Chemistry, Medical, Material Science, and Engineering. Introduction to nanofabrication tools, clean room and scanning electron microscope (SEM) via remote lab demonstrations."},
    {"id":"EGR 2231","title":"Troubleshooting of Automated Systems","description":"Concept of troubleshooting and its importance in manufacturing systems. Troubleshooting philosophies, flowchart examination, electrical and mechanical troubleshooting. Techniques for troubleshooting systems containing sensors, PLCs, Robots, HMIs and other common automation equipment. Fault determination using software to monitor the performance of small automated systems. Two classroom, three lab hours per week."},
    {"id":"EGR 2250","title":"Electromechanical Repair","description":"Teaches the student theory of controller operation, function of power inputs and supply units, command and feedback loops.  Also, troubleshooting, diagnostics and repair including removal and replacement of belts, pulleys, bearings and gears.  Finalizing with alignment and recalibration through the computer controller. Three classroom, three lab hours per week."},
    {"id":"EGR 2252","title":"Teach Pendant Robot Programming","description":"Introduction to Teach Pendant Programming (TPP) for robots, including TPP program development on the teach pendant and through offline programming software. Programs, tested using Fanuc robots, will be written for motion control, input/output activation and palletizing. One classroom, two lab hours per week."},
    {"id":"EGR 2256","title":"Automated Data Acquisition Systems","description":"Data acquisition technologies with the use of bar coding, image recognition, optical character recognition, Charge Coupled Device (CCD) camera images, laser scanning, voice recognition, radio frequency and microwave transponder. Two classroom, two lab hours per week."},
    {"id":"EGR 2261","title":"Engineering Problem Solving using \"C\" & \"C++\"","description":"Solve representative engineering problems with a focus on: writing in object-oriented style, computer control of input/output port control, stand-alone executable code and library linking. Computer solutions of engineering problems using C and C++ incorporating compiling, running, editing and debugging techniques along with language-specific functions, array and pointer structures and stream I/O. Three classroom, three lab hours per week."},
    {"id":"EGR 2270","title":"Automation & Control Internship I","description":"Students earn credit  toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each semester.  Ten hours work per week per credit hour."},
    {"id":"EGR 2271","title":"Automation & Control Internship II","description":"Students will earn credit toward degree requirements for work learning experience for a second semester. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare reports and/or projects each semester. Ten hours work per week per credit hour."},
    {"id":"EGR 2272","title":"Automation & Control Internship III","description":"Students earn credit toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each semester. Ten hours work per week per credit hour."},
    {"id":"EGR 2273","title":"Automation & Control Internship IV","description":"Students earn credit toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each semester. Ten hours work per week per credit hour."},
    {"id":"EGR 2274","title":"Automation & Control Internship V","description":"Students earn credit toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each semester. Ten hours work per week per credit hour."},
    {"id":"EGR 2275","title":"Automation & Control Technology Internship","description":"Students earn credit toward degree requirements for work learning experience.  Students already working may apply to use that experience to meet internship requirements.  Students establish learning outcomes and prepare related reports and/or projects each semester.  Ten hours work per week per credit hour."},
    {"id":"EGR 2278","title":"Automation & Control Capstone","description":"Project-based review of robotic workcell system design, layout and integration of related industrial systems, and skills from the following areas: robots and programming languages, electronic systems, component installation, troubleshooting, mechanical repair and preventative maintenance. Additional focus on graphics, work processing, analytical and simulation tools, assembly, testing, troubleshooting and repair of a functional robot workcell. One classroom, four lab hours per week."},
    {"id":"EGR 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"EGV 1101","title":"Alternate & Renewable Energy Sources","description":"Overview of past, recent and current research to find viable alternative sources of energy. Examples include water, wind, solar, bio-mass, alternative liquid fuels and introduction to fuel cell technology. Study of applied technologies in the context of how to relieve complete dependence on petrochemical-based products. A case study approach to learning is used. Two classroom, two lab hours per week."},
    {"id":"EGV 1251","title":"Introduction to Energy Management Principles","description":"The course introduces the principles of energy management and an effective energy management plan.  This course provides an overview of energy consuming systems and operations of commercial and industrial buildings and systems and energy saving opportunities for them.  Two classroom, two lab hours per week."},
    {"id":"EGV 1301","title":"Architectural Energy Analysis","description":"Critical examination of energy consumption in building, both residential and commercial, for the purpose of identifying energy conservation opportunities. One classroom, two lab hours per week."},
    {"id":"EGV 1401","title":"Weatherization & Building Performance Training","description":"This course covers energy assessment and weatherization methods for single and multifamily dwellings.  The course covers the operation of the equipment: blower door, duct blaster, pressure pan, flow meter, infrared camera, and combustion analyzer.  This course is designed to prepare students for the Building Performance Institute Building Analyst written and field test.  Two classroom, two lab hours per week."},
    {"id":"EGV 1501","title":"Environmental Assessment & Analysis","description":"Sampling and analysis techniques for site characterization and assessment. Sampling methods and protocols are presented in detail with respect to environmental liability, compliance and property transfer. Environmental monitoring is explained with emphasis on air quality, surface water and groundwater.  Two  classroom, three lab hours per week."},
    {"id":"EGV 1610","title":"Water Distribution Systems","description":"Examination of the basic concepts pertaining to the operation and maintenance of water and wastewater treatment plants. Students will become familiar with analyzing and solving operational problems, operator performance, contingency procedures, and water treatment equipment. This course is designed to prepare students to sit for EPA Operator Certifications. Two lecture, two lab hours per week."},
    {"id":"EGV 1620","title":"GIS Mapping","description":"Examination of geographic information systems (GIS) as they relate to water and wastewater treatment, collection, and distribution systems. Students are exposed to theoretical and practical application of GIS devices and mapping software. Two classroom, two lab hours per week."},
    {"id":"EGV 1630","title":"Wastewater Collection Systems","description":"Examination of topics related to the operation and maintenance of wastewater collection systems (WWCS). The course will cover basic concepts related to the inspection, testing, cleaning of pipes, maintenance, underground repair, new construction, administration, and organization of WWCS. Designed to prepare students to sit for Ohio EPA Operator Certifications. Two classroom, two labs hours per week."},
    {"id":"EGV 1640","title":"Introduction to Backflow","description":"Examination of backflow prevention concepts designed to meet the Ohio Administrative Code (OAC) 3745-95 Backflow Prevention and Cross Connection Control requirements. A water supply employee may be eligible to sit for the State of Ohio Department of Commerce Certified Backflow Technician exam. One half classroom hour, one and one half lab hours per week."},
    {"id":"EGV 2101","title":"Solar Photovoltaic Design & Installation","description":"This course covers components of solar PV systems and components and the sizing of PV systems and components. Designed to prepare the student to take the NABCEP PV Entry Level Exam. Two classroom, two lab hours per week."},
    {"id":"EGV 2151","title":"Solar Thermal Systems","description":"This course covers some of the basic cognitive materials needed to install and maintain solar thermal systems. Designed to help individuals better prepare for the North American Board of Certified Energy Practitioner (NABCEP) Solar Thermal Installer examination but does not provide all of the materials needed to complete the certification examination. Two classroom, two lab hours per week."},
    {"id":"EGV 2201","title":"Electrical Lighting & Motors","description":"This course covers components of lighting systems, control strategies, current technologies and electric motors. Energy efficiency opportunities and environmental impacts are identified and analyzed. One classroom, two lab hours per week."},
    {"id":"EGV 2251","title":"Energy Control Strategies","description":"This course covers the use of utility data to conduct a “Lean Energy Analysis,” utility rate structures, the use of both whole building computer simulation and discrete system computer simulation to estimate building and system energy use, energy demand and carbon footprint.  Two classroom, two lab hours per week."},
    {"id":"EGV 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"EGV 2301","title":"Commercial & Industrial Assessment","description":"This course covers methods of collecting data (utility, envelope, mechanical systems, and operational procedures) for both commercial and industrial facilities and analyzing the data with statistical procedures and simulation software to develop energy-saving management plans. Two classroom, two lab hours per week."},
    {"id":"EGV 2351","title":"LEED Green Associate Exam Preparation","description":"This course helps prepare the student for the first of the LEED Green Associate Exams and meets the requirement of the student having involvement on a LEED- registered project, or employment in a sustainable field of work or completion of an education program that addresses green building principles in LEED, to qualify to take the LEED Green Associate Exam. One classroom, two lab hours per week."},
    {"id":"EGV 2501","title":"Waste Management","description":"Develop a working knowledge of present waste-management practices including minimization, storage, transportation, treatment and disposal of various waste related to the life cycle of a given activity and corrective actions related to contamination. Two classroom, three lab hours per week."},
    {"id":"EGV 2610","title":"Water Supply","description":"Examination of the concepts related to the operation of water treatment systems. The course will cover basic concepts related to contaminant identification, safety procedures, regulations and treatment technologies along with other properties related to water supply. This course is designed to prepare students to sit for Ohio EPA Operator Certification. Two classroom, two lab hours per week."},
    {"id":"EGV 2630","title":"Wastewater Treatment","description":"Examination of the concepts related to the operation of wastewater treatment systems. The course will cover basic concepts related to pollutant identification, safety procedures, regulation and treatment theory along with properties related to wastewater treatment. This course is designed to prepare students to sit for Ohio EPA Operations Certifications. Two classroom, two lab hours per week."},
    {"id":"EGV 2700","title":"Energy Management Technology Internship","description":"Students earn credits toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each term. Ten co-op hours per week per credit hour."},
    {"id":"EGV 2701","title":"Environmental Engineering Technology Internship","description":"Students earn credits toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each term. Ten co-op hours per week per credit hour."},
    {"id":"EGV 2780","title":"Energy Management Technology Capstone","description":"Assessment of achievement by Energy Management Technology students in attaining program outcomes by completing a project demonstrating principles and practice of the major. Teamwork on projects will be emphasized.  Two classroom, four lab hours per week."},
    {"id":"EGV 2781","title":"Environmental Engineering Technology Capstone","description":"Assessment of achievement by Environmental Engineering Technology students in attaining program outcomes by completing a project demonstrating principles and practice of the major. Teamwork on projects will be emphasized.  Two classroom, four lab hours per week."},
    {"id":"EMS 1100","title":"Emergency Medical Responder Lecture & Laboratory","description":"Emergency medical responders provide initial care for the sick and injured prior to the arrival of the ambulance. This education can be valuable to safety officers working in industry, police officers and some rural fire departments. This course will meet a total of 16 lecture hours and 32 laboratory hours. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 1150","title":"Emergency Medical Technician: Lecture","description":"The Emergency Medical Technician Lecture provides students with the didactic information needed to understand the care of the sick and injured at the emergency medical technician level. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 1155","title":"Laboratory for Emergency Medical Technician","description":"The Emergency Medical Technician Laboratory provides students with the psychomotor information needed to understand the care of the sick and injured at the emergency medical technician level. Student will attend a total of 64 hours of laboratory time. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 1175","title":"Emergency Medical Technician Refresher","description":"This course meets or exceeds the national standard curriculum for EMT refresher. Designed to meet the needs of two types of emergency medical services providers: 1) Students who are recertifying their state licensure cards can use this course to complete their state requirements. 2) Students who need to remediate on material before taking their national registry examination can use this course to meet those needs. This course will meet a total of 16 lecture hours and 32 laboratory hours."},
    {"id":"EMS 2100","title":"Applied Anatomy, Physiology & Pathophysiology for Emergency Medical Services Provider","description":"This course provides the fundamental anatomy, physiology and pathophysiology information necessary to understand the care of the sick and injured at the paramedic level. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2105","title":"Paramedic 1: Lecture","description":"The paramedic student is introduced to the fundamental concepts of patient assessment, airway management and pharmacology. These concepts are reinforced within the laboratory setting of EMS 2110. Successful students will then be prepared to begin applying this knowledge within the clinical setting. Clinical activity begins in the second semester of the paramedic program. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2110","title":"Paramedic 1: Laboratory","description":"This is the introductory laboratory experience within the paramedic program. Skills will be reviewed and retested from the Emergency Medical Technician level. CPR will be retested. New skills will involve patient assessment, airway management and pharmacology, including drug math. Four lab hours per week. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2125","title":"Paramedic 2: Lecture","description":"The paramedic student will explore cardiology, pulmonology and pediatrics. This course covers ECG acquisition, interpretation and appropriate patient management, cardiovascular pathology and management, respiratory pathology and management and care for the pediatric patient. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2130","title":"Paramedic 2: Laboratory","description":"This course covers the assessment and management of patients with cardiac/respiratory disease. Also covered will be care of the pediatric patient. Specific skills covered include ECG, cardiac monitor use, CPR, advanced cardiac life support (adult and pediatric) and management of respiratory emergencies. Four lab hours per week. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2135","title":"Paramedic 2: Clinical","description":"The paramedic student is introduced to the hospital clinical setting where he/she will apply knowledge learned from the lecture and laboratory setting in the direct supervised care of patients. Fourteen practicum hours per week. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2136","title":"Paramedic 2a: Clinical","description":"The paramedic student is introduced to the hospital clinical setting where he/she will apply knowledge learned from the lecture and laboratory setting in the direct supervised care of patients. All students must complete 112 hours in the hospital setting. Seven practicum hours per week. This course must be followed by EMS 2137. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2137","title":"Paramedic 2b: Clinical","description":"The paramedic student is introduced to the hospital clinical setting where he/she will apply knowledge learned from the lecture and laboratory setting in the direct supervised care of patients. All students must complete a 112-hour hospital experience. Seven practicum hours per week. This course occurs after EMS 2136. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2150","title":"Paramedic 3: Lecture","description":"The paramedic student will explore management of the trauma patient and an in-depth discussion of the medical patient including assessment and management. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2155","title":"Paramedic 3: Laboratory","description":"This course covers the assessment and management of patients suffering from traumatic injuries, basic rescue techniques, obstetrical emergencies and medical emergencies. Specific skills covered include managing patients in vehicles, traumatic airway management, neonatal care and differential diagnosis. Four lab hours per week. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2160","title":"Paramedic 3: Clinical","description":"This course is divided into two settings: hospital and out-of-hospital. Within the hospital clinical setting, the student will work to master knowledge learned related to the direct supervised care of patients. Once mastery is demonstrated, the student will be cleared to apply his/her education in the out-of-hospital setting. All students must complete a 112-hour field experience/ambulance assignment. Seven practicum hours per week. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2175","title":"Paramedic 4: Lecture","description":"This course is a complaint based approach to patient care. Students will focus on integrating pathology, assessment and patient care knowledge in the care of patients with multiple pathologies. Four classroom hours per week for eight weeks. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2180","title":"Paramedic 4: Field Experience","description":"Under direct supervision, students work to integrate lecture, laboratory and hospital clinical knowledge to treat patients in the out-of-hospital setting. The goal is to establish mastery of patient care skills as they apply to the out-of-hospital care setting. All students must complete a 112-hour field internship. Seven practicum hours per week. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2200","title":"Paramedic 5: Integration / Refresher Lecture","description":"This course reviews all materials from the entire paramedic program. Its goals are to integrate past knowledge and skills into a comprehensive approach to out-of-hospital patient care. Four classroom hours per week for eight weeks. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2205","title":"Paramedic 5: Integration / Refresher Laboratory","description":"This course will review all psychomotor skills covered within the paramedic program as students are prepared for the comprehensive practical examination. Students will also have applied knowledge evaluated within the field setting. Students will discuss appropriate patient care with the medical director of the EMS program. Four lab hours per week for eight weeks. The EMS program is accredited by the Ohio Division of EMS, Department of Public Safety. Accreditation number 326."},
    {"id":"EMS 2250","title":"Paramedic Refresher","description":"This course meets or exceeds the national standard curriculum for paramedic refresher. Designed to meet the needs of two types of emergency medical services providers: 1) Students who are recertifying their state licensure cards can use this course to complete their state requirements. 2) Students who need to remediate on material before taking their national registry examination can use this course to meet those needs. Two classroom, two lab hours per week."},
    {"id":"EMS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"EMS 2300","title":"Critical Care Paramedic 1","description":"This course explores the technologically challenging area of critical care medicine. The use of advanced diagnostics such as arterial lines and swan ganz catheters and patient management skills such as ventilators, LVADs and advanced pharmacology will be explored."},
    {"id":"EMS 2305","title":"Critical Care Paramedic 2","description":"This course explores the technologically challenging area of critical care medicine. The use of advanced diagnostics such as laboratory results, x-rays and ultrasounds and the management of patients with trauma, shock and various medical conditions will be explored."},
    {"id":"EMS 2310","title":"EMS Management 1","description":"This course is for Emergency Medical Services (EMS) personnel who need to understand the roles and responsibilities of EMS managers. Various aspects of management, including finance, injury prevention, communication, public access and EMS system management will be discussed."},
    {"id":"EMS 2315","title":"EMS Management 2","description":"This course is for Emergency Medical Services (EMS) personnel who need to understand the roles and responsibilities of EMS managers. Various aspects of management, including EMS law, quality assurance, customer service and the evaluation of specific mass casualties will be addressed."},
    {"id":"END 1101","title":"Introduction to Electroneurodiagnostic Technology","description":"Introduction and orientation to health careers in field of electroneurodiagnostic including specific duties, certifications and licensure requirements, work setting and conditions, and career ladder opportunities. Overview of standards of practice of clinical neurophysiology with emphasis on neuroscience technique, instrumentation, terminology of electoneurodiagnostic practices and recording/monitoring techniques utilized in determination of treatment plans for neurological disorders."},
    {"id":"END 1102","title":"Introduction to Electroencephalography (EEG)","description":"Provides basic knowledge of electroencephalography, understanding EEG concepts utilized for diagnosis of various cerebral disorders. Includes history, development, basic neurophysiology concepts of EEG, normal and abnormal brain wave patterns in adults and children, with emphasis on instrumentation and recording techniques. Two classroom, six lab hours per week."},
    {"id":"END 1182","title":"Lab for Intro to EEG","description":"This is the laboratory portion of the Introduction to Electroencephalography (EEG) and will provide the \"hands-on\" aspects to the basic knowledge of electroencephalography, understanding EEG concepts utilized for diagnosis of various cerebral disorders. Includes basic neurophysiology concepts of EEG, normal and abnormal brain wave patterns in adults and children, with emphasis on instrumentation and recording techniques."},
    {"id":"END 1250","title":"Intermediate Electroencephalography (EEG)","description":"Discussion of clinical significance of epileptiform patterns, pharmacological effects on EEG recordings; EEG correlation of infection; and vascular and structural disease. Presentation and discussion of criteria for specialized recording techniques used in prolonged EEG recordings, specialized areas of the hospital, such as intensive care and operating room. Discussion of EEG signal analysis.  Two classroom, two lab hours per week."},
    {"id":"END 1260","title":"Basic Evoked Potentials","description":"Basic discussion of evoked potential recording techniques. Emphasis on equipment, principles of operation, associated waive related to normal and abnormal waveforms, placement and calibration, obtaining clearly resolved and replicated obligated waveforms of brainstem auditory, visual, and somatosensory evoked potentials in adults and pediatric subjects. Two classroom, three lab hours per week."},
    {"id":"END 1285","title":"Lab for Intermediate EEG","description":"Lab for Intermediate Electroencephalography (EEG) will identify clinical significance of epileptiform patterns, pharmacological effects on EEG recordings; EEG correlation of infection; and vascular and structural disease. Analyze criteria for specialized recording techniques used in prolonged EEG recordings, specialized areas of the hospital, such as intensive care and operating room. Perform EEG signal analysis."},
    {"id":"END 1286","title":"Lab for Basic Evoked Potentials","description":"Lab for Basic Evoked Potential Basic demonstrate evoked potential recording techniques. Emphasis on equipment, principles of operation, associated waive related to normal and abnormal waveforms, placement and calibration, obtaining clearly resolved and replicated obligated waveforms of brainstem auditory, visual, and somatosensory evoked potentials in adults and pediatric subjects."},
    {"id":"END 1421","title":"Intermediate Polysomnography","description":"The course will focus on discussion of the classification of sleep disorders, and the physiological effects of sleep disorders and ramifications/implications on patient health. In addition there will be discussion of medication effects on sleep stages/patterns. Discussion of various therapies for sleep disordered breathing, and other sleep disorders. Discussion on monitoring of nocturnal seizures, and seizure types. Two classroom, three lab hours per week."},
    {"id":"END 1425","title":"Lab for Intermediate Polysomnography","description":"Lab includes demonstration of pattern recognition of sleep disorders, physiological effects of sleep disorders and ramifications/implications on patient health, Positive Airway Pressure (PAP) set-up, nocturnal O2, CO2 monitoring. Recognizing medication effects on sleep stages/patterns. Determining various therapies for sleep disordered breathing, and other sleep disorders, nocturnal seizures, and seizure types."},
    {"id":"END 1430","title":"Advanced Polysomnography","description":"The course will discuss parameters, digital and technical specifications of polysomnography, staging and scoring of sleep patterns, identification of various forms of sleep breathing and movement disorders. Discuss sleep calculations and daytime sleep studies, advanced PAP therapies, esophageal pH and NPT testing, sleep disorders lab management, and Home Sleep Testing. Two classroom, three lab hours per week."},
    {"id":"END 1435","title":"Lab for Advanced Polysomnography","description":"Lab for this course involves parameters, digital and technical specifications of polysomnography, staging and scoring sleep patterns, identification breathing and movement disorders. Demonstrating sleep calculations and daytime sleep studies advanced PAP therapies, esophageal pH and NPT testing. Evaluating the aspects of sleep disorders lab management, and Home Sleep Testing."},
    {"id":"END 1801","title":"Seminar for Polysomnography Practicum","description":"Classroom portion to enhance the END 1881 Polysomnography practicum experience in a selected polysomnography lab or an affiliated health care facility under the direct supervision of a PSG technologist or physician. Emphasis on PSG concepts."},
    {"id":"END 1891","title":"Polysomnography Practicum","description":"Practicum in the clinical setting in a sleep laboratory or a sleep center. Departmental orientation, policies and procedures, individual body mechanics and patient transfer techniques. Gather and analyze patient information, perform testing preparation procedures, perform polysomnographic procedures. Emphasis on performing overnight diagnostic and therapeutic polysomnograms. Twenty-one practicum hours per week."},
    {"id":"END 1901","title":"Seminar for END Practicum I","description":"Classroom portion to enhance the Clinical electroencephalography experience in a selected neurodiagnostic lab or an affiliated health care facility under the direct supervision of an EEG technologist or physician. Emphasis on EEG concepts. One classroom, seven practicum hours per week."},
    {"id":"END 1991","title":"Practicum Experience I for END","description":"Clinical electroencephalography experience in a selected neurodiagnostic lab or an affiliated health care facility under the direct supervision of an EEG technologist or physician. Emphasis on EEG concepts. Performance of EEG testing on clinical patients, medical record keeping and clinical history taking."},
    {"id":"END 2350","title":"Intraoperative Monitoring for Electroneurodiagnostic Technologists","description":"Discussion of intraoperative monitoring of CNS (brain, brainstem, spinal cord) function during surgical procedures. Types of recordings, technologist’s role, recording parameters, reason for surgical monitoring, variables affecting monitoring, and outcome of surgery."},
    {"id":"END 2360","title":"Neonatal/Pediatric Electroneurodiagnostic","description":"Discussion of recording neonatal and pediatric EEG and polysomnograms. Development of sleep-wake cycle, monitoring the EEG in neonatal and pediatric populations, and differential diagnosis based on polysomnographic variables. Two classroom, two lab hours per week."},
    {"id":"END 2386","title":"Lab for Neonatal/Pediatric EEG","description":"Lab for Neonatal/Pediatric Electoneurodiagnostic will demonstrate recording neonatal and pediatric EEG and polysomnograms. Development of sleep-wake cycle, monitoring the EEG in neonatal and pediatric populations, and differential diagnosis based on polysomnographic variables."},
    {"id":"END 2450","title":"Nerve Conduction Studies","description":"Basic discussion of nerve conduction studies and electromyography. Emphasis on equipment, knowledge of placement stimulation sites, sources of error in nerve conduction studies, electronics, pathology (abnormal nerve conduction studies, anatomy as it pertains to entrapment sites and nerve conduction studies), waveforms identification and case presentation. Two classroom, two lab hours per week."},
    {"id":"END 2460","title":"Neurophysiology of Electroencephalography/Sleep Disorders","description":"Analysis of the central and peripheral nervous systems, electrophysiology, and nerve conducting velocities in health and disease. Includes discussion of neurophysiology of sleep and the role of the autonomic nervous system. Emphasis on respiratory and cardiovascular effects, regulation of sleep, circadian rhythms and maturation of the sleep stages addressing neonates to adults."},
    {"id":"END 2485","title":"Lab for Nerve Conduction Studies","description":"The lab for nerve conduction studies and electromyography will emphasis equipment, knowledge of placement stimulation sites, sources of error in nerve conduction studies, electronics, pathology (abnormal nerve conduction studies, anatomy as it pertains to entrapment sites and nerve conduction studies), waveforms identification and case presentation."},
    {"id":"END 2550","title":"Fundamentals of Polysomnography","description":"Overview of field of polysomnography including job responsibilities and credentialing. Normal and abnormal sleep disorders, integrating the physiologic functions of nervous, respiratory, and cardiovascular systems. Discussion of recording sleep apnea montage, placement and calibration of diagnostic, electrodes, and associated equipment. Emphasis on monitoring, diagnosis, scoring, and treatment of sleep disorders. Continuous Positive Airway Pressure (CPAP) and Bilevel Positive Airway Pressures equipment, artifact and troubleshooting of sleep montage results. Two classroom, three lab hours per week."},
    {"id":"END 2585","title":"Lab for Polysomnography","description":"Lab for the fundamentals of polysomnography including assessing normal and abnormal sleep disorders, integrating the physiologic functions of nervous, respiratory, and cardiovascular systems. Recording sleep apnea montage, placement and calibration of diagnostic, electrodes, and associated equipment. Emphasis on monitoring, diagnosis, scoring, and treatment of sleep disorders. Continuous Positive Airway Pressure (CPAP) and Bilevel Positive Airway Pressures equipment, artifact and troubleshooting of sleep montage results."},
    {"id":"END 2902","title":"Seminar for END Practicum II","description":"Continuation of practicum in clinical setting at neurology laboratory or neurodiagnostics department. Departmental orientation, policies and procedures, assist patient setup, performance and discontinuance of neurodiagnostic activities performed at the assigned clinical site.  One classroom, fourteen practicum hours per week."},
    {"id":"END 2903","title":"Seminar for END Practicum III","description":"Directed practice in clinical setting at neurology laboratory or neurodiagnostics department. Departmental orientation, policies and procedures, assist patient setup and discontinuance in monitoring of electromyography (EMG) activities. Experience with nerve conduction studies, and continuation of performance of EEG testing.  One classroom, fourteen practicum hours per week."},
    {"id":"END 2990","title":"Electroneurodiagnostic Capstone","description":"Capstone course in Electroneurodiagnostic Technology. Assessment of one’s knowledge, experience and skills as electroneurodiagnostic technologist. Preparation and presentation of qualifications through written resume and portfolio. Guidelines and preparation for employment interview. Investigation into electroneurodiagnostic issues."},
    {"id":"END 2992","title":"END Practicum II","description":"Continuation of practicum in clinical setting at neurology laboratory or neurodiagnostics department. Departmental orientation, policies and procedures, assist patient setup, performance and discontinuance of neurodiagnostic activities performed at the assigned clinical site."},
    {"id":"END 2993","title":"END Practicum III","description":"Practicum in clinical setting at neurology laboratory or neurodiagnostics department. Departmental orientation, policies and procedures, assist patient setup and discontinuance in monitoring of electromyography (EMG) activities. Experience with nerve conduction studies, and continuation of performance of EEG testing."},
    {"id":"ENG 1101","title":"English Composition I","description":"In English Composition I students learn reflective, analytical and argumentative writing strategies, incorporating sources and personal experience. Students will negotiate between public and private rhetorical situations and purposes to achieve academic literacy. They will write multiple drafts using a recursive writing process as they work toward fluency in style and mechanics."},
    {"id":"ENG 1131","title":"Business Writing","description":"Using audience analysis, a writing process and grammatical and formatting skills in an electronic environment, students write letters and messages for both internal and external business situations; they conduct business research and write and document short, informal research business reports, incorporating graphics and presentation materials."},
    {"id":"ENG 1199","title":"Textual Editing","description":"Strategies to achieve a clear, concise, cohesive and emphatic writing style; sentence structure; contemporary grammar and usage."},
    {"id":"ENG 1201","title":"English Composition II","description":"English Composition II, building on the skills in English Composition I, develops rhetorical literacy through research, critical reading and multigenre writing tasks. Through major and minor, cumulative and stand-alone assignments, students construct arguments and analyses, ethically incorporating academic sources while developing their own voices as writers and citizens."},
    {"id":"ENG 2255","title":"Poetry Writing","description":"Writing and critical reading of poetry. Manuscript form, publication and market information."},
    {"id":"ENG 2256","title":"Fiction Writing","description":"Traditional elements of short stories (character, scene, conflict, exposition, dialogue, plot and point of view) will be studied, involving student practice in a workshop setting. In addition to extensive student practice, students will read and analyze the work of published writers, learn how to submit their own work for publication and extensively study and write in one or more literary genres. Students will also study alternative or experimental fiction writing techniques."},
    {"id":"ENG 2257","title":"Freelance Writing","description":"Freelance writing covers magazine, newspaper and Internet article writing. It emphasizes generating, researching and developing nonfiction prose."},
    {"id":"ENG 2259","title":"Novel Writing","description":"Novel writing covers advanced study of traditional novel elements in a workshop setting, including the mechanics of manuscript submission."},
    {"id":"ENG 2262","title":"Writing to Publish","description":"This course introduces students to concepts and procedures related to publishing writing, as well as the business and professional aspects of establishing and maintaining a writing career. Topics covered include submission procedures, self-promotion, working with editors and social media, giving readings, and conducting workshops. The types of publishing houses and presses, as well as professional conduct and correspondence is also covered in this course. By the end of this course, students will have an understanding of how to publish their work and market their writing."},
    {"id":"ENG 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"ENS 1114","title":"Introduction to Sport & Recreation Management","description":"Historical, sociological and philosophical foundations of sport, including an emphasis on professional opportunities, application of management and organizational concepts."},
    {"id":"ENS 1116","title":"Introduction to Exercise Science & Health Promotion","description":"Historical, theoretical, ethical and philosophical foundations of exercise science and health promotion, including an emphasis on role, responsibilities, work settings and future direction of the profession."},
    {"id":"ENS 1118","title":"Lifetime Physical Fitness & Wellness","description":"This course provides an overview of the concepts of physical fitness, conditioning principles and appropriate exercise and health practices with application to lifelong fitness and wellness. Course includes lecture and physical fitness testing."},
    {"id":"ENS 1212","title":"Business Management for Exercise Science","description":"Students will learn to apply business and budgeting concepts in the world of exercise science. Students will develop an understanding of budgeting, legal and ethical considerations, financing and marketing and how they are applied within the fitness environment."},
    {"id":"ENS 1214","title":"Personal & Community Health Behavior","description":"This course enables the student to build a philosophy of health and health behaviors. Basic health principles and theories are applied to both personal and community health issues, including an emphasis on behavioral change and health and wellness education interventions."},
    {"id":"ENS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"ENS 2314","title":"Sport Promotions","description":"Sport Promotions introduces a wide range of promotional and marketing strategies within the sport environment. Students will gain an understanding of the history of sport marketing and discover how contemporary efforts attract and increase fan base. Legal issues will also be discussed."},
    {"id":"ENS 2316","title":"Motor Development & Motor Learning","description":"Explore motor development and motor skills across the lifespan. Awareness of body systems, their development and cycle of physical growth and maturation. Exploration of principles of motor learning and performance, including examination of elements that facilitate or prohibit the control, achievement and retention of motor skills."},
    {"id":"ENS 2317","title":"Methods of Teaching Lab","description":"This course will look at the practical application of teaching. Best practices for training and teaching in the industry will be explored. The student will have hands-on exposure and experience instructing a variety of modalities. Three lab hours per week."},
    {"id":"ENS 2318","title":"Fitness Assessment & Exercise Prescription","description":"Students learn and experience the process of risk stratification, fitness assessment in the five components of fitness and exercise testing for low- to moderate-risk individuals. Integration and analysis of results and norms to design individualized exercise prescription."},
    {"id":"ENS 2412","title":"Integration of Physical Education for Elementary Educators","description":"An introduction to the theory and practice of conducting a physical education program, as well as integrating movement education into the teaching of all elementary disciplines. Students will become proficient in methods and skills necessary for effective teaching."},
    {"id":"ENS 2414","title":"Foundations of Coaching","description":"Foundations of Coaching will cover many aspects of coaching in today’s sport-rich society. Topics include youth, collegiate and professional sport, coaching models, training, coaching networking and professional growth. Other discussions will cover sport-specific topics."},
    {"id":"ENS 2415","title":"Coaching & Leadership","description":"Coaching and Leadership introduces students to the principles of coaching and the qualities necessary to lead a sport organization. Topics focus on discovering an individual coaching and leadership style, contemporary coaching issues, leadership models and an investigation into the great leaders of the past."},
    {"id":"ENS 2416","title":"Certification Preparatory Course","description":"This course will help prepare the student for a nationally recognized certification exam.  Current national standards issued by the United States Department of Labor will be implemented."},
    {"id":"ENS 2417","title":"Methods of Teaching","description":"This course will look at the practical application of teaching. Best practices for training and teaching in the industry will be explored. The student will have hands-on exposure and experience instructing a variety of modalities."},
    {"id":"ENS 2418","title":"Exercise Prescription for Special Populations","description":"Integration of risk stratification, fitness assessments, exercise testing, interpretation and exercise prescription skills. Application of knowledge and skills for special populations and medical conditions through case studies and simulations."},
    {"id":"ENS 2419","title":"Health Promotion, Fitness & Sport Programming","description":"This course provides organizational techniques, administrative procedures and principles of managing health promotion, fitness and sport programs, including facility design and operational standards and guidelines."},
    {"id":"ENS 2420","title":"Concepts of Lifestyle Coaching","description":"This course provides the student with the principles and practices of coaching psychology. Core coaching skills along with approaches and guidelines for helping clients develop visions, set goals, make plans and track progress for healthy lifestyles."},
    {"id":"ENS 2471","title":"Exercise, Wellness & Sports Science Practicum","description":"This course is the capstone course. It is an on- or off-campus work experience integrated with academic instructions, coordinated by a faculty member. The course is completed in the last semester of the degree program. Fourteen practicum hours per week."},
    {"id":"ENT 2140","title":"Small Business Finance","description":"For the student/entrepreneur with no background in finance and accounting. Students will gain a foundation in small business finance: financial and economic concepts; financial terminology; understanding, preparing, analyzing and presenting financial statements; and financial forecasting and budgeting techniques."},
    {"id":"ENT 2160","title":"Business Plan Development","description":"Upon successfully completing the course, students will understand the business plan development process and will have developed a business plan.  Extensive research, writing and oral presentations are required.  Students will address: business concept evaluation; business plan development; presentation; evaluation of business plans; identification and evaluation of funding sources for new or existing enterprises."},
    {"id":"ENT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"ESL 0120","title":"Reading & Writing Basics","description":"This beginning course focuses on developing basic reading comprehension and basic grammar and writing skills.  Students will be able to write simple sentences, questions, and complete personal information forms. Common everyday vocabulary, spelling, and punctuation will be developed."},
    {"id":"ESL 0125","title":"Basic Speaking & Listening","description":"This beginning course focuses on developing basic skills in pronunciation, listening, and speaking. This course will provide practice in pronunciation and comprehension of informal and academic situations."},
    {"id":"ESL 0130","title":"ESL Reading & Writing I","description":"This class focuses on developing reading and writing skills at the sentence and paragraph level.  The class also includes vocabulary development and intensive grammar review."},
    {"id":"ESL 0135","title":"ESL Reading & Writing II","description":"This class develops reading skills in both fluency and comprehension. Writing skills at the paragraph level and short essay.  Advanced vocabulary development and grammar usage are included."},
    {"id":"ESL 0140","title":"ESL Listening & Speaking I","description":"Through individual and collaborative activities, this course will introduce basic to intermediate conversational skills to promote student development and achievement of listening and speaking skills needed for communication in the English language."},
    {"id":"ESL 0145","title":"ESL Listening & Speaking II","description":"Through individual and collaborative activities, this course will introduce high-intermediate to advanced conversational skills to promote student development and achievement of listening and speaking skills needed for communication in the English language."},
    {"id":"ESL 0150","title":"ESL Basic","description":"This basic integrated skills course will provide opportunities for listening, speaking, reading, and writing.  The goal is to build student confidence in using English skills to communicate in everyday life, at work, and in school."},
    {"id":"ESL 0170","title":"ESL Intermediate","description":"This intermediate integrated skills course will provide opportunities for listening, speaking, reading and writing. The goal is to build student confidence in using English skills for academic purposes."},
    {"id":"ESL 0180","title":"ESL Intensive English Lab II","description":"This course is a multi-level skills lab for basic, intermediate, and advanced ESL students. Students will practice the listening and speaking skills presented from the following courses:  ESL 0125, ESL 0140, and ESL 0145.  Three lab hours per week."},
    {"id":"ESL 0185","title":"ESL Intensive Lab I","description":"This course is a multi-level skills lab for basic, intermediate, and advanced ESL students. Students will practice reading and writing skills presented from the following courses ESL 0120, ESL 0130, and ESL 0135.  Six lab hours per week."},
    {"id":"ESL 0190","title":"ESL Advanced","description":"This advanced integrated skills course will provide opportunities for listening, speaking, reading and writing. The goal is to build student confidence in using English skills for academic purposes."},
    {"id":"ESL 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"FIN 2450","title":"Personal Finance","description":"Overview of the theories, concepts, principles, and processes of personal financial management, with an emphasis on everyday financial decision making."},
    {"id":"FRE 1100","title":"Conversational French","description":"A foundation for gaining knowledge about French culture and basic phrases related to simple spoken French and travel situations."},
    {"id":"FRE 1101","title":"Elementary French I","description":"A foundation for gaining basic knowledge of French grammar, speaking, writing, reading and cultural knowledge."},
    {"id":"FRE 1102","title":"Elementary French II","description":"Continuing French grammar skills, verbs in the future conditional and subjunctive tenses, speaking, writing, reading and cultural knowledge."},
    {"id":"FRE 2201","title":"Intermediate French I","description":"Reviews and extends basic principles through composition and conversation, stressing fluency. Work outside of class and/or in the language laboratory is required."},
    {"id":"FRE 2202","title":"Intermediate French II","description":"Continue to review and extend basic principles through composition and conversation, stressing fluency. Work outside of class and/or in the language laboratory is required."},
    {"id":"FRE 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"FST 1100","title":"Volunteer Firefighter","description":"Basic instruction in fire suppression, fire chemistry and behavior, rescue, firefighting tools, appliances and equipment, and firefighter safety and survival. One classroom, two lab hours per week."},
    {"id":"FST 1101","title":"Firefighter I Transition","description":"Intermediate instruction in fire suppression, fire chemistry and behavior, rescue, firefighting tools, appliances and equipment, fire protection systems, and firefighter safety and survival. Two classroom, six lab hours per week."},
    {"id":"FST 1102","title":"Firefighter I","description":"Basic and intermediate instruction in fire suppression, fire chemistry and behavior, rescue, firefighting tools, appliances, equipment, built-in fire suppression systems and firefighting safety and survival. Three classroom, eight lab hours per week."},
    {"id":"FST 1103","title":"Firefighter II Transition","description":"Advanced instruction in fire-suppression operations, fire chemistry and behavior, rescue, firefighting tools, appliances, equipment, built-in fire suppression systems and firefighting safety and survival. Two classroom, four lab hours per week."},
    {"id":"FST 1104","title":"Firefighter II","description":"Basic, intermediate and advanced instruction in fire-suppression operations, fire chemistry and behavior, rescue, firefighting tools, appliances, equipment, built-in fire protection systems and firefighting safety and survival. Seven classroom, eight lab hours per week."},
    {"id":"FST 1120","title":"Fire Safety Inspector","description":"This course prepares the student to identify, abate and document fire safety hazards and meets the requirements for those qualified students to take the state certification examination for Fire Safety Inspector. Two classroom, four lab hours per week."},
    {"id":"FST 1125","title":"Fire Investigation I","description":"The fundamentals and technical knowledge needed for proper fire scene investigations, including recognizing and conducting origin and cause, preservation of evidence and documentation, scene security, motives of the fire setter and types of fire causes."},
    {"id":"FST 1403","title":"Live Fire Instructor","description":"The Live Fire Instructor course certifies firefighters to be a live fire instructor. In order for a firefighter to teach in a live fire environment, they must take both Fire Instructor (FST 2209) and Live Fire Instructor (FST 1403). This course will go beyond the NFPA 1403 standards and focus on the how-to aspects of conducting valuable live fire training evolutions in a safe and compliant manner. Four lab hour per week."},
    {"id":"FST 1442","title":"Emergency Vehicle Operator","description":"Sixteen hour course meeting the requirements of the State of Ohio, Division of Emergency Medical Services for emergency vehicle drivers education."},
    {"id":"FST 1555","title":"Hazardous Waste Operations & Emergency Response (HAZWOPER)","description":"Training required to enter or work on a hazardous waste site with emphasis on personnel safety, site hazards, toxicology, personal protective equipment, decontamination, site characterization and site control. Two classroom, two lab hours per week."},
    {"id":"FST 2209","title":"Fire Service Instructor","description":"This course covers the development and delivery of fire service training materials.  Instructional motivations, student learning strategies and evaluation are addressed.  This course also meets the requirements of the State of Ohio certification as a State Fire Instructor as well as the objectives in the National Fire Protection Association Standard 1041. Two classroom, four lab hours per week."},
    {"id":"FST 2251","title":"Fire Officer I","description":"Management, supervision and leadership of the fire company. This course meets the requirements of National Fire Protection Association 1021, Fire Officer I."},
    {"id":"FST 2252","title":"Fire Officer II","description":"Management, supervision, leadership and command of multi-company operations. This course meets the requirements of National Fire Protection Association 1021, Fire Officer II."},
    {"id":"FST 2253","title":"Fire Officer III","description":"Administration of fire department operations and the management of programs, facilities and resources to provide a fire protection delivery system. This course meets the requirements of National Fire Protection Association Standard 1021, Fire Officer III."},
    {"id":"FST 2254","title":"Fire Officer IV","description":"Assessing the public fire protection needs of a community and the strategic planning and development of various components of the fire protection delivery system. This course meets the needs of National Fire Protection Association 1021, Fire Officer IV."},
    {"id":"FST 2270","title":"Fire Science Internship","description":"Students earn credits toward degree requirements for work learning experience.  Students already working may apply to use that experience to meet internship requirements. Twenty co-op hours per week."},
    {"id":"FST 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"GEO 1101","title":"Global Forces, Local Diversity","description":"This course serves as an introduction to the study of human or cultural phenomena from a geographic perspective.  Students will become familiar with the basic elements associated with human or cultural geography to include: the interface among human settlement, the environment, and economic systems; geographic patterns and processes associated with language, religion, and ethnicity; geographic patterns and processes associated with demographics and migration; political patterns and processes; geographic distribution of resources and economic activity; globalization and the impact on cultural/social, economic, and spatial relationships at various scales; how geographers approach the study of human and cultural activities; geographic patterns of urbanization; and impact of human behavior on landscapes. Students will use maps/geo-technologies to explain geographic themes and patterns."},
    {"id":"GEO 1102","title":"Earth's Physical Environment","description":"Analysis of the principle spheres of earth - atmosphere, lithosphere, hydrosphere and biosphere; an explanation of processes involved in shaping the earth&apos;s physical environment. Three classroom, two lab hours per week."},
    {"id":"GEO 1103","title":"Introduction to Geographic Information System I","description":"This is Part I of the introduction to the basic theoretical as well as practical concepts of Geographic Information Systems (GIS). Students will learn the basics of ArcMap and ArcCatalog and explore how these applications interrelate in a complete GIS software system. Through computer lab tutorials and homework assignments, students will learn to use ArcGIS.  One classroom, two lab hours."},
    {"id":"GEO 1104","title":"Introduction to Geographic Information Systems II","description":"Part II is an Introduction to the basic theoretical as well as practical concepts of Geographic Information Systems (GIS). Students will continue to learn the basics of ArcMap and ArcCatalog and explore how these applications interrelate in a complete GIS software system. Through lessons and labs, students will learn to use ArcGIS and work through an independent mapping project."},
    {"id":"GEO 1105","title":"Geospatial Awareness","description":"Introduction to the fundamental concepts of Geographic Information Science and Technology (GIS &amp;T) including Geographic Information Systems (GIS), Global Positioning Systems(GPS), cartography, remote sensing, and spatial analysis.  Exploration of how geospatial technologies are used in addressing human and environmental issues.  Overview of careers in Geospatial Technology."},
    {"id":"GEO 1107","title":"Introduction to Geographic Information Systems (GIS)","description":"Introduction to the basic theoretical as well as practical concepts of Geographic Information Systems (GIS). Students will learn the basics of ArcMap and ArcCatalog and explore how these applications interrelate in a complete GIS software system through exercises, labs and a final project. Three classroom, two lab hours per week."},
    {"id":"GEO 1201","title":"World Regional Geography: People, Places & Globalization","description":"This course serves as an introduction to the study of regional geography at the global scale.  Students will become familiar with and understand the use of maps/geo-technologies to explain geographic phenomena and patterns as they relate to world regions and their interrelationships, apply geographic concepts to the study of regions or a specific region,compare and contrast human and physical patterns and their variations over space,develop an appreciation of the complexities of regional and global environmental and socio-economic problems,understand globalization and place local issues in their global and historical context,and to understand human-environment interactions in various regions around the world."},
    {"id":"GEO 1206","title":"Appalachian Environment","description":"Overview of the various geographic aspects of the rural and urban Appalachian region, including physiography and geology; migration and settlement patterns; historical development and cultural diffusion; and population characteristics and economy."},
    {"id":"GEO 1209","title":"Map Design & Visualization","description":"This course is an introduction to the science and art of map making. From the history and principles of thematic map compilation and design, basics of map projections, data sources and processing, map color, symbolization and topography to common types and styles of thematic maps. Three classroom, two lab hours per week."},
    {"id":"GEO 1212","title":"Geospatial Data Acquisition & Management","description":"This course addresses the interpretation and understanding of a variety of data formats available in GIS.  Introduces the fundamental concepts of primary GIS data creation and discusses quantitative techniques for collection, classification, and management of geographical data. Two classroom, two lab hours per week."},
    {"id":"GEO 1215","title":"Introduction to Remotely Sensed Imagery","description":"This course is an introduction to remote sensing.  Topics include fundamentals of the physical principles on which remote sensing is based, history and future trends, sensors and their characteristics, image data sources, and image classification, interpretation and analysis techniques. Two classroom. two lab hours per week."},
    {"id":"GEO 1300","title":"Introduction to Extreme Weather & Climate","description":"An introduction to the characteristics and processes of Earth's atmosphere and how it interacts with the planet's surface, oceans, and human activity. The course focuses on how these interactions work to produce extreme weather events and climate extremes and how they affect people. Three classroom, two lab hours per week."},
    {"id":"GEO 2210","title":"Advanced Spatial Analysis","description":"This course will focus on GIS extensions to apply more complex functions and tools of ArcGIS.  Students will learn how to utilize ArcGIS Network Analyst and Spatial Analyst tools to create, query and analyze data sets.  Students will also learn to use GPS technology to collect data, build databases and prepare data for analysis using more advanced geodatabase tools. Three classroom, two lab hours per week."},
    {"id":"GEO 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"GEO 2310","title":"Scripting Language for GIS","description":"The course provides an understanding of how to customize GIS software applications by way of modified service interface elements.  Topics include the theory and implementation of the various scripting languages currently in use.  Upon completion, students will be able to solve geospatial problems and streamline GIS workflows through the creation and modification of scripts."},
    {"id":"GEO 2600","title":"Geospatial Technology Capstone","description":"The capstone is a learning experience resulting in a consolidation of a student&apos;s educational experience and certifies mastery of entry level workplace geospatial competencies. The capstone experience should occur during the last semester of the student&apos;s educational program. Methods of providing a capstone experience include a summative project and compilation of portfolio."},
    {"id":"GEO 2700","title":"Geospatial Technology Internship","description":"A structured experience in a supervised setting that provides students with an overview of procedural, professional and ethical issues faced by a geospatial technician on the job. Ten hours per week per credit hour."},
    {"id":"GER 1100","title":"Conversational German","description":"A foundation for gaining knowledge about Germanic culture and basic phrases related to  simple spoken German, including travel situations."},
    {"id":"GER 1101","title":"Elementary German I","description":"Foundation for understanding, speaking, reading, and writing German. Work outside of class and/or in the language laboratory is required."},
    {"id":"GER 1102","title":"Elementary German II","description":"Continued understanding, speaking, reading and writing German beyond the discourse level. Work outside of class and/or in the language laboratory is required."},
    {"id":"GER 2201","title":"Intermediate German I","description":"Reviews and extends basic principles through composition and conversation, stressing fluency. Work outside of class and/or in the language laboratory is required."},
    {"id":"GER 2202","title":"Intermediate German II","description":"Intermediate-level composition and conversation, stressing fluency. Work outside of class and/or in the language laboratory is required."},
    {"id":"GER 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"GLG 1101","title":"Physical Geology","description":"Surface processes of wind, water and ice in changing Earth&apos;s surface, plate tectonics; interior forces that cause earthquakes, volcanoes, mountain building.  Introduction to natural resources; impact of natural hazards on human populations; and impact of human activities in the natural world.  Laboratory component stresses introduction to and use of basic scientific method and problem solving.  Three classroom, two lab hours per week."},
    {"id":"GLG 1111","title":"Physical Geology Laboratory","description":"Identification of minerals, sediments and rocks; interpretation of topographic maps and geologic maps.  Laboratory must be taken concurrently with Physical Geology."},
    {"id":"GLG 1201","title":"Historical Geology","description":"The Earth in space, physical evolution of the oceans, atmosphere and continents, origin of life and its evolution, physical and biological development of the North American continent.  Lab component stresses further application of scientific method and problem solving.  Three classroom, two lab hours per week."},
    {"id":"GLG 1211","title":"Historical Geology Laboratory","description":"Rates of change, age dating, fossils, depositional environments, stratigraphy, correlation, facies, and interpretation of geologic maps.  Laboratory must be taken concurrently with Historical Geology."},
    {"id":"GLG 1301","title":"Geologic Field Trips","description":"Saturdays before spring break, class meets on campus for lecture and lab to build preparatory knowledge and skills. Missing more than two classroom meetings will be cause for withdrawal.  Saturdays after break, students participate in day-long, hands-on field experiences throughout Ohio.   Students will apply their knowledge and skills to interpret and understand the building of the Appalachian Mountains and the geological development of Ohio.  Field activities are meant to mimic the work of professional geologists.  Missing more than two trips will be cause for withdrawal.  Three classroom, two lab hours per week.  Note: Any student choosing to register and take this class may be required to sign one or more liability waivers in favor of the locations where certain learning activities in this course take place.  Students who would like additional information about this matter should contact the Physical Sciences Department."},
    {"id":"GLG 1311","title":"Ohio Field Geology","description":"Hands-on experience during several Saturday, day-long field trips to different locations in Ohio.  Field activities are meant to mimic what field geologists do.  Use of on-site observations to interpret and understand the building of the Appalachian Mountains and the geological development of Ohio.  Two lab hours in the field per week.  Course offered during 8-week B-term.  Open to undergraduate and graduate students with or pursuing degrees in geology, and K-12 science educators with or working toward Master Science Teaching (MST).  GLG 1201/1211 may be taken concurrently to meet prerequisite."},
    {"id":"GLG 1401","title":"Environmental Geology","description":"Introduction to minerals, rock cycle, Plate Tectonics.  Use/misuse of natural resources, waste disposal, pollution.  Analysis of natural hazards: floods, volcanism, earthquakes, mass wasting, and others.  Consequences of human activities: population growth, sustainability; mitigation and remediation strategies and processes.  Laboratory component stresses use of scientific method, critical thinking, and problem solving.  Three classroom, two lab hours per week."},
    {"id":"GLG 1411","title":"Environmental Geology Laboratory","description":"Identification of minerals and rocks.  Analysis of natural hazards, natural resources, pollution and anthropogenic problems.  Development/proposal of mitigation/remediation strategies.  Laboratory must be taken concurrently with GLG 1401, Environmental Geology.  Two lab hours per week."},
    {"id":"GLG 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"HIM 1101","title":"Medical Terminology","description":"Basic prefixes, roots and suffixes; terminology including anatomic, diagnostic, symptomatic, procedural, eponymic terms and standard abbreviations required for a working knowledge and understanding of the language of medicine."},
    {"id":"HIM 1110","title":"Health Information Processing","description":"Foundations of health information management, the Health Information Management profession, including health care systems and organization of HIM functions, data quality, access and retention, patient and healthcare data and data collection methodologies. Discussion of classification systems, clinical vocabularies and nomenclatures. Two classroom, two lab hours per week."},
    {"id":"HIM 1150","title":"Survey of Electronic Health Records","description":"Students will be introduced to the basic concepts and functions associated with keeping the electronic health records while exploring technology requirements and related options.  Through this course, students will gain an understanding of the importance of privacy, confidentiality and security while maintaining patient information within the healthcare environment."},
    {"id":"HIM 1160","title":"Medical Office Coding Concepts","description":"Introduction to principles and conventions for assigning ICD-10 and CPT codes to patient encounter for billing physician services. Students should possess proficiency in basic medical terminology."},
    {"id":"HIM 1165","title":"Drug Classification for Coding","description":"An overview of the major drug classifications, common drugs in each class, conditions for which drugs are administered and their general effects to assist medical coders in analyzing health care documentation for coding and reimbursement applications."},
    {"id":"HIM 1201","title":"Introductory Medical Office Coding","description":"Introduction to principles, guidelines and conventions for assigning ICD-10-CM diagnostic codes and CPT procedure codes to patient encounters for physician services. Additional out-of-class assignments are required."},
    {"id":"HIM 1204","title":"Medicolegal & Ethics in Healthcare Records","description":"Evaluation of health care records as legal documents; special emphasis on policies and procedures concerning release of medical information and protecting patient confidentiality; principles and organization of the judicial system; health care fraud and abuse and Health Insurance Portability and Accountability Act (HIPAA) regulations. Ethical issues in health care settings addressed. One classroom, two lab hours per week."},
    {"id":"HIM 1217","title":"Alternative Health Records & Registries","description":"Organization and operation of a hospital cancer program emphasizing registry case finding, accession, indexing, abstracting and follow-up of cancer data. Purposes, uses and handling of health information, departmental and facility administration, licensing and accreditation requirements and introduction to payment systems in long-term care and home health care.  Note:  HIM 1201 may be taken concurrently."},
    {"id":"HIM 2110","title":"Ambulatory Coding","description":"Introduction to principles, guidelines and conventions for assigning ICD-10-CM diagnostic codes and CPT procedure codes to patient encounters for outpatient facility services. Students should possess proficiency in basic medical terminology and human anatomy and physiology. Additional out-of-class assignments are required. Three classroom, two lab hours per week."},
    {"id":"HIM 2144","title":"Quality Improvement, Statistics & Research","description":"Organization and analysis of data in health care quality programs including quality assessment and monitoring, utilization and risk management and medical staff credentialing. Theory and application of health care statistics including data definitions, computation of formulae and research principles. Two classroom, two lab hours per week."},
    {"id":"HIM 2145","title":"Health Information Resource Management","description":"Planning, organizing, staffing, budgeting and analysis of management systems along with job standards and performance evaluations emphasizing development of supervisory management, leadership and communication skills. Two classroom, two lab hours per week."},
    {"id":"HIM 2165","title":"Healthcare Data in Reimbursement","description":"Organization of health care delivery system including managed care and capitation. Theory and use of reimbursement systems such as Diagnostic Related Groups, Ambulatory Payment Classifications, Resource-based Relative Value Scale. Discussion of data flow from admission to billing and analysis of casemix. In addition, other external forces, such as Health Insurance Portability and Accountability Act and Recovery Audit Contractors, will be discussed. Two classroom, two lab hours per week."},
    {"id":"HIM 2211","title":"Inpatient Coding","description":"Introduction to principles and coding conventions for using ICD-10-CM and ICD-10-PCS for coding inpatient records. Students should possess proficiency in medical terminology and human anatomy and physiology. Additional out-of-class assignments are required. Three classroom, two lab hours per week."},
    {"id":"HIM 2233","title":"Healthcare Information Systems","description":"An in-depth look at the use of information systems technology in the health care delivery system. Includes information security, electronic clinical systems and health records. Two classroom, two lab hours per week."},
    {"id":"HIM 2252","title":"Professional Practice Experience","description":"Practical application of health information management processes, including health information retrieval, qualitative and quantitative analysis of health data, record completion by practitioners, release of health information, document scanning, revenue cycle functions, coding, statistical reporting, hospital-wide and HIM department quality improvement and various other registries and department functions utilizing medical data. Ten directed-practice hours per week at an approved off-site location."},
    {"id":"HIM 2262","title":"Advanced Medical Office Coding","description":"ICD-10-CM diagnosis and CPT procedure coding for the physician&apos;s office and other ambulatory facilities. Two classroom, two lab hours per week."},
    {"id":"HIM 2275","title":"Home Health Medical Coding","description":"Introduction to principles and medical coding for using ICD-10-CM for coding home health records.  Students should possess proficiency in medical terminology and human anatomy and physiology. Additional out-of-class assignments are required.  Two classroom, two lab hours per week."},
    {"id":"HIM 2278","title":"Health Information Management Capstone","description":"A variety of specially designed projects, student oral presentations, case studies, simulations, interviewing, resumes and two mock accreditation exams. Two lab hours per week."},
    {"id":"HIM 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"HIS 1101","title":"United States History I","description":"Development of the people of the United States in political, social, economic, and cultural areas from pre-Columbian America through Reconstruction."},
    {"id":"HIS 1102","title":"United States History II","description":"Development of the people of the United States in political, social, economic and cultural areas from Reconstruction to the present."},
    {"id":"HIS 1105","title":"African-American History","description":"Contributions of African-Americans to the institutions and culture of the United States from 1619 to the present."},
    {"id":"HIS 1111","title":"Western Civilization I","description":"Major trends in the development of Western culture, emphasizing political, economic, social and cultural achievements, from prehistory to the seventeenth century."},
    {"id":"HIS 1112","title":"Western Civilization II","description":"Major trends in the development of Western culture, emphasizing political, economic, social and cultural achievements from the seventeenth century to the present."},
    {"id":"HIS 2215","title":"Survey of African History","description":"Overview of the history of Africa from prehistoric times to the present; special emphasis on modern challenges the continent faces."},
    {"id":"HIS 2216","title":"Survey of Latin American History","description":"A survey of Latin American history and culture from pre-colonial times to the present, tracing colonial influences, 20th century revolutions, dictatorships and democratic alternatives and the evolution of global economics, U.S. and Organization of American States policies."},
    {"id":"HIS 2217","title":"Survey of East Asian History","description":"Survey of eastern Asia from earliest times to the present, including economic, political, religious and colonial influences on modern nations of Asia, with special emphasis on twentieth- and twenty-first- century issues and problems."},
    {"id":"HIS 2218","title":"History of Ohio","description":"A survey of the political, social, economic and cultural development of the peoples of Ohio, from prehistoric times to the present. Ohio's role in the growth of the United States."},
    {"id":"HIS 2219","title":"Survey of the Middle East","description":"A survey of the Middle East, beginning with an overview of early history and the rise of Islam, and then concentrating on historical developments since the 19th century, tracing the development of Zionism, Arab, Turkish, Kurdish and Iranian nationalisms, the involvement of the superpowers and the U.N. and the resulting crises."},
    {"id":"HIS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format."},
    {"id":"HIS 6297","title":"Non-Credit History Special Topics","description":"This non-credit course explores various history topics."},
    {"id":"HMT 1101","title":"Basic Culinary Skills","description":"This course is an introduction to food preparation techniques and culinary theory. Basic concepts of kitchen organization and operation, basic terminology, use of standardized recipes, weights and measures, product evaluation, recipe conversion, food composition and introduction to commercial equipment and work methods. American Culinary Federation competency skills included.  HMT 1107 must be completed prior to registering for this course or may be taken at the same time.  Four lab hours per week."},
    {"id":"HMT 1102","title":"Kitchen Chemistry","description":"An introduction to applied chemistry of food and food preparation. Lecture and demonstrations will be used to illustrate course principles. One classroom, four lab hours per week."},
    {"id":"HMT 1105","title":"Introduction to the Hospitality & Tourism Industry","description":"This course will provide an overview of the Hospitality and Tourism Industry. Topics include in-depth views of the restaurant and culinary industry, lodging industry, meeting and events, tourism, casinos, cruise-lines and more. Hospitality Interactive simulation, My Hospitality Lab, and service scenarios will provide an experience of fun socialistic learning. Successful students of this course will receive a Hospitality Reception and Service Specialist short term certificate."},
    {"id":"HMT 1107","title":"Sanitation & Safety","description":"Sanitation and safety involves key concepts such as harmful micro-organisms, contamination and food-borne illnesses, the nine steps within the flow of food from supplier to service, minimum internal cooking temperatures/times for proteins, food safety management systems, sanitary facilities and pest management control.  Students must successfully pass a national sanitation exam to pass the course.  Students who are culinary or baking majors may not register for kitchen lab courses without a current servsafe certification."},
    {"id":"HMT 1108","title":"Pastry & Confectionery Basics","description":"Theory and practice of pastry and confectionery for the hotel and restaurant industry, applying the fundamentals of baking science to the preparation of a variety of products. The use and care for the baking equipment normally found in the bakeshop or baking area. One classroom, six lab hours per week."},
    {"id":"HMT 1110","title":"Menu Planning & Table Service Practicum","description":"Menu design and development, standardizing recipes, cost controls and pricing. Practical applications in varieties of table service, catered events and customer service processes. Two classroom hours per week and a total of thirty lab hours to be conducted as part of the Tartan Terrace Dining Room service experience."},
    {"id":"HMT 1112","title":"Food Principles & Basic Preparation","description":"Preparation of culinary cuisine with a wide variety of plate production techniques including soups, sauces, vegetables, fruits, grains, salads, meats, game, poultry, fish and seafood. Apply food pairing, plating, and garnishing techniques to culinary cuisine. Skill training based on American Culinary Federation competencies. Includes recipe conversion, product evaluation and maintenance of a safe, sanitary kitchen. One classroom, six lab hours per week."},
    {"id":"HMT 1125","title":"Beverage Management","description":"The history and process of different wines, ales and spirits, including pronunciation and selection of wines with food and identifying the required glassware for all drinks. Mixology, establishing a par stock and reorder point, discussion of Ohio’s drinking laws and bartender’s legal and social responsibilities, and bar design and layout."},
    {"id":"HMT 1126","title":"Baking I","description":"Practical application of basic baking techniques, ingredients, weights and measures, terminology and formula calculations.  Four lab hours per week."},
    {"id":"HMT 1128","title":"Baking II & Barista Basics","description":"This course gives students a practical experience of more advanced and complicated techniques required by the baking and pastry industry. It will also provide students with more advanced techniques in preparing designer pastries, foundations of artisan breads, cake decorating, and assembling two tiered cakes. This course will also cover barista training and gourmet coffee pairings. Four lab hours per week."},
    {"id":"HMT 1129","title":"Restaurant Desserts","description":"Practical application of basic baking ingredients, weights and measures, terminology and formula calculations. Use of mixes and frozen bakery products to create commercial-grade finished products for restaurant service. One classroom, four lab hours per week."},
    {"id":"HMT 1136","title":"Front Office Operations","description":"This course presents a systematic approach to front office procedures by detailing the flow of business through a hotel, from the reservations process to check-out and account settlement. The course also examines the various elements of effective front office management, paying particular attention to the planning and evaluation of front office operations."},
    {"id":"HMT 1137","title":"Hospitality Industry Computer Systems","description":"Students will learn about computer-based property management systems, involving both front- and back-of-the-house operations. The course is designed to show the inter-related computer functions of an entire full-service lodging property with departments such as food and beverage service outlets, sales, front office, etc."},
    {"id":"HMT 1138","title":"Managing Lodging Operations","description":"This course provides an understanding of the General Manager's role in both small and large hotels. Addresses each department, including sales and marketing, maintenance, security, human resources. Features lodging green initiatives, revenue optimization, and a better understanding of the complexity with regard to overseeing an entire property."},
    {"id":"HMT 1139","title":"Housekeeping Management","description":"This course provides students with the principles of housekeeping management as they apply specifically to the hospitality industry. Housekeeping is critical to the success of today's lodging operations and this course will illustrate what it takes to direct day-to-day operations of this department, from big-picture management issues to technical details for cleaning each area."},
    {"id":"HMT 1140","title":"Dimensions of Air Travel","description":"Study of  airline industry terminology, rules and ethics, aircraft types, location and city codes of major airports worldwide. Thorough study of transportation security, passport regulations and foreign documentation requirements."},
    {"id":"HMT 1141","title":"Destination Geography","description":"Study of important tourism destinations around the world.  The course discusses recreational, cultural, economic and social significance."},
    {"id":"HMT 1143","title":"Organization of the Travel Product","description":"Study of travel/tourism industry products and procedures, including cruises, rail, motorcoach, tours, car rentals, resort and hotel features and travel insurance."},
    {"id":"HMT 1146","title":"Airline Travel Technology","description":"Computer-based training using airline and travel technology to develop knowledge and skills to complete the fundamental elements of a travel reservation. Elements include searching, confirming and pricing for airline, car and hotel reservations in a Passenger Name Record. Two classroom, two lab hour per week."},
    {"id":"HMT 1148","title":"Meeting & Events Contracts & Obligations","description":"An introduction to the Meeting &amp; Events industry where by contracts are a necessary part of doing business. This course will explore four key components which are the offer, consideration, acceptance, as well as the Banquet Event Order (BEO) or sometimes referred to as the Catering Event Order (CEO)."},
    {"id":"HMT 1149","title":"Meeting & Events Set-up & Breakdown","description":"This course will focus on the types of meeting set-ups and break-down procedures involved.  The student will also focus on the role of using outside contractors for large events requiring massive structural developments.  Students will also physically set-up and break-down meeting rooms such as theater style, classroom style, boardroom style, etc."},
    {"id":"HMT 1150","title":"Meeting & Event Planning","description":"This course teaches students meeting and event basics such as negotiation and contracts, marketing, trade-shows, sponsorships, technology utilization, and post-event activities."},
    {"id":"HMT 1151","title":"Special Events, Expositions & Festivals","description":"This course specializes in weddings, social events, parties, receptions, grand openings, expositions, and festivals.  Students will learn strategies for building floor plans, pricing, and religious/cultural values that could affect the protocol of an event."},
    {"id":"HMT 2118","title":"Artisan Breads","description":"An in depth study of artisan baking training in proper mixing, kneading, and baking techniques to make a wide variety of crusty breads from around the world.  Students will use healthy bread production techniques and whole grains, sour levain starters, poolish’s, and sour ryes, to make all classic breads. Six lab hours per week."},
    {"id":"HMT 2126","title":"Cake Production","description":"Students will gain methods and skill of cake fundamentals and production techniques used in commercial baking operations.  This course will only focus on the making/baking/production of several varieties of cakes.  Four lab hours per week."},
    {"id":"HMT 2128","title":"Cake Decoration","description":"Students will design and use proper equipment/utensils to decorate cakes demonstrating a variety of icing techniques on multi-tiered cakes. Students will also, produce and or Work with a variety of products such as marzipan, glaze, gum paste, butter-cream icing, royal icing, cream-cheese icing, etc. to decorate cakes.   Four lab hours per class."},
    {"id":"HMT 2200","title":"Baking & Culinary Fundamentals & Commercial Equipment","description":"This course is designed for students that are transferring from another institution, entering the program with advanced credits such as Tech Prep, and/or for students that need to refresh their skills.  All students will be assessed to ensure that appropriate advanced skills match those required of upper level courses in the program.  This course will also orient students to kitchen laboratory processes/procedures and commercial equipment for a smooth transition. Four lab hours per week."},
    {"id":"HMT 2201","title":"Food Service Equipment, Design & Maintenance","description":"This course provides students with skills to perform maintenance, cleaning, and sanitation of commercial kitchen equipment typically found in restaurants for the purposes of avoiding costly repairs and maintaining longevity. Layout of equipment in terms of efficiency and cost is also a part of this course.  One classroom, two lab hours per week."},
    {"id":"HMT 2203","title":"Street Foods & Food Trucks","description":"Students will be able to operate and sustain a successful Food Truck.  The course will entail choosing vending locations, opening checklist, closing checklist, and cooking on an actual food truck. Another part of the course will be to develop a sound business plan complete with a reasonable budget, commissary controls, daily operations, and how to stay lean and profitable by avoiding the most common operating mistakes."},
    {"id":"HMT 2206","title":"Garde Manger","description":"Introduction of Garde Manger discipline, including tools and equipment, preparation of pâtés, terrines, mousse, galantines, hors d'oeuvres and canapes. Demonstrate basic skills in charcuterie, carving of edible and non-edible showpieces, garnishes, ice carvings, chaud-froid and aspics. Includes buffet and plate presentation. One classroom, six lab hours per week."},
    {"id":"HMT 2207","title":"Butchery & Fish Management","description":"Students will fabricate primal cuts of meat, poultry, fish, and pork with emphasis given to portion control, purchasing, costing, and utilization of byproducts. The product produced in this course will be used in menu development for the Tartan Terrace Restaurant. Four lab hours per week for eight weeks."},
    {"id":"HMT 2208","title":"Advanced Culinary & Competition Skills","description":"Competitions play a vital role in culinary arts as the industry standard excellence bar continues to raise. There is no better way for a culinarian to hone their craft than by putting their skills and knowledge to the test in a competitive format. This class will utilize the format of the ACF Ohio State Team competition to test the readiness of students entering into the work force. Students will develop a menu, set purchasing specs. and preform cost calculations. Once this is completed, students will then prepare various appetizers, soups, entrées, and desserts from their menus serving guest in the Tartan Terrace Restaurant prior to competition.  These efforts will be followed by an opportunity to participate in a state wide competition event. Six lab hours per week."},
    {"id":"HMT 2209","title":"Advanced Culinary Skills","description":"Capstone course in Culinary Arts which students will prepare seven course meals. The course involves preparation of classical and contemporary cuisine, including American Regional cuisine. Students will develop a menu, set purchase specs, and perform cost calculations. Once this is completed, students will then prepare various appetizers, soups, salads, entrees and desserts from the menus they have created and serve the cuisine prepared in a chef's table style at the Tartan Terrace Restaurant. Six lab hours per week."},
    {"id":"HMT 2215","title":"Hospitality Cost Controls","description":"In-depth analysis of financial costs associated with hospitality operations. Although the primary focus will be on restaurants, other operational costs from lodging, meeting and events, etc. will be introduced. Topics include financial statement interpretations, breakeven calculations, butcher test computations, inventory systems and in-depth labor cost control function."},
    {"id":"HMT 2218","title":"Advanced Pastry Skills","description":"Advanced pastry and confectionery techniques, including laminated doughs, candy making, plate and platter displays and an introduction of sugar work. Six lab hours per week."},
    {"id":"HMT 2225","title":"Hospitality & Tourism Supervision","description":"This course is designed to provide students with the principles of supervision in the hospitality and tourism industry and the associated responsibilities. Topics include managing resources, team building, productivity cost formulas and the unique supervision techniques used in restaurants, lodging, bakeries, kitchens, and meeting and event planning.  The course emphasis will be on leadership."},
    {"id":"HMT 2226","title":"Hospitality Purchasing & Negotiations","description":"Food service functions regarding negotiations, laws, buying, science, packaging, distribution, ingredient process, storage, organization, cost controls, security, garden(s) sustainability, and procurement processing experience(s). One classroom, two lab hours per week."},
    {"id":"HMT 2227","title":"Hospitality Marketing","description":"Organization of the marketing concepts in the hospitality and tourism industry, utilizing all aspects necessary to build a marketing plan."},
    {"id":"HMT 2230","title":"Risk & Prevention Management","description":"This course will cover the broad task of protecting guests, non-guests, employees and assets. Topics will include security, property access, perimeter control, alarm systems, communication systems, closed circuit television, computer security, employment screening, terrorism, emergency procedures and general safety procedures."},
    {"id":"HMT 2291","title":"Hospitality Management & Tourism Cooperative Work Experience","description":"This course is a co-op credit experience which requires students to be employed at a work site that coincides with his/her degree option. The goal of this course is for students to apply concepts learned throughout his/her educational experience to a practical work environment.  Students are required to complete a minimum of 20 HMT semester hours in his/her concentration prior to registering for this course."},
    {"id":"HMT 2292","title":"Culinary Arts Option Cooperative Work Experience","description":"This course is a co-op credit experience which requires students to be employed at a work site that coincides with his/her degree option.   A minimum of 20 work hours per week is required.  The goal of this course is for students to apply concepts learned throughout his/her educational experience to a practical work environment. A minimum of 25 HMT semester hours in his/her concentration is required prior to registering for this course."},
    {"id":"HMT 2293","title":"Baking & Pastry Arts Option Cooperative Work Experience","description":"This course is a co-op credit experience which requires students to be employed at a work site that reflects the Baking and/or Pastry field of his/her degree option.   A minimum of 20 work hours per week is required.  The goal of this course is for students to apply concepts learned throughout his/her educational experience to a practical work environment. A minimum of 25 HMT semester hours in his/her concentration is required prior to registering for this course."},
    {"id":"HMT 2295","title":"Hospitality Management & Tourism Capstone","description":"Application of previously learned hospitality management and tourism concepts through case study, readings and discussion of contemporary issues."},
    {"id":"HMT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"HRT 1109","title":"Greenhouse Management Capstone","description":"This course is the culminating course in the Greenhouse Management Certificate.  This course combines the knowledge obtained in HRT 1101 to HRT 1108 resulting in an applicable working understanding of how to manage a greenhouse in today's economy. Two classroom, two lab hours per week."},
    {"id":"HUM 1125","title":"Introduction to the Humanities","description":"Explores the nature and content of the humanities by examining and analyzing various cultures from the past. In addition, this course provides an introduction to human thought, creativity and human forms of expression by examining the links between historical realities and human culture."},
    {"id":"HUM 1130","title":"Humanity & the Challenge of Technology","description":"This course examines the role of technology in the modern society.  It explores the opportunities and dangers faced by humankind in the evolution of new technologies.  It inquires into such questions as whether the human quest for mastery of nature has made us masters or slaves of the machine."},
    {"id":"HUM 1131","title":"The Search for Utopia","description":"The Search for Utopia is a survey of humanity's search for the ideal society from ancient times to the modern age. It examines various utopian ideas and practices that have changed world communities and compares Western utopian traditions with Eastern perceptions of the perfect society."},
    {"id":"HUM 1132","title":"Holocaust & Genocide","description":"Explores the concept of genocide primarily through the prism of the 20th Century Holocaust perpetrated by Nazi Germany.   The course addresses the background, progress and historical context of the Nazi holocaust, as well as other genocides, and the philosophy and psychology of &quot;racial purity.&quot;"},
    {"id":"HUM 1135","title":"Environmental Ethics","description":"Overview of philosophical and ethical dimensions of the environmental crisis, such as environmental politics, animal rights and nonwestern views."},
    {"id":"HUM 1140","title":"Appalachian Folkways","description":"Overview of the facets of folkways and folklore in the Appalachian region of the United States, including folk customs, language, material culture, performing folk arts and literary themes of selected contemporary writers."},
    {"id":"HUM 1141","title":"Appalachian History & Culture","description":"An examination of various facets of life in Appalachia, including history, culture, economics, politics, education and religion."},
    {"id":"HUM 1142","title":"Native American History","description":"Survey of the political, social, economic, and cultural development of Native Americans, from prehistoric times to the present, with specific emphasis on Native Americans of Ohio and the Appalachian region."},
    {"id":"HUM 1195","title":"Leadership Development","description":"An exploration and study of the concept of leadership in the context of community and global issues. Includes Service Learning and applying leadership skills in the community."},
    {"id":"HUM 2236","title":"International Studies","description":"Under the supervision of Sinclair faculty, students visit another country and work on study and/or service learning activities related to specific academic majors or topics."},
    {"id":"HUM 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"HVA 1201","title":"Basic HVAC Systems with Cooling","description":"Basic concepts and theory of heating, ventilating, air conditioning and refrigeration systems. Foundations in the applications of cooling principles in light commercial equipment. Major components include refrigerant flow through equipment, applications of equipment to the refrigeration cycle, heat transfer fundamentals and preparation for the Environmental Protection Agency (EPA) refrigerant handler&apos;s certification exam. Two classroom, two lab hours per week."},
    {"id":"HVA 1221","title":"Heating Systems","description":"Introduction to the basic concepts of all heating systems found in light commercial applications for the experienced and inexperienced in HVAC. A comprehensive presentation of HVAC systems, including rooftop packaged systems, heat pumps, packaged low-pressure boiler systems, and packaged unitary heaters. Includes low-pressure hot water and steam generation, including the fundamentals of heat generation in water-based heating systems. Two classroom, two lab hours per week."},
    {"id":"HVA 1241","title":"HVAC Installation Techniques & Practices","description":"Basic practices required for new installation and replacement of HVAC equipment including an introduction to sheet metal skills, and copper and black pipe plumbing. Hands-on skills and code requirements will be stressed along with good safety practices. Includes installation of a complete residential HVACR system. Two classroom, six lab hours per week."},
    {"id":"HVA 1261","title":"HVAC Loads & Distribution for Small Buildings","description":"A discussion and demonstration of the importance of proper air distribution systems and principles of balanced heat distribution, including design considerations for light commercial applications. Loads will be calculated using commercially available software. Testing, Adjusting and Balancing procedures are included. Two classroom, four labs hours per week."},
    {"id":"HVA 1301","title":"Air & Water Distribution Systems","description":"Theory and practice of fluid flow in HVAC distribution systems, including water systems, sizing and performance, duct system sizing and performance, fan and pump selection and operation, valve and damper selection and Testing, Adjusting, and Balancing (TAB) procedures. Hand calculations and use of computer-based design and analysis tools; select hands-on laboratory studies reinforce basic principles; proper installation practices are also included. Two classroom, three lab hours per week."},
    {"id":"HVA 1351","title":"Building Psychrometrics & Load Calculations","description":"Theory and practice of performing psychrometric analysis of HVAC systems. Principles and practice performing detailed heating and cooling load calculations for commercial facilities. Students learn both hand calculation and use of computer- based design and analysis tools. Selected hands-on laboratory studies reinforce basic principles. Two classroom, two lab hours per week."},
    {"id":"HVA 1352","title":"Psychrometrics, Health & Comfort in HVAC","description":"This course teaches you to perform a psychrometric analysis for purposes of system control and system troubleshooting.  It also teaches you to perform ventilation calculations, room air distribution calculations, and filter selection for buildings and building spaces.  This is accomplished by concentrating on applicable codes and industry standards to create a healthy environment with an emphasis on the background necessary to properly control an HVAC system.  One classroom, three lab hours per week."},
    {"id":"HVA 1401","title":"HVAC Mechanical & Electrical Troubleshooting","description":"Diagnostic methods of mechanical,  electrical and control system problems in heating and cooling systems.  Other topics include common faults and how to avoid repair failures.  Two lecture, three lab hours per week."},
    {"id":"HVA 2251","title":"Primary HVAC Equipment Operation & Selection","description":"Student will learn how to do refrigeration cycle analysis, how to select condensers, evaporators, compressors, boilers, chillers and cooling towers from a manufacturer&apos;s catalog for a specific application, how to apply manufacturer&apos;s literature, including electrical schematics, to the troubleshooting process and proper installation and equipment room piping practices. Two lecture, two lab hours per week."},
    {"id":"HVA 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"HVA 2351","title":"HVAC Systems & Controls","description":"Theory and techniques for the control, troubleshooting, commissioning and operational parameters of a variety of systems used in today&apos;s buildings. This course emphasizes control strategies for energy efficiency and indoor environmental quality as directed by current standards for commercial and industrial HVAC systems. The control portions of this course begin with basic control elements and theory and continue with instruction regarding the ModBus protocols prevalent within the Dayton area. The course prepares the student for a major installation and commissioning project using state-of-the-art equipment. Three classroom, six lab hours per week."},
    {"id":"HVA 2700","title":"HVACR Engineering Technology Internship","description":"Students earn credits toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each term. Ten work hours per week per credit hour."},
    {"id":"HVA 2751","title":"HVAC-R Operations & Best Practices","description":"As today&apos;s building HVAC systems grow more varied and more sophisticated, the service and operations technician must keep up and be able to determine if a system is operating properly.  This course will provide case studies of various building HVAC systems, system operational issues, and changes in applicable standards and codes.  The student is then exposed to methods of finding the problem and how to differentiate between a symptom and the underlying cause using the fundamentals learned in previous classes.  Two lab hours per week."},
    {"id":"HVA 2780","title":"HVACR Engineering Technology Capstone Project","description":"Assessment of achievement by HVACR Engineering Technology students in attaining program outcomes by completing a project demonstrating principles and practices of the major. Teamwork on projects will be emphasized. One classroom, six lab hours per week."},
    {"id":"IND 1180","title":"History of Interior Design","description":"Examination of the stylistic development of interior design, domestic furniture and furnishings from classical times to the present."},
    {"id":"IND 1230","title":"Residential Design","description":"Introduction to Interior Design includes exploring the profession, the principles, elements and design processes; residential space planning, kitchen design and furniture arrangement fundamentals with emphasis on design drawings and professional presentation form. Two classroom, four lab hours per week."},
    {"id":"IND 1234","title":"Materials & Textiles","description":"Overview of specifications, relative costs, performance properties and installation methods of materials, including textiles used in interior design."},
    {"id":"IND 1240","title":"Color Theory","description":"Course will cover Josef Albers color theory, the effect of light on color and color psychology, including forecasting and trends."},
    {"id":"IND 2130","title":"Non-Residential Design","description":"Students investigate the design of health care, institutional, hospitality, retail and office environments and identify basic historical exterior styles. Design projects will integrate corporate culture, building codes, Americans with Disabilities Act (ADA) compliance, aesthetic, social and psychological factors. Advanced oral and visual presentation skills. Two classroom, four lab hours per week."},
    {"id":"IND 2135","title":"Rendering","description":"Drawing and computer-aided drawing technique development to aid in the visualization of materials, color and lighting of a three-dimensional interior space."},
    {"id":"IND 2140","title":"Sustainable Design","description":"Course includes discussions of environmental movements, designer responsibility, legislation, LEED certification selection and application of lighting fixtures. Students will integrate sustainable strategies to develop design solutions for a variety of small-scale projects. Two classroom, four lab hours per week."},
    {"id":"IND 2260","title":"Interior Design Portfolio","description":"Interior design business practices; including cost estimating, contract writing, sales and communication techniques. Development of a portfolio from previous course work, work experience, freelance, etc. Two classroom, four lab hours per week."},
    {"id":"IND 2280","title":"Kitchen & Bath Design","description":"Design foundations exploring standards for industry professionals specializing in residential kitchen and bath planning, basic fundamentals with emphasis on design layouts, design concept drawings and professional presentation formats."},
    {"id":"IND 2290","title":"Interior Construction Systems","description":"Provides the interior designer (or non-architectural major) a working knowledge of various building systems which impact design decisions when creating functional, aesthetically pleasing interior environments to meet the needs of the building&apos;s occupants."},
    {"id":"IND 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"JOU 2101","title":"Introduction to Journalism","description":"The principles and functions of newspapers, including current changes and challenges.  Students will learn basic and advanced reporting skills, including how to interview, gather information and write news stories. Computer skills are required."},
    {"id":"JOU 2203","title":"Reporting & Writing for Media","description":"Students will develop and advance journalistic principles to address the challenges today's journalists encounter, gain skills to report and write news stories for print, broadcast and on-line media platforms and develop the basic skills for creating multimedia stories."},
    {"id":"JOU 2270","title":"Journalism Internship","description":"Students earn credits toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each term. Seven work hours per credit hour each week."},
    {"id":"JOU 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"JPN 1100","title":"Conversational Japanese I","description":"A foundation for gaining knowledge about Japanese culture and basic phrases related to  simple spoken Japanese, including travel situations."},
    {"id":"JPN 1101","title":"Elementary Japanese I","description":"Foundation for understanding, speaking, reading and writing Japanese.  Work outside of class and/or in the language laboratory is required."},
    {"id":"JPN 1102","title":"Elementary Japanese II","description":"This Elementary Japanese II class aims to promote students to attain a basic level of Japanese proficiency in listening, speaking, reading, and writing in the cultural context.  This course will focus on essential speaking and writing skills while helping students improve contextual reading and listening competencies."},
    {"id":"JPN 1105","title":"Conversational Japanese II","description":"Develops the conversational skills to a greater degree of complexity and covering more situations.  Promotes free expression in Japanese within more specific and complex cultural contents."},
    {"id":"JPN 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"LAW 1101","title":"Business Law","description":"The American legal system as it relates to business transactions, including the judicial system and sources of law, legal procedures, torts, business ethics and social responsibility, contracts, property, employment law, agency, partnerships and corporations."},
    {"id":"LAW 1102","title":"Consumer Law","description":"This course develops student skills in application of state and federal consumer laws and regulations including privacy, warranties, credit and purchasing assets issues."},
    {"id":"LAW 1103","title":"Domestic Violence","description":"This course covers domestic violence dynamics for those working with victims and perpetrators of family violence, Ohio Civil Protection Order (CPO) law, preparation of CPO court documents and the enforcement of CPOs by law enforcement agencies and courts."},
    {"id":"LAW 1104","title":"Employment Law","description":"This course will provide the student with an understanding of current legal issues in the area of employer/employee relations.  Emphasis is placed on legal issues that arise in the employment relationship, employment discrimination issues and federal and state regulations applicable to employment law.  The use of current events is emphasized to reinforce areas covered in the course materials."},
    {"id":"LAW 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"LIT 2201","title":"British Literature I","description":"A chronological survey of major writers of English poetry, drama and prose from the beginnings through the eighteenth century (through 1785)."},
    {"id":"LIT 2202","title":"British Literature II","description":"A chronological survey of major writers of English poetry, drama and prose from 1785 to the present."},
    {"id":"LIT 2211","title":"American Literature I","description":"A chronological survey of major writers of American poetry, drama and prose from the Colonial Period through the Civil War (through 1865)."},
    {"id":"LIT 2212","title":"American Literature II","description":"A chronological survey of major writers of American poetry, drama and prose from the Civil War through the present."},
    {"id":"LIT 2217","title":"Images of Women in Literature","description":"Major images of women in literature, with emphasis on contemporary literature's role in both reflecting and shaping society's views of women."},
    {"id":"LIT 2220","title":"Introduction to Literature","description":"Introduction to Literature introduces students to the major literary genres of literature, including narrative fiction, poetry, and drama. Emphasis is placed on literary terminology and interpretation. Upon completion, students should be able to analyze and respond to literature. Students will effectively and ethically argue their interpretations of literary works using textual evidence and Modern Language Association (MLA) documentation."},
    {"id":"LIT 2230","title":"Great Books of the Western World","description":"A chronological survey of the major literary works of periods of Western culture beginning with the Greeks and progressing through the Middle Ages, the Renaissance, Neo-Classicism and Enlightenment, Romanticism, Realism and Modernism."},
    {"id":"LIT 2234","title":"Literature of Africa, Asia, & Latin America","description":"Selected thematic study of major literary works of Africa, Asia and Latin America, emphasizing universal values and the commonality of experience."},
    {"id":"LIT 2236","title":"African-American Literature","description":"This course provides an overview of the African-American literary tradition with emphasis on early slave narratives, the Harlem Renaissance, the Black Revolution and Arts Movement and contemporary social expression."},
    {"id":"LIT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"LIT 2400","title":"Children's & Adolescent Literature","description":"Children&apos;s and Adolescents&apos; Literature focuses on reading, analyzing and evaluating various literary genres for children and adolescents. Students will examine the literary elements and values presented in classic and modern picture books, fiction, fairy tales and poetry. Children&apos;s books that have won the Newbery and Caldecott Awards will be studied and discussed."},
    {"id":"MAN 1106","title":"Introduction to Radio Frequency Identification","description":"Overview of the technology of Radio Frequency Identification (RFID).  Applications, terminology. Introduction of global standards and case studies discussed."},
    {"id":"MAN 1107","title":"Foundations of Business","description":"The American business system and basic principles of the free market system. Includes introduction of business concepts, entrepreneurship, management, marketing, economics, accounting and other important business principles."},
    {"id":"MAN 1110","title":"International Business","description":"Global dimensions of business, overview of theories and institutions of trade, investment and management, emphasizing the managerial perspective on issues arising from international business and global operations."},
    {"id":"MAN 1114","title":"Introduction to Sports Management","description":"Historical, sociological and business foundations of sport, including an emphasis on professional opportunities, application of sports management and organizational concepts."},
    {"id":"MAN 1157","title":"Management Applications of Radio Frequency Identification Technology","description":"This course will introduce students to the management applications of Radio Frequency Identification (RFID) technology, which enables automated gathering and sending of asset information. Case studies and hands-on activities will allow students the opportunity to experience RFID from a business perspective, linking cost, price, customer satisfaction and product performance measures to business application outcomes."},
    {"id":"MAN 2101","title":"Introduction to Supervision","description":"Strategies and techniques for current, as well as prospective, supervisors emphasizing the assessment of skills required, the analysis of situational factors and the development of creative approaches to effective supervision."},
    {"id":"MAN 2110","title":"Introduction to Project Management","description":"Introduction to basic project management concepts, including how to scope, plan, launch, monitor, control and close a project. The course includes the Project Management Institute’s (PMI) basic knowledge areas: Integration, Scope, Time, Cost, Quality, Human Resources, Communication, Risk and Procurement."},
    {"id":"MAN 2140","title":"Human Resource Management","description":"Introduction through application of Human Resource Management concepts. Emphasis on Strategic Human Resource Management, Workforce Planning and Employment, Human Resource Development, Total Compensation and Rewards, Employee and Labor Relations and Risk Management."},
    {"id":"MAN 2144","title":"Negotiation Techniques","description":"This course addresses the application of basic principles of negotiation through the introduction and analysis of the negotiation process, case studies and simulations. It focuses on accurately identifying requirements specifications, analyzing proposals and conducting purchasing and contracting negotiations ethically and legally, but is also relevant to compromise and agreement in other business and personal life situations."},
    {"id":"MAN 2150","title":"Management & Organizational Behavior","description":"Introduction to fundamental concepts necessary for understanding management, motivation and behavior in organizational settings. Emphasis on planning, organizing, influencing and controlling to continually improve effective management skills."},
    {"id":"MAN 2155","title":"Management Information Systems","description":"The exploration of the use and management of information systems and technology to continually improve organizations by providing efficiencies and effectiveness for operations, customer service, marketing, finance and other critical organizational processes."},
    {"id":"MAN 2159","title":"Supply Chain Management Concepts & Applications","description":"This course provides an in-depth study of Supply Chain Management (SCM) functions and the application of effective SCM strategies and practices to achieve improved operations in manufacturing and service organizations. It focuses on analysis of real-world SCM challenges, strategies and techniques."},
    {"id":"MAN 2240","title":"Emerging Markets","description":"This course will cover countries considered emerging markets and developing nations.  Due to changing economics, the country specific foci of this course will potentially change each semester, while retaining the basic information on developing and developed country economies.  The macro and micro environments of developing countries and the implications of changes in these environments will be explored through lecture and hands-on learning assignments."},
    {"id":"MAN 2270","title":"Management Internship","description":"Students earn credit toward degree requirements for work learning experience.  Students already working may apply to use that experience to meet internship requirements.  Students establish learning outcomes and prepare related reports and/or projects each term. Thirty hours per week in the workplace."},
    {"id":"MAN 2275","title":"Retail Management Capstone","description":"Discover the nature and scope of retailing. Topics include strategic and financial planning, supply chain management, impact of laws and regulations, product life cycles, and technology in retail."},
    {"id":"MAN 2279","title":"Business Management Capstone","description":"Assessment of achievement by Business Management degree students in attaining program outcomes by employing reflective learning through demonstration of management-related principles and practices."},
    {"id":"MAN 2280","title":"International Business Certification Prep","description":"This course will cover the material and possible test questions needed to pass the National Association of Small Business International Trade Educators (NASBITE) certification exam."},
    {"id":"MAN 2290","title":"International Business Capstone","description":"This course will allow students to demonstrate a collective understanding and mastery of international business terminology and current research concepts important to working within a global economy and in preparation for the National Association of Small Business International Trade Educators (NASBITE) certification exam.  Additionally, students will explore themes related to global business concepts and practices important to success within the global business environment.  Finally, students will have the opportunity to practice and improve skills related to success in international business through hands on assignments and networking opportunities, which relate directly to employment in multinational firms or Non-Governmental Organizations (NGOs)."},
    {"id":"MAN 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"MAN 2414","title":"Foundations of Coaching","description":"This course will cover many aspects of coaching in society.  Topics include youth, collegiate and professional sport, coaching models, training, coaching networking, coaching for managers and professional growth.  Other discussions will cover sport and business topics."},
    {"id":"MAN 2415","title":"Foundations of Leadership","description":"This course introduces students to the principles of leadership in sport and organizations.  Topics focus on discovering an individual coaching and leadership style, contemporary coaching issues, leadership models and an investigation into the great leaders of the past."},
    {"id":"MAS 1102","title":"Clinical Medical Assisting I","description":"Introduction to the theory components of clinical assisting procedures in the medical office, emphasizing patient preparation, medical history interviews, vital signs, positioning and draping, medical asepsis, assisting with physical exams, pediatric assessment, techniques required for patient assessment and treatment during medical office emergencies and the role of the medical assistant in urgent situations with the physician present and also during the physician's absence. Two classroom, three lab hours per week."},
    {"id":"MAS 1103","title":"Clinical Medical Assisting II","description":"This course will introduce students to intermediate-level clinical procedures in a family practice setting such as medical microbiology, minor office surgery, administering therapeutic modalities, preparing and administering medications, electrocardiography, and eye and ear procedures. Two classroom, six lab hours per week."},
    {"id":"MAS 1110","title":"Administrative Medical Assisting","description":"The Administrative Medical Assistant course will provide an overview of the role of a medical assistant within the health care industry and different work environments, administrative duties in a physician&apos;s office including monitoring patient appointments, outpatient procedures, medical and office equipment maintenance, storing supplies and pharmaceuticals, hiring, evaluating and managing personnel, as well as the fundamentals of medical ethics and law in the medical office setting. Two classroom, six lab hours per week."},
    {"id":"MAS 1130","title":"Reimbursement Specialist Practicum","description":"Student will complete seven hours per week of non-paid directed practice at a medical billing facility in order to obtain practical knowledge with medical reimbursement procedures.  Students will, as part of this course, attend a one hour lecture per week in order to prepare to sit for the national credentialing certificate, Certified Medical Reimbursement Specialist."},
    {"id":"MAS 1192","title":"Lab for MAS 1102","description":"This is the laboratory portion of MAS 1102 – Clinical Medical Assisting I and will provide “hands-on” aspects of the clinical topics taught in MAS 1102 to prepare students to perform clinical assisting procedures in the medical office, emphasizing patient preparation, medical history interviews, vital signs, positioning and draping, medical asepsis, assisting with physical exams, pediatric assessment, techniques required for patient assessment and treatment during medical office emergencies and the role of the medical assistant in urgent situations with the physician present and also during the physician's absence, theory and techniques of Basic Life Support as established by the American Heart Association."},
    {"id":"MAS 1193","title":"Lab for MAS 1103","description":"This is the laboratory portion of MAS 1103 – Clinical Medical Assisting II and will provide “hands-on” aspects of the clinical topics taught in MAS 1103 to prepare students to perform intermediate-level clinical procedures in a family practice setting such as medical microbiology, minor office surgery, administering therapeutic modalities, preparing and administering medications, electrocardiography, and eye and ear procedures."},
    {"id":"MAS 2201","title":"Clinical Medical Assisting III","description":"This course will introduce students to advanced/specialized procedures such as assisting with gastroenterologic procedures, urinary procedures, basic respiratory procedures, OB/GYN procedures and laboratory procedures that are performed in ambulatory care settings. Two classroom, six lab hours per week."},
    {"id":"MAS 2210","title":"Medical Billing Specialist","description":"This course is designed to introduce the student to the practice of medical billing within the medical office, including the use of computerized medical billing software. It is also designed to introduce the student to the principles of bookkeeping, automated and manual patient financial accounting, collection techniques, employee payroll and banking procedures. Two classroom, three lab hours per week."},
    {"id":"MAS 2220","title":"MAS Practicum","description":"Introduction to the ambulatory care clinical setting involving structured observation and unpaid participation in the administrative and clinical aspects of patient care under the supervision of a licensed physician or certified medical assistant, discussion of practicum experience and topics relative to the medical assisting profession, preparation to sit for a national credentialing certificate. Healthcare Professional CPR, health certificate, immunizations, student health insurance, and background check must be completed prior to the start of MAS 2220 - MAS Practicum. One classroom, fourteen practicum hours per week."},
    {"id":"MAS 2221","title":"MAS Practicum II","description":"Advanced experience in a physician's office, involving structured observations and unpaid participation in the administrative and clinical aspects of patient care under the supervision of a licensed physician, assisting with specialized clinical procedures, performing electrocardiograms, venipunctures and basic laboratory tests and advanced office management skills. One classroom, fourteen practicum hours per week."},
    {"id":"MAS 2291","title":"Lab for MAS 2201","description":"This is the laboratory portion of MAS 2201 – Clinical Medical Assisting III and will provide “hands-on” aspects of the clinical topics taught in MAS 2201 to prepare students to perform advanced/specialized procedures such as assisting with gastroenterologic procedures, urinary procedures, basic respiratory procedures, OB/GYN procedures and laboratory procedures performed in the ambulatory care setting."},
    {"id":"MAS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"MAT 0050","title":"Arithmetic Refresher","description":"This course is a refresher in the arithmetic topics essential for studying algebra.  Successful students will demonstrate proficiency in operations with whole numbers, fractions, decimal numbers, and percents.  Note: Courses that begin with a zero are developmental in nature.  Credit earned in developmental courses will not apply to the overall program hours."},
    {"id":"MAT 0100","title":"Algebra I","description":"Course provides a brief review of pre-algebra concepts including: operations with rational numbers; translating, evaluating, and simplifying expressions; translating, simplifying, and solving various types of first degree equations, inequalities and applied problems, including geometry, percent proportions, and other formulas; an introduction to coordinate planes, graphing and writing equations of straight lines.  Traditional testing (proctored or in Testing Center) is used in all online sections.  Note: Courses that begin with a zero are developmental in nature.  Credit earned in developmental courses will not apply to the overall program hours."},
    {"id":"MAT 0200","title":"Algebra II","description":"Factoring; operations with polynomials and rational expressions; solving second-degree equations by factoring; solving equations with rational expressions. Traditional testing (proctored or in Testing Center) is used in all online sections. Note: Courses that begin with a zero are developmental in nature.  Credit earned in developmental courses will not apply to the overall program hours."},
    {"id":"MAT 0300","title":"Algebra III","description":"Systems of linear equations in two variables and applied problems; two-variable inequalities and systems of inequalities and applied problems; operations with rational exponents, radical expressions and complex numbers; relations and functions; simplifying radical expressions; solving equations with rational exponents, equations with radical expressions, quadratic equations by factoring, completing the square, and the quadratic formula, equations quadratic in form; quadratic functions. Traditional testing (proctored or in Testing Center) is used in all online sections. Note: Courses that begin with a zero are developmental in nature.  Credit earned in developmental courses will not apply to the overall program hours."},
    {"id":"MAT 0445","title":"Quantitative Reasoning Booster","description":"This course is taken in conjunction with MAT 1445, Quantitative Reasoning.  It reviews prerequisite concepts for the topics in MAT 1445.  Each prerequisite concept is covered in this course just prior to being needed in MAT 1445.  Topics covered include: numerical reasoning, proportional reasoning, algebraic reasoning and modeling with linear functions.  Two lab hours per week. Note: Courses that begin with a zero are developmental in nature.  Credit earned in developmental courses will not apply to the overall program hours."},
    {"id":"MAT 0450","title":"Introductory Statistics Booster","description":"This course is taken in conjunction with MAT 1450, Introductory Statistics.  This course reviews prerequisite concepts for the topics in MAT 1450.  Each prerequisite concept is covered in this course just prior to being needed in MAT 1450.  Topics covered include:  use of summation notation, solving equations and inequalities with square roots, and extracting information from tables and graphs.  Two lab hours per week. Note: Courses that begin with a zero are developmental in nature.  Credit earned in developmental courses will not apply to the overall program hours."},
    {"id":"MAT 0460","title":"Booster for Mathematics for Business Analysis","description":"This course is taken in conjunction with MAT 1460, Mathematics for Business Analysis. This course reviews prerequisite concepts for the topics in MAT 1460. Each prerequisite concept is covered in this course prior to being needed in MAT 1460. Topics covered include: operations on polynomials, radical and rational functions, solving quadratic/rational equations/applications and graphing basic functions. Three lab hours per week. Note: Courses that begin with a zero are developmental in nature. Credit earned in developmental courses will not apply to the overall program hours."},
    {"id":"MAT 0470","title":"College Algebra Booster","description":"This course is taken in conjunction with MAT 1470, College Algebra.  This course reviews prerequisite concepts for the topics in MAT 1470.  Each prerequisite concept is covered in this course just prior to being needed in MAT 1470.  Topics covered include: operations on polynomial, radical and rational functions, solving quadratic/rational equations/applications and graphing basic functions.  Three lab hours per week. Note: Courses that begin with a zero are developmental in nature.  Credit earned in developmental courses will not apply to the overall program hours."},
    {"id":"MAT 0600","title":"Bridge to College Math","description":"The topics contained in MAT 0100, MAT 0200, and MAT 0300 will be delivered in a self-paced format using technology, allowing students to begin at the appropriate level based on course placement and allowing them to move through as many topics, and courses, as they can within the time limits of the semester.  Topics include:  a brief review of pre-algebra concepts, solving and graphing linear equations and inequalities, factoring, simplifying polynomial and rational expressions, solving and graphing systems of linear equations and inequalities in two variables, solving and graphing quadratic equations, complex numbers, and simplifying exponential and radical expressions.  At the end of the semester, based on proficiency of the topics in one or more courses, students will earn a grade of \"S\" for satisfactory progress and gain permission to enter subsequent courses in their plan of study.  Note:  Courses that begin with a zero are developmental in nature.  Credit earned in developmental courses will not apply to the overall program hours.  Six lab hours per week."},
    {"id":"MAT 1110","title":"Math for Technologists","description":"Use ratio and proportion to solve applications in technology; convert within and between metric and customary systems of measurement; read and interpret measurement tools and gauges; simplify algebraic expressions; solve linear equations; apply the geometry of lines, angles, and circles to technology applications.  Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 1120","title":"Business Mathematics","description":"Mathematics of finance, mathematics of trade, payroll, taxes, insurance, elementary statistics. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 1125","title":"Math for the Culinary Arts & Baking & Pastry Arts Professional","description":"This course is specifically for Culinary Arts and Baking &amp; Pastry Arts majors.  The math requirement for this course will form the foundations needed for costing of food and beverage, recipe conversion, bakers scaling (of liquid verses dry weights), edible product yield percentages, and menu cost cards.  Students will be expected to demonstrate proficiency in converting improper as well as mixed number fractions, (add, subtract, multiply, and divide) decimals, solve complicated word problems and more."},
    {"id":"MAT 1130","title":"Mathematics in Health Sciences","description":"Solve health science applications; convert within and between metric, household and apothecary systems; read and interpret health science labels and graphs; calculate and apply statistical concepts; solve problems involving parenteral, pediatric and/or intravenous administration and dosage calculations. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 1200","title":"Technical Mathematics","description":"This is a context-centered course intended for majors in Construction Management Technology, Architectural Technology, and Civil Engineering Technology.  It contains collaborative labs which apply the mathematical methods to relevant applications.  Students will apply properties of shapes in 2-dimensional and 3-dimensional geometry; use dimensional analysis to convert between systems of units; use scientific notation; apply vector analysis and basic trigonometry to find indirect measurements; use and solve linear and quadratic functions and equations; and use systems of linear equations in applications and find their solutions.  Four classroom, two lab hours per week."},
    {"id":"MAT 1445","title":"Quantitative Reasoning","description":"The course will explore various applications of mathematics in the social, finance, health and environmental fields with emphasis on developing informational, technological, logical, and visual reasoning skills.  Topics from numeracy, probability and statistics, finance, mathematical modeling with linear, statistical, and exponential functions, and other areas of mathematics will be covered.  Note:  Students who have not completed the required pre-requisite courses listed, but have successfully completed MAT 0100 with a grade of \"C\" or better, can register for MAT 1445 together with the co-requisite course MAT 0445, Quantitative Reasoning Booster.  Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 1450","title":"Introductory Statistics","description":"An introduction to the fundamental ideas of statistics, including statistical methods to gather, analyze and present data; fundamentals of probability; statistical distributions, sampling distributions, confidence intervals, hypothesis testing, Chi-square tests, regression and correlation. Three classroom, two lab hours per week. Note: Students who have not completed the required pre-requisite courses listed, but have successfully completed MAT 0100 or MAT 1130 with a grade of \"C\" or better, can register for MAT 1450 together with the co-requisite course MAT 0450, Introductory Statistics Booster. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 1460","title":"Mathematics for Business Analysis","description":"Applications of mathematics and functions to business analysis. Linear applications, functions, financial mathematics, systems, matrices, inequalities. Traditional testing (proctored or in Testing Center) is used in all online sections.  Note:  Students who have not completed the required prerequisite courses listed, but have successfully completed MAT 0200 with a grade of C or better, can register for MAT 1460 together with the co-requisite course MAT 0460, Booster for Mathematics for Business Analysis."},
    {"id":"MAT 1470","title":"College Algebra","description":"Polynomial, radical, rational, exponential and logarithmic functions and their graphs; roots of polynomial functions, rational and polynomial inequalities; systems of linear and nonlinear equations; matrices; and applications. Traditional testing (proctored or in Testing Center) is used in all online sections.  Note:  Students who have not completed the required pre-requisite courses listed, but have successfully completed MAT 0200 with a grade of \"C\" or better, can register for MAT 1470 together with the co-requisite course MAT 0470, College Algebra Booster."},
    {"id":"MAT 1510","title":"Numerical Concepts & Algebra for Teachers","description":"Introduction to the basic mathematical concepts of arithmetic and problem solving as appropriate for early- and middle-childhood teachers. An inquiry- and activity-based approach is used to explore problem solving, sets, linear and quadratic functions, systems of equations, mathematical modeling, numeration systems, whole numbers, basic number theory, integers, rational numbers and real numbers."},
    {"id":"MAT 1520","title":"Geometry & Statistics for Teachers","description":"Introduction to the concepts of probability, statistics and geometry as appropriate for early- and middle-childhood teachers. An inquiry- and activity-based approach is used to explore probability concepts, descriptive statistics and basic inferential statistics, 2- and 3-dimensional geometric concepts, geometric constructions, congruence, similarity, measurement, computing area and volume, symmetry and transformations of two-dimensional figures."},
    {"id":"MAT 1570","title":"Analytic Geometry & Trigonometry","description":"Trigonometric functions of angles, solving right and oblique triangles, identities, trigonometric and inverse trigonometric equations, vectors, radian measure, graphs of trigonometric functions and inverse trigonometric functions, conic sections, sequences, and series.  Two classroom, two lab hours per week. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 1580","title":"Precalculus","description":"Polynomial, radical, rational, exponential and logarithmic functions and their graphs, roots of polynomial functions, rational and polynomial inequalities, conic sections, systems of linear equations; sequences and series. Trigonometric functions of angles, solving right and oblique triangles, trigonometric identities and equations, vectors, radian measure, graphs of trigonometric functions, inverse trigonometric functions and applications. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 2160","title":"Calculus for Business & Economics","description":"Functions and graphs, limits, continuity, derivatives, techniques of differentiation, applied problems in business and economics, exponential and logarithmic functions, techniques of integration, applications of integration, functions of two variables, partial derivatives and applications. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 2170","title":"Business Statistics I","description":"Statistical techniques and methodology. Graphical and tabular presentation of data, probability, parameters, statistical distributions, sampling, confidence intervals, tests of hypotheses, regression, and correlation. Three classroom, two lab hours per week. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 2180","title":"Business Statistics II","description":"Statistical inferences, including estimation, confidence intervals, and tests of hypotheses for means, standard deviations and proportions; analysis of variance; regression analysis; chi-square; business applications. Students will develop a basic competency using a computer spreadsheet to perform statistical calculations. Two classroom, two lab hours per week. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 2215","title":"Mathematics for Machine Learning & Artificial Intelligence","description":"The four pillars of machine learning are regression, dimensionality reduction, density estimation, and classification. This course aims to establish the mathematical foundation upon which to build these pillars by covering carefully selected topics in linear algebra, analytic geometry, vector calculus, probability and distributions, and optimization."},
    {"id":"MAT 2240","title":"Calculus for the Life Sciences","description":"This course uses calculus as a tool for modeling applications in the life sciences.  Limits, derivatives, integrals, and differential equations are introduced and applied in this context.  Emphasis is placed on qualitative analysis and interpretation."},
    {"id":"MAT 2270","title":"Calculus & Analytic Geometry I","description":"The first course of a three-semester sequence of courses. Topics include limits and continuity, the derivative and its applications including related rates and optimization, L'Hopital's rule, antiderivatives, the Fundamental Theorem of Calculus, integration by substitution. Traditional testing (proctored or in Testing Center) is used in all online sections."},
    {"id":"MAT 2280","title":"Calculus & Analytic Geometry II","description":"The second course of a three-semester sequence of courses. Techniques of integration, applications of integration, numerical integration, improper integrals, infinite sequences and series, power series, parametric equations, polar coordinates, conic sections."},
    {"id":"MAT 2290","title":"Calculus & Analytic Geometry III","description":"Vectors in the plane and space, dot and cross product of two vectors.  Lines, planes and surfaces in space, vector-valued functions, arc length and curvature. Functions of several variables, partial derivatives with applications, multiple integrals with applications, line integrals, surface integrals, vector fields, Green’s Theorem, the Divergence Theorem and Stokes’ Theorem."},
    {"id":"MAT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"MAT 2310","title":"Elementary Differential Equations","description":"Solutions and applications of ordinary differential equations including separable, exact, homogeneous and non-homogeneous linear equations and others. Numerical approximation methods as well as substitutions, the total differential, separation of variables, integrating factors, undetermined coefficients, variation of parameters, Laplace Transforms and power series methods are covered."},
    {"id":"MAT 2320","title":"Linear Algebra","description":"Systems of linear equations, matrices, determinants, linear transformations, Euclidean n-space, coordinate vectors, abstract vector spaces, dimension and rank, eigenvalues and eigenvectors, orthogonal vectors, least-square problems, diagonalization, quadratic forms, singular-value decomposition.  Applications such as Markov chains and computer graphics will be covered.  Students will be introduced to the use of MATLAB for Linear Algebra. Two classroom, two lab hours per week."},
    {"id":"MAT 2330","title":"Differential Equations & Linear Algebra","description":"Ordinary differential equations of first and second order including, the Laplace transform, numerical approximation methods and applications. Vectors in Rn, systems of linear equations, systems of differential equations, matrices, linear transformations, subspaces, dimension and rank, coordinate vectors, determinants, eigenvalues, eigenvectors and abstract vector spaces."},
    {"id":"MAT 2415","title":"Mathematics for Elementary Education I","description":"This is the first of two mathematics courses designed for future elementary school teachers. The focus is on understanding numbers, operations, algebraic thinking, and number theory. This is a mathematics content course. Please note that this is not a teaching methods course, but a course focusing on using, justifying and connecting mathematical concepts. This course employs oral and written communication as both a learning tool and as preparation for handling mathematical questions which arise in elementary school classrooms. Discussion focuses on the deep mathematical reasoning underlying the computational procedures that are usually taught in elementary school. The course explores common misconceptions with preservice teachers, enabling the interpretation of children&apos;s work which might be incorrect, incomplete, or different from adult ways of thinking. Also this course is activity based, providing opportunities for deep, connected learning. It is essential for all teachers of mathematics to understand the reasoning underlying the mathematics they are teaching. They need to understand why various procedures work, how each idea they will be teaching connects with other important ideas in mathematics, and how these ideas develop and become more sophisticated. Please note that students are expected to pass a mathematics competency exam without the use of a calculator in order to be eligible to take the final exam."},
    {"id":"MAT 2435","title":"Mathematics for Elementary Education II","description":"This is the second of two mathematics courses designed for future elementary school teachers. The focus is on understanding ratios, proportional relationships, functions, measurement, geometry, statistics, and probability. This is a mathematics content course. Please note that this is not a teaching methods course, but a course focusing on using, justifying and connecting mathematical concepts. This course employs oral and written communication as both a learning tool and as preparation for handling mathematical questions which arise in elementary school classrooms. Discussion focuses on the deep mathematical reasoning underlying the computational procedures that are usually taught in elementary school. The course explores common misconceptions with preservice teachers, enabling the interpretation of children's work which might be incorrect, incomplete, or different from adult ways of thinking. Also this course is activity based, providing opportunities for deep, connected learning. It is essential for all teachers of mathematics to understand the reasoning underlying the mathematics they are teaching. They need to understand why various procedures work, how each idea they will be teaching connects with other important ideas in mathematics, and how these ideas develop and become more sophisticated."},
    {"id":"MAT 2570","title":"Discrete Mathematics","description":"A course in Discrete Mathematics for students interested in information technology, computer science, and related fields. Topics include logic, proof techniques, set theory, functions and relations, counting, elementary number theory, graphs and tree theory, base-n arithmetic, and Boolean Algebra."},
    {"id":"MAT 2600","title":"Applied Statistics","description":"Covers sample spaces and probability laws; discrete and continuous random variables with special emphasis on the binomial, Poisson, hypergeometric, normal and gamma distributions; fundamental sampling distributions and data descriptions; use of computer software packages for simulating, summarizing, and displaying data.  Provides a foundation for the further study of statistics."},
    {"id":"MAT 2700","title":"Mathematics Internship","description":"Students work at an approved mathematics related industry site and will earn credits toward degree requirements for their work experiences. Students already working may apply to use that experience to meet internship requirements. Students prepare and submit reports and/or projects describing their industry experience and are evaluated by the course instructor as well as their on-site supervisor. Ten work hours per week per credit hour."},
    {"id":"MEE 2101","title":"Statics for Engineers","description":"Vectorial treatment of forces and moments. Analysis of trusses and frames.  Centroids, friction and moment of inertia. Internal shear and moment for beams.  Virtual work. This calculus-based course is designed for Engineering University Transfer students. Two classroom, two lab hours per week."},
    {"id":"MEE 2201","title":"Thermodynamics for Engineers","description":"First and second laws of thermodynamics; thermodynamic properties of gases, vapors and gas-vapor mixtures; energy-systems analysis including power cycles, refrigeration cycles and air-conditioning processes. Introduction to thermodynamics of reacting mixtures. Two classroom, two lab hours per week."},
    {"id":"MEE 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"MEE 2301","title":"Strength of Materials for Engineers","description":"Stress and deformations, torsions, shear and moments in beams, stresses in beams, beam deflections, combined stresses and eccentric loading. This course is calculus based. One classroom, four lab hours per week."},
    {"id":"MEE 2401","title":"Dynamics for Engineers","description":"Kinematics of particles and rigid bodies; acceleration, work, energy, impulse and momentum of particles and rigid bodies. Two classroom,  two lab hours per week."},
    {"id":"MEE 2700","title":"Mechanical Engineering Internship","description":"Students earn credits toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each term. Ten work hours per week per credit hour."},
    {"id":"MET 1101","title":"Introduction to Engineering Drafting","description":"Interpretation of engineering drawings. Includes principles of orthographic projection, drafting symbols, surface finish symbols, welding symbols and geometric dimensioning and tolerancing symbols. One classroom, two lab hours."},
    {"id":"MET 1111","title":"Preparatory Math for Engineering Technology","description":"Mathematics for engineering technology students to prepare them for critical thinking, analytical reasoning and problem solving.  Students will apply math to typical engineering technology problems from a variety of fields.  Two classroom, two lab hours per week."},
    {"id":"MET 1131","title":"Personal Computer Applications for Engineering Technology","description":"Applied computer tools to solve engineering technology problems, emphasizing the integration of word processing, spreadsheets, presentation software and engineering research skills using the Internet. Applications of an integrated approach to research papers, engineering technology analysis, technical laboratory reports and technical presentations. One-half classroom, one and one-half lab hours per week."},
    {"id":"MET 1151","title":"Guitar Manufacturing using Science, Technology, Engineering, & Mathematics (STEM) Concepts","description":"This course looks at the design elements, manufacturing and assembly of solid-body electric guitars. Science, Technology, Engineering & Mathematics (STEM) concepts that relate directly to guitars are used to help students make an applied learning connection. Two classroom, two lab hours per week."},
    {"id":"MET 1161","title":"Software Tools for Engineering Technology","description":"Introduction to computer-based solution of engineering and engineering technology problems. Includes the fundamentals and applications of computer-based software (MathCAD) and integration with other software for documentation of work, including proper use of units and unit systems. Software solution applications include graphing functions and data, basic statistical calculations, use of matrices, vectors, solution of simultaneous and an introduction to Boolean logic. One-half classroom, one and one-half lab hours per week."},
    {"id":"MET 1231","title":"Introduction to Engineering Design Using 3D CAD","description":"Application of the process of design and the interpretation of engineering drawings. Includes design development, product development, and problem solution, principles of orthographic projection, drafting symbols, surface finish symbols, and geometric dimensioning and tolerancing symbols. Student is exposed to parametric 3D CAD modeling for the purpose of creating parts and assemblies and to properly dimension and detail drawings to effectively communicate design intent.  Three classroom, three lab hours per week."},
    {"id":"MET 1241","title":"Principles of Engineering","description":"Development of student understanding of the engineering and engineering technology fields through applied math, science and technology principles. Introductory looks into the applied learning of mechanics, strength of materials, free body diagrams and forces.  One classroom, two lab hours per week."},
    {"id":"MET 1301","title":"SolidWorks Basics","description":"Utilize SolidWorks mechanical design automation software to build parametric models of parts and assemblies and learn how to make drawings of those parts and assemblies.  Three classroom, three lab hours per week."},
    {"id":"MET 1331","title":"NX (Unigraphics) Basics","description":"An introduction to Unigraphics 3-D Modeling software intended for new Unigraphics users or individuals with basic CAD skills. Emphasis will be placed on the development of basic skills and methods to create solid models.  One classroom, six lab hours per week."},
    {"id":"MET 1351","title":"Solid Edge Basics","description":"A computer-aided drafting course using Solid Edge software with information for new users on how to get started with the software. Emphasis on the development of basic skills and methods to create solid models. One classroom, six lab hours per week."},
    {"id":"MET 1371","title":"CAD Concepts using AutoCAD","description":"Computer-aided drafting using the latest release of AutoCAD. Topics include: drawing and editing tools, two- and three-dimensional drawing, drawing layouts, scaling, dimensioning techniques and plotting. Two classroom, two lab hours per week."},
    {"id":"MET 1401","title":"Additive Design & Printing","description":"A course in the design, development and operation of additive manufacturing machines. Types of machines, input types, materials and design considerations will be accomplished as part of the hands-on model making class.  Two classroom, two lab hours per week."},
    {"id":"MET 1431","title":"Additive Manufacturing Post Process","description":"A course designed to examine the post process aspects of bonding, securing, finishes  and assembly operation of components. Emphasis on metal materials and case studies.  Two classroom, two lab hours per week."},
    {"id":"MET 2101","title":"Thermodynamics","description":"The laws and application of the principles of thermodynamics as they apply to internal combustion engines, steam cycles and refrigeration. Two classroom, two lab hours per week."},
    {"id":"MET 2151","title":"Material Science","description":"Terminology, designations of metals and the relationship among the properties of metals, the environment and heat treatment processes. Selecting and testing materials. Factors related to the selection of nonmetallic materials and the relationship between the nature of the materials and their properties. Thermoplastics, thermosetting, ceramics, composites and glasses are included. Three classroom, three lab hours per week."},
    {"id":"MET 2201","title":"Statics","description":"Analysis of various types of two and three dimensional force systems, analysis of trusses, frames, friction, center of gravity and moment of inertia.  Two classroom, three lab hours per week."},
    {"id":"MET 2251","title":"Strength of Materials","description":"Stress and deformations, torsions, shear and moments in beams, stresses in beams, beam deflections, combined stresses. This course is algebra based. Two classroom, two lab hours per week."},
    {"id":"MET 2281","title":"Engineering Technology Professional Practice","description":"A project-based course utilizing reverse engineering to integrate ABET professional components in preparing students with the knowledge, techniques, skills, and use of modern equipment in mechanical engineering technology.  The course strengthens student ability in specifying, installing, building, testing, documenting, operating, selling or maintaining basic mechanical systems. Two classroom, two lab hours per week."},
    {"id":"MET 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format."},
    {"id":"MET 2301","title":"Fluid Mechanics","description":"Essentials of fluid properties, fluid statics, flow measurements, force of a fluid jet including turbo machinery, open channel flow losses through flow in pipe and duct and pump and fan performance and operation. Two classroom, two lab hours per week."},
    {"id":"MET 2351","title":"Dynamics","description":"Kinematics and kinetics of rectilinear motion, curvilinear motion and rotation; plane motion, work, energy, power, impulse and momentum. Two classroom, two lab hours per week."},
    {"id":"MET 2401","title":"Machine Design","description":"Design and evaluation of machine elements, design for safety, strength, stability and wear. Analysis and design of gears, shafts, drive systems, mechanical fasteners, permanent connections, roller and journal bearings and springs. A design project including an oral presentation and written report is required. Two classroom, three lab hours per week."},
    {"id":"MET 2700","title":"Mechanical Engineering Technology Internship","description":"Students earn credits toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each term. Ten work hours per week per credit hour."},
    {"id":"MET 2711","title":"Ethics for Engineering Technology Professionals","description":"Instruction to the core skills of an engineering professional.  Technical skills, soft skills and team management techniques. Concepts of lifelong learning, continued personal improvement, engineering ethics, working in a diverse industry and future trends in engineering technology.  One-half classroom, one and one-half lab hours per week."},
    {"id":"MET 2780","title":"Mechanical Engineering Technology Capstone","description":"Assessment of achievement by Mechanical Engineering Technology students in attaining program outcomes by completing a project demonstrating principles and practice of the major. Teamwork on projects will be emphasized.  One classroom, six lab hours per week."},
    {"id":"MHT 1101","title":"Introduction to Mental Health Services","description":"Introduction to the field of mental health services. Students will identify principles, skills and history and evolution of the mental health field. Analyze motives, values, biases, cultural and social influences for becoming a helper. Examine key terminology and concepts. Describe basic theories, evidenced based practices and interventions of the helping profession. Recognize professional behaviors, documentation practices and ethical standards required to work in the helping profession."},
    {"id":"MHT 1130","title":"Fundamentals of Addiction Counseling CDCA Preliminary","description":"Course provides 40 hours of chemical dependency specific educational content required for application for CDCA Preliminary credential with the Ohio Chemical Dependency Professionals Board. Theories and fundamentals of addictive illness and physical/mental effects of psychoactive drugs. Dynamics of substance related and addictive disorders on persons, families and society. Knowledge of disease concept, stigmas, identification, assessment, trends in treatment and relapse process. Develop insights, challenge biases and identify personal and professional issues. Elements of professional/ethical behaviors."},
    {"id":"MHT 1201","title":"Interviewing Skills","description":"Basic interviewing, active listening skills, elements of the helping relationship, professional ethics and issues. Practice in conducting interviews. Cultural factors in interviewing. Introduction to Motivational Interviewing. Health Information Portability and Accountability Act (HIPAA) privacy law."},
    {"id":"MHT 1202","title":"Motivational Interviewing","description":"Learning and applying the model of Motivational Interviewing including engaging, focusing, evoking, and planning. Concepts of OARS, stages of change, communication skills, client ambivalence, developing discrepancies, change talk, client goals, and developing a change plan."},
    {"id":"MHT 1203","title":"Professional Documentation","description":"Functional, legal, and ethical aspects of documentation including behavioral observation, mechanics of writing problem statements, client assessments, and progress notation. Introduction to electronic record keeping."},
    {"id":"MHT 1236","title":"Assessment & Diagnosis of Substance Use Disorders","description":"Holistic assessment and diagnosis of substance use disorders.  Assessment skill development. Use of and interpretation of assessment instruments.  Use of current Diagnostic and Statistical Manual (DSM) criteria related to substance use disorders."},
    {"id":"MHT 2105","title":"Psychosocial Methods","description":"Applying advanced clinical interventions and treatment modalities for various client populations.  Cognitive behavioral therapy and motivational interviewing are emphasized.  Examining the nature of selected mental disorders from the current Diagnostic and Statistical Manual (DSM)."},
    {"id":"MHT 2111","title":"Group Dynamics I","description":"Introduction to interpersonal dynamics in therapeutic groups. Awareness of group leadership skills and personal issues affecting participation. Laboratory group promotes personal learning while providing experiential awareness of stages of group development. History of the group work method. Factors in group composition. Professional ethics. Practice in group facilitation. Two classroom, two lab hours per week."},
    {"id":"MHT 2112","title":"Lab for Group Dynamics I"},
    {"id":"MHT 2121","title":"Practicum I","description":"First of two field experiences in human service delivery.  Professional documentation and refinement of interviewing skills.  Pharmacology in behavioral health.  Three classroom, fourteen practicum hours per week."},
    {"id":"MHT 2130","title":"Fundamentals of Addiction Counseling CDCA Renewable","description":"Course provides 30 hours of chemical dependency specific educational content required for application for CDCA Renewable credential with the Ohio Chemical Dependency Professionals Board. Content included: Addiction and treatment knowledge, individual and group counseling, evaluation, service coordination, documentation and professionalism. Persons who currently hold a CDCA Preliminary with the State of Ohio may also take this course."},
    {"id":"MHT 2137","title":"Treatment Techniques in Substance Use Disorders","description":"Contemporary holistic treatment methods, including motivational interviewing. Models of treatment with individual, group, case management, intervention and families. Levels of care and stages of recovery. Ethical, legal and professional behaviors."},
    {"id":"MHT 2138","title":"Ethical Issues in Behavioral Healthcare","description":"Ethical codes and responsibilities in the human services field. Federal Confidentiality Regulations, case law, scope of practice, expectations of funding bodies and managed care. Principles of professional behavior with clients and self-awareness of their personal boundary and value concerns. Importance of cultural diversity."},
    {"id":"MHT 2211","title":"Group Dynamics II","description":"Stages of group development, process planning, and group leadership skills. Advanced practice in group co-facilitation and critical analysis of group processes.  Curative factors in groups.  Laboratory group promotes personal learning while providing experiential awareness of group dynamics and stages of group development. Two classroom, two lab hours per week."},
    {"id":"MHT 2212","title":"Lab for Group Dynamics II"},
    {"id":"MHT 2222","title":"Practicum II","description":"Second of two semesters of field experience in human service delivery.  Refinement of clinical documentation and clinical interviewing skills. Three classroom, fourteen practicum hours per week."},
    {"id":"MHT 2235","title":"Family Dynamics of Addiction","description":"Impact of substance use disorders on individual family members and overall family functioning. Focuses on the nature of addiction as a disease, its progression, symptoms and treatments. The nature of codependency is discussed. Insight is gained by the students regarding their biases about the disease of addiction."},
    {"id":"MHT 2239","title":"Dual Diagnosis","description":"Treatment of persons with substance use disorders and mental illness.  Unique challenges and effective treatment models for client population are explored. Stages of Change and Motivational Interviewing. Community and support resources."},
    {"id":"MHT 2245","title":"Mental Health & the Family","description":"Underlying dynamics and interactional patterns in the functioning of the family system. The impact of family dysfunction upon children, parents and the family system.  Issues and trends facing contemporary families and methodologies of clinical intervention are highlighted."},
    {"id":"MHT 2250","title":"Child & Adolescent Mental Health","description":"Mental health and mental ill-health issues related to childhood and adolescents. Etiology and treatment approaches."},
    {"id":"MHT 2252","title":"Issues in Behavioral Health","description":"Mental Health Technology elective course examining  current social policies, best practices, and innovations and methods of treatment in behavioral health and human services."},
    {"id":"MHT 2253","title":"Issues in Chemical Dependency","description":"Current research and issues regarding special populations. Trends and best practices in addictions."},
    {"id":"MHT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"MRK 2100","title":"Foundations of Marketing","description":"Foundations of Marketing is designed to provide a broad introduction to the field of marketing. Marketing is far more than just selling or advertising within a business setting; it is a major part of our everyday lives. This course will illustrate the importance of marketing and skills that are applicable to all specializations within business."},
    {"id":"MRK 2101","title":"Principles of Marketing Management","description":"Marketing strategies and decision making in the context of other business functions. Topics include: research and analysis of markets, environments and competition; market segmentation and selection of target markets; consumer and organizational behavior; planning and integration of product, price, promotion and distribution activities for profit and nonprofit, domestic and global settings."},
    {"id":"MRK 2102","title":"Principles of Advertising","description":"This course focuses on integrated marketing communications (IMC). The concepts of IMC enhance the equity of brands and show how advertising, promotion, packaging and branding strategies, point-of-purchase communications, public relations, event and cause-oriented sponsorships can affect the marketing of products, goods, services or ideas."},
    {"id":"MRK 2135","title":"Digital Marketing","description":"Digital marketing is a broad and growing field encompassing any form of marketing that utilizes technology to deliver a message. Students in this course study some of the most popular tactics used by successful businesses today to promote their brand and reach their customers. This information is applied in hands-on activities that expose students to real-world business situations, including developing a digital marketing campaign for a brand."},
    {"id":"MRK 2145","title":"Principles of  Retailing","description":"Functions and concepts for the retail organization. Development and implementation of policies and procedures in planning, pricing, display, layout, buying and services from a management perspective. A consumer-centered approach to examining problems of various types."},
    {"id":"MRK 2220","title":"Small Business Marketing","description":"This course explores the marketing strategy and planning process. Special emphasis is given to analyzing marketing techniques used by innovative entrepreneurs. Students will work collaboratively to develop a marketing plan for a start-up or existing business."},
    {"id":"MRK 2225","title":"Sales Fundamentals","description":"Along with exploring potential career opportunities, this course introduces students to the approaches and philosophies used by successful sales professionals.  Topics such as identifying and communicating with prospects, identifying needs, matching presentation styles to the situation, handling objections, closing techniques and long-term relationship building strategies will be explored."},
    {"id":"MRK 2230","title":"Social Media & Consumer Engagement","description":"The emergence and growth of social media changed the way we communicate and conduct business. Consumers can influence one another and brands now more than ever, and this power shift creates both opportunities and challenges for organizations today. This course includes a brief overview of the popular social media platforms, but primarily focuses on how brands can use social media strategically to connect with customers and achieve their goals. Students apply this information by evaluating and developing social marketing campaigns."},
    {"id":"MRK 2236","title":"Consumer Behavior","description":"This course is designed to enable students to acquire an understanding of the purchasing decision process for individuals, families and organizations. Psychological, societal, and cultural influences on consumer decisions will be studied. Marketing strategy implications of conceptual constructs will be discussed throughout the course."},
    {"id":"MRK 2250","title":"Digital Marketing Analytics","description":"Today’s marketers are concerned about measuring both qualitative and quantitative data as well as return on investment for their digital marketing campaigns. In this course, students will learn about digital marketing analytics theories, gain experience in using tools such as Google Analytics, and will be prepared to take the Google Analytics Individual Qualification (IQ) certification."},
    {"id":"MRK 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"MUS 1101","title":"Introduction to Music","description":"Fundamentals of music theory including notation of pitches, rhythms, scales, intervals, triads and chords."},
    {"id":"MUS 1102","title":"Introduction to Aural Skills","description":"Fundamentals of sight singing, dictation, ear training including reading, hearing and notating rhythms, melodies and intervals."},
    {"id":"MUS 1103","title":"Sight-Singing for Singers","description":"Developing and understanding of solfeggio through the practice and drill of singing exercises utilizing the syllables and hand signals of Solmization to recognize the notes, rhythms and intervals important to basic sight-reading skills."},
    {"id":"MUS 1109","title":"Introduction to Music Education","description":"Aspects of professional music teaching and pedagogy including teaching methodologies and philosophies; elements of learning and teaching; variety of professional organizations and their resources will be introduced."},
    {"id":"MUS 1110","title":"Music Technology for Music Majors","description":"Introduction to technology resources used by music majors and future musicians.   Typical music studio set-up.  Sinclair systems, music notation software, MIDI and digital audio recording."},
    {"id":"MUS 1111","title":"Music Theory I","description":"First in a sequence of four music theory courses. Focus placed on diatonic melodic and harmonic structures, including scales and modes, intervals, tonality and keys, melodic organization, voice leading, instrument and voice ranges, transposition, triads and seventh chords. Activities include musical composition, analysis, listening, discussion and computer work."},
    {"id":"MUS 1112","title":"Aural Skills I","description":"First in a sequence of four aural skills courses. Focus placed on diatonic melodic and harmonic structures within a more basic rhythmic environment. Practical transcription and singing skills are systematically studied. Activities include dictation of intervals, chords, melodies, harmonic progressions and rhythms, as well as singing of intervals, melodies and rhythms."},
    {"id":"MUS 1113","title":"Music Theory II","description":"Second in a sequence of four music theory courses. Focus placed on diatonic and chromatic melodic and harmonic structures, including voice leading, seventh chords, modulation, secondary dominant-functioning chords and binary and ternary forms. Activities include musical composition, analysis, listening, discussion and computer work."},
    {"id":"MUS 1114","title":"Aural Skills II","description":"Second in a sequence of four aural skills courses. Focus placed on diatonic melodic and harmonic structures within a more challenging rhythmic environment. Practical transcription and singing skills systematically studied. Activities include interval, chord, melodic, harmonic progression and rhythm dictation, as well as singing of intervals, melodies and rhythms."},
    {"id":"MUS 1115","title":"Piano for Music Majors I","description":"First semester of a four-semester sequence for nonpianist music majors. Instruction in correct piano playing techniques with emphasis on skills needed by future music educators."},
    {"id":"MUS 1116","title":"Piano for Music Majors II","description":"Second semester of a four-semester sequence for nonpianist music majors. Continued instruction in correct piano playing techniques with emphasis on early intermediate repertoire. The integration of circle of fifths with the playing of all major and minor scales, chords and arpeggios is also stressed."},
    {"id":"MUS 1117","title":"Vocal Diction I","description":"Italian and German diction, studied with emphasis on correct pronunciation, with regard to clarity, expressiveness, fundamentals of the International Phonetic Alphabet and sound production as applied to singing and reading."},
    {"id":"MUS 1118","title":"Vocal Diction II","description":"German and French diction, studied with emphasis on correct pronunciation, with regard to clarity, expressiveness, fundamentals of the International Phonetic Alphabet and sound production as applied to singing and reading."},
    {"id":"MUS 1119","title":"Secondary Voice","description":"Private instruction in Applied Voice is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements vary per term, according to the singing ability of the student. Secondary Voice is the required minor instrument for pianists and organists who are seeking an associate degree in music. Four semesters are required; MUS 1119 is the first of these."},
    {"id":"MUS 1121","title":"Music Appreciation","description":"Basic parameters of music through a survey of styles from Gregorian Chant to jazz and current popular styles focusing on melody, rhythm, harmony, performance media and form."},
    {"id":"MUS 1122","title":"History of Pop/Rock Music","description":"The musical reasons and social conditions under which pop music and rock music have developed, with particular emphasis on music from 1955 until the present. The personalities, events and music that shaped this music and which continue to evolve today."},
    {"id":"MUS 1123","title":"World Music","description":"A course that describes and analyzes historical-social elements of western culture, non-western culture, and the global interdependence of groups and individuals as seen in the music(s) of these various cultures."},
    {"id":"MUS 1131","title":"Chorale","description":"Large select SATB (soprano-alto-tenor-bass)choral ensemble specializing in the performance of significant choral repertoire representing varied styles, historical periods and languages.  School and public performances required."},
    {"id":"MUS 1133","title":"Chamber Choir","description":"Small select SATB (soprano-alto-tenor-bass) choral ensemble specializing in the performance of significant choral repertoire representing varied styles, historical periods and languages. School and public performances required."},
    {"id":"MUS 1135","title":"Women's Ensemble","description":"Three-part female choral ensemble specializing in the performance of significant choral repertoire for women's voices, representing varied styles, historical periods and languages. School and public performances required."},
    {"id":"MUS 1137","title":"Men's Ensemble","description":"The performance and presentation of choral literature written for male voices from all musical periods.  The ensemble will present at least one public concert per semester."},
    {"id":"MUS 1139","title":"Contemporary Gospel Ensemble","description":"The performance and presentation of mixed-voice choral literature from the African-American, spiritual and contemporary worship and praise music tradition. The ensemble will present at least one public concert per semester."},
    {"id":"MUS 1141","title":"Wind Symphony","description":"Concentration on instrumental problems and techniques. Development of large and small group wind repertoire. Public performance is a major part of course activities."},
    {"id":"MUS 1143","title":"Concert Band","description":"Concentration on instrumental problems and techniques. Development of large concert band repertoire. Public performance is a major part of course activities."},
    {"id":"MUS 1145","title":"Classical Guitar Ensemble","description":"The study and performance of selected classical guitar ensemble literature.  The size of the ensemble and the respective backgrounds of its players will determine the performance level of the repertoire.  End-of-term performance."},
    {"id":"MUS 1147","title":"Jazz Ensemble","description":"Big band jazz ensemble open to college and community musicians.  Concerts and appearances are scheduled during academic year."},
    {"id":"MUS 1149","title":"Jazz Combo","description":"Small jazz group, limited to ten or fewer players. Development of basic jazz performance skills, including improvisation. Concerts scheduled near end of terms."},
    {"id":"MUS 1151","title":"Concert Handbell Choir","description":"The study and performance of handbell ensemble repertoire, ranging from classical to novelty, sacred to secular and original to transcription, as well as aspects of the preparation of a program for performance."},
    {"id":"MUS 1153","title":"Piano Ensemble","description":"The learning of piano duets and duos for advanced students only.  Public performance a primary goal.  Familiarization with major composers and repertoire for piano ensemble performance."},
    {"id":"MUS 1171","title":"Piano Class","description":"Basic music reading skills and correct piano playing techniques acquired in a group setting.  Simple chords and pieces.  No piano playing or musical experience required."},
    {"id":"MUS 1172","title":"Voice Class","description":"Fundamentals of vocal production, song literature, interpretation and performance skills are studied, either as a terminal course or to prepare students for possible private applied study. Combines lecture with group and individual singing."},
    {"id":"MUS 1173","title":"Guitar Class I","description":"Fundamental study of guitar playing techniques.  Students must provide their own acoustic instruments.  (Electric guitars are not appropriate.)"},
    {"id":"MUS 1174","title":"Guitar Class II","description":"Continuation of Guitar Class I with additional keys learned and more ensemble playing.  Students must provide their own acoustic instruments. (Electric guitars are not appropriate.)"},
    {"id":"MUS 1500","title":"Applied Piano for Non-Majors","description":"Private instruction in piano is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice.  Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1501","title":"Applied Voice for Non-Majors","description":"Private instruction in voice is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1502","title":"Applied Classical Guitar for Non-Majors","description":"Private instruction in classical guitar is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1503","title":"Applied Flute for Non-Majors","description":"Private instruction in flute is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1504","title":"Applied Clarinet for Non-Majors","description":"Private instruction in clarinet is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1505","title":"Applied Saxophone for Non-Majors","description":"Private instruction in saxophone is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1506","title":"Applied Oboe for Non-Majors","description":"Private instruction in oboe is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1507","title":"Applied Bassoon for Non-Majors","description":"Private instruction in bassoon is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1508","title":"Applied Trumpet for Non-Majors","description":"Private instruction in trumpet is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1509","title":"Applied Trombone for Non-Majors","description":"Private instruction in trombone is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1510","title":"Applied French Horn for Non-Majors","description":"Private instruction in french horn is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1511","title":"Applied Baritone Horn for Non-Majors","description":"Private instruction in baritone horn is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1512","title":"Applied Tuba for Non-Majors","description":"Private instruction in tuba is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1513","title":"Applied Violin for Non-Majors","description":"Private instruction in violin is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1514","title":"Applied Viola for Non-Majors","description":"Private instruction in viola is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1515","title":"Applied Cello for Non-Majors","description":"Private instruction in cello is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1516","title":"Applied String Bass for Non-Majors","description":"Private instruction in string bass is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1517","title":"Applied Percussion for Non-Majors","description":"Private instruction in percussion is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1518","title":"Applied Organ for Non-Majors","description":"Private instruction in organ is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1519","title":"Applied Harpsichord for Non-Majors","description":"Private instruction in harpsichord is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1520","title":"Applied Popular Guitar for Non-Majors","description":"Private instruction in popular guitar is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1521","title":"Applied Electric Bass for Non-Majors","description":"Private instruction in electric bass is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1522","title":"Applied Jazz Drumming for Non-Majors","description":"Private instruction in jazz drumming is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 1523","title":"Applied Jazz Piano for Non-Majors","description":"Private instruction in jazz piano is given on the basis of one credit for 30-minute lessons for 15 weeks, with an expectation of 45 minutes per day of practice. Repertoire and technical requirements according to advancement of student."},
    {"id":"MUS 2111","title":"Music Theory III","description":"Third in a sequence of four music theory courses. Focus will be placed on more advanced chromatic melodic and harmonic structures, including borrowed chords, the Neapolitan chord and augmented-sixth chords. Activities include musical composition, analysis, listening, discussion and computer work."},
    {"id":"MUS 2112","title":"Aural Skills III","description":"Third in a sequence of four aural skills courses. Focus placed on chromatic melodic and harmonic structures within a challenging rhythmic environment. Practical transcription and singing skills systematically studied. Activities include interval, chord, melodic, harmonic progression and rhythm dictation, as well as singing of intervals, melodies and rhythms."},
    {"id":"MUS 2113","title":"Music Theory IV","description":"Final course in a sequence of four music theory courses. Focus placed on advanced chromatic melodic and harmonic structures, including extended harmony, altered dominant chords, chromatic mediants, sonata and rondo forms, late 19th-century and contemporary techniques. Activities include musical composition, analysis, listening, discussion and computer work."},
    {"id":"MUS 2114","title":"Aural Skills IV","description":"Last in a sequence of four aural skills courses. Focus placed on advanced chromatic melodic and harmonic structures within an advanced rhythmic environment. Practical transcription and singing skills systematically studied. Activities include interval, chord, melodic, harmonic progression and rhythm dictation, as well as singing of intervals, melodies and rhythms."},
    {"id":"MUS 2115","title":"Piano for Music Majors III","description":"Third semester of a four-semester sequence for nonpianist music majors. Transposition and harmonization are emphasized."},
    {"id":"MUS 2116","title":"Piano for Music Majors IV","description":"Fourth semester of a four-semester sequence for nonpianist music majors. Sight reading and vertical four-part reading are emphasized. A comprehensive review of the skills acquired during the four-semester sequence precedes a year-end assessment of all these skills."},
    {"id":"MUS 2117","title":"Survey of Musical Styles I","description":"The historical styles of Western music in chronological sequence through analysis of various musical compositions and musical forms from the Medieval, Renaissance, Baroque and Classical eras."},
    {"id":"MUS 2118","title":"Survey of Musical Styles II","description":"The historical styles of Western music in chronological sequence through analysis of various musical compositions and musical forms from the early 19th century to contemporary times, including the Romantic, Modern and 20th century eras."},
    {"id":"MUS 2210","title":"Conducting Fundamentals","description":"Fundamentals of conducting music ensembles with emphasis on basic baton technique, meters, cueing, addressing different styles, conducting terminology and score reading."},
    {"id":"MUS 2211","title":"Handbell Choir Conducting","description":"Major factors associated with direction of handbell ensembles, emphasizing organization of choirs, performance pedagogy, conducting techniques, repertoire selection, performance aspects and care of equipment."},
    {"id":"MUS 2231","title":"Chorale for Majors","description":"Large select SATB (soprano-alto-tenor-bass) choral ensemble for music majors specializing in the performance of significant choral repertoire representing varied styles, historical periods and languages. School and public performances required."},
    {"id":"MUS 2240","title":"Music Practicum","description":"Music majors may receive credit for practical performance experiences such as performing in a (non-Sinclair) musical, opera, choral organization, etc. Arrangements must be made through department chairperson; only for those students who, due to scheduling impossibilities, cannot participate in Sinclair ensembles at their regularly scheduled times."},
    {"id":"MUS 2241","title":"Music Practicum for Majors","description":"Music majors may fulfill requirements for performance experiences such as performing in a (non-Sinclair) orchestra, musical, opera, choral organization, etc. Arrangements must be made through department chairperson; only for those students who, due to scheduling impossibilities, cannot participate in Sinclair ensembles at their regularly scheduled times."},
    {"id":"MUS 2243","title":"Concert Band for Majors","description":"Concentration on instrumental problems and techniques for music majors. Development of large concert band repertoire. Public performance is a major part of course activities."},
    {"id":"MUS 2245","title":"Classical Guitar Ensemble for Majors","description":"The study and performance of selected classical guitar ensemble literature for music majors. The size of the ensemble and the respective backgrounds of its players will determine the performance level of the repertoire. End-of-term performance."},
    {"id":"MUS 2251","title":"Performance Class","description":"Performance repertoire from intermediate to advanced levels on one's instrument. Designed to anticipate and alleviate public performance problems. Emphasizing all aspects of technique and music. Also addressed: sight reading in public, memorization of scores and nervousness/anxiety caused by stage fright. Section 01 - Pianists; Section 02 - Singers; Section 03 - Guitarist"},
    {"id":"MUS 2261","title":"Applied Music Practicum","description":"Applied music study for early-intermediate or advanced-level instrumentalists or singers. Sixty minute lessons for 15 weeks. No student recital or board examination obligations (although recital performance is optional, according to the desire of student.) Indefinitely repeatable. section 01 - piano section 02 - voice section 03 - guitar Additional sections, representing other instruments, added each term as necessary."},
    {"id":"MUS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"MUS 2500","title":"Applied Piano for Majors I","description":"Private instruction in Applied Piano for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2500 is the first and second semesters of four required semesters of applied piano study."},
    {"id":"MUS 2501","title":"Applied Piano for Majors II","description":"Private instruction in Applied Piano for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2501 is the third and fourth semesters of four required semesters of applied piano study."},
    {"id":"MUS 2502","title":"Applied Voice for Majors I","description":"Private instruction in Applied Voice for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2502 is the first and second semesters of four required semesters of applied voice study."},
    {"id":"MUS 2503","title":"Applied Voice for Majors II","description":"Private instruction in Applied Voice for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2503 is the third and fourth semesters of four required semesters of applied voice study."},
    {"id":"MUS 2504","title":"Applied Classical Guitar for Majors I","description":"Private instruction in Applied Classical Guitar for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2504 is the first and second semesters of four required semesters of applied classical guitar study."},
    {"id":"MUS 2505","title":"Applied Classical Guitar for Majors II","description":"Private instruction in Applied Classical Guitar for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2505 is the third and fourth semesters of four required semesters of applied classical guitar study."},
    {"id":"MUS 2506","title":"Applied Flute for Majors I","description":"Private instruction in Applied Flute for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2506 is the first and second semesters of four required semesters of applied flute study."},
    {"id":"MUS 2507","title":"Applied Flute for Majors II","description":"Private instruction in Applied Flute for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2507 is the third and fourth semesters of four required semesters of applied flute study."},
    {"id":"MUS 2508","title":"Applied Clarinet for Majors I","description":"Private instruction in Applied Clarinet for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2508 is the first and second semesters of four required semesters of applied clarinet study."},
    {"id":"MUS 2509","title":"Applied Clarinet for Majors II","description":"Private instruction in Applied Clarinet for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2509 is the third and fourth semesters of four required semesters of applied clarinet study."},
    {"id":"MUS 2510","title":"Applied Saxophone for Majors I","description":"Private instruction in Applied Saxophone for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2510 is the first and second semesters of four required semesters of applied saxophone study."},
    {"id":"MUS 2511","title":"Applied Saxophone for Majors II","description":"Private instruction in Applied Saxophone for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2511 is the third and fourth semesters of four required semesters of applied saxophone study."},
    {"id":"MUS 2512","title":"Applied Oboe for Majors I","description":"Private instruction in Applied Oboe for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2512 is the first and second semesters of four required semesters of applied oboe study."},
    {"id":"MUS 2513","title":"Applied Oboe for Majors II","description":"Private instruction in Applied Oboe for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2513 is the third and fourth semesters of four required semesters of applied oboe study."},
    {"id":"MUS 2514","title":"Applied Bassoon for Majors I","description":"Private instruction in Applied Bassoon for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2514 is the first and second semesters of four required semesters of applied bassoon study."},
    {"id":"MUS 2515","title":"Applied Bassoon for Majors II","description":"Private instruction in Applied Bassoon for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2515 is the third and fourth semesters of four required semesters of applied bassoon study."},
    {"id":"MUS 2516","title":"Applied Trumpet for Majors I","description":"Private instruction in Applied Trumpet for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2516 is the first and second semesters of four required semesters of applied trumpet study."},
    {"id":"MUS 2517","title":"Applied Trumpet for Majors II","description":"Private instruction in Applied Trumpet for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2517 is the third and fourth semesters of four required semesters of applied trumpet study."},
    {"id":"MUS 2518","title":"Applied French Horn for Majors I","description":"Private instruction in Applied French Horn for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2518 is the first and second semesters of four required semesters of applied French horn study."},
    {"id":"MUS 2519","title":"Applied French Horn for Majors II","description":"Private instruction in Applied French Horn for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2519 is the third and fourth semesters of four required semesters of applied French horn study."},
    {"id":"MUS 2520","title":"Applied Baritone Horn for Majors I","description":"Private instruction in Applied Baritone Horn for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2520 is the first and second semesters of four required semesters of applied baritone horn study."},
    {"id":"MUS 2521","title":"Applied Baritone Horn for Majors II","description":"Private instruction in Applied Baritone Horn for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2521 is the third and fourth semesters of four required semesters of applied baritone horn study."},
    {"id":"MUS 2522","title":"Applied Trombone for Majors I","description":"Private instruction in Applied Trombone for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2522 is the first and second semesters of four required semesters of applied trombone study."},
    {"id":"MUS 2523","title":"Applied Trombone for Majors II","description":"Private instruction in Applied Trombone for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2523 is the third and fourth semesters of four required semesters of applied trombone study."},
    {"id":"MUS 2524","title":"Applied Tuba for Majors I","description":"Private instruction in Applied Tuba for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2524 is the first and second semesters of four required semesters of applied tuba study."},
    {"id":"MUS 2525","title":"Applied Tuba for Majors II","description":"Private instruction in Applied Tuba for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2525 is the third and fourth semesters of four required semesters of applied tuba study."},
    {"id":"MUS 2526","title":"Applied Violin for Majors I","description":"Private instruction in Applied Violin for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2526 is the first and second semesters of four required semesters of applied violin study."},
    {"id":"MUS 2527","title":"Applied Violin for Majors II","description":"Private instruction in Applied Violin for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2527 is the third and fourth semesters of four required semesters of applied violin study."},
    {"id":"MUS 2528","title":"Applied Viola for Majors I","description":"Private instruction in Applied Viola for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2528 is the first and second semesters of four required semesters of applied viola study."},
    {"id":"MUS 2529","title":"Applied Viola for Majors II","description":"Private instruction in Applied Viola for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2529 is the third and fourth semesters of four required semesters of applied viola study."},
    {"id":"MUS 2530","title":"Applied Cello for Majors I","description":"Private instruction in Applied Cello for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2530 is the first and second semesters of four required semesters of applied cello study."},
    {"id":"MUS 2531","title":"Applied Cello for Majors II","description":"Private instruction in Applied Cello for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2531 is the third and fourth semesters of four required semesters of applied cello study."},
    {"id":"MUS 2532","title":"Applied String Bass for Majors I","description":"Private instruction in Applied String Bass for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2532 is the first and second semesters of four required semesters of applied string bass study."},
    {"id":"MUS 2533","title":"Applied String Bass for Majors II","description":"Private instruction in Applied String Bass for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2533 is the third and fourth semesters of four required semesters of applied string bass study."},
    {"id":"MUS 2534","title":"Applied Percussion for Majors I","description":"Private instruction in Applied Percussion for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2534 is the first and second semesters of four required semesters of applied percussion study."},
    {"id":"MUS 2535","title":"Applied Percussion for Majors II","description":"Private instruction in Applied Percussion for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2535 is the third and fourth semesters of four required semesters of applied percussion study."},
    {"id":"MUS 2536","title":"Applied Organ for Majors I","description":"Private instruction in Applied Organ for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2536 is the first and second semesters of four required semesters of applied organ study."},
    {"id":"MUS 2537","title":"Applied Organ for Majors II","description":"Private instruction in Applied Organ for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2537 is the third and fourth semesters of four required semesters of applied organ study."},
    {"id":"MUS 2538","title":"Applied Harpsichord for Majors I","description":"Private instruction in Applied Harpsichord for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2538 is the first and second semesters of four required semesters of applied harpsichord study."},
    {"id":"MUS 2539","title":"Applied Harpsichord for Majors II","description":"Private instruction in Applied Harpsichord for music majors.  Weekly one-hour lessons for 15 weeks, with an expectation of two hours per day of practice. Student recital performances and board examinations required each semester.  MUS 2539 is the third and fourth semesters of four required semesters of applied harpsichord study."},
    {"id":"MUS 2600","title":"General Ensemble","description":"General Ensemble exists in order to allow registrants who desire to participate in an ensemble--and who have utilized all of their previous possible registrations for a particular ensemble--the opportunity to continue participation in the ensemble of their choice.  General Ensemble is inclusive of Chorale, Chamber Choir, Women's Ensemble, Men's Ensemble, Wind Symphony, Concert Band, and Jazz Ensemble (150 minutes rehearsal/week) as well as Contemporary Gospel Ensemble, Classical Guitar Ensemble, Jazz Combo, Concert Handbell Choir, and Piano Ensemble (100 minutes rehearsal/week)."},
    {"id":"MUS 6121","title":"Non-Credit Music Appreciation","description":"Non-credit basic parameters of music through a survey of styles from Gregorian Chant to jazz and current popular styles focusing on melody, rhythm, harmony, performance media and form."},
    {"id":"MUS 6141","title":"Non-Credit Wind Symphony","description":"Concentration on instrumental problems and techniques. Development of large and small group wind repertoire. Public performance is a major part of course activities."},
    {"id":"MUS 6143","title":"Non-Credit Concert Band","description":"Concentration on instrumental problems and techniques. Development of large concert band repertoire. Public performance is a major part of course activities."},
    {"id":"MUS 6147","title":"Non-Credit Jazz Ensemble","description":"Big band jazz ensemble open to college and community musicians. Concerts and appearances are scheduled during academic year."},
    {"id":"MUS 6149","title":"Non-Credit Jazz Combo","description":"Non-credit small group jazz, limited to ten or fewer players.  Development of basic jazz performance skills, including improvisation."},
    {"id":"MUS 6171","title":"Non-Credit Piano Class","description":"Non-credit basic music reading skills and correct piano playing techniques acquired in a group setting. Simple chords and pieces. No piano playing or musical experience required."},
    {"id":"MUS 6600","title":"Non-Credit Bluegrass Ensemble","description":"Non-credit ensemble experience for bluegrass players of all levels of experience on mandolin, guitar, banjo, fiddle, and bass."},
    {"id":"NSG 1200","title":"Introduction to Nursing","description":"Explores nursing as a personal career choice. An overview of practice areas, essential functions, basic ethical and legal responsibilities and professional behaviors expected of registered nurses and nursing students. Topics include history of nursing practice, the art and science of nursing, legal and ethics, professional behaviors, and basic medical terminology. This is a blended course containing both face-to-face and online elements."},
    {"id":"NSG 1400","title":"Health & Illness I: Foundational Concepts in Nursing","description":"Introduces program and foundational nursing concepts and nursing process. Utilizes knowledge from general education courses to assist students to plan and implement nursing care of individuals across the lifespan. Develops communication, assessment and basic psychomotor skills. Total clock hours for theory: 45;  Total planned clinical hours: 67.5; total planned lab hours: 112.5; Clinical and lab hours combined: 180 hours."},
    {"id":"NSG 1450","title":"Professional Nursing I: Introduction to the Role of the Professional Nurse","description":"Introduces the scope and practice of the nursing profession. Examines the role of the nurse in the health care team including communication and collaboration. Applies basic legal, ethical, and safety principles to the practice of nursing. A minimum of 30 class hours. This is a blended course containing both face-to-face and online elements."},
    {"id":"NSG 1500","title":"Transition from Licensed Practical Nurse to Registered Nurse","description":"Prepares for advanced placement into the RN program with foundational nursing concepts. Utilizes knowledge from general education courses and previous nursing education to care for individuals and families experiencing prevalent health issues across the lifespan. Focuses on scope of practice, critical thinking and refinement of psychomotor skills. Total clock hours for theory: 22.5; Total planned clinical hours: 36; Total planned lab hours: 31.5; Clinical and lab hours combined: 67.5 hours."},
    {"id":"NSG 1600","title":"Health & Illness II: Health & Wellness Concepts","description":"Applies nursing concepts and utilizes the nursing process in health promotion and nursing care of individuals and families experiencing prevalent health issues across the lifespan, including introduction to care of the child-bearing family. Total clock hours for theory: 45; Total planned clinical hours: 135; Total planned lab hours: 45; Clinical and lab hours combined: 180 hours."},
    {"id":"NSG 1650","title":"Professional Nursing II: Healthcare System Concepts","description":"Explores current federal and state laws, practice standards and organizational regulations that impact healthcare delivery and nursing practice. Identifies the importance of economic resources, ethical principles, and evidence-based practice in improving quality and safety to achieve optimal patient outcomes in a variety of healthcare settings. A minimum of 30 class hours. This is a blended course containing both face-to-face and online elements."},
    {"id":"NSG 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"NSG 2400","title":"Health & Illness III: Health & Wellness Concepts","description":"Integrates nursing concepts and utilizes the nursing process to plan and provide nursing care to individuals and families experiencing complex physiologic and psychosocial health issues across the lifespan. Develops care competencies to enhance patient outcomes in a variety of settings. Total clock hours for theory: 45; Total planned clinical hours: 135; total planned lab hours: 45; Clinical and lab hours combined: 180 hours."},
    {"id":"NSG 2450","title":"Professional Nursing III: Leadership & Management of Care","description":"Facilitates development of leadership and management skills, with an emphasis on prioritization, delegation, supervision, and collaboration with the health care team. A minimum of 30 class hours. This is a blended course containing both face-to-face and online elements."},
    {"id":"NSG 2600","title":"Concept Synthesis","description":"Synthesizes professional nursing and health and illness concepts to manage and plan collaborative care for individuals and families in a variety of settings. Facilitates the transition from student to beginning associate degree nurse, managing the care of a group of patients with the healthcare team. Total clock hours for theory: 52.5; Total planned clinical hours: 180; Total planned lab hours: 22.5; Clinical and lab hours combined: 202.5."},
    {"id":"OPT 1100","title":"Tooling & Machining Metrology","description":"Various measurement techniques involving shop measuring instruments; correct use and care of basic inspection instruments; interpretation of blueprints as well as machined products related to engineering needs. Introduction to Coordinate Measuring Machines (CMM). One classroom, three lab hours per week."},
    {"id":"OPT 1101","title":"Introduction to Industrial Engineering Technology","description":"Introduction to operations process design, process improvement and the skills, methods and techniques used to accomplish this; the interactions and relationships between people and process change and the interactions between different processes in organizations. Two classroom, two lab hours per week."},
    {"id":"OPT 1110","title":"Operations Work Measurement & Ergonomics","description":"The application of fundamentals of work measurement techniques including taking time studies, calculating standard times, estimating product costs, performing work sampling and Methods Time Measurement (MTM). Also  the understanding of  how the human body reacts to loads and stresses and how poor work motions and workplace layouts can contribute to this.  Two classroom, two lab hours per week."},
    {"id":"OPT 1112","title":"World Class Quality Systems & Procedures","description":"Improve customer relations, measure products and processes, analyze current process control and capability and define and audit the quality management system. Two classroom, two lab hours per week."},
    {"id":"OPT 1113","title":"Coordinate Measurement","description":"Course will prepare students to use and program coordinate measurement machines, apply Geometric Dimensioning and Tolerancing (GD&T) principles, use advanced operating techniques for a servo driven coordinate measuring device. Two classroom, two lab hours per week."},
    {"id":"OPT 1125","title":"World Class Operations","description":"An overview of world-class operations principles, illustrating the many inter-related functions within successful companies, including design, planning, operations, sales and customer support. Additional focus on modern operations processes found in service industries, healthcare, transportation, food service, financial and retail. Special emphasis on resource planning and quality assurance."},
    {"id":"OPT 1126","title":"Supervision, Team Leadership & Project Management","description":"Introduction to the fundamental techniques of industrial supervision and team leadership and the effective management of projects.  Includes the five functions of supervision, team leadership functions, leadership styles and the Project Management Body of Knowledge."},
    {"id":"OPT 1130","title":"Lean Operations & Continuous Improvement","description":"Lean operations principles including lead time reduction, containerization, module design, standardized work and Takt time, Kanban, 5S&apos;s and Office Lean. Two classroom, two lab hours per week."},
    {"id":"OPT 1201","title":"Introduction to Manufacturing Safety","description":"This course introduces the concepts and practices of safety in advanced manufacturing, including good practices, hazards, prevention, and corrective action.  Students will have the opportunity to earn the Safety certification through the Manufacturing Skills Standards Council (MSSC) as part of their Certified Production Technician (CPT) program."},
    {"id":"OPT 1202","title":"Quality Practices & Measurement for Manufacturing","description":"An introduction to controlling and improving quality in a manufacturing setting, including the concepts of measurement, calibration, auditing, inspection, and continuous improvement. Explores ways that manufacturers use data and analysis to improve quality. Students will have the opportunity to earn the Quality Practices and Measurement certification through the Manufacturing Skills Standards Council (MSSC) as part of the Certified Production Technician (CPT) program."},
    {"id":"OPT 1203","title":"Manufacturing Processes & Production","description":"This course introduces the manufacturing processes and production systems commonly used in modern industry.  Additional emphasis on communication, teamwork, planning, documentation, and coordination.  Students will have the opportunity to earn the Manufacturing Processes and Production certification through the Manufacturing Skills Standards Council (MSSC) as part of their Certified Production Technician (CPT) program."},
    {"id":"OPT 1204","title":"Maintenance Awareness for Manufacturing","description":"Awareness of basic maintenance requirements of electrical, pneumatic, hydraulic, and mechanical systems utilized in modern manufacturing.  Includes the monitoring of key indicators and recognizing potential issues.   Students will have the opportunity to earn the Maintenance Awareness certification through the Manufacturing Skills Standards Council (MSSC) as part of their Certified Production Technician (CPT) program."},
    {"id":"OPT 2201","title":"Statistical Process Control","description":"Emphasis on process capability, control charts, techniques and analysis. This is followed by more advanced study of reliability, quality function deployment, design of experiments, failure mode effects analysis and quality costs. Two classroom, two lab hours per week."},
    {"id":"OPT 2205","title":"Manufacturing Processes","description":"Students will gain a basic understanding of manufacturing processes within a safe working environment."},
    {"id":"OPT 2207","title":"Operations Systems Analysis","description":"Computer simulation to solve manufacturing and nonmanufacturing problems. Involves actual programming of computer models consisting of labor, material, processing times and resources to predict future outcome of different alternatives."},
    {"id":"OPT 2208","title":"Engineering Technology Economics & Cost Analysis","description":"Basic economic cost concepts, decisions, analysis and evaluations as applied to engineering design, production, maintenance and quality control."},
    {"id":"OPT 2216","title":"Work Flow Layout & Efficiency","description":"Students will gain a basic understanding of facility planning, layout strategies and material handling techniques within a safe working environment. Two classroom, two lab hours per week."},
    {"id":"OPT 2240","title":"Six Sigma: Green Belt","description":"An applied introduction to Six Sigma using problem-solving tools, concepts and methodology to improve customer satisfaction. Includes application of Green Belt-based tools to reduce costs and improve business processes. Two classroom, two lab hours per week."},
    {"id":"OPT 2251","title":"Supply Chain Operations & Logistics","description":"In-depth study of the technical and business functions of operations logistics, materials procurement and management and supply chain development and management in manufacturing, service industries and government in a global economy."},
    {"id":"OPT 2270","title":"Operations Technology Internship","description":"Students earn credit toward degree requirements for work co-op or internship work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and /or projects each semester. Ten co-op hours per week per credit hour."},
    {"id":"OPT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"OPT 2780","title":"Operations Technology Capstone","description":"Assessment of achievement by Operations Technology students in attaining program-related outcomes by completing a project demonstrating principles and practices of the major."},
    {"id":"OTA 1111","title":"Introduction to Occupational Therapy Assistant","description":"History, philosophy, ethics and definitions of occupational therapy; overview of occupational therapy practice areas; differences between occupational therapists and occupational therapy assistants; functions of professional and regulatory agencies; exploration of learning experiences within the occupational therapy assistant program curriculum; basic prefixes, roots and suffixes; terminology and standard abbreviations required for understanding of the language used in medicine.  One classroom, two lab hours per week."},
    {"id":"OTA 1211","title":"Occupational Therapy Assistant Foundations I","description":"Introduction to occupational therapy and people with physical, psychosocial and/or developmental disabilities in a non-traditional community-based setting with supervision provided by a licensed Occupational Therapy Assistant.  Focuses on activity implementation, observation skills, professionalism, therapeutic use of self and occupational therapy practice framework language.  Two classroom, three clinical hours per week."},
    {"id":"OTA 1212","title":"Functional Anatomy","description":"Functional Anatomy of neurological and musculoskeletal systems. Analysis of nervous systems, major joint and muscle groups involved in daily living tasks such as bathing, dressing, grooming, eating, cooking, and housekeeping."},
    {"id":"OTA 1213","title":"Occupational Therapy & Adults with Physical Dysfunction","description":"Effect of the features of major diseases, injuries, and disorders on adult occupational performance.  Physical dysfunction theories, models of practice, frames of reference that provide the foundation of occupational therapy.  Screening and evaluation of occupational performance affected by physical dysfunction.  Includes use of occupations for the purpose of assessment, specified screening tools, assessments, observation, checklists, histories and interviews with clients and/or their family members or caregivers."},
    {"id":"OTA 1214","title":"Occupational Therapy & Adults with Physical Dysfunction Lab","description":"Focus on physical dysfunction intervention to increase independence in areas of occupation.  Screening and evaluation of occupational performance affected by physical dysfunction.  Includes use of occupations for purpose of physical dysfunction assessments and interventions. Six lab hours per week."},
    {"id":"OTA 1311","title":"Occupational Therapy Assistant Foundations II","description":"Continue refining definition of occupational therapy and experience with people with physical, psychosocial and/or developmental disabilities in a non-traditional community-based setting under the supervision of a licensed Occupational Therapy Assistant.  Focuses on occupational therapy documentation, observation skills, professionalism and activity analysis.  Two classroom, three clinical hours per week."},
    {"id":"OTA 1312","title":"Occupational Therapy & Human Development","description":"Exploration of human development from conception to death including developmental stages, theories supporting human development, occupational engagement throughout the lifespan and cultural impact on human development."},
    {"id":"OTA 1313","title":"Occupational Therapy & Adults with Neurological Dysfunction","description":"Effect of the features of major neurological diseases, injuries and disorders on adult occupational performance.  Neurological  Dysfunction theories, models of practice, frames of reference that provide the foundation of Occupational Therapy.  Screening and evaluation of occupational performance affected by neurological dysfunction.  Includes use of occupations for the purpose of assessment, specified screening tools, assessments, observation, checklists, histories and interviews with clients and/or their family members or caregivers."},
    {"id":"OTA 1314","title":"Occupational Therapy & Neurological Dysfunction Lab","description":"Focus on neurological dysfunction intervention to increase independence in areas of occupation.  Screening and evaluation of occupational performance affected by neurological dysfunction.  Includes use of occupations for purpose of neurological dysfunction assessments and interventions.  Four lab hours per week."},
    {"id":"OTA 1315","title":"Therapeutic Use of Self","description":"Personal development, including development of the self as an effective therapeutic tool, exploration of values, personal and cultural attitudes, sensitivity to cultural differences, group process and ethical decision making.  One classroom, two lab hours per week."},
    {"id":"OTA 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"OTA 2412","title":"Occupational Therapy Assistant & Pediatrics","description":"Effect of the features of major diseases, injuries, and disorders that affect pediatric population&apos;s occupational performance.  Pediatric Function and Dysfunction theories, models of practice, frames of reference that provide the foundation of Occupational Therapy.  Screening and evaluation of occupational performance affected by dysfunction in the pediatric population.  Includes use of occupations for the purpose of assessment, specified screening tools, assessments, observation, checklists, histories and interviews with clients and/or their family members or caregivers.  Pediatric medical and educational practice settings."},
    {"id":"OTA 2413","title":"Occupational Therapy Assistant & Pediatrics Lab","description":"Focus on intervention to increase independence in areas of occupation with pediatric population.  Screening and evaluation of occupational performance affected by dysfunction.  Includes use of occupations for purpose of assessments and interventions with infants, children and adolescents.  Four lab hours per week."},
    {"id":"OTA 2414","title":"Occupational Therapy Assistant & Psychosocial Dysfunction","description":"Effect of the features of major psychosocial diseases and disorders on occupational performance.  Psychosocial dysfunction theories, models of practice, frames of reference that provide the foundation of occupational therapy.  Screening and evaluation of occupational performance affected by psychosocial dysfunction.  Includes use of occupations for the purpose of assessment, specified screening tools, assessments, observation, checklists, histories and interviews with clients and/or their family members or caregivers."},
    {"id":"OTA 2415","title":"Occupational Therapy Assistant & Psychosocial Dysfunction Lab","description":"Focus on psychosocial dysfunction intervention to increase independence in areas of occupation.  Screening and evaluation of occupational performance affected by psychosocial dysfunction.  Includes use of occupations for purpose of psychosocial dysfunction assessments and interventions.  Role of the Occupational Therapy Assistant in a variety of mental health settings and in the therapeutic group process.  Four lab hours per week."},
    {"id":"OTA 2416","title":"Occupational Therapy Assistant Level 1 Fieldwork","description":"Level 1 Fieldwork integrates the academic classroom instruction and clinical experiences in an Occupational Therapy setting(s) under the direct supervision of an Occupational Therapy practitioner and coordinated by the Academic Fieldwork Educator. Two classroom, seven practicum hours per week."},
    {"id":"OTA 2511","title":"Occupational Therapy Assistant Level 2 Fieldwork A","description":"First of two full-time 8-week assignments of advanced clinical experience under the supervision of a licensed Occupational Therapy Practitioner which must be completed before the student is eligible for national certification examination.  Fourteen practicum hours per week."},
    {"id":"OTA 2512","title":"Occupational Therapy Assistant Level 2 Fieldwork B","description":"Second of two 8-week full-time assignments of advanced clinical experience under the supervision of a licensed Occupational Therapy Practitioner which must be successfully completed before the student is eligible for national certification examination.  Fourteen practicum hours per week."},
    {"id":"OTA 2523","title":"Occupational Therapy Assistant Clinical Issues A","description":"Facilitation of increased practical knowledge and problem-solving skills to address professional, ethical, legal and social issues within clinical practice."},
    {"id":"OTA 2524","title":"Occupational Therapy Assistant Clinical Issues B","description":"Facilitation of discussion on issues related to the transition from student to entry-level Occupational Therapy Assistant.  Advanced exploration of legal and ethical issues related to occupational therapy practice."},
    {"id":"PAR 1101","title":"Introduction to Legal Studies","description":"The paralegal's role in the legal system is introduced.  The function of case law, statutes, administrative regulations, constitutions and court rules are explored."},
    {"id":"PAR 1102","title":"Legal Technology","description":"This course develops students' skills in introduction to the technology used in law firm environments."},
    {"id":"PAR 1103","title":"Litigation","description":"Introduction to the civil system, courts, torts and civil pleadings. The student will develop skills in drafting basic pleadings.  Note:  This course must be taken concurrently with PAR 1101 and PAR 1102."},
    {"id":"PAR 1201","title":"Legal Research & Writing","description":"This course develops student skills in researching Ohio's legal resources, writing legal memos and letters and using the Ohio Manual of Citations.  Note:  This may be taken concurrently with PAR 1103."},
    {"id":"PAR 1202","title":"Advanced Legal Technology","description":"This course develops students' skills in use of software in a legal environment, including spreadsheets, databases, data backup media, group calendaring and research on the Internet."},
    {"id":"PAR 1203","title":"Advanced Litigation","description":"The paralegal&apos;s role in the litigation process, from pleadings through discovery and trial. This course develops student paralegal skills in drafting pleadings, use of discovery tools and litigation software."},
    {"id":"PAR 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"PAR 2301","title":"Advanced Legal Research & Writing","description":"Develops skills introduced in Legal Research &amp; Writing. This course covers research in federal and national legal resources, writing trial briefs, writing research memoranda and letters and using a citations manual."},
    {"id":"PAR 2302","title":"Family Law","description":"This course develops student paralegal skills in preparation of documents in a domestic relations practice, including pleadings and forms."},
    {"id":"PAR 2303","title":"Probate Law","description":"Summary and full administration of probate estates, adoptions, guardianships, name changes and minor settlements."},
    {"id":"PAR 2401","title":"Legal Studies Internship","description":"Application of skills in a legal environment. Professionalism, resumes and interviewing skills. One classroom, seven practicum hours per week."},
    {"id":"PAR 2504","title":"Bankruptcy Law","description":"This course develops student paralegal skills in bankruptcy data collection, preparation of bankruptcy schedules, and online filing of bankruptcy cases.  This course develops student paralegal skills in bankruptcy data collection, preparation of bankruptcy schedules and online filing of bankruptcy cases."},
    {"id":"PAR 2507","title":"Legal Interviewing Skills","description":"This course develops student paralegal skills in client and witness interviews, including using interpersonal skills and identifying ethical concerns."},
    {"id":"PAR 2510","title":"Criminal Law","description":"This course develops students paralegal skills in analyzing Ohio criminal law, Ohio criminal procedure, and Ohio juvenile law and preparing documents utilized in both criminal and juvenile trial proceedings."},
    {"id":"PAR 2511","title":"Online Legal Research","description":"Advanced use of computer-assisted research to find federal and state statutory and case law. Analysis of law. Students use LEXIS-NEXIS, and free legal resources."},
    {"id":"PED 1101","title":"Introduction to Swimming","description":"Introduction to Swimming introduces elementary aquatic skills, basic swimming strokes and personal water safety for the entry-level student. This course includes active participation and academics. Two lab hours per week."},
    {"id":"PED 1102","title":"Fitness Swimming","description":"Fitness Swimming provides exercise for the intermediate and advanced swimmer. The course emphasizes stroke refinement, distance swimming and training techniques while improving cardiorespiratory endurance. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 1103","title":"Beginning Scuba Diving","description":"Beginning Scuba Diving requires the students to develop physical skills and an understanding of diving physics and physiology, safe use of diving equipment, communications, safety rules and problem management. Open-water certification is available at additional cost. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 1104","title":"Advanced Scuba Diving","description":"Advanced Scuba Diving requires the students to further develop their diving skills. Fundamental skills will be reviewed. Additional specialty skills will be included. Advanced Open-Water certification is available at additional cost. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1105","title":"Lifeguard Training","description":"Successful completion of this course results in the following certifications: Lifeguard Training/First Aid, CPR/AED for the Professional Rescuer and Bloodborne Pathogens: Preventing Disease Transmission.  Two classroom, two lab hours per week."},
    {"id":"PED 1106","title":"Water Safety Instructor","description":"Course covers methods and techniques of teaching swimming.  Students who successfully fulfill course requirements are eligible for the American Red Cross Water Safety Instructor and Basic Water Rescue certifications.  Two classroom, two lab hours per week."},
    {"id":"PED 1107","title":"Golf","description":"Golf introduces students to the following topics: history, rules and regulations of the game, etiquette, club selection, techniques, swing analysis and playing strategies. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1109","title":"Bowling","description":"Bowling introduces students to the following topics: history, rules and regulations of the game, etiquette, ball selection, techniques and game strategies. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1111","title":"Volleyball","description":"Volleyball introduces students to the following topics: history, rules and regulations of the game, etiquette, skills and team strategies. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 1113","title":"Basketball","description":"Basketball introduces the students to the following topics: history, rules and regulations of the game, etiquette, skills and team strategies. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1115","title":"Tennis I","description":"Tennis I is designed to offer the fundamentals of tennis. Basic rules and regulations of singles and doubles, stroke technique, parts of the court along with singles and doubles playing strategies will be the focus. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1116","title":"Tennis II","description":"Tennis II is designed for students who already have the fundamentals of tennis. Strategies and training techniques are emphasized to take the player to the next level of competition. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 1117","title":"Social Dance","description":"Social Dance introduces the fundamentals of popular social dances that include ballroom, country western and line. Basic skills, styles, techniques and movement patterns will be emphasized. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1119","title":"Martial Arts","description":"Martial Arts include Judo, Karate and other forms.  The philosophy and skills related to these arts will be introduced.  Mental and physical fundamentals, skills and techniques will be emphasized. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1121","title":"Fencing","description":"Fencing introduces the students to the following topics:  history, rules and regulations of the sport, etiquette, skills, maneuvers and strategies.  This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1201","title":"Physical Fitness","description":"Physical Fitness is designed to offer a variety of fitness components; cardiorespiratory endurance, muscular strength and endurance and flexibility. Sections will focus on selected fitness programming; Nia Technique, Boot Camp, TRX Training, Functional Fitness and other programs. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1203","title":"Strength Training","description":"Strength Training introduces basic and intermediate strategies to develop an appropriate individual strength training program. Emphasis will be placed on understanding basic program design, implementing and execution of basic strength exercises. This course will include both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1205","title":"Flexibility Fitness","description":"Flexibility Fitness introduces basic concepts for a safe and effective flexibility program. Proper stretching techniques along with exercises that improve flexibility, strength, balance and relaxation will be incorporated. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1207","title":"Yoga","description":"Yoga introduces the student to the philosophy and principles of yoga including coordination, strength, flexibility and meditation/relaxation. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1209","title":"Pilates","description":"Pilates is a specific body conditioning method. Pilates strengthens muscles, improves posture, balance and flexibility, and concentrates on training the mind and body to work together toward the goal of overall health and fitness. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 1211","title":"Aquatic Exercise","description":"Aquatic Exercise provides cardiorespiratory endurance, muscular strength, endurance and flexibility workouts in the water. A variety of equipment and programming will be introduced to enhance the workout. Both swimmers and nonswimmers can participate in this course. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 1213","title":"Aerobic Conditioning","description":"Aerobic conditioning is an energetic class composed of fitness techniques for cardiorespiratory endurance, muscular strength and endurance, coordination and agility. The class provides a workout for all fitness levels. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 1215","title":"Group Strength Training","description":"Group Strength Training incorporates both strength and endurance exercises using various types of equipment. Choreographed routines that incorporate different types of lifting techniques are performed. Emphasis is placed on correct body placement and proper technique. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1217","title":"Fitness Walking & Conditioning","description":"Fitness Walking and Conditioning is designed to introduce the proper walking techniques used for fitness.  Programming will incorporate interval training, strengthening and stretching exercises along with monitoring of intensity. Various types of equipment will be introduced to enhance the workout experience. This course includes both active participation and academics.  Two lab hours per week."},
    {"id":"PED 1219","title":"Tai Chi","description":"Tai Chi is an ancient art that promotes serenity through gentle movements that connect the mind and body. The exercises are performed in a series of postures or movements in a slow graceful manner. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 1221","title":"Core Conditioning","description":"Core Conditioning provides fitness techniques with an emphasis on the deepest muscles of the trunk, improving posture and coordination through stabilization and strength with a cardiorespiratory endurance component. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 1223","title":"Indoor Group Cycling","description":"Indoor Group Cycling provides a group cardiorespiratory endurance workout. Proper seat setup, cycling techniques and body positioning will be emphasized. This course includes both active participation and academics. Two lab hours per week."},
    {"id":"PED 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"PED 6101","title":"Non-Credit Beginning Swimming","description":"Non-Credit beginning swimming provides an opportunity for beginners to work individually on their aquatic skills along with refining basic swimming strokes. This class does not include instruction."},
    {"id":"PED 6102","title":"Non-Credit Intermediate Swimming","description":"Non-Credit intermediate swimming provides an opportunity for intermediate and advanced swimmers to individually work on their stroke refinement, distance swimming and training techniques. This class does not include instruction.  The official 25 yard lap lanes will be used in this class."},
    {"id":"PED 6107","title":"Non-Credit Golf","description":"Non-credit golf provides an opportunity to work on swing techniques and club selection."},
    {"id":"PED 6111","title":"Non-Credit Volleyball","description":"Non-Credit Volleyball allows students to work on skills and team strategies."},
    {"id":"PED 6115","title":"Non-Credit Beginning Tennis","description":"Non-Credit Beginning Tennis introduces the fundamentals of tennis including basic rules of singles and doubles, stroke technique, parts of the court and basic playing strategies."},
    {"id":"PED 6116","title":"Non-Credit Tennis","description":"Non-Credit Tennis is designed for individuals to have an opportunity to play Singles, Doubles and/or French Doubles in a structured environment.  Participants must know tennis rules."},
    {"id":"PED 6117","title":"Non-Credit Social Dance","description":"Non-credit social dance provides an opportunity to participate in popular social dances that include ballroom, country western and line."},
    {"id":"PED 6119","title":"Non-Credit Martial Arts","description":"Non-Credit Martial Arts include Judo, Karate and other forms. The philosophy and skills related to these arts will be introduced. Mental and physical fundamentals, skills and techniques will be emphasized."},
    {"id":"PED 6121","title":"Non-Credit Beginning Fencing","description":"Non-Credit Beginning Fencing introduces the fundamentals of fencing including history, rules of the sport, etiquette, skills, maneuvers and strategies."},
    {"id":"PED 6123","title":"Non-Credit Beginning Pickleball","description":"Non-Credit Beginning Pickleball introduces the fundamentals of pickleball including basic rules of singles and doubles, stroke technique, parts of the court and basic playing strategies."},
    {"id":"PED 6201","title":"Non-Credit Physical Fitness","description":"Non-credit physical fitness is designed to offer a variety of fitness activities."},
    {"id":"PED 6203","title":"Non-Credit Strength Training","description":"Non-Credit Strength Training  is designed for individuals to have an opportunity to utilize the weight room in a supervised environment. Participants must have a basic understanding of strength training."},
    {"id":"PED 6205","title":"Non-Credit Flexibility Fitness","description":"Non-Credit Flexibility Fitness introduces basic concepts for a safe and effective flexibility program. Proper stretching techniques along with exercises that improve flexibility, strength, balance and relaxation will be incorporated."},
    {"id":"PED 6207","title":"Non-Credit Yoga","description":"Non-credit yoga provides an opportunity to work on coordination, strength, flexibility and meditation/relaxation."},
    {"id":"PED 6209","title":"Non-Credit Pilates","description":"Non-credit Pilates is a specific body conditioning method. Pilates strengthens muscles, improves posture, balance and flexibility, and concentrates on training the mind and body to work together toward the goal of overall health and fitness."},
    {"id":"PED 6211","title":"Non-Credit Aquatic Exercise","description":"Non-credit Aquatic Exercise provides cardiorespiratory endurance, muscular strength, endurance and flexibility workouts in the water. A variety of equipment and programming will be introduced to enhance the workout. Both swimmers and nonswimmers can participate in this course."},
    {"id":"PED 6213","title":"Non-Credit Aerobic Conditioning","description":"Non-credit Aerobic Conditioning is an energetic class composed of fitness techniques for cardiorespiratory endurance, muscular strength and endurance, coordination and agility. The class provides a workout for all fitness levels."},
    {"id":"PED 6215","title":"Non-Credit Group Strength Training","description":"Non-Credit Group Strength Training incorporates both strength and endurance exercises using various types of equipment. Choreographed routines that incorporate different types of lifting techniques are performed. Emphasis is placed on correct body placement and proper technique."},
    {"id":"PED 6217","title":"Non-Credit Fitness Walking & Conditioning","description":"Non-Credit Fitness Walking & Conditioning is designed for individuals to have an opportunity to utilize the indoor track in a supervised environment."},
    {"id":"PED 6219","title":"Non-Credit Tai Chi","description":"Tai Chi is an ancient art that promotes serenity through gentle movements that connect the mind and body. The exercises are performed in a series of postures or movements in a slow graceful manner."},
    {"id":"PED 6221","title":"Non-Credit Core Conditioning","description":"Non-Credit Core Conditioning provides fitness techniques with an emphasis on the deepest muscles of the trunk, improving posture and coordination through stabilization and strength with a cardiorespiratory endurance component."},
    {"id":"PED 6223","title":"Non-Credit Indoor Group Cycling","description":"Non-Credit Indoor Group Cycling provides a group cardiorespiratory endurance workout. Proper seat setup, cycling techniques and body positioning will be emphasized."},
    {"id":"PHI 2204","title":"Great Books: Philosophy","description":"Introduction to selected great books in the history of Western Philosophy. Three eras will be introduced (ancient/medieval, modern and contemporary) and studied within their respective historical contexts."},
    {"id":"PHI 2205","title":"Introduction to Philosophy","description":"Basic nature of philosophy, its relationship to physical and social sciences and theology and its value to the individual."},
    {"id":"PHI 2206","title":"Introduction to Ethics","description":"Historical inquiry into the major concepts and attitudes of moral and ethical theory in Western society, emphasizing the role of human responsibility and the conditions for making ethical judgments."},
    {"id":"PHI 2207","title":"Logic","description":"Principle elements in deductive and inductive logic. Analysis of three acts of the intellect and the laws of reasoning. Application of principles to specific cases."},
    {"id":"PHI 2208","title":"Symbolic Logic","description":"This course explores techniques in logical analysis using both philosophical and mathematical processes.  Students will focus on constructing and evaluating deductive arguments, engage in symbolic translation, recognize formal argument forms, use truth-tables to analyze statements and arguments, conduct proofs, and learn and apply the rules of sentential and predicate logic."},
    {"id":"PHI 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"PHY 1100","title":"Introduction to Physics","description":"A survey of motion, forces, energy, thermodynamics, properties of matter, electricity and magnetism for nonscience majors. Three classroom, three lab hours per week."},
    {"id":"PHY 1104","title":"Sound, Light & Modern Physics","description":"A survey of sound, light, color, atomic and nuclear physics and special relativity for nonscience majors. Three classroom, three lab hours per week."},
    {"id":"PHY 1106","title":"Physics for Technology","description":"Survey of conceptual physics for technology majors.  Topics include motion, forces, energy, electricity, magnetism, waves, sound, light, atomic structure and emission and absorption of radiation.  Two classroom, two lab hours per week."},
    {"id":"PHY 1107","title":"Lab for Physics for Technology"},
    {"id":"PHY 1110","title":"Lab for Introduction to Physics"},
    {"id":"PHY 1119","title":"Lab for Sound, Light & Modern Physics"},
    {"id":"PHY 1141","title":"College Physics I","description":"Algebra-based university-parallel sequence in mechanics, including vectors, statics, work and energy, momentum, rotational motion, elasticity, fluids and thermodynamics. Three classroom, three lab hours per week."},
    {"id":"PHY 1142","title":"College Physics II","description":"Algebra-based university-parallel course in oscillations, waves, sound, optics, electricity, magnetism and electromagnetism. Three classroom, three lab hours per week."},
    {"id":"PHY 2201","title":"General Physics I","description":"Fundamentals of mechanics including kinematics, dynamics, work and energy, momentum, oscillations, gravity, fluids, waves and sound, thermodynamics and kinetic theory, using calculus as appropriate. Four classroom, three lab hours per week."},
    {"id":"PHY 2202","title":"General Physics II","description":"Electrostatics, DC conduction and circuits, magnetism, electromagnetic induction, quantum mechanics, optics and special relativity. Calculus used extensively. Four classroom, three lab hours per week."},
    {"id":"PHY 2203","title":"Introduction to Modern Physics","description":"Introduction to the experimental and theoretical basis of 20th century ideas in physics including relativity, quantum mechanics, atomic, molecular and solid state physics, nuclear structure, particle physics and cosmology. Calculus used extensively."},
    {"id":"PHY 2207","title":"Lab for General Physics I"},
    {"id":"PHY 2208","title":"Lab for General Physics II"},
    {"id":"PHY 2210","title":"MATLAB for Scientists & Engineers","description":"Introduction to problem solving and programming using MATLAB. Topics include the MATLAB desktop, arrays, graphics, basic programming concepts and structures such as logical and relational operators, control flow statements, M files, functions and object oriented programming. Applications will be chosen from the sciences and engineering."},
    {"id":"PHY 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"PHY 2780","title":"Scientific Thought & Method","description":"Exploration of methods employed in the natural sciences primarily through an undergraduate research project designed to illustrate scientific thinking and related mathematical skills especially as they apply to physics. Intended for physics majors. Two classroom,\rtwo lab hours per week."},
    {"id":"PLS 1120","title":"American Federal Government","description":"American political system at the national level, including process of government; democratic theory and development of the U. S. Constitution; citizen participation through voting; interest groups and political parties; structure, functions and powers of legislative, executive and judicial branches; issues of civil liberties and equal rights."},
    {"id":"PLS 1232","title":"State & Local Government","description":"The study of state and local governments (with emphasis on Ohio), organizational structures of state and local governments, state constitutions, county and city charters, state and local government  powers and programs, financing, and taxation, and trends in government programs are all documented and analyzed."},
    {"id":"PLS 2200","title":"Political Life, Systems & Issues","description":"Basic political and government concepts and systems, including ideologies and comparative political systems; current political issues in Asia, Africa, Europe, Latin America, along with United States interests and policy options."},
    {"id":"PLS 2220","title":"International Relations","description":"Principles and techniques of international politics, including theories, organizations and different world perspectives."},
    {"id":"PLS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"PLS 2860","title":"Model UN/International Issues","description":"History and structure of the United Nations with an in-depth look at selected current world issues; participation in Model UN simulations and opportunity to attend the Dayton Model United Nations Conference and/or other Model UN conferences."},
    {"id":"PRL 1100","title":"Prior Learning Portfolio Development","description":"This course will engage students in the process of preparing a portfolio for a specific Sinclair course describing and documenting learning gained from prior experience. Upon completion, the portfolio may be evaluated to determine college credit.  Students may present additional portfolios for two years. See Academic Advisor for additional information."},
    {"id":"PRL 1130","title":"ATS/AIS Degree Development","description":"Development of the individual degree plan of study to be followed for successful completion of the ATS or AIS degree."},
    {"id":"PRL 2101","title":"Building & Industrial Technology","description":"Twenty (20) to thirty (30) semester hours awarded through the One-Year-Option program as a block of credit (rather than credit for specific courses) and will satisfy the technical course requirements for the Building and Industrial Technology Associate of Technical Study degree."},
    {"id":"PRL 2102","title":"Business Technology","description":"Twenty (20) to thirty (30) semester hours awarded through the One-Year-Option program as a block of credit (rather than credit for specific courses) and will satisfy the technical course requirements for the Business Technology Associate of Technical Study degree."},
    {"id":"PRL 2103","title":"Health & Allied Health Technology","description":"Twenty (20) to thirty (30) semester hours awarded through the One-Year-Option program as a block of credit (rather than credit for specific courses) and will satisfy the technical course requirements for the Health & Allied Health Technology  Associate of Technical Study degree."},
    {"id":"PRL 2104","title":"Information Technology","description":"Twenty (20) to thirty (30) semester hours awarded through the One-Year-Option program as a block of credit (rather than credit for specific courses) and will satisfy the technical course requirements for the Information Technology Associate of Technical Study degree."},
    {"id":"PRL 2105","title":"Services Technology","description":"Twenty (20) to thirty (30) semester hours awarded through the One-Year-Option program as a block of credit (rather than credit for specific courses) and will satisfy the technical course requirements for the Services Technology Associate of Technical Study degree."},
    {"id":"PRL 2106","title":"Barbering","description":"Thirty (30) semester hours awarded as a block of credit (rather than credit for specific courses) and will satisfy the technical course requirements for the Barbering Management Associate of Technical Study degree."},
    {"id":"PRL 2107","title":"Cosmetology","description":"Thirty (30) semester hours awarded as a block of credit (rather than credit for specific courses) and will satisfy the technical course requirements for the Cosmetology Management Associate of Technical Study degree."},
    {"id":"PRL 2700","title":"Prior Learning Internship","description":"Students earn credits toward degree requirements for work learning experience. Students already working may apply to use that experience to meet internship requirements. Students establish learning outcomes and prepare related reports and/or projects each term."},
    {"id":"PRL 2780","title":"Study Abroad Experience","description":"Provides a structured cross-cultural experience, including pre-departure cultural orientation, in-country immersion experience and culminating project.  Exposes students to the culture of a specific country or region outside of the U.S. which may include geography, history, religion, philosophy, literature, fine arts, anthropology, food, language and other relevant topics.  Includes a short-term study abroad experience with additional fees for travel."},
    {"id":"PRL 4000","title":"SME Work Experience","description":"This course is designed to capture, and document, work experiences for students transferring to a four-year university; or for students who are required by a company to be registered in an internship/co-op to be eligible to work. Students establish learning outcomes and prepare a reflection form upon completion of their internship experience."},
    {"id":"PSY 1100","title":"General Psychology","description":"University-parallel course covering history and systems of psychology, behavioral research methods, physiology of behavior, sensation, perception, learning, memory, consciousness, cognition, personality, lifespan development, gender, social psychology, motivation, emotion, stress, mental disorders and therapies."},
    {"id":"PSY 1160","title":"Black Psychology","description":"Multidisciplinary study of theories, cultural themes and psychological constructs used to further promote understanding of thoughts, feelings and behaviors of Black Americans."},
    {"id":"PSY 2126","title":"Stress Management","description":"Application of diverse stress management techniques. Topics covered include assertiveness, stress-related personality factors, holistic health, relaxation techniques, communication patterns, cognitive restructuring, and time management."},
    {"id":"PSY 2180","title":"Psychology of Gender","description":"An introduction to the basic theories and principles of the psychology of gender in a multicultural context with emphasis on application of social psychology principles to professional and personal awareness.  Gender perspectives are considered in a multicultural context.  Topics include gender stereotypes and social constructions, theories of gender development, biological and cognitive differences, and implications of gender for work, family, and mental and physical health."},
    {"id":"PSY 2200","title":"Lifespan Human Development","description":"Research and theory concerning the physical, cognitive and social development of a person from conception to death, including prenatal and child development, adolescence, adult life crises, marriage, family, work, leisure and senescence."},
    {"id":"PSY 2205","title":"Child Development","description":"Research and theory concerning the physical, cognitive and psychosocial development of children from conception to puberty. Covers the impact of genetic, prenatal and environmental factors and challenges appropriate to this age range."},
    {"id":"PSY 2206","title":"Adolescent & Adult Development","description":"Research and theory concerning physical, cognitive, social and psychological development from adolescence through old age. Focus is on developmental issues such as identity development, cognitive growth and developmental tasks such as education, marriage, family, work, leisure, aging and facing death."},
    {"id":"PSY 2214","title":"Drugs & Behavior","description":"An introduction to behavioral pharmacology examining the major classes of psychoactive substances. Topics include basic principles of neuropharmacology, pharmacodynamics (drug absorption, distribution and elimination) and physiology of tolerance and dependence for each class of drugs."},
    {"id":"PSY 2217","title":"Abnormal Psychology","description":"A study of the diagnostic criteria, symptoms, causes and treatments of disorders listed in the Diagnostic and Statistical Manual of Mental Disorders, with an emphasis on current clinical research."},
    {"id":"PSY 2218","title":"Principles of Counseling","description":"An introduction to professional issues in counseling with emphasis on the development of basic interviewing and counseling skills, a survey of classic and contemporary theories and techniques of the counseling process, and a comparison of various theoretical approaches."},
    {"id":"PSY 2220","title":"Personality Psychology","description":"An introduction to personality with emphasis on principles, research and theories, including psychodynamic, ego-psychology, object relations, trait/biological, phenomenology, behavior-environmental and cognitive/self-regulation."},
    {"id":"PSY 2225","title":"Social Psychology","description":"A study of the interaction between individual and social environment within a multicultural context. Topics include: self-concept formation, attitudes, persuasion, attributions, group structure and processes, prejudice, aggression and violence."},
    {"id":"PSY 2228","title":"Industrial Organizational Psychology","description":"Introduction to the theories and practices of psychology in the workplace, including  human resource management, organizational science, and human factors engineering.  Specific topics include motivation and satisfaction, group decision making and development, leadership, workplace politics, employee selection and training, work-related stress, performance appraisal systems, and organizational improvement."},
    {"id":"PSY 2235","title":"Behavioral Science Research Methods","description":"Basic research methods for the behavioral sciences covering: correlational/descriptive and laboratory/experimental design methodology, dependent and independent variables, principles of measurement, and reading and writing scientific research reports."},
    {"id":"PSY 2236","title":"Behavioral Science Statistics","description":"Basic statistical techniques used in behavioral sciences, including descriptive and inferential statistics, frequency distributions, measures of central tendency and distribution, non-parametric statistics, hypothesis testing, tests of significance, analysis of variance and post-hoc tests."},
    {"id":"PSY 2242","title":"Educational Psychology","description":"Principles of learning and development applied to educational settings emphasizing research-supported development of effective learning in varied educational environments."},
    {"id":"PSY 2250","title":"Behavior Modification","description":"This course will provide students with knowledge of learning principles and skills required to implement basic behavioral interventions in school, home, industry, clinical, and other social settings. Operant, respondent, and cognitive-behavior modification methods are reviewed in terms of treatment interventions, self-control strategies, and improving productivity in industry."},
    {"id":"PSY 2270","title":"Psychology Service Learning","description":"This course will allow students to become involved in a field-related experience. This experience will deepen students' understanding of psychological topics and assist students in applying psychological principles to clinical settings. The specific learning outcomes and forms of evaluation will be determined by the supervising instructor and may vary with the nature of the field experience.  Seven practicum hours per week per credit hour."},
    {"id":"PSY 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"PTA 1000","title":"Introduction to Physical Therapy","description":"Purpose, philosophy, history and development of the physical therapy (PT) profession; physical therapist assistant (PTA) duties; PT/PTA relationship; essential functions; legal and ethical responsibilities and professional behaviors; function of regulatory agencies, licensing bodies and professional associations; PTA program expectations; physical therapy practice; communication basics; medical terminology."},
    {"id":"PTA 1100","title":"Professional Issues","description":"The American Physical Therapy Association and it&apos;s role in supporting physical therapists (PTs), physical therapist assistants (PTAs), and the profession; Professionalism and professional behaviors in the health care setting; Communication and interpersonal skills to build rapport and foster effective teamwork; Introduction to documentation and physical therapy specific medical terminology; Legal and ethical considerations for the practicing PTA; Scope of practice, including the roles and responsibilities of PTs versus PTAs; Introduction to interprofessional collaboration; Critical thinking and problem-solving for evidence-based practice."},
    {"id":"PTA 1120","title":"Functional Anatomy Lecture","description":"Human anatomy and clinical kinesiology with emphasis on integration of neuromusculoskeletal anatomy, physiology, physics principles and biomechanics in relationship to human movement."},
    {"id":"PTA 1125","title":"Functional Anatomy Lab","description":"Application of human anatomy and clinical kinesiology with emphasis on integration of neuromusculoskeletal anatomy, physiology, physics principles and biomechanics in relationship to human movement.  Six lab hours per week."},
    {"id":"PTA 1135","title":"Introduction to Manual Therapy","description":"Introduction to palpation and therapeutic touch; massage and manual therapy for soft tissue; patient draping; physiological effects, therapeutic applications, contraindications, soft tissue mobilization and myofascial release for the body.  Four lab hours per week."},
    {"id":"PTA 1140","title":"Introduction to Therapeutic Exercise Lecture","description":"Theory of the use of basic therapeutic exercises and functional activities."},
    {"id":"PTA 1145","title":"Introduction to Therapeutic Exercise Lab","description":"Performance of basic treatment, therapeutic exercises, and functional activities.  Six lab hours per week."},
    {"id":"PTA 1200","title":"Pathology for the Physical Therapist Assistant","description":"Recognition of pathology and clinical rationale for the appropriate therapeutic management of physiological responses in body systems associated with commonly treated pathological conditions."},
    {"id":"PTA 1215","title":"Functional Mobility","description":"Theory, clinical rationale and application of therapeutic interventions utilized in the practice of physical therapy, with emphasis on demonstration of knowledge, skillful performance and patient education related to functional mobility and gait training.  Six lab hours per week."},
    {"id":"PTA 1220","title":"Neuropathology","description":"Structure and function of the nervous system; recognition of pathology and clinical rationale for the appropriate therapeutic management of commonly treated neurological diseases/conditions."},
    {"id":"PTA 1230","title":"Orthopedic Principles & Application Lecture","description":"Theory and clinical rationale for the use of common tests, measures, data collection procedures, basic treatment, therapeutic exercises, and functional activities for common orthopedic and musculoskeletal diagnoses seen in a general population."},
    {"id":"PTA 1235","title":"Orthopedic Principles & Application Lab","description":"Application and performance of common tests, measures, data collection procedures, basic treatment, therapeutic exercises, and functional activities for common orthopedic and musculoskeletal diagnoses seen in a general population.  Nine lab hours per week."},
    {"id":"PTA 1245","title":"Clinical Assessment for the Physical Therapist Assistant","description":"Theory, clinical rationale, application and performance of common tests, measures, and data collection procedures utilized in the practice of physical therapy.  Six lab hours per week."},
    {"id":"PTA 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"PTA 2305","title":"Neuromuscular Rehabilitation","description":"Use of therapeutic interventions for neurological and pediatric pathologies.  Six lab hours per week."},
    {"id":"PTA 2315","title":"The Medically Complex Patient","description":"Theory and application of physical therapy treatment techniques for more complex and specialized diagnoses across the lifespan including, but not limited to, cardiovascular, pulmonary, and multisystem disorders; recognition of psychosocial concerns associated with aging and end-of-life.  Two lab hours per week."},
    {"id":"PTA 2325","title":"Modalities","description":"Application of commonly used passive and mechanical physical agents, with emphasis on safe application of the treatment intervention. Four lab hours per week."},
    {"id":"PTA 2330","title":"Seminar for Clinical Practicum I","description":"Integration of didactic and clinical skills and their application of principles in the clinical setting including, but not limited to, concepts related to billing, insurance, and quality assurance."},
    {"id":"PTA 2335","title":"Clinical Practicum I","description":"Introductory experience in the clinical setting under the supervision of a clinical instructor who is a physical therapist or physical therapist/physical therapist assistant team.  Application of theories and techniques for patient interventions, and interprofessional collaboration.  Fourteen practicum hours per week."},
    {"id":"PTA 2400","title":"Advanced Topics","description":"Exploration of specialty and niche areas of physical therapy practice and ethical situations; resume writing; utilization of current evidence to educate others.  Physiology and rational for use and application of emerging and re-emerging biophysical agents.  Two classroom, two lab hours per week."},
    {"id":"PTA 2405","title":"Modalities II","description":"Physiology and rationale for use and application of biophysical agents including, but not limited to, hydrotherapy, biofeedback, light therapies and taping.  Two lab hours per week."},
    {"id":"PTA 2430","title":"Seminar for Clinical Practicum II","description":"Integration of didactic and clinical skills and their application of principles in the clinical setting including, but not limited to, career development and lifelong learning, risk management, and defensible documentation; preparation for national licensure."},
    {"id":"PTA 2435","title":"Clinical Practicum II","description":"Advanced experience in the clinical setting under the supervision of a clinical instructor who is a physical therapist or physical therapist/physical therapist assistant team.  Application of theories and techniques for patient interventions, documentation, and interprofessional collaboration.  Fourteen practicum hours per week."},
    {"id":"RAT 1101","title":"Introduction to Radiologic Technology","description":"Introduction to the field of radiologic technology, including history, basic radiation production and safety concepts, patient communication, clinical education, ethical, legal and professional issues."},
    {"id":"RAT 1111","title":"Clinical Practicum I","description":"Orientation to clinical facility and radiology department, introduction to competency performance of radiographic procedures, image analysis, radiation protection, patient care and team work. Seven practicum hours per week."},
    {"id":"RAT 1121","title":"Radiographic Procedures I","description":"Radiographic anatomy, equipment manipulation, positioning and image analysis of the thorax, abdomen and appendicular skeleton. Four classroom, three lab hours per week."},
    {"id":"RAT 1127","title":"Lab for Radiographic Procedures I"},
    {"id":"RAT 1131","title":"Patient Care in Radiologic Technology","description":"Safety and assessment techniques related to care of the patient in a radiography department, including legal and professional aspects, elements of ethical behavior and practical dilemmas, and current infection control practices. Two classroom, three lab hours per week."},
    {"id":"RAT 1137","title":"Lab for Patient Care in Radiologic Technology"},
    {"id":"RAT 1212","title":"Clinical Practicum II","description":"Continuation of clinical competency development to include spine, skull, contrast media procedures, mobile and surgical radiography, trauma radiography,  exposure factors, radiation protection and image analysis/evaluations. Fourteen practicum hours per week."},
    {"id":"RAT 1222","title":"Radiographic Procedures II","description":"Radiographic anatomy, positioning and image analysis of the spine, skull, gastrointestinal and genitourinary systems, as well as general pharmacological principles as they pertain to radiology. Alternative positioning for trauma and mobile radiography as well as pediatric and geriatric procedures will be covered. Four classroom, three lab hours per week."},
    {"id":"RAT 1228","title":"Lab for Radiographic Procedures II"},
    {"id":"RAT 1241","title":"Radiologic Sciences I","description":"This course is designed to help the student understand the concepts of electromagnetic energy, electricity, x-ray equipment, production of x-radiation and its interaction with matter. Special radiographic equipment including digital radiography and the concepts of radiation safety and protection will also be presented."},
    {"id":"RAT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"RAT 2413","title":"Clinical Practicum III","description":"Continuation of clinical competency development to include diagnostic radiography, mobile radiography, contrast studies, pediatric, geriatric, advanced imaging, alternative shifts, radiation protection and image analysis. Twenty-one practicum hours per week."},
    {"id":"RAT 2415","title":"Radiographic Pathology","description":"Introductory concepts of disease processes and etiologies with emphasis on radiographic appearances and exposure factor compensation."},
    {"id":"RAT 2423","title":"Radiographic Procedures III","description":"Radiographic considerations related to pharmacology, advanced imaging procedures and modalities. Includes analysis of human anatomical structures using various anatomical planes. Two classroom, two lab hours per week."},
    {"id":"RAT 2429","title":"Lab for Radiographic Procedures III","description":"Imaging modalities, sectional anatomy and image analysis."},
    {"id":"RAT 2442","title":"Radiologic Sciences II","description":"Principles of digital imaging technology to include applications in exposure formulation, image quality factors and variables, and image management processes. Three classroom, two lab hours per week."},
    {"id":"RAT 2448","title":"Lab for Radiologic Sciences II"},
    {"id":"RAT 2514","title":"Clinical Practicum IV","description":"Final clinical competency experience, including total exposure to the health care system and entry-level radiographer skills, completion of all program requirements (including final competency evaluations). Twenty-one practicum hours per week."},
    {"id":"RAT 2526","title":"Capstone in Radiologic Technology","description":"Synthesis of current knowledge of radiologic technology concepts, professional development including certification and licensure requirements , ethical/legal responsibilities and transition from student to radiographer."},
    {"id":"RAT 2543","title":"Radiologic Sciences III","description":"Fundamental principles of molecular and cellular effects of x-ray interaction, along with a comprehensive study of health physics and radiation protection to include quality management and quality assurance testing of the radiographic system. Includes basic principles and applications of computed tomography."},
    {"id":"RAT 2640","title":"Computed Tomography Practicum","description":"A clinical education course that provides hands-on experience performing computed tomography procedures at an affiliate hospital and/or imaging center. Seven practicum hours per week."},
    {"id":"RAT 2641","title":"Principles of Computed Tomography","description":"Basic instrumentation and application concepts, including computer and x-ray unit components and their application to protocols for acquiring sectional images of various body systems."},
    {"id":"RAT 2643","title":"Principles of Magnetic Resonance Imaging","description":"Basic physics concepts involving the generation and construction of human planar images using magnetic resonance imaging technology."},
    {"id":"RAT 2645","title":"Magnetic Resonance Imaging Practicum","description":"A clinical education course that provides hands-on experience performing magnetic resonance imaging procedures at an affiliate hospital and/or imaging center. Seven practicum hours per week."},
    {"id":"RAT 2647","title":"Principles of Mammography","description":"Comprehensive overview of mammographic concepts and equipment, including patient care/education, communication, anatomy and physiology, epidemiology, pathology, positioning techniques, interventional procedures, image analysis, imaging media and processing, quality assurance testing and principles of exposure."},
    {"id":"RAT 2649","title":"Mammography Practicum","description":"A variable credit clinical education course that provides hands-on experience performing mammographic procedures and quality assurance testing at an affiliate hospital and/or imaging center. Seven practicum hours per week for each semester credit hour."},
    {"id":"REL 1111","title":"Eastern Religions","description":"Introduction to Far Eastern religions and cultural traditions, including beliefs, practices, stories and rituals, and historical context."},
    {"id":"REL 1112","title":"Western Religions","description":"Introduction to Western religions and cultural traditions, including beliefs, practices, stories, rituals and historical context."},
    {"id":"REL 1135","title":"American Religious Movements","description":"Examination of the history of unique American religious movements and their impact on our culture and thought."},
    {"id":"REL 2204","title":"Great Books: The Bible & Western Culture","description":"An exploration of how and why the Bible is viewed as a great book. Both the Old and New Testaments will be explored in their respective historical contexts."},
    {"id":"REL 2255","title":"People & Religion","description":"Overview of the rich diversity of human religiosity and the key beliefs, practices, stories and rituals that serve to connect humans to the sacred. Special attention to unique individuals within each religious tradition. A comparative look at religion in our society."},
    {"id":"REL 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"RES 1101","title":"Real Estate Principles","description":"This course develops skills in areas encompassed in the real estate industry, including the market, investment, brokerage, contractual and property rights, as they affect both the investor and the purchaser. Agency law, fair housing and environmental issues as they concern the field will also be explored. This course is part of Sinclair’s Ohio Real Estate Sales Associate pre-licensure program. Successful completion of the courses in Sinclair’s Ohio Real Estate Sales Associate pre-licensure program meets the educational requirement for you to sit for the Ohio Real Estate Salesperson Examination only (further state requirements must also be satisfied). Sinclair’s pre-licensure program is not intended to meet requirements for license examination in any other state."},
    {"id":"RES 1102","title":"Real Estate Abstracting","description":"Examine how recorded documents affect real estate, terms used in abstracting and the function of public offices in the process. Additionally, demonstrate correct procedures in title search and in using indices records to determine ownership, outstanding interests and rights in interests."},
    {"id":"RES 1201","title":"Real Estate Law","description":"Explanation of  the legal phases of a realty transaction. Examination of types of estates in land, co-ownership, mortgages, Ohio license law, landlord/ tenant law and legal factors in financing. This course is part of Sinclair’s Ohio Real Estate Sales Associate pre-licensure program. Successful completion of the courses in Sinclair’s Ohio Real Estate Sales Associate pre-licensure program meets the educational requirement for you to sit for the Ohio Real Estate Salesperson Examination only (further state requirements must also be satisfied). Sinclair’s pre-licensure program is not intended to meet requirements for license examination in any other state."},
    {"id":"RES 1301","title":"Real Estate Finance","description":"Exploration of the institutions, methods, instruments and procedures involved in the financing of real estate, mortgage market and effects of government monetary/ fiscal policy, the requirements for loan application, loan closing process, and defaults and foreclosures. This course is part of Sinclair’s Ohio Real Estate Sales Associate pre-licensure program. Successful completion of the courses in Sinclair’s Ohio Real Estate Sales Associate pre-licensure program meets the educational requirement for you to sit for the Ohio Real Estate Salesperson Examination only (further state requirements must also be satisfied). Sinclair’s pre-licensure program is not intended to meet requirements for license examination in any other state."},
    {"id":"RES 1302","title":"Real Estate Investment: Analysis & Financing","description":"An analytical approach to investment in real estate. Analysis and financing is emphasized. The use of leverage, tax considerations, appraisal, internal rate of return, acquisitions and exchanges. Case studies are used to provide examples of investment analysis techniques.  Completion of MAT 1120 is strongly encouraged prior to attempting this course."},
    {"id":"RES 1401","title":"Real Estate Appraisal","description":"Methodology of neighborhood, sites and building analysis, three basic techniques of appraising and the theory underlying these techniques. This course is part of Sinclair’s Ohio Real Estate Sales Associate pre-licensure program. Successful completion of the courses in Sinclair’s Ohio Real Estate Sales Associate pre-licensure program meets the educational requirement for you to sit for the Ohio Real Estate Salesperson Examination only (further state requirements must also be satisfied). Sinclair’s pre-licensure program is not intended to meet requirements for license examination in any other state."},
    {"id":"RES 1402","title":"Property Management","description":"Management of residential, business, and commercial properties. Topics presented are real estate taxes, public relations, utilities, sustainability, taxes, leasing, accounting and insurance."},
    {"id":"RES 2170","title":"Real Estate Internship","description":"Students will earn credits towards a degree requirement for work learning experience. Students establish learning outcomes and prepare related reports and/or projects each term."},
    {"id":"RES 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"RES 2401","title":"Real Estate Capstone","description":"Apply knowledge and practice skills acquired in real estate courses concerning principles, law, finance, appraisal, investing and property management through the use of case studies, simulations and role playing."},
    {"id":"RET 1100","title":"Introduction to Respiratory Care","description":"Respiratory Care as a profession to include standards of practice, regulating agencies, ethics and legal issues, education and program requirements, communication in health care and areas of clinical focus and employment outlook for a respiratory therapist."},
    {"id":"RET 1101","title":"Respiratory Care Fundamentals I","description":"Respiratory care theory, physical assessment, equipment and skill development of procedures required for clinical practice, including vital signs, isolation precautions, body mechanics, respiratory vitals, airway management, oxygen therapy, humidity and aerosol therapy, medicinal therapy, bronchoscopy and charting. Four classroom, three lab hours per week."},
    {"id":"RET 1102","title":"Lab for Respiratory Care Fundamentals I"},
    {"id":"RET 1124","title":"Cardiopulmonary Pharmacology I","description":"Actions, effects, dosages and indications for drug classes commonly used to treat pulmonary and cardiovascular diseases."},
    {"id":"RET 1125","title":"Respiratory Care Sciences","description":"Advanced study of adult lung, heart and renal anatomy and physiology, including: ventilation, pulmonary mechanics, diffusion, gas transport, cardiac function and pulmonary perfusion, acid-base balance and interpretation, control mechanisms and physiological stressors; microbiology and infection control methods; emphasis on application/integration of respiratory sciences to patient scenarios."},
    {"id":"RET 1201","title":"Respiratory Care Fundamentals II","description":"Respiratory care theory, equipment and skill development of procedures required for clinical practice, including fluidics, hyperinflation therapy, Non-Invasive Ventilation (NIV), bronchopulmonary hygiene therapy, arterial blood gas puncture and analysis, pulse oximetry, electrocardiographs (ECGs), capnography, home care, cardiopulmonary rehabilitation and smoking cessation techniques. Four classroom, three lab hours per week."},
    {"id":"RET 1202","title":"Lab for Respiratory Care Fundamentals II"},
    {"id":"RET 1203","title":"Respiratory Care Clinic I","description":"Acquire and evaluate clinical data, initiate prescribed respiratory care treatments, manage life support activities, evaluate and monitor patient responses to therapy and modify the prescribed therapy to achieve the desired therapeutic objectives. Fifteen directed practice hours per week."},
    {"id":"RET 1205","title":"Cardiopulmonary Disease Processes","description":"Diseases and disorders affecting the cardiopulmonary systems emphasizing diagnosis, selection and implementation of therapeutic modalities and the role of the respiratory care practitioner in treatment."},
    {"id":"RET 1301","title":"Respiratory Care Fundamentals III","description":"Diseases and disorders affecting the cardiopulmonary systems emphasizing diagnosis, selection and implementation of therapeutic modalities and the role of the respiratory care practitioner in treatment."},
    {"id":"RET 1303","title":"Respiratory Care Clinic II","description":"Enhance clinical skills by performing prescribed therapy, evaluating clinical data, assessing patient status and observing/performing diagnostic studies, rehabilitation, hyperbaric oxygen therapy and patient education in multiple health care settings.  Seven practicum hours per week."},
    {"id":"RET 2101","title":"Critical Care I","description":"Assessment and treatment of patients in respiratory failure to include the following categories:  airway management and emergencies, physiology and treatment of oxygenation/ventilation failure, physiology of lung mechanics, ventilator classification and management, positive pressure ventilation including volume and pressure control ventilation modes, ventilator troubleshooting, weaning from mechanical ventilation, and the application of ventilator graphic interpretation in the management of the mechanically ventilated patient. Four classroom, three lab hours per week."},
    {"id":"RET 2102","title":"Lab for Critical Care I","description":"Lab to accompany RET 2101"},
    {"id":"RET 2103","title":"Respiratory Care Clinic III","description":"Enhance clinical skills by performing prescribed mechanical ventilation within the critical care environment, diagnostic studies and evaluating clinical data on the adult and pediatric patient.  Fifteen directed practice hours per week."},
    {"id":"RET 2124","title":"Cardiopulmonary Pharmacology II","description":"Actions, effects, dosages and indications for drug classes commonly used to treat pulmonary and cardiovascular disease."},
    {"id":"RET 2201","title":"Critical Care II","description":"Assessment, management, and treatment of critically-ill patients to include the following categories:  Management of neonatal/pediatric mechanical ventilation, advanced modes of ventilation, non-conventional oxygenation and ventilation strategies, diagnostics, special procedures for the respiratory therapist in the critical care setting, critical conditions, nutritional considerations, transport, and home care ventilation/disease management. Three classroom, three lab hours per week."},
    {"id":"RET 2202","title":"Lab for Critical Care II","description":"Lab to accompany RET 2201"},
    {"id":"RET 2203","title":"Respiratory Care Clinic IV","description":"Apply adult, pediatric and neonatal clinical skills, including respiratory care procedures, diagnostics and mechanical ventilation within the critical care environment. Ten directed practice hours per week."},
    {"id":"RET 2204","title":"Respiratory Care Clinic V","description":"Summative skills performance to include initiating prescribed respiratory care treatments, managing life-support activities, evaluating patient responses to such therapy and modifying therapy, performing diagnostic studies, rehabilitation, hyperbaric oxygen therapy, providing education for in-patient and home care environment, performing mechanical ventilation on adults, pediatrics and neonates and completing a communication skills assessment. Seven practicum hours per week."},
    {"id":"RET 2220","title":"Respiratory Care Emergency Preparedness","description":"Advanced resuscitation techniques for the adult and pediatric patient with additional focus on disaster and epidemic preparedness/treatment and transport of the critically ill patient. One classroom, three lab hours per week."},
    {"id":"RET 2222","title":"Lab for Respiratory Care Emergency Preparedness"},
    {"id":"RET 2250","title":"Pediatrics & Neonatology","description":"Development of the fetus, anticipation of high-risk pregnancies and care of the newborn infant, emphasizing neonatal and pediatric physiology and diseases, and pertinent diagnostics."},
    {"id":"RET 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"RET 2301","title":"Respiratory Care of the Newborn I","description":"Orientation to neonatal respiratory care including history, fetal development and preparation for delivery for the practicing Respiratory Therapist."},
    {"id":"RET 2302","title":"Respiratory Care of the Newborn II","description":"Orientation to initial steps of resuscitation, respiratory support devices in the delivery room and strategies with special conditions in the delivery room. Course is designed for the practicing Respiratory Therapist.  Note: RET 2301 is encouraged, but not required for RET 2302."},
    {"id":"RET 2303","title":"Respiratory Care of the Newborn III","description":"Advanced strategies for the Respiratory Therapist in support of complex neonatal conditions in the NICU. Note: RET 2301 and RET 2302 are encouraged, but not required for RET 2303."},
    {"id":"RET 2501","title":"Respiratory Care of the Newborn","description":"Orientation to neonatal respiratory care including history, fetal development, stabilization, evidence-based practices, and multi-disciplinary approaches.  Includes respiratory devices employed for ongoing support of term and preterm infant."},
    {"id":"SCC 1100","title":"Life Skills I","description":"This course will focus on how to be a successful college student, self-care, time management, and study skills."},
    {"id":"SCC 1101","title":"First Year Experience","description":"This course is designed to help new students make a successful transition to Sinclair Community College. Topics include college resources; academic, career and personal services available through Sinclair; learning styles; the learning process; financial responsibility; stress and wellness; and computer literacy through eLearn and library resources."},
    {"id":"SCC 1200","title":"Life Skills II","description":"This course will focus on career exploration, workplace readiness, interview readiness, resume development, an internship introduction, and a service learning project."},
    {"id":"SCC 1300","title":"Work Skills I","description":"The purpose of this course is to facilitate students with attaining the knowledge and skills needed to be successful in the workplace. This course will focus on attendance  and punctuality, self-presentation, positive attitude, honesty, work ethic and perseverance, passion, respect, oral communication, written communication, and professionalism."},
    {"id":"SCC 1400","title":"Work Skills II"},
    {"id":"SCC 2100","title":"Inclusive Internship","description":"The purpose of this inclusive internship course is to provide students with an opportunity to gain work experience through an on- or off-campus employer. The internship experiences will be established in an inclusive environment with both non-disabled work colleagues and peer mentors."},
    {"id":"SCC 6015","title":"College & Career Advancement for Writing","description":"This non-credit course is intended to help students improve their score on the WritePlacer assessment and prepare them for future college courses. Students will practice writing for college and explore careers and majors while developing job skills. At the end of this course, students will have the opportunity to retake the WritePlacer Assessment to determine next steps."},
    {"id":"SCC 6050","title":"College & Career Advancement for Math","description":"This non-credit course is intended to help students improve their basic math skills to improve their score on the ALEKS Assessment. Students will also be able to practice math skills that will help them succeed in Sinclair math courses and improve skills that are applicable to use in life and career situations. At the end of this course, students will have the opportunity to retake the ALEKS Assessment to determine next steps."},
    {"id":"SCC 6102","title":"Supplemental Instruction for Business Mathematics","description":"This course is an academic assistance program that is designed to help improve grades in Business Mathematics (MAT 1120), which is a historically difficult course.  Through informal review sessions, students will compare notes, discuss lecture materials and homework assignments, develop organizational tools, and predict test items.  Students will learn how to integrate course content and study skills while working collaboratively with their peers and an experienced facilitator."},
    {"id":"SCC 6103","title":"Supplemental Instruction for Introduction to Software Applications","description":"This course is an academic assistance program that is designed to help improve grades in Introduction to Software Applications (BIS 1120), which is a historically difficult course.  Through informal review sessions, students will compare notes, discuss lecture materials and homework assignments, develop organizational tools, and predict test items.  Students will learn how to integrate course content and study skills while working collaboratively with their peers and an experienced facilitator."},
    {"id":"SOC 1101","title":"Introduction to Sociology","description":"A critical analysis of contemporary American society with review of major sociological theories, research  methods, culture, socialization, groups, social structure, social institutions, deviance, social inequalities, social processes and social change."},
    {"id":"SOC 1108","title":"Appalachian Families","description":"A critical and analytical examination of the Appalachian experience from the 1700s through the present day with emphasis on the Appalachian family (both rural and urban) as a varied and complex social system, including an examination of the diverse populations within the Appalachian region."},
    {"id":"SOC 1115","title":"Sociology of Marriage & Family","description":"This course is a sociological examination of theoretical perspectives on the institution of family.  Topics include the historical context of the family, the role of marriage and family in society, family formation, socialization, divorce, parenting, family issues, family throughout the life course and social policy.  Variations in family types and lifestyles among diverse groups worldwide are examined."},
    {"id":"SOC 1117","title":"Popular Culture","description":"Exploration of contemporary popular culture and popular culture in a historical context: examination of influence of popular culture on the development of a unique American society and culture through media, music, sports, entertainment and/or food."},
    {"id":"SOC 1145","title":"Introduction to Cultural Anthropology","description":"An examination of what is meant by culture and a review of the various theories and methods in Cultural Anthropology. Includes a comparison of the similarities and differences among world cultures as well as comparative analysis of family organization, religious beliefs, educational systems, economics and governmental systems."},
    {"id":"SOC 1160","title":"Sociology of Aging","description":"Orientation to the sociological, biological and psychological dimensions of the aging process and society's response to its older members and social concerns. Examination of social forces that impact the aging process."},
    {"id":"SOC 1216","title":"Sociology of Human Sexuality","description":"A critical analysis of the interrelatedness of sociological, cultural, biological, psychological and religious factors influencing attitudes towards sexuality."},
    {"id":"SOC 1219","title":"Global Poverty","description":"This course focuses on the issue of global poverty. It includes a comparison of relative poverty and absolute poverty. This course specifically examines three areas of global poverty through both a sociological and interdisciplinary perspective(including a comparison of western and nonwestern perspectives). The three areas include an examination of the many hypotheses and theories about the causes of global poverty, an examination of the consequences of poverty at the micro, meso and macro levels, and an examination of the theories and solutions to reduce/solve global poverty."},
    {"id":"SOC 2130","title":"Sociology of Family Violence","description":"Sociological explanation of the nature and scope of family violence: child abuse, spousal abuse, elder abuse, sexual abuse, neglect and emotional abuse.  Analysis of social and legal implications; intervention and prevention will be explored."},
    {"id":"SOC 2205","title":"Social Problems","description":"An introduction to social problems facing large, complex societies using sociological theories and methodology to examine causes, treatments and solutions. Among the topics discussed are: mental illness, health care, alcohol and drug abuse, violence, crime, delinquency, inequality, poverty, immigration, family, global and environmental issues."},
    {"id":"SOC 2208","title":"Sociology of American Cities","description":"The socioeconomic evolution and growth of cities, emphasizing affluence and poverty, racial and ethnic pluralism, physical and moral decay of inner cities and the effects on both urban and suburban residents."},
    {"id":"SOC 2210","title":"Cultural Humility for Working with Youth","description":"Examines historical and current racial, ethnic, gender, sexual orientation, linguistic, and social class stereotypes and biases as related to youth in the United States and globally. Students identify personal preconceptions and learn ways of becoming culturally responsive working with youths."},
    {"id":"SOC 2214","title":"Applied Population Demography","description":"Introduction to the study of human populations and the process that governs their change, fertility, migration and mortality.  Application and comparison of demographic data related to the United States Census, emphasizing current and future social and economic trends through computer applications for demographic research."},
    {"id":"SOC 2215","title":"Race & Ethnicity","description":"Sociological exploration of American racial and ethnic diversity. Emphasis given to the social construction of race and ethnicity, patterns of intergroup contact and global migration. Historical comparative analysis of selected groups with emphasis given to economic, political and structural inequalities."},
    {"id":"SOC 2226","title":"Criminology","description":"This course presents a framework for studying the nature and the causes of crime and criminal behavior.  Focus is provided through criminal typologies and the myriad of theories using multi-disciplinary perspectives."},
    {"id":"SOC 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"SPA 1100","title":"Conversational Spanish I","description":"A foundation for gaining knowledge about Hispanic culture and basic phrases related to  simple spoken Spanish, including travel situations."},
    {"id":"SPA 1101","title":"Elementary Spanish I","description":"Foundation for understanding, speaking, reading and writing Spanish. Work outside of class and/or in the language laboratory is required."},
    {"id":"SPA 1102","title":"Elementary Spanish II","description":"Foundation for understanding, speaking, reading, and writing Spanish. Work outside of class and/or in the language laboratory is required."},
    {"id":"SPA 1161","title":"Conversational Spanish for Criminal Justice","description":"Conversational Spanish focused on learning to communicate with Spanish-speaking individuals in the professional capacity of a law enforcement officer.  Oral practice and discussions center on the understanding of the language within its cultural context.  Considerable supplementary work required."},
    {"id":"SPA 2201","title":"Intermediate Spanish I","description":"Reviews and extends basic principles through composition and conversation, stressing fluency. Work outside of class and/or in the language laboratory is required."},
    {"id":"SPA 2202","title":"Intermediate Spanish II","description":"Reviews and extends basic principles through composition and conversation, stressing fluency. Work outside of class and/or in the language laboratory is required."},
    {"id":"SPA 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"SUT 1100","title":"Sterile Processing I","description":"An introduction to the principles, techniques and issues in the surgical and sterile processing environment.  Topics include, sterile technique, packaging and wrapping techniques, sterilization methods, basic surgical instruments, basic microbiology, and hospital equipment identification. Three classroom, three lab hours per week."},
    {"id":"SUT 1101","title":"Tissue Banking I","description":"Provides the framework and environment for the practice of Tissue Banking. Focuses on history and organizational systems, ethics, rules and regulations, basic sciences, procurement, processing, distribution, and quality systems set by the American Association of Tissue Banking (AATB), the U.S. Food and Drug Administration (FDA) and related regulatory agencies."},
    {"id":"SUT 1107","title":"Lab for Tissue Banking I","description":"Provides the framework and environment for the practice of sterile technique, laboratory and machining skills, and sterile processing in Tissue Banking. Introduces the use of sterile technique, surgical hand preparation, gowning and gloving procedures, clean room procedures and protocols, and post processing protocols.  Introduces basic tissue processing skills to include setting up sterile tables and use of surgical instrumentation and machining tools and equipment.  Introduces sterile processing skills to include decontamination, wrapping and sterilization, and storage procedures.  Taught in a 8-week term.  Five lab hours per week."},
    {"id":"SUT 1110","title":"Theory & Fundamentals","description":"Discusses the framework and environment for the practice of Surgical Technology. Focuses on safety through the impact of sterile technique and sterilization practices, patient care, anesthesia, 'all hazards', and introduces the use of therapeutic communication, professionalism, group process, and critical thinking."},
    {"id":"SUT 1117","title":"Laboratory for Theory & Fundamentals","description":"Beginning competencies in aseptic technique, surgical hand preparation, gowning and gloving techniques, patient positioning, patient skin preparation, patient draping, preoperative patient care techniques to include chart review, vital signs, and intraoperative surgical case management. Three lab hours per week."},
    {"id":"SUT 1120","title":"The Surgical Process","description":"Advances the framework and environment for the practice of Surgical Technology.  Focuses on wound healing and management, surgical approaches, and advanced specialty equipment and supplies."},
    {"id":"SUT 1127","title":"Lab for the Surgical Process","description":"Advances the techniques for preparing the patient, operating room, instruments, supplies, and the equipment to be used during a surgical procedure. Applies these techniques to basic abdominal surgeries. Thirteen lab hours per week."},
    {"id":"SUT 1200","title":"Sterile Processing II","description":"Offers advanced principles of inventory control, materials management, information technology, and quality control systems integral to the Sterile Processing department in health-care facilities.  Introduces specialty surgical instrumentation and patient care equipment. Prepares the student to take the CRCST exam upon graduation and completion of 400 work related hours."},
    {"id":"SUT 1207","title":"Practicum for Sterile Processing II","description":"Provides 'hands on' experience in a clinical environment to assist in integration of all concepts basic to the field of Sterile Processing. Students will participate in all areas of the Sterile Processing department to include decontamination, instrument set preparation, sterilization, case cart preparation, business technologies for storage and distribution, and quality control and monitoring processes. Twenty-one practicum hours per week."},
    {"id":"SUT 2101","title":"Tissue Banking II","description":"This course is designed to help prepare the graduate for the Certified Tissue Banking Specialist (CTBS) Certification exam review.  Topics include the CTBS exam content outline: Quality Assurance, Distribution, Donor Screening &amp; Testing, Recovery, and Processing."},
    {"id":"SUT 2107","title":"Practicum for Tissue Banking II","description":"Role transition to beginning Tissue Banking Technology practitioner. Emphasizes a common systematic approach to tissue processing and sterile processing procedures. Introduces Tissue Banking Technologist’s role on recovery and processing teams in all related environments. 14 Practicum Hours per week.  Taught in an 8-week term."},
    {"id":"SUT 2110","title":"Surgical Procedures I","description":"Discusses specific surgical procedures of the gastrointestinal and biliary systems."},
    {"id":"SUT 2117","title":"Directed Practice for Surgical Procedures I","description":"Implements the surgical process in the operating room for general surgery procedures. Thirteen clinical hours per week."},
    {"id":"SUT 2120","title":"Surgical Procedures II","description":"Discusses OB-GYN, genitourinary, ophthalmic, ear/nose/throat, head and neck, oral, plastic, vascular and neuro surgical procedures.  Explains the role of the scrub technologist when intraoperative emergencies occur."},
    {"id":"SUT 2127","title":"Directed Practice for Surgical Procedures II","description":"Implements the surgical process in the operating room for OB-GYN, Genitourinary, Eye-Ear-Nose-Throat, Ophthalmology, Plastics, Vascular and Neuro surgical procedures. 18 clinical hours per week."},
    {"id":"SUT 2200","title":"Surgical Procedures III","description":"Emphasizes a common systematic approach to all surgeries and focuses on role transition to beginning Surgical Technologist. Discusses specific orthopedic, cardiothoracic, trauma and pediatric procedures. Examines immediate postanesthesia care.  Discusses  the Surgical Technologist's role on specialty teams, as a second circulator, in ambulatory surgery centers, and in pediatrics.  18 clinical hours per week."},
    {"id":"SUT 2207","title":"Directed Practice for Surgical Procedures III","description":"Implements the surgical process in the operating room for orthopedic, thoracic, open heart, trauma, pediatrics.  Implements the role transition to beginning Surgical Technology practitioner."},
    {"id":"SUT 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"SUT 2300","title":"Surgical Technology Review","description":"This course will offer the Surgical Technology major the opportunity to review all program content in preparation for the national certification exam.  Provides systematic review of all course material with related exams similar in design to the Certified Surgical Technologist (CST) Exam."},
    {"id":"SUT 2500","title":"RN Scrub","description":"The RN Scrub course is designed specifically for Perioperative Nurses to increase their skills and knowledge in the role of the surgical Scrub.  It will give the Perioperative Nurse the opportunity to learn the technical skills required to perform in the role of the Scrub during surgical intervention of the intraoperative period.  Must be a Registered Nurse (RN) trained in Perioperative Nursing to take this course.  Five lab hours per week.  Course taught in an 8-week term."},
    {"id":"SUT 2600","title":"Fundamentals of Perioperative Nursing","description":"This course is designed to give the RN, RN new graduate, or RN student, who has no previous experience in the operating room, an opportunity to become specialized in the field of Perioperative Nursing.  The course will include all fundamental technical skills and theory required to provide care to patients having surgical intervention during the preoperative, intraoperative, and postoperative periods. Upon completion, students will be prepared for an entry-level operating room staff nurse position. Three classroom, three lab hours per week."},
    {"id":"SWK 1206","title":"Introduction to Social Work","description":"Explore how historical events have shaped the social work profession. Recognize personal values in the context of one's practice as social worker and identify roles and knowledge base required. Recognize social, cultural and economic justice issues related to vulnerable groups and the impact of inequality. Describe social work settings and various fields of practice.  Service Learning opportunities available."},
    {"id":"SWK 1213","title":"Introduction to Social Welfare","description":"Explore history, values, ideologies and ethics in development of social welfare in the United States and identify the role of government in the delivery of social services. Learn how institutional structures, including forms of oppression and discrimination, and human diversity issues influence the delivery of social services.  Forty-eight hour agency observation required."},
    {"id":"SWK 2207","title":"Anti-Oppressive Social Work","description":"Learn skills to be effective with multicultural clients. Develop an understanding of theories, which will enhance competence in terms of behaviors, attitudes and policies that come together to assist professionals to work effectively in cross-cultural situations. Strong emphasis on self-awareness of personal cultural values and beliefs to increase appreciation of multicultural identities."},
    {"id":"SWK 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"THE 1101","title":"Theatre Appreciation","description":"Theatre as an art form presented from the historical, literary and production points of view.  Includes an exploration into the creative processes associated with the production of plays and the collaborative contributions of the actor, director, designers, playwright, critic and audience."},
    {"id":"THE 1103","title":"Acting For The Non-Major","description":"Introduction to the art of acting, focusing on acquainting nonmajors with the concepts and skills of the acting profession. Two classroom, two lab hours per week."},
    {"id":"THE 1105","title":"Introduction to Theatre","description":"An exploration of the artists, innovators and techniques that have influenced theatrical practices in historical and contemporary productions through research, script analysis and viewing theatre productions."},
    {"id":"THE 1106","title":"Stagecraft","description":"A study of techniques for building and handling theatrical scenery. Covers tools, materials and hardware used, along with standard safety practices and the artistic and practical considerations of scenery construction."},
    {"id":"THE 1107","title":"Lab for Stagecraft","description":"A study of techniques for building and handling theatrical scenery. Covers tools, materials and hardware used, along with standard safety practices and the artistic and practical considerations of scenery construction.  Three lab hours per week."},
    {"id":"THE 1111","title":"Acting I","description":"Basic training and practice in vocal, physical and creative processes used by the actor. One classroom, four lab hours per week."},
    {"id":"THE 1116","title":"Stage Lighting Fundamentals","description":"Study of theatrical lighting equipment, materials, methods and techniques. Emphasis on technical aspects of stage lighting, with an introduction to the principles of lighting design."},
    {"id":"THE 1117","title":"Lab for Stage Lighting Fundamentals","description":"Study of theatrical lighting equipment, materials, methods and techniques.  Emphasis on technical aspects of stage lighting, with and introduction to the principles of lighting design.  Three lab hours per week."},
    {"id":"THE 1118","title":"Costume Fundamentals","description":"Basic training in fundamental concepts and practices of costume design and construction."},
    {"id":"THE 1119","title":"Lab for Costume Fundamentals","description":"Three lab hours per week."},
    {"id":"THE 1194","title":"Applied Theatre Technology I","description":"Lab experience in theatre technology; positions can include production assistant, front of house, run crew and construction crews for theatre department productions.  Assignments are made through department faculty and staff."},
    {"id":"THE 1196","title":"Applied Theatre Technology II","description":"Continued lab experience in theatre technology; positions can include production assistant, front of house, run crew and construction crews for theatre department productions.  Assignments are made through department faculty and staff."},
    {"id":"THE 1199","title":"Applied Theatre Performance","description":"Applied Theatre Performance provides the student the opportunity to receive credit for practical experience."},
    {"id":"THE 1212","title":"Voice For The Actor","description":"Introduces the techniques of training the voice for the stage.  Designed to develop an awareness of the physical instrument through vocal production.  One classroom, four lab hours per week."},
    {"id":"THE 2115","title":"Movement For The Actor","description":"Introduces the techniques of training the body for the stage.  Designed to develop an awareness of the physical instrument through movement.  One classroom, four lab hours per week."},
    {"id":"THE 2201","title":"History of Theatre I","description":"Theatre in its purest form is storytelling. Discover how it has evolved throughout the ages from tribal rituals to producing the high-tech blockbusters of today. Explore a wealth of world cultures that influence theatrical traditions. Who are the authors, actors, directors, and designers creating these stories? Read the scripts and explore the spaces that share these stories. This course will explore theatre from tribal origins through the 1700s."},
    {"id":"THE 2202","title":"History of Theatre II","description":"Theatre in its purest form is storytelling. Discover how it has evolved throughout the ages from tribal rituals to producing the high-tech blockbusters of today. Explore a wealth of world cultures that influence theatrical traditions. Who are the authors, actors, directors, and designers creating these stories? Read the scripts and explore the spaces that share these stories. This course will explore theatre from the 1700s to present day."},
    {"id":"THE 2206","title":"Script Analysis","description":"Focus on discovering creative, in-depth techniques of script analysis and realizing different methods for researching the script. Techniques can be applied to understanding the script as an actor, director, designer, dramaturg or playwright. One classroom, four lab hours per week."},
    {"id":"THE 2216","title":"Acting II","description":"Intermediate training and practice in vocal, physical and creative processes used by the actor. One classroom, four lab hours per week."},
    {"id":"THE 2220","title":"Theatre Portfolio","description":"Process for creating a theatre resume and portfolio: development of presentation and interview skills."},
    {"id":"THE 2240","title":"Stage Management","description":"An introduction to the creative and administrative work of the stage manager, including hands-on activities in learning the principles and practices of stage management.  Attendance at department production rehearsals and performance required.  Two classroom, two lab hours per week."},
    {"id":"THE 2255","title":"Theatre Workshop","description":"Focused on a specialized area in theatre.  This course is designed to bring together performance, direction and design/technology."},
    {"id":"THE 2270","title":"Theatre Internship","description":"Students earn credit toward certificate requirements for work learning experience related to the discipline of theatre. Students establish learning outcomes related to theatre and prepare reports and/or projects each term, detailing how the experience allowed for the application of theatre theory and/or skills. One (1) credit hour will be earned for a minimum of seven (7) practicum hours per week."},
    {"id":"THE 2296","title":"Applied Theatre Technology IV","description":"Advanced practical experience in theatre design and technology. Participation in department production required. Assignments made through department faculty and staff."},
    {"id":"THE 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in non-traditional format."},
    {"id":"THE 2298","title":"Applied Theatre Technology V","description":"Further advanced practical experience in theatre design and technology. Participation in department production required. Assignments made through department faculty and staff."},
    {"id":"VET 1102","title":"Introduction to Veterinary Technology I","description":"This course will introduce the student to veterinary medicine: the history, marketing systems, economic impact, and legal and current interests.  Students will learn to identify a variety of species of animals and breeds within those species.  Students will discuss reproductive practices, nutritional impact of feedstuffs, animal husbandry, animal behavior, preventive care and common diseases seen with a wide variety of animal species.  Finally, students will be introduced to the human-animal bond and the impact of geriatric medicine and euthanasia on the veterinary technician."},
    {"id":"VET 1120","title":"Introduction to Large Animal Sciences: Handling & Husbandry","description":"Introduction to Large Animal Sciences will provide the student with knowledge and practical experience of safe handling practices and husbandry management for a variety of farm animal species.  Specifically, students will learn about animal contributions to our society, production systems, animal breeding, nutrition, and contemporary issues surrounding agriculture today."},
    {"id":"VET 1202","title":"Introduction to Veterinary Technology II","description":"This course will continue the education of the veterinary technical student in the subjects of: Clinical Sciences, Medical Nursing, Emergency and Critical Care, Pain Management, and the basics of Surgical Nursing."},
    {"id":"VET 1205","title":"Clinical Practice I: Hospital Practices & Professionalism","description":"An introduction to veterinary laws, record-keeping, hospital management, communication, and professionalism. The course will cover such topics as OSHA, state and nationwide veterinary laws, record keeping, and effective communication between coworkers and clients."},
    {"id":"VET 2005","title":"Clinical Practice II: Medical Terminology & Ethics","description":"An introduction to veterinary medical terminology, veterinary ethics, ethical situations, and a continued focus on professionalism."},
    {"id":"VET 2101","title":"Comparative Anatomy & Physiology, Animal Husbandry and Disease","description":"Discussion of anatomy and physiology of multiple species.  Proper use of medical and common terminology when discussing animal anatomy.  Development and understanding of different physiologies on disease development, diagnoses, and treatment of animals Discusses the care and keeping of companion animals, farm animals, equines, exotic animals, and laboratory animals.  Three classroom, six lab hours per week. Blended course in which much of the material is presented in an online format for home-review prior to in-class discussion/laboratory work."},
    {"id":"VET 2105","title":"Veterinary Anesthesia, Surgery, Diagnostic Laboratory & Radiology","description":"Develops an understanding for the role diagnostic testing plays in the treatment of animals. Discusses the techniques employed to retrieve, handle, and evaluate laboratory samples. Develops the student's understanding of sterile technique, surgical technique and assistance, and anesthesia application and monitoring. Identifies and discusses the use of radiology, pharmacology, and records management in veterinary medicine. This is a lecture course with some hands-on applications. Three classroom, four lab hours per week.  Blended course in which some of the material is presented in an online format for home-review prior to class discussion."},
    {"id":"VET 2107","title":"Technical Practicum I","description":"Practicum course in which the student is paired with a screened veterinary practice in order to develop beginning practical skills within a hospital setting.  Fourteen practicum hours per week in a Veterinary Practice."},
    {"id":"VET 2111","title":"Large Animal Husbandry & Veterinary Techniques","description":"This course will cover the basics of the care and husbandry of large animal species, as well as specific techniques that the students are required to learn in the care of these animals.  The class will be conducted both in lecture as well as lab format, with multiple field trips off-campus for live-animal simulations.  All students will be required to develop the essential psychomotor skills required for passage of the course as well as the program.  This is an 8-week, limited enrollment course.  Four hours of classroom and/or laboratory time per week."},
    {"id":"VET 2205","title":"Veterinary Dentistry, Advanced Radiology & Diagnostic Laboratory","description":"This course will offer a brief review of venipuncture and the collection of different samples (blood, urine, feces, skin).  It will also review surgical assisting procedures such as gowning, gloving, and passing instruments. This course will provide continuous hands on practice of laboratory tests like manual blood counts, differentials, and centrifugation fecal floatation. Several labs which will include radiology, surgical suturing, and intravenous and urinary catheter placement will be held.  Dental care of companion animals will be introduced and practiced within laboratory and clinical settings.  This is a 4 credit-hour course that will be offered in A-Term of the Spring semester and is limited to Veterinary Technology Cohort students.  Two classroom, four lab hours per week. Blended course in which some of the material is presented in an online format for home-review prior to class discussion."},
    {"id":"VET 2207","title":"Technical Practicum II","description":"Practicum course in which the student is paired with a screened veterinary practice in order to advance practical skills within a hospital setting. Included are observational experiences to large, laboratory, and exotic animal facilities to advance experience with a wide variety of animals. Fourteen practicum hours per week in a veterinary practice."},
    {"id":"VET 2211","title":"Veterinary Case Studies","description":"Using examples of real-life veterinary cases, the student will formulate technical assessments, calculate appropriate doses of medications, develop treatment plans, and integrate previously learned knowledge from different courses into cases of animals with systemic and/or multiple diagnoses."},
    {"id":"VET 2220","title":"Principles of Large Animal Reproduction","description":"The course will provide students with in-depth information on the physiological mechanisms controlling the reproductive processes in production animals.  Students will understand comparative differences in the anatomy, function, and regulation of male and female reproductive systems.  Students will also learn key concepts in reproduction as it applies to animal management systems."},
    {"id":"VET 2225","title":"Principles of Large Animal Nutrition","description":"The course will discuss the fundamentals of animal nutrition and feedstuffs dealing with principles of digestion, absorption, assimilation and utilization of nutrients, balancing of rations, and feeding of livestock."},
    {"id":"VET 2250","title":"Veterinary Pharmacology","description":"Veterinary Pharmacology, the development, uses, and administration of specific classes of drugs used within veterinary medicine.  This will include (but not be limited to) discussions on antibiotics, antiseptics, anti-inflammatories, analgesics, and medications used for the treatment of systemic diseases."},
    {"id":"VET 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
    {"id":"VET 2300","title":"Preceptorship","description":"During this 8-week period, each student will be partnered with an affiliate veterinary hospital.  Within each practice, students will uphold proper professional attire and attitude and perform duties as set to them by the practice. This will be an unpaid preceptorship with twenty-five hours expected per week within the hospitals, the additional three hours a week to be used for research and preparation in order to present a senior capstone project. The student will meet weekly with the instructor to discuss progress and concerns."},
    {"id":"VIS 1100","title":"Design Foundations","description":"Introduction to the fundamentals of two-dimensional and three-dimensional design. Students will apply critical and creative thinking techniques to applied projects that will explore the use of conceptualization, color, typography, image, modeling and applying the fundamentals of design. Two classroom, four lab hours per week."},
    {"id":"VIS 1110","title":"Design Drawing","description":"Introduction to design drawing techniques as applied to visual communications, including stylization, perspective, shading and rendering. Two classroom, four lab hours per week."},
    {"id":"VIS 1140","title":"Design Processes","description":"Introduction to the design development process including hand sketching, creation of thumbnails, computer illustration, imaging, photo manipulation, page layout and composition techniques using industry-based software."},
    {"id":"VIS 1180","title":"History of Design","description":"History of graphic design covering major designers and their work, as well as design movements.  From the origins of graphic art including printing and typography through the rise of the Internet, this course will explore the connection between culture and technology in the evolution of graphic design."},
    {"id":"VIS 1208","title":"Typography","description":"Introduction to typography as an element and tool of visual communication. The concept of type as image is emphasized. Two classroom, four lab hours per week."},
    {"id":"VIS 1218","title":"Web Design","description":"Introduction to media design, including web-based software applications for the development of online interactive media. Design basics and a hands-on approach emphasized."},
    {"id":"VIS 1250","title":"Print Production","description":"This course will provide the fundamentals of the various printing processes. It will include digital prepress techniques used to prepare layouts for the different processes used in the industry."},
    {"id":"VIS 2110","title":"Design Principles","description":"Development of an identity system, visual language, stationery system and identity manual. Exploration of advanced elements and principles of design; introduction to symbology. Two classroom, four lab hours per week."},
    {"id":"VIS 2120","title":"Design Applications I","description":"Design Applications I will cover the development of a pictogram and wayfinding system that will be used as a component to an overall Identity Manual developed in Design Principles. Two classroom, four lab hours per week."},
    {"id":"VIS 2160","title":"Design Applications II","description":"Advanced application of design principles. Emphasis will be placed on information design to include creating print and fully developed digital media. Two classroom, four lab hours per week."},
    {"id":"VIS 2260","title":"Visual Communications Portfolio","description":"Graphic design business practices including individualized portfolio development, work experience and development of professional practice skills; including cost estimating, contract writing, sales and communication techniques. Two classroom, four lab hours per week."},
    {"id":"VIS 2270","title":"Design Internship","description":"Students earn elective credits toward Visual Communications or Interior Design degree requirements for work-learning experience. Students establish learning outcomes and prepare related reports and/or projects in consultation with the employer and faculty.  Twelve field experience hours per credit hour each week."},
    {"id":"VIS 2297","title":"Special Topics","description":"Varied content offering of special interest to the discipline but not covered within existing courses; may be scheduled in a classroom/seminar setting or in nontraditional format."},
]
module.exports={data}