<template>
  <div id="scrollContainer">
    <!-- <PageBanner :bannerText="'Select up to ' + selectedTerm.reservationLimit + ' courses'" /> -->
    <PageBanner
      :bannerText="getBannerText()"
    />
    <TermCoursesSlots
      :readOnly="false"
      v-on:course-deleted="onCourseDeleted"
      :appMode="appMode"
    />
  </div>
</template>

<script>
// import lodash from "lodash";
import { mapGetters } from "vuex";
import { scrollElementIntoView } from "@/utils";
import PageBanner from "@/components/PageBanner";
import TermCoursesSlots from "@/components/TermCoursesSlots";
import { countItems } from "@/api/reservations";

import logdown from "logdown";
const logger = logdown("TermCourses");
// logger.state.isEnabled = true;

export default {
  name: "TermCourses",
  components: {
    PageBanner,
    TermCoursesSlots,
  },
  data: () => {
    return {
      //
    };
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      appMode: "getAppMode",
    }),
  },
  methods: {
    getBannerText(){
      if(this.appMode != 'instructor'){
        return `Select up to ${this.selectedTerm.reservationLimit} courses for ` + this.selectedTerm.title
      }else{
        return 'What courses do you want to teach?'
      }
      
    },
    onCourseDeleted(event) {
      logger.debug("onCourseDeleted", event);
      if (0 == this.courseCount(event.term)) {
        this.$emit("no-courses", { term: event.term });
      }
    },
    courseCount(term) {
      logger.debug("courseCount, term:", term);
      logger.debug(
        "courseCount, userCourses:",
        this.$store.getters.getUserCourses
      );
      return countItems(this.$store.getters.getUserCourses, term);
    },
  },
  mounted: function() {
    let el = document.getElementById("scrollContainer");
    if (el) {
      // el.scrollIntoView(true);
      scrollElementIntoView(el);
    }
  },
};
</script>
