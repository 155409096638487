/**
 * Mocking the Locations
*/

const _locations = [
  // { id: "main", title: "Main campus" },
  // { id: "east", title: "East campus" },
  // { id: "downtown", title: "Downtown campus" },
  // { id: "riverton", title: "Riverton campus" },
  { id: "Dayton Campus", title: "Dayton Campus" },
  { id: "Centerville CENT", title: "Centerville CENT" },
  { id: "Mason CVCC", title: "Mason CVCC" },
  { id: "Englewood ELC", title: "Englewood ELC" },
  { id: "Huber Heights HHLC", title: "Huber Heights HHLC" },
]

function fetchLocations() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if(_locations){
        resolve(_locations) 
      }else{
        reject('some error')
      }
    }, 0)
  })
}

export {
  fetchLocations,
}