// import _ from 'lodash'
import logdown from 'logdown'
import axios from 'axios'
import { apiRoot } from '@/api/config'
import { getAuthKey, } from "@/utils";

const logger = logdown('advisor')

function updateStudent({ studentId, termId, userConfirmation, userCourses }) {
    logger.log(`updateStudent, ${studentId}`)
    return new Promise((resolve, reject) => {
        axios.post(`${apiRoot()}/us`, {
            localKey: getAuthKey(),
            studentId,
            termId,
            userConfirmation,
            userCourses
        })
            .then(response => {
                const data = response
                logger.debug('updateStudent', data)
                resolve(data)
            })
            .catch(error => {
                // Errors 
                logger.error('updateStudent', error)
                reject(error)
            })
    })
}

function getStudentData(studentId) {
    logger.log(`getStudentData, ${studentId}`)
    return new Promise((resolve, reject) => {
        axios.get(`${apiRoot()}/s`, {
            params: {
                localKey: getAuthKey(),
                studentId,
            }
        })
            .then(response => {
                const data = response
                logger.debug('getStudentData', data)
                resolve(data)
            })
            .catch(error => {
                // Errors 
                logger.error('getStudentData', error)
                reject(error)
            })
    })
}

export {
    updateStudent,
    getStudentData,
}