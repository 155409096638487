/**
*/
import { customer } from '@/api/config'
import _ from 'lodash'
import logdown from 'logdown'
const logger = logdown('courses')

const _subjects = {
  ASTR: 'Astronomy',
  GEOG: 'Geography',
  MATH: 'Mathematics',
  STAT: 'Statistics',
  PHYS: 'Physics',
  HPHY: 'Physiology',
  CHEM: 'Chemistry',
  BIOL: 'Biology',
  ENGL: 'English',
  SPAN: 'Spanish',
  FREN: 'French',
  CHIN: 'Mandarin',
  JAPN: 'Spanish',
  HIST: 'History',
  ECOL: 'Ecology',
  ECON: 'Economy',
  ACCT: 'Accounting',
  CSCI: 'Computer Science',
  COMM: 'Communication',
  MRKT: 'Marketing',
  BUSI: 'Business',
  SOCS: 'Social Science',
  PSYC: 'Psycology',
}

const _levels = ["", "beginner",
  "Intermediate, level I",
  "Intermediate, level II",
  "Advanced",
  "Complete"]

const _variations = [
  "", "extended ciriculum", "revision A", "revision B", "revision C",
  "methods", "revised ciriculum", "intensive", "paced", "non traditional methodology",
  "special needs", "with ESL support", "athletics department", "remote", "",
  "", "", "", "", "traditional methodology",
]

// const _courses = [
//     {id:'MATH 101', title: "Calculus I", seats: 3, },
//     {id:'MATH 201', title: "Calculus II", seats: 100, },
//     {id:'PHYS 101', title: "Physics I, Mechanics", seats: 100, },
//     {id:'BIOL 130', title: "Human Biology I", seats: 100, },
//     {id:'BIOL 230', title: "Human Biology II", seats: 100, },
//     {id:'ENGL 101', title: "English Composition I", seats: 0, },
//     {id:'ENGL 201', title: "English Composition II", seats: 50, },
//     {id:'HIST 101', title: "Modern History Since 1861", seats: 100, },
//     {id:'HIST 201', title: "Modern European History Since 1861", seats: 100, },
//     {id:'HIST 301', title: "Modern American History Since 1864", seats: 100, },
// ]

const _descriptions = [
  "This course description is to be updated as soon as the information will become available. This is a long test text that should be wrapped over multiple lines over and over again. I am not sure how many lines, but there will be many.",
  "This course is being tought with care and love, remotely and in person, and everybody should take it if they are intersted.",
]

const _modalities = [
  ['In person', 'Hybrid', 'Online'],
  ['In person', 'Hybrid', 'Online'],
]

const _instructors = [
  ["00000090", "00000091"],
  ["00000091", "00000092"]
]

function genCatalog() {
  var courses = []
  for (var hundred = 1; hundred < 6; hundred++) {
    for (var left = 0; left < 20; left++) {
      const i = hundred * 100 + left
      for (var subject in _subjects) {
        courses.push({
          id: subject + ' ' + i,
          title: _subjects[subject] + ', ' + _levels[hundred] + ', ' + _variations[left],
          seats: Math.floor((Math.random() * 20)),
          description: _descriptions[left % 2],
          modalities: _modalities[left % 2],
          instructors: _instructors[left % 2],
        });
      }
    }
  }
  console.log('courses.length: ' + courses.length)
  return courses;
}

const _catalog = {
  '2021Summer': customer.courses2021summer.data,
  '2021Fall': customer.courses2021fall.data,
  '2021Winter': customer.courses2021winter.data,
  '2022Spring': customer.courses2022spring.data,
  '2022Summer': customer.courses2022summer.data,
  '2022Fall': customer.courses2022fall.data,
  '2022Winter': customer.courses2022winter.data,
}

function prepCatalog(term) {
  const _modalities = _.map(customer.modalities, v=>_.get(v,'id'))
  logger.log(`configured modalities: ${JSON.stringify(_modalities)}`)
  const catalog = _catalog[term] || []
  let out = []
  for (let i = 0; i < catalog.length; i++) {
    let id = _.get(catalog, [i, 'id'])
    if (id[id.length - 1] == '.') { id = id.substring(0, id.length - 1) } // removing trailing dot
    const record = {
      id: id,
      title: _.get(catalog, [i, 'title']),
      seats: _.get(catalog, [i, 'seats']) || 99,
      description: _.get(catalog,[i,'description']),
      modalities: _.get(catalog, [i, 'modalities']) || _modalities,
      // instructors: _.get(catalog,[i,'instructors']),
    }
    const description = _.get(customer.courses.data, [record.id, 'description'])
    if (description) {
      record['description'] = description
    }
    out.push(record)
  }
  return out
}
// import('data.js').then()

const _courses = genCatalog();

async function fetchCourses(term) {
  return new Promise((resolve) => {
    if (term) {
      setTimeout(() => {
        resolve(prepCatalog(term))
      }, 10)
    } else {
      setTimeout(() => {
        resolve(_courses)
      }, 10)
    }
  })
}

export {
  fetchCourses,
}