import _ from 'lodash'
import Bowser from "bowser";
import logdown from 'logdown'
import { decode as decodeBase64 } from 'js-base64';
// import scrollIntoView from 'scroll-into-view-if-needed'
import scrollIntoView from 'scroll-into-view'
const logger = logdown('utils')

const X_SESSION_KEY = 'x-session-data-v4'

/**
 * Collects all the objects that match the "text" param.
 * The matching is performed agains a lsit properties defined by 
 * the "properties" param. And, the matching is case-insensitive. 
 * @param {*} array 
 * @param {*} text 
 * @param {*} properties 
 */
function searchAll(array, text, properties, sortBy) {
  var filtered = [];
  if (typeof (text) == "string" && Array.isArray(array) && Array.isArray(properties)) {
    array.forEach(element => {
      for (var i in properties) {
        var v = element[properties[i]];
        if (typeof (v) == "string" && v.toLowerCase().search(text.toLowerCase()) > -1) {
          filtered.push(element)
          break;
        }
      }
    });
  }
  return sortBy ? _.sortBy(filtered, sortBy) : filtered
}

var lock = 0;
const _lockClickMs = 1000
const _lockNumber = 3

function unlockTheDebug() {
  return new Promise((resolve, reject) => {
    if (lock == _lockNumber) {
      resolve(lock)
    } else {
      lock++;
      if (lock == 1) {
        console.log('lock timer started')
        setTimeout(() => {
          lock = 0
          console.log('lock timer finished')
        }, _lockClickMs * _lockNumber)
      }
      reject(lock)
    }
  })
}

// API: isFullScreen, enterFullScreen, exitFullScreen

function setFullScreen(win, os, browserName, browserVersion) {
  // const fullScreenElement = document.fullscreenElement;
  logger.debug(win, os, browserName, browserVersion);

  if (win.document.documentElement.requestFullscreen) {
    logger.debug('requestFullscreen')
    win.document.documentElement.requestFullscreen()
      .then(() => { logger.info('Fullscreen') })
      .catch((e) => { logger.warn(e) })
  }
  else if (win.document.documentElement.requestFullScreen) {
    logger.debug('requestFullScreen')
    win.document.documentElement.requestFullScreen()
      .then(() => { logger.info('FullScreen') })
      .catch((e) => { logger.warn(e) })
  }
  else if (win.document.documentElement.mozRequestFullscreen) {
    logger.debug('mozRequestFullscreen')
    win.document.documentElement.mozRequestFullscreen()
      .then(() => { logger.info('mozRequestFullscreen') })
      .catch((e) => { logger.warn(e) })
  }
  else if (win.document.documentElement.mozRequestFullScreen) {
    logger.debug('mozRequestFullScreen')
    win.document.documentElement.mozRequestFullScreen()
      .then(() => { logger.info('mozRequestFullScreen') })
      .catch((e) => { logger.warn(e) })
  }
  else if (win.document.documentElement.webkitRequestFullscreen) { win.document.documentElement.webkitRequestFullscreen() }
  else if (win.document.documentElement.webkitRequestFullScreen) { win.document.documentElement.webkitRequestFullScreen() }
}

function formatCourseSlots(slots, courses, termId, reservationMax) {
  let termCourses = _.get(courses, [termId]);
  let maxSlot = _.parseInt(_.max(_.keys(termCourses)));
  logger.debug('formatCourseSlots, maxSlot:', maxSlot);
  slots.splice(0, slots.length);
  for (let slot = 1; slot <= reservationMax || slot < maxSlot+1; slot++) {
    let course = _.get(termCourses, [slot]);
    if (course) {
      slots.push({
        slot: slot,
        id: course.id,
        title: _.get(course, ['title']),
        description: _.get(course, ['description']),
        modalities: _.get(course, ['modalities']),
        instructors: _.get(course, ['instructors']),
        unselectedModalities: _.get(course, ['unselectedModalities']),
        unselectedInstructors: _.get(course, ['unselectedInstructors']),
      });
      logger.debug('formatCourseSlots', slots)
    } else {
      slots.push({ slot: slot });
    }
  }
  logger.debug("formatCourseSlots, slots:", slots);
}

function seasonBullet(season) {
  return {
    spring: 'flowerBullet',
    summer: 'sunBullet',
    fall: 'leafBullet',
    winter: 'snowflakeBullet'
  }[season]
}

function getAuthKey() {
  try {
    return _.get(JSON.parse(localStorage.getItem(X_SESSION_KEY)), ['localKey']) || ''
  } catch (err) {
    logger.log(`getAuthKey: could not get`)
  }
}

function decodeAuthKey(encodedKey) {
  try {
    const key = _.get(_.split(encodedKey, ':'), [0])
    const decoded = JSON.parse(decodeBase64(key))
    logger.debug('decodeAuthKey, key', decoded)
    return decoded 
  } catch (err) {
    console.log(`decodeAuthKey: could not decode`)
  }
}

function getUserId() {
  return _.get((decodeAuthKey(getAuthKey()) || {}), 'user')
}

function userIsAdmin() {
  return _.get((decodeAuthKey(getAuthKey()) || {}), 'admin')
}

/**
 * retrurns 'advisor' or 'student', depending on a user role
 */
function getAppMode(bookmark) {
  logger.log(`getAppMode, bookmark: ${bookmark}`)
  // TODO: remove this stub
  const advisor = _.get((decodeAuthKey(getAuthKey()) || {}), 'advisor')
  const instructor = _.get((decodeAuthKey(getAuthKey()) || {}), 'faculty')
  if (instructor) {
    return 'instructor';
  }
  if (advisor) {
    return 'advisor';
  }
  logger.log(`getAppMode, advisor: ${advisor}`)
  const userid = getUserId();
  const instructors = ['andrey'];
  const advisors = [
    "andrey1",
    "justin1",
    "@02321742",
    "000002546",
    "000511841",
    "000003134",
    "000640936",
    "000002154",
    "000407768",
    "000003359",
    "000582253",
    "000665190",
    "000504747",
    "000003795",
    "000586016",
    "000506547",
    "000003258",
    "000655140",
    "000555206",
    "000116973",
    "000004175",
    "000004198",
    "000632288",
    "000163734",
    "000501580",
    "000163570",
    "000445371",
  ]
  if (bookmark) {
    return 'student'
  }
  return _.findIndex(instructors, a => a == userid) > -1 ? 'instructor' :
    _.findIndex(advisors, a => a == userid) > -1 ? 'advisor' : 'student'
    ;
}

function getUserStore() {
  return 'uid-' + getUserId()
}

function getUserHumanName() {
  const fn = _.get((decodeAuthKey(getAuthKey()) || {}), 'firstName')
  const ln = _.get((decodeAuthKey(getAuthKey()) || {}), 'lastName')
  if (fn) {
    return `${fn} ${(ln) ? ln.substring(0, 1) : ''}.`
  }
}

function logout() {
  logger.debug('logout')
  localStorage.removeItem(X_SESSION_KEY)
  window.location.reload()
}

function reload() {
  logger.debug('reload')
  window.location.replace(`/#${(new Date()).getTime()}`)
  window.location.reload()
}

const _browser = (() => {
  const agent = _.get(window, ['navigator', 'userAgent'])
  try {
    const info = Bowser.parse(agent)
    logger.debug('browser', info)
    return info
  } catch (err) {
    logger.debug('browser not detected, using stub')
    return {
      browser: { name: "unknown", version: "x.x" },
      os: { name: "unknown", version: "x.x", versionName: "x.x", },
      platform: { type: "unknown" },
      engine: { name: "unknown", version: "x.x" }
    }
  }
})();

function getBrowserInfo() {
  return _browser;
}

function getBrowser() {
  const info = {
    name: _browser.browser.name,
    version: _browser.browser.version,
    os: _browser.os.name,
  }
  logger.debug(`reporting browser`, info)
  return info;
}
//(10000000).toString(20).toLocaleUpperCase()
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function computeConfirmation() {
  const n = 10000000 + getRandomInt(10000000)
  return n.toString(20).toLocaleUpperCase()
}

function computeRefCode(id) {
  return Number.parseInt(`${id}`).toString(20).toLocaleUpperCase()
}

function isTestHostname(hostname) {
  return /^(localhost|([0-9]+\.){3,}([0-9])+)$/ig.test(hostname || '')
}

function scrollElementIntoView(el) {
  scrollIntoView(el, { align: { top: 0, left: 0 } })
}

function reportEvent(ctx, eventName, courseId, termId) {
  logger.debug(`reportEvent: ${eventName}, ${courseId}, ${termId}`);
  ctx.$gtag &&
    ctx.$gtag.event(eventName, {
      event_category: "user_interaction",
      data_course_id: courseId,
      data_term_id: termId,
    });
}

function formatCourseModalities(course, modalities) {
  logger.debug("formatModalities", course, modalities);
  const unselectedModalities =
    _.get(course, ["unselectedModalities"]) || [];
  const toFormat = _.filter(
    modalities,
    (modality) => unselectedModalities.indexOf(modality.id) == -1
  );
  return _.join(
    _.map(toFormat, (modality) => modality.title),
    ", "
  );
}

export {
  searchAll,
  unlockTheDebug,
  setFullScreen,
  formatCourseSlots,
  seasonBullet,
  getAuthKey,
  decodeAuthKey,
  getUserId,
  userIsAdmin,
  getUserHumanName,
  getUserStore,
  getAppMode,
  logout,
  reload,
  getBrowser,
  getBrowserInfo,
  computeConfirmation,
  computeRefCode,
  isTestHostname,
  scrollElementIntoView,
  reportEvent,
  formatCourseModalities,
}