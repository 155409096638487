<template>
  <div>
    <div v-if="appMode == 'instructor'">
      <PageBanner
        :bannerText="'EdReserve for Instructors'"
        :subtitle="section"
      />
      <v-container>
        <v-row>
          <v-col class="d-flex justify-center">
            <IntroContentForInstructor />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <PageBanner :bannerText="config.introText.title" :subtitle="section" />
      <v-container class="px-4 pt-3 pb-0" v-if="config.introText.headerWhat">
        <v-row
          no-gutters
          v-for="(text, index) in config.introText.headerWhat"
          :key="index"
        >
          <v-col cols="12" class="pa-0">
            <p class="fine-print-header">{{ text }}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pa-3">
        <v-row
          v-for="(text, index) in config.introText.bulletsWhat"
          :key="index"
        >
          <v-col cols="2" class="pl-6 pr-1 py-1"
            ><v-icon class="info-bullet">${{ bullet() }}</v-icon></v-col
          >
          <v-col cols="10" class="pl-4 pr-4 py-1">
            <p class="info-text">{{ text }}</p>
          </v-col>
        </v-row>
      </v-container>
      <!-- fine print bold header -->
      <v-container class="px-4 pt-3 pb-0" v-if="config.introText.headerHow">
        <v-row
          no-gutters
          v-for="(text, index) in config.introText.headerHow"
          :key="index"
        >
          <v-col cols="12" class="pa-0">
            <p class="fine-print-header">{{ text }}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pa-3">
        <v-row
          v-for="(text, index) in config.introText.bulletsHow"
          :key="index"
        >
          <v-col cols="2" class="pl-6 pr-1 py-1"
            ><v-icon class="info-bullet"
              >${{ `number${index + 1}Bullet` }}</v-icon
            ></v-col
          >
          <v-col cols="10" class="pl-4 pr-4 py-1">
            <div class="info-text-how d-flex flex-column justify-center">{{ text }}</div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// import
import { mapGetters } from "vuex";
import PageBanner from "@/components/PageBanner";
import IntroContentForInstructor from "@/components/IntroContentForInstructor";

export default {
  name: "Intro",
  components: { PageBanner, IntroContentForInstructor },
  props: {
    section: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters({
      config: "getConfig",
      selectedPage: "getSelectedPage",
      TAC: "getTermsAndConditions",
      appMode: "getAppMode",
    }),
  },
  methods: {
    bullet() {
      return "checkBullet";
    },
  },
};
</script>

<style scoped>
.purple-text {
  color: #9b51e0;
}
.bold-text {
  font-weight: 900;
}
.special-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.normal-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #828282;
  font-weight: 400;
}

.message-text p {
  color: #333333;
}
/* p {
  margin-bottom: 0px;
} */
.fine-print-header {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: center;

  /* Purple 1 */
  color: #9b51e0;

  /* font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left; */
}
.fine-print-text p {
  color: #333333;
  font-size: 14px;
}
.info-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #333333;
}
.info-text-how {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  color: #333333;
}
.info-bullet {
  width: 34px;
  height: 43px;
}
.page-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;

  /* Gray 2 */

  color: #4f4f4f;
}
.banner-text {
  color: white;
  font-size: 18px;
}
.subtitle-banner {
  color: black;
}
</style>
<style>
.v-label {
  font-size: 14px;
}
</style>
