<template>
  <div>
    <PageBanner
      :bannerText="'Acceptable Locations'"
      subtitle
      v-if="readOnly && locationCount()"
      section="locations"
    />
    <div v-if="readOnly">
      <v-container>
        <div v-for="location in locations" :key="location.id">
          <v-row
            v-if="check(location)"
            class="d-flex align-content-space-around flex-wrap"
          >
            <v-col
              cols="2"
              class="d-flex align-content-space-around flex-wrap align-center px-1 py-0 my-0"
            >
            </v-col>
            <v-col
              v-if="readOnly"
              cols="10"
              class="d-flex align-content-space-around flex-wrap align-center px-1 py-0 my-0"
            >
              <v-card
                flat
                class="d-flex align-content-space-around flex-wrap pa-1 pl-4"
              >
                <div style="color: #282828;" class="text-caption">
                  {{ location.title }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <div v-else>
      <v-container>
        <div v-for="location in locations" :key="location.id">
          <v-row
            class="d-flex align-content-space-around flex-wrap"
            @click="toggleCheck(location)"
            no-gutters
            style="cusror: ponter;"
          >
            <v-col cols="1"></v-col>
            <v-col cols="11">
              <v-checkbox
                color="primary"
                :label="location.title"
                :input-value="check(location)"
                class="location-selector"
                off-icon="$boxBlank"
                on-icon="$boxChecked"
              ></v-checkbox>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import PageBanner from "@/components/PageBanner";
// import { countItems } from "@/api/reservations";

import logdown from "logdown";
import _ from "lodash";

const logger = logdown("TermCoursesLocations");
// logger.state.isEnabled = true;

export default {
  name: "TermCoursesLocations",
  components: {
    PageBanner,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      locations: "getAllLocations",
    }),
  },
  methods: {
    ...mapGetters({
      userLocations: "getUserLocations",
    }),
    ...mapMutations({
      setUnselectedUserLocations: "setUnselectedUserLocations",
    }),
    locationCount() {
      return (
        _.size(this.locations) - _.size(this.getUnselectedLocations() || [])
      );
    },
    toggleCheck(location) {
      const term = this.selectedTerm.id;
      let unselectedLocations = _.clone(this.getUnselectedLocations() || []);
      if (!this.check(location)) {
        // if (this.locationCount() > 1) {
          logger.debug("unset unselected user location", location.id);
          _.remove(unselectedLocations, (v) => v == location.id);
        // }
      } else {
        logger.debug("set unselected user location", location.id);
        unselectedLocations.push(location.id);
      }
      this.setUnselectedUserLocations({ term, unselectedLocations });
      this.$forceUpdate();
    },
    getUnselectedLocations() {
      const term = this.selectedTerm.id;
      // logger.debug(
      //   "getUnselectedLocations, userLocations:",
      //   this.userLocations()
      // );
      const unselectedLocations = _.get(this.userLocations(), [
        term,
        "unselectedLocations",
      ]);
      return unselectedLocations;
    },
    check(location) {
      return !((this.getUnselectedLocations() || []).indexOf(location.id) >= 0);
    },
  },
};
</script>

<style>
.location-selector .v-label {
  font-size: 18px;
  margin-left: 18px;
}
</style>
