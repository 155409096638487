/**
 * Term Catalog
*/
import { nick } from './static'

const introText = {
  "title": "What is EdReserve?",
  "headerWhat": ["What it does:"],
  "bulletsWhat": [
    "Request seats for classes you want",
    "Get first access to seats held for you within your priorty group",
    "Have a voice in the Sinclair Community College class scheduling process"
  ],
  "headerHow": ["How it works:"],
  "bulletsHow": [
    "Request classes in future terms and tell us when and how you want to take classes.",
    `${nick} uses your requests together with other considerations to create the class schedule.`,
    "Your requests are matched with classes when the schedule is published.",
    `${nick} holds a seat in your matched classes for 24-hours at your registration time, when possible.`
  ]
}

const commonCourseBullets = [
  { class: "special-text purple-text bold-text pb-4", text: "Request without worry." },
  { class: "special-text purple-text pb-2", text: "Here is the deal:" },
  { class: "special-text purple-text pb-3", text: "" },
  { class: "special-text purple-text pb-3", text: "Tell us the courses you want and we’ll match you to classes and let you know when it’s time to register.​" },
  { class: "special-text purple-text pb-3", text: "You can change your mind anytime or even decide to take something completely different when you register. That’s cool .​" },
  { class: "special-text purple-text pb-3", text: "Letting us know the courses you want helps us schedule the classes you need.​" },
];

// const commonBullets = [
//   "You may request up to 3 courses for Fall 2022.",
//   "Requests will be processed in March 2022. ",
//   "You can change or cancel your request at any time until March 2022.",
//   "Sinclair College will do our best to hold a seat at registration if one is available at a time that works for you.",
//   "You have 48-hours to claim your held seats when registration starts if you still want it."
// ];

const terms = [
  // {
  //   id: '2021Winter', termId: '202160', season: 'winter', reservationLimit: 2,
  //   title: "Winter 2021-2022",
  //   info: "",
  //   begins: "January 18",
  //   courseBullets: commonCourseBullets,
  //   bullets: commonBullets,
  // finePrintBoldHeader: [
  //   `${nick} will make its best effort to pre-register you in the courses you reserve.`
  // ],
  // finePrintHeader: [
  //   "You may reserve up to 2 courses for Winter 2021-2022.",
  //   "When processing your reservations, we verify:",
  // ],
  // finePrintBullets: [
  //   "A section is offered at a time, location, and delivery method that you indicate is acceptable in EdReserve.",
  //   "You meet the prerequisite and other eligibility requirements for the course and section at the time of pre-registration. It is your responsibility to check and ensure you meet these requirements ahead of time. ",
  //   "The course and section has seats available when your pre-registration is processed.",
  //   "You do not have any holds that prevent registration."
  // ],
  // finePrintFooter: [
  //   "The reservation deadline is 11:59pm December 1, 2021. Reservations cannot be changed or canceled after this date. You will need to wait until your assigned registration window to make any changes after this date."
  // ]
  // },
  // {
  //   id: '2022Spring', termId: '202230', season: 'spring', reservationLimit: 2,
  //   title: "Spring 2022",
  //   info: "",
  //   begins: "January 18",
  //   courseBullets: commonCourseBullets,
  //   bullets: commonBullets,
  // },
  // {
  //   id: '2022Summer', termId: '202240', season: 'summer', reservationLimit: 2,
  //   title: "Summer 2022",
  //   info: "",
  //   begins: "",
  //   courseBullets: commonCourseBullets,
  //   bullets: commonBullets,
  // },
  {
    id: '2022Fall', termId: '202250', season: 'fall', reservationLimit: 3,
    title: "Fall 2022",
    info: "",
    begins: "",
    courseBullets: commonCourseBullets,
    bullets: [
      "You may request up to 3 courses for Fall 2022.",
      "Requests will be processed in March 2022. ",
      "You can change or cancel your request at any time until March 2022.",
      "Sinclair College will do our best to hold a seat at registration if one is available at a time that works for you.",
      "You have 48-hours to claim your held seats when registration starts if you still want it."
    ],
    finePrintBullets: [
      "Sinclair College will make its best effort to hold a seat for you in the courses you reserve.",
      "You may reserve up to 3 courses for Fall 2022.",
      "We will attempt to match your request with a section that is offered at a time, location, and delivery method that you indicate is acceptable in EdReserve.  A match is not always possible.",
      "We will hold a seat for you in matched sections provided there is still a seat available at the beginning of your registration window.",
      "You have 48 hours from when we hold a seat to claim the held seat by registering normally for the matched section.  You are not required to register for this section or even this course.  The held seat will be released after 48 hours."
      ],
  },
]

export {
  introText,
  terms,
}