<template>
  <div id="scrollContainer">
    <PageBanner :bannerText="'That’s it!'" />
    <v-container class="page-text pa-0" v-if="!ratingForm">
      <v-row no-gutters align="center" class="pa-0 confirmation-section">
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center"
        >
          <p class="text-caption">
            {{ selectedTerm.title }} confirmation number:
          </p>
        </v-col>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center"
        >
          <p class="text-h3">
            {{ getConfirmation() }}
          </p>
        </v-col>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center"
        >
          <p class="text-caption">
            Your reservation request is confirmed.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="page-text pa-0"
      v-if="selectedTerm.id == '2021Fall' && !ratingForm"
    >
      <v-row class="text-banner" no-gutters>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pt-5"
        >
          <div>Considering taking</div>
        </v-col>
      </v-row>
      <v-row class="text-banner" no-gutters>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pt-1"
        >
          <div>courses next summer?</div>
        </v-col>
      </v-row>
      <v-row class="text-fineprint" no-gutters>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pa-6"
        >
          <div>
            Let us know which courses you want and we will do our best to offer
            them in a way that works for you.
          </div>
        </v-col>
      </v-row>
      <v-row class="text-fineprint" no-gutters>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pa-6"
        >
          <CustomButton
            text="Start Summer Survey"
            look="longPrimary"
            @click="startSummerSurvey()"
          />
        </v-col>
      </v-row>
    </v-container>
    <UserRating @show-input="onShowInput" v-if="appMode != 'instructor'"/>
    <div ref="bottom" class="page-bottom"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { scrollElementIntoView } from '@/utils'
import PageBanner from "@/components/PageBanner";
import CustomButton from "@/components/CustomButton";
import UserRating from "@/components/UserRating";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("ATermConfirmation");

export default {
  name: "ATermConfirmation",
  components: { PageBanner, CustomButton, UserRating },
  data: function() {
    return {
      ratingForm: false,
      rating: 0,
      feedbackText: "",
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      appMode: "getAppMode",
    }),
    ...mapState({
      termsToSelect: (state) => state.allTerms,
    }),
  },
  methods: {
    onShowInput() {
      logger.debug("onShowInput");
      this.ratingForm = true;
    },
    ...mapGetters({
      getUserConfirmations: "getUserConfirmations",
    }),
    ...mapMutations({
      selectPage: "setSelectedPage",
      selectTerm: "setSelectedTerm",
    }),
    startSummerSurvey() {
      this.selectTerm(
        _.find(this.termsToSelect, (el) => el.id == "2021Summer")
      );
      this.selectPage("ATermLanding");
    },
    getConfirmation() {
      logger.debug("getConfirmation");
      const confirmations = _.cloneDeep(this.getUserConfirmations() || {});
      const confirmation = _.get(confirmations, [this.selectedTerm.id]);
      return confirmation;
    },
  },
  mounted: function() {
    let el = document.getElementById("scrollContainer");
    if (el) {
      // el.scrollIntoView(true);
      scrollElementIntoView(el)
    }
  },
};
</script>

<style scoped>
.page-text p {
  color: #333333;
}
.confirmation-section {
  border-bottom: solid 1px gray;
}
.text-banner {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  /* Gray 1 */
  color: #333333;
}
.text-fineprint {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #828282;
}
.share-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #828282;
}
.share-link {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #4f4f4f;
}
.share-click {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #9b51e0;
  cursor: pointer;
}
</style>
