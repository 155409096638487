/**
 * Reservarion API mock.
 * author: Andrey Stepantsov, (C) 2020
 * 
 * supported types: 'course', 'location', 'time', 'istructor', 'modality'
 * supported identities: 'term', 'id'
 * for audit purposes supported: 'adate'
 * 
 * example: {"type":"course", "id":"MATH 101", "term":"2021SPR", "title":"College Algebra"} 
 */

import _ from 'lodash'
import logdown from 'logdown'
import axios from 'axios'
import { apiRoot } from '@/api/config'
const logger = logdown('reservationStorage')
// logger.state.isEnabled = true

const _mockDelayMillis = 0

const STORAGE_KEY = "edreserve-reservations-1.0"

var reservationLocalStorage = {
    fetch: function (storageId) {
        logger.debug('fetch:', storageId)
        return JSON.parse(localStorage.getItem(STORAGE_KEY + '-' + storageId) || "{}");
    },
    save: function (storageId, data) {
        logger.debug('save:', storageId, data)
        localStorage.setItem(STORAGE_KEY + '-' + storageId, JSON.stringify(data));
    }
};

function __reject(msg) {
    logger.debug(msg)
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(msg)
        }, _mockDelayMillis)
    })
}

function __undefined(msg) {
    return __reject('undefined: ' + msg)
}

// function __notImplemented(msg) {
//     return __reject('not implemented: ' + msg)
// }

class Reservations {

    _storageId = undefined

    _data = {
        courses: {},
        times: {},
        locations: {},
        confirmations: {},
    }

    constructor(storageId, data) {
        logger.debug('Reservation constructor')
        this._storageId = storageId
        this._data.courses = _.has(data, 'courses') ? data.courses : {}
        this._data.times = _.has(data, 'times') ? data.times : {}
        this._data.locations = _.has(data, 'locations') ? data.locations : {}
        this._data.confirmations = _.has(data, 'confirmations') ? data.confirmations : {}
    }

    get id() { return this._storageId }

    get version() { return "0.0.0" }

    get data() { return this._data }

    _undefined(msg) {
        return __undefined(msg)
    }

    _setL2(root, l1, l2, data) {
        if (!root[l1]) { root[l1] = {} }
        root[l1][l2] = data
    }

    _getL1(root, term) {
        return root[term]
    }

    _removeL2(root, l1, l2) {
        if (root[l1]) {
            let temp = root[l1]
            delete temp[l2]
        }
    }

    _removeL1(root, l1) {
        delete root[l1]
    }

    setConfirmations(confirmations) {
        return new Promise((resolve) => {
            setTimeout(() => {
                _.set(this._data, ['confirmations'], confirmations)
                resolve('ok')
            }, _mockDelayMillis)
        })
    }

    getConfirmations() {
        logger.debug('getConfirmations', this._data.confirmations)
        return new Promise((resolve) => {
            setTimeout(() => {
                logger.debug('getConfirmations', this._data.confirmations)
                resolve(this._data.confirmations)
            }, _mockDelayMillis)
        })
    }

    addCourse(term, slot, data) {
        if (!term) { return this._undefined('addCourse: term') }
        if (!slot) { return this._undefined('addCourse: slot') }
        if (!data) { return this._undefined('addCourse: data') }
        return new Promise((resolve) => {
            setTimeout(() => {
                this._setL2(this._data.courses, term, slot, data)
                logger.debug('add course', this._data)
                resolve('ok')
            }, _mockDelayMillis)
        })
    }

    removeCourse(term, slot) {
        if (!term) { return this._undefined('removeCourse: term') }
        if (!slot) { return this._undefined('removeCourse: slot') }
        return new Promise((resolve) => {
            setTimeout(() => {
                this._removeL2(this._data.courses, term, slot)
                logger.debug('removeCourse', term, slot)
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    removeTermCourses(term) {
        if (!term) { return this._undefined('removeTermCourses: term') }
        return new Promise((resolve) => {
            setTimeout(() => {
                this._removeL1(this._data.courses, term)
                logger.debug('removeTermCourses', term)
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    removeAllCourses() {
        return new Promise((resolve) => {
            setTimeout(() => {
                this._data.courses = {}
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    getTermCourses(term) {
        if (!term) { return this._undefined('getTermCourses: term') }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(this._getL1(this._data.courses, term))
            }, this._mockDelayMillis)
        })
    }

    getAllCourses() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(this._data.courses)
            }, _mockDelayMillis)
        })
    }

    addTime(term, id, data) {
        if (!term) { return this._undefined('addTime: term') }
        if (!id) { return this._undefined('addTime: id') }
        if (!data) { return this._undefined('addTime: data') }
        return new Promise((resolve) => {
            setTimeout(() => {
                this._setL2(this._data.times, term, id, data)
                resolve('ok')
            }, _mockDelayMillis)
        })
    }

    removeTime(term, id) {
        if (!term) { return this._undefined('removeTime: term') }
        if (!id) { return this._undefined('removeTime: id') }
        return new Promise((resolve) => {
            setTimeout(() => {
                this._removeL2(this._data.times, term, id)
                logger.debug('removeTime', term, id)
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    setTermTimes(term, times) {
        if (!term) { return this._undefined('setTermTimes: term') }
        return new Promise((resolve) => {
            setTimeout(() => {
                _.set(this._data.times, term, times)
                logger.debug('setTermTimes', term, times)
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    setTermLocations(term, locations) {
        if (!term) { return this._undefined('setTermLocations: term') }
        return new Promise((resolve) => {
            setTimeout(() => {
                _.set(this._data.locations, term, locations)
                logger.debug('setTermLocations', term, locations)
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    removeTermTimes(term) {
        if (!term) { return this._undefined('removeTermTimes: term') }
        return new Promise((resolve) => {
            setTimeout(() => {
                this._removeL1(this._data.times, term)
                logger.debug('removeTermTimes', term)
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    removeAllTimes() {
        return new Promise((resolve) => {
            setTimeout(() => {
                this._data.times = {}
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    getTermTimes(term) {
        if (!term) { return this._undefined('getTermTimes: term') }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(this._getL1(this._data.times, term))
            }, this._mockDelayMillis)
        })
    }

    getAllTimes() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(this._data.times)
            }, _mockDelayMillis)
        })
    }

    addLocation(term, locationId, data) {
        if (!term) { return this._undefined('addLocation: term') }
        if (!locationId) { return this._undefined('addLocation: id') }
        if (!data) { return this._undefined('addLocation: data') }
        return new Promise((resolve) => {
            setTimeout(() => {
                this._setL2(this._data.locations, term, locationId, data)
                resolve('ok')
            }, _mockDelayMillis)
        })
    }

    removeLocation(term, locationId) {
        if (!term) { return this._undefined('removeLocation: term') }
        if (!locationId) { return this._undefined('removeLocation: id') }
        return new Promise((resolve) => {
            setTimeout(() => {
                this._removeL2(this._data.locations, term, locationId)
                logger.debug('removeLocation', term, locationId)
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    removeTermLocations(term) {
        if (!term) { return this._undefined('removeTermLocations: term') }
        return new Promise((resolve) => {
            setTimeout(() => {
                this._removeL1(this._data.locations, term)
                logger.debug('removeTermLocations', term)
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    removeAllLocations() {
        return new Promise((resolve) => {
            setTimeout(() => {
                this._data.locations = {}
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    getTermLocations(term) {
        if (!term) { return this._undefined('getTermLocations: term') }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(this._getL1(this._data.locations, term))
            }, this._mockDelayMillis)
        })
    }

    getAllLocations() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(this._data.locations)
            }, _mockDelayMillis)
        })
    }

    removeAll() {
        return new Promise((resolve) => {
            setTimeout(() => {
                this._data.courses = {}
                this._data.times = {}
                this._data.locations = {}
                this._data.confirmations = {}
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

    setAll(data) {
        logger.debug('setAll')
        return new Promise((resolve) => {
            setTimeout(() => {
                logger.debug('setAll, courses', _.get(data, ['courses']) )
                this._data.courses = _.get(data, ['courses']) || {}
                this._data.times = _.get(data, ['times']) || {}
                this._data.locations = _.get(data, ['locations']) || {}
                this._data.confirmations = _.get(data, ['confirmations']) || {}
                resolve('ok')
            }, this._mockDelayMillis)
        })
    }

}

function getReservationStorage(storageId) {
    logger.debug('getReservationStorage', storageId)
    return new Promise((resolve) => {
        logger.debug('getReservationStorage: ' + storageId)
        setTimeout(() => {
            let data = reservationLocalStorage.fetch(storageId)
            logger.debug('getReservationStorage: ', data)
            resolve(new Reservations(storageId, data))
        }, _mockDelayMillis)
    })
}

function saveReservationStorage(reservations) {
    logger.debug('saveReservationStorage', reservations.id)
    return new Promise((resolve) => {
        setTimeout(() => {
            reservationLocalStorage.save(reservations.id, reservations.data)
            resolve('ok')
        }, _mockDelayMillis)
    })
}

/**
 * counts collection elements that are not null or undefined
 * @param {c} collection 
 */
function countDefined(collection) {
    let r = _.reduce(
        _.map(
            collection, (v) => (null === v || undefined === v) ? 0 : 1
        ), (a, b) => a + b
    )
    return r ? r : 0
}

/**
 * counts items on the 2nd level of hierachy
 * @param {*} data  root of the data
 * @param {*} key   to filter objects from the 1st level 
 */
function countItems(data, key) {
    return _.reduce(_.map(data, (vl1, kl1) => {
        return (undefined == key || key == kl1) ?
            countDefined(vl1)
            : 0
    }), (a, b) => a + b)
}

// helper
function saveUserTimes(storageName, term, times) {
    return getReservationStorage(storageName)
        .then(reservationStorage => {
            logger.debug('saving', term, times)
            reservationStorage.setTermTimes(term, times)
                .then(() => {
                    saveReservationStorage(reservationStorage)
                })
        })
        .catch(err => logger.error(err))
}

// helper
function saveUserLocations(storageName, term, locations) {
    return getReservationStorage(storageName)
        .then(reservationStorage => {
            logger.debug('saving', term, locations)
            reservationStorage.setTermLocations(term, locations)
                .then(() => {
                    saveReservationStorage(reservationStorage)
                })
        })
        .catch(err => logger.error(err))
}

// helper
function saveUserCourse(storageName, term, slot, course) {
    return getReservationStorage(storageName)
        .then(reservationStorage => {
            logger.debug('adding', term, slot, course)
            reservationStorage.addCourse(term, slot, course)
                .then(() => {
                    saveReservationStorage(reservationStorage)
                })
        })
        .catch(err => logger.error(err))
}
// helper
function saveUserConfirmations(storageName, confirmations) {
    return getReservationStorage(storageName)
        .then(reservationStorage => {
            logger.debug('saving confirmations', confirmations)
            reservationStorage.setConfirmations(confirmations)
                .then(() => {
                    saveReservationStorage(reservationStorage)
                })
        })
        .catch(err => logger.error(err))
}

//helper
function deleteUserCourse(storageName, term, slot) {
    return getReservationStorage(storageName)
        .then(reservationStorage => {
            reservationStorage.removeCourse(term, slot)
                .then(() => {
                    saveReservationStorage(reservationStorage)
                })
        })
        .catch(err => logger.error(err))
}

//helper 
function postReservations(storageName, localKey, confirmations) {
    logger.debug('postReservations, storage:', storageName)
    return getReservationStorage(storageName).then(
        reservationStorage => {
            const data = _.cloneDeep(reservationStorage._data)
            data['localKey'] = localKey
            data['confirmations'] = confirmations
            const url = apiRoot() + '/write'
            axios.post(url, data)
                .then(() => { logger.debug('postReservations, ok', data, localKey) })
                .catch(err => logger.error(err))
        }
    )
        .catch(err => logger.error(err))
}

export {
    // encode,
    // decode,
    getReservationStorage,
    saveReservationStorage,
    countItems,
    saveUserCourse,
    deleteUserCourse,
    saveUserTimes,
    saveUserLocations,
    saveUserConfirmations,
    postReservations,
}