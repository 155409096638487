<template>
  <div class="d-flex">
    <div class="d-flex flex-column justify-start">
    <div class="banner-box">
      <div class="banner-text pa-9">
        <div class="py-3">Sinclair College needs your input.</div>
        <div>
          Help ensure the courses offered are
        </div>
        <div>
          what you want, how you want to
        </div>
        <div>
          teach them.
        </div>
      </div>
    </div>
    <div class="text0 px-6 pt-4">
      Easily provide your input on:
    </div>
    <v-container class="list pa-0">
      <v-row class="pa-0" v-for="bullet in bullets" :key="bullet">
        <v-col cols="1">
        </v-col>
        <v-col cols="2">
          <check-bullet class="custom-bullet" />
        </v-col>
        <v-col>
          {{ bullet }}
        </v-col>
      </v-row>
    </v-container>
    </div>
  </div>
</template>

<style scoped>
.banner-box {
  /* width: 329px; */
  /* height: 192px; */
  /* left: 23px; */
  /* top: 160px; */
  background: #e0e0e0;
}
.banner-text {
  /* State University needs your input. Help ensure the courses offered are what you want, how you want to teach them. */

  /* position: absolute; */
  /* width: 288px; */
  /* height: 99px; */
  /* left: 43px; */
  /* top: 202px; */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  /* Gray 1 */
  color: #333333;
}
.text0 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 41px;
  height: 50px;
}
.list {
  /* What you want to teach What modalites you prefer When classes should be offered */

  /* width: 261px; */
  /* height: 182px; */
  /* left: 97px; */
  /* top: 423px; */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  /* Gray 1 */
  color: #333333;
}

.custom-bullet {
  height: 20px;
}
</style>
<script>
import CheckBullet from "./icons/CheckBullet.vue";
export default {
  name: "IntroContentForInstructor",
  components: {
    CheckBullet,
  },
  data: () => {
    return {
      bullets: [
        "What you want to teach",
        "What modalites you prefer",
        "When classes should be offered",
      ],
    };
  },
};
</script>
