<template>
  <div id="term-review">
    <PageBanner
      :bannerText="(appMode == 'instructor')?'Review your selection':'Review your reservation request'"
      :confirmation="getTermConfirmation()"
    />
    <TermCoursesSlots :readOnly="true" :appMode="appMode" />
    <TermCoursesLocations :readOnly="true" v-if="available('TermLocations')" />
    <TermCoursesTimes :readOnly="true" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { scrollElementIntoView } from "@/utils";
import { skipPage } from "@/api/config";
import PageBanner from "@/components/PageBanner";
import TermCoursesSlots from "@/components/TermCoursesSlots";
import TermCoursesLocations from "@/components/TermCoursesLocations";
import TermCoursesTimes from "@/components/TermCoursesTimes";
import _ from "lodash";

// import logdown from "logdown";
// const logger = logdown("TermReview");
// logger.state.isEnabled = true;

export default {
  name: "TermReview",
  components: {
    PageBanner,
    TermCoursesSlots,
    TermCoursesLocations,
    TermCoursesTimes,
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      config: "getConfig",
      appMode: "getAppMode",
      userConfirmations: "getUserConfirmations",
    }),
  },
  methods: {
    getTermConfirmation() {
      return _.get(this.userConfirmations, [this.selectedTerm.id]);
    },
    available: function(page) {
      return !skipPage(this.config, page);
    },
  },
  mounted: function() {
    let el = document.getElementById("term-review");
    if (el) {
      //el.scrollIntoView(true);
      scrollElementIntoView(el);
    }
  },
};
</script>

<style scoped>
/* #parent {
  border: solid 5px navy;
  position: fixed;
  width: 700px;
  height: 100%;
  overflow: scroll;
}
#term-review-box {
  border: solid 5px salmon;
  height: inherit;
  display: flex;
  flex-flow: column;
}
#term-review {
  border: solid 5px olivedrab;
  height: 100%;
} */
</style>
